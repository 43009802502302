import { FC, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// kendo
import { ListView, Popup } from '@progress/kendo-react-all';
import { ClickOutside } from '@components';
// components
import CustomerAdminItemView from '../CustomerAdminItemView';
import NavTab from './NavTab';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import useAccountDetails from '../../useAccountDetails';
// interfaces
import { AccountTabRoutes } from '../../interfaces';
// style
import styles from '../../AccountDetail.module.scss';

const routes = [
  AccountTabRoutes.PaymentHistory,
  AccountTabRoutes.LeaseAmendment,
  AccountTabRoutes.Gps,
  AccountTabRoutes.OdometerTracking,
  AccountTabRoutes.CpiRefund,
  AccountTabRoutes.PrincipalPayment,
  AccountTabRoutes.SavedPaymentMethods,
  AccountTabRoutes.Cbr,
  AccountTabRoutes.LegalAccount,
  AccountTabRoutes.ChargeOff,
  AccountTabRoutes.ReactivateChargeOff,
  AccountTabRoutes.PaymentReversal,
  AccountTabRoutes.CustomFields,
];

/** ###
 * @todo convert to mui <Tabs> component
 */
const NavTabs: FC = () => {
  const manageAnchorRef = useRef(null);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { customerAdminList } = useAccountDetails();
  // local state
  const customerAdminListOpen = useAccountSelector((s) => s.customerAdminListOpen);

  const manageActive = routes.find((path) => pathname.endsWith(path)) || customerAdminListOpen;

  return (
    <>
      <div className={styles.tabs}>
        {/* @todo convert to mui <Tabs> component */}
        <NavTab end to={AccountTabRoutes.Activity} name="Activity" />
        <NavTab to={AccountTabRoutes.Contact} name="Contact" />
        <NavTab to={AccountTabRoutes.Employer} name="Employer" />
        <NavTab to={AccountTabRoutes.Insurance} name="Insurance" />
        <NavTab to={AccountTabRoutes.References} name="References" />
        <NavTab to={AccountTabRoutes.AccountInfo} name="Account Info" />
        <div
          ref={manageAnchorRef}
          className={manageActive ? styles.tabActive : styles.tab}
          onClick={() => dispatch(accountActions.setCustomerAdminListOpen(!customerAdminListOpen))}
        >
          Manage
        </div>
      </div>
      <Popup
        anchor={manageAnchorRef.current}
        show={customerAdminListOpen}
        popupAlign={{ vertical: 'top', horizontal: 'right' }}
        anchorAlign={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <ClickOutside
          onClick={() => dispatch(accountActions.setCustomerAdminListOpen(false))}
          exceptionRef={manageAnchorRef}
        >
          <div className={styles.customerAdminContainer}>
            <ListView data={customerAdminList} item={CustomerAdminItemView} />
          </div>
        </ClickOutside>
      </Popup>
    </>
  );
};

/** ###  */
const Header: FC<{ title: string; hasTabs: boolean }> = ({ title, hasTabs }) => {
  return (
    <div className={styles.accountDetailsHeader}>
      <div className={styles.contents}>
        <div className={styles.title}>{title}</div>
        {hasTabs && <NavTabs />}
      </div>
    </div>
  );
};

export default Header;
