import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
// kendo
import { Button } from '@/components';
// components
import PaymentsTable from './PaymentsTable';
// state
import { useSalesViewCtx } from '@/features/Sales/SalesViewProvider';
import { useWsPmtsViewCtx } from './WsPmtsViewProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';

const DisplayItem: FC<{ label: string; value?: string }> = ({ label, value }) => {
  const isLoading = useSalesViewCtx((s) => s.isLoading);

  return (
    <Grid container direction="row" columnGap={0.5}>
      <Typography fontWeight={700} fontSize={16} lineHeight="24px">
        {label}:
      </Typography>
      <Typography fontWeight={400} fontSize={16} lineHeight="24px">
        {isLoading ? '-' : value}
      </Typography>
    </Grid>
  );
};

/** ###  */
const PaymentsSummary: FC = () => {
  const navigate = useNavigate();

  const tOfPBal = useWsPmtsViewCtx((s) => s.paymentData?.tOfPBal || 0);
  const salesPrice = useWsPmtsViewCtx((s) => s.saleData?.sale?.salesPrice || 0);
  const paymentRows = useWsPmtsViewCtx((s) => s.paymentRows);

  const paidAmt = salesPrice - tOfPBal;
  const isDisabled = tOfPBal === 0;

  // Event handlers
  const handleNavCollect = () => navigate(`collect`, { relative: 'route' });

  return (
    <Grid container direction="column" gap={1} wrap="nowrap" pb={1}>
      <DisplayItem label="Wholesale Payment" value={formatCurrency(salesPrice)} />
      <DisplayItem label="Paid" value={formatCurrency(paidAmt)} />
      <DisplayItem label="Balance Due" value={formatCurrency(tOfPBal)} />

      <Button
        label="Collect"
        disabled={isDisabled}
        onClick={handleNavCollect}
        style={{ maxWidth: 140 }}
      />

      <PaymentsTable rows={paymentRows} />
    </Grid>
  );
};

export default PaymentsSummary;
