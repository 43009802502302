import { ChangeEvent, FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import TextField from '@/mui/components/form/MuiKendoTextField';
import MuiKendoAccountingField from '@/mui/components/form/MuiKendoAccountingField';
import MuiKendoNumericField from '@/mui/components/form/MuiKendoNumericField';
// components
import WsFormSection from '../layoutComponents/WsFormSection';
import FormGroupHeader from './FormGroupHeader';
import SamList from './SamList';
import SalespersonField from './SalespersonField';
import VehicleSelectTable from './VehicleSelect';
import ListSelectorLink from './ListSelectorLink';
// state
import { useWholesaleFormCtx } from '../WholesaleFormProvider';
// utils
import { formatDate } from '@/utils/helpers/general';
import { formFieldStyle } from './default';

/** ###  */
const GeneralInfoForm: FC = () => {
  // form view state
  const wsFormRes = useWholesaleFormCtx((s) => s.wsFormRes!);
  const wsForm = useWholesaleFormCtx((s) => s.wsForm!);
  const wsFormErrors = useWholesaleFormCtx((s) => s.wsFormErrors);
  const wsFormVehicle = useWholesaleFormCtx((s) => s.wsFormVehicle);
  const setWsFormFieldError = useWholesaleFormCtx((s) => s.setWsFormFieldError);
  const setWsFormField = useWholesaleFormCtx((s) => s.setWsFormField);
  const setIsModalOpen = useWholesaleFormCtx((s) => s.setIsModalOpen);

  // Event handlers
  const handleChangeMilesOut = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newMilesOutStr = e.target.value;
    setWsFormField('milesOut', newMilesOutStr);

    const milesOutNum = Number(newMilesOutStr);
    const milesInNum = Number(wsFormVehicle?.milesIn || 0);

    const isMilesOutNum = newMilesOutStr === '' ? false : !isNaN(milesOutNum);
    if (isMilesOutNum) {
      const milesOutNum = Number(newMilesOutStr);

      // Error case: `milesIn` must be <= `milesOut`
      if (milesInNum > milesOutNum) {
        setWsFormFieldError('milesOut', 'Miles Out must be greater than Miles In.');
      } else {
        setWsFormFieldError('milesOut', '');
      }
    }
    // Error case: `milesOut` must be a number
    else {
      setWsFormFieldError('milesOut', 'Miles Out must be a number.');
    }
  };
  const handleChangeSalePrice = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newSalePriceStr = e.target.value;
    const parsedSalePriceStr = newSalePriceStr
      .replaceAll(',', '')
      .replaceAll('$', '')
      .replaceAll(/\s*/g, '');

    setWsFormField('salesPrice', parsedSalePriceStr);

    const isValid = !isNaN(Number(parsedSalePriceStr)) && Number(parsedSalePriceStr) > 0;
    if (isValid) {
      setWsFormFieldError('salesPrice', '');
    } else {
      setWsFormFieldError('salesPrice', 'Invalid sale price. Must be greater than $0.00');
    }
  };

  return (
    <WsFormSection>
      <FormGroupHeader title="General Information" />
      <Grid
        container
        direction="row"
        flex={1}
        columnGap={1}
        alignItems="start"
        justifyContent="start"
      >
        {/* Column 1: Info */}
        <Grid
          container
          direction="column"
          flex={1}
          alignItems="start"
          justifyContent="start"
          height="100%"
          p={1}
        >
          <TextField
            label="Sale Date"
            value={wsFormRes ? formatDate(wsFormRes.saleDate) : formatDate(new Date())}
            disabled
            sx={formFieldStyle}
          />
          <TextField label="Sale Status" value={wsForm.saleStatus} disabled sx={formFieldStyle} />
          <SalespersonField />

          <MuiKendoAccountingField
            label="Sale Price"
            placeholder="-"
            value={wsForm.salesPrice}
            onChange={handleChangeSalePrice}
            errorText={wsFormErrors.salesPrice.msg}
            disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
            sx={formFieldStyle}
          />

          <TextField
            label="Stock Number"
            placeholder="-"
            value={wsFormVehicle?.stockNum || ''}
            disabled
            sx={formFieldStyle}
          />
          <TextField
            label="Vehicle"
            placeholder="None Selected"
            value={wsFormVehicle?.ymm || ''}
            disabled
            helperComponent={
              wsForm.saleStatus === 'Pending' && (
                <ListSelectorLink
                  setIsModalOpen={setIsModalOpen}
                  text="+ Select Vehicle from list"
                />
              )
            }
            sx={formFieldStyle}
          />

          <VehicleSelectTable />

          <TextField
            label="VIN"
            placeholder="-"
            value={wsFormVehicle?.vin || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoNumericField
            label="Miles In"
            placeholder="-"
            value={wsFormVehicle?.milesIn || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoNumericField
            label="Miles Out"
            placeholder="-"
            value={wsForm.milesOut}
            onChange={handleChangeMilesOut}
            errorText={wsFormErrors.milesOut.msg}
            disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
            sx={formFieldStyle}
          />

          <Grid container direction="row">
            <FormControlLabel
              label="Not Actual"
              control={
                <Checkbox
                  checked={wsForm.notActual}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setWsFormField('notActual', event.target.checked)
                  }
                  disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
                />
              }
              labelPlacement="end"
            />
            <FormControlLabel
              label="Exceeds Limit"
              control={
                <Checkbox
                  checked={wsForm.exceedsLim}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setWsFormField('exceedsLim', event.target.checked)
                  }
                  disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
                />
              }
              labelPlacement="end"
            />
            <FormControlLabel
              label="Exempt"
              control={
                <Checkbox
                  checked={wsForm.exempt}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setWsFormField('exempt', event.target.checked)
                  }
                  disabled={wsForm.saleStatus?.toLowerCase() === 'posted'}
                />
              }
              labelPlacement="end"
            />
          </Grid>
        </Grid>

        <Divider orientation="vertical" />

        <SamList />
      </Grid>
    </WsFormSection>
  );
};

export default GeneralInfoForm;
