import { Modal } from '@/components/lightModal/LightModal';
import styles from './emailModal.module.scss';
import { Icons } from '@/components/icons';
import { DropdownInput, TextInput } from '@/components/inputs';
import EmailEditor from '@/components/emailEditor/EmailEditor';
import { DynamicData, EmailTemplate, collectionsService } from '@/services/collectionsService';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAccountSelector } from '@/features/Accounts/accountSlice';

const EmailModal: FC<{
  colRecId: number;
  open: boolean;
  initEmailData: () => void;
  onClose: () => void;
  canEmailCoBuyer: boolean;
  canEmailBuyer: boolean;
}> = ({ colRecId, open, onClose, initEmailData, canEmailBuyer, canEmailCoBuyer }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const [emails, setEmails] = useState<string[]>([]);
  const { compId } = useAuthSelector((state) => state);
  const [dynamicData, setDynamicData] = useState<DynamicData[]>([]);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate[]>([]);
  const [emailSubject, setEmailSubject] = useState('');

  const [errors, setErrors] = useState<{ input: string; message: string }[]>([]);

  const initData = async () => {
    const getDynamicData = await collectionsService.getDynamicData();
    setDynamicData(getDynamicData);

    const getEmailTemplates = await collectionsService.getEmailTemplates(compId!);
    setEmailTemplate(getEmailTemplates.filter((t) => t.category === 'Collections'));

    setEmails([accountInformation.buyer.email]);
    if (accountInformation.coBuyer.email) {
      setEmails((prevEmails) => [...prevEmails, accountInformation.coBuyer.email]);
    }
  };

  const sendEmail = (emailSubject: string, emailMessage: string) => {
    if (emailSubject === '') {
      setErrors([{ input: 'subject', message: 'Email subject is required' }]);
      return;
    } else {
      collectionsService
        .sendEmail(colRecId, emailSubject, emailMessage)
        .then(
          () => {
            toast.success('Email messages sent successfully');
            initEmailData();
          },
          (e) => {
            toast.error(e.response.data.message);
          }
        )
        .finally(() => {
          closeEmail();
        });
    }
  };

  const removeErrors = () => {
    if (errors.length > 0) {
      setErrors([]);
    }
  };

  const closeEmail = () => {
    setEmailSubject('');
    setErrors([]);
    onClose();
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Modal open={open} closeModal={() => onClose()}>
      <div className={styles.emailModalContainer}>
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={() => onClose()}>
            <Icons.X />
          </button>
        </div>
        <div className={styles.title}>Email</div>

        <div className={styles.inputRow}>
          <label className={styles.label}>To:</label>
          <div className={styles.input}>
            <DropdownInput
              value={emails.length > 1 ? (canEmailBuyer ? emails[0] : emails[1]) : emails[0]}
              data={emails}
              disabled={!canEmailBuyer || !canEmailCoBuyer}
            />
            {(!canEmailBuyer || !canEmailCoBuyer) && (
              <small className={styles.disclaimer}>{`${
                canEmailBuyer ? 'Co-buyer' : 'Buyer'
              } has do not email preference on`}</small>
            )}
          </div>
        </div>
        <div className={styles.inputRow}>
          <label className={styles.label}>Subject:</label>
          <div className={styles.input}>
            <TextInput
              onBlur={(e) => setEmailSubject(e.currentTarget.value)}
              errors={errors.find((e) => e.input === 'subject')?.message}
              onChange={removeErrors}
            />
          </div>
        </div>

        <EmailEditor
          emailSubject={emailSubject}
          dynamicData={dynamicData}
          emailTemplate={emailTemplate}
          sendEmail={sendEmail}
          closeEmail={closeEmail}
        />
      </div>
    </Modal>
  );
};

export default EmailModal;
