import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
// state
import { useAccountSelector } from '../../accountSlice';
import { useGpsCtx } from './components/GpsView/GpsProvider';
// utils
import useCtxFactory from '@/utils/ctxState/useCtxFactory';
import { loadGpsLastLocate } from './components/GpsView/requests';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { OptInData, OptStatusKey } from '../interfaces';

const useCtxState = () => {
  const colRecId = getRouteParamNum(useParams().colRecId);
  const invRecId = getRouteParamNum(useParams().invRecId);
  const root = useMatch({ path: '/:root/*' })?.params.root;
  const isInventory = root === 'inventory';

  const gpsLastLocateLoading = useGpsCtx((s) => s.gpsLastLocateLoading);
  const setGpsLastLocateLoading = useGpsCtx((s) => s.setGpsLastLocateLoading);
  const loadGpsInfo = useGpsCtx((s) => s.loadGpsInfo);

  const setGpsLastLocation = useGpsCtx((s) => s.setGpsLastLocation);
  const gpsInformation = useGpsCtx((s) => s.gpsInformation);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const vehRecId = isInventory ? invRecId : accountInformation?.vehRecId;

  const [optIn, setOptIn] = useState<OptInData>(OptInData.new(null));
  const [optStatus, setOptStatus] = useState<OptStatusKey>('unsent');
  const [optInAuthorization, setOptInAuthorization] = useState(false);

  useEffect(() => {
    if (vehRecId && gpsInformation) {
      loadGpsLastLocate(vehRecId, gpsInformation, setGpsLastLocateLoading, setGpsLastLocation);
    }
  }, [vehRecId]);

  useEffect(() => {
    colRecId && loadGpsInfo(colRecId, 'colRecId');
  }, [colRecId]);

  return {
    gpsLastLocateLoading,

    optIn,
    setOptIn,

    optStatus,
    setOptStatus,

    optInAuthorization,
    setOptInAuthorization,
  };
};

export type IAcctDetailCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<IAcctDetailCtx | null>(null);
export const useAcctDetailsCtx = useCtxFactory(Ctx);
const AcctDetailsProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default AcctDetailsProvider;
