import {  Button, Checkbox, DropdownInput, Modal, NumberInput, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './Gps.module.scss';
import { systemService } from '@/services/systemService';
import { toast } from 'react-toastify';

interface PasstimeWarnFormProps {
  onClose: () => void;
}

const passtimeToneTypes = [
  { value: 1, displayValue: '1 - Standard' },
  { value: 2, displayValue: '2 - Fast' },
  { value: 3, displayValue: '3 - Very Fast' },
];

export const PasstimeWarnForm = (props: PasstimeWarnFormProps) => {
  const { onClose } = props;
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const [warnSubmitting, setWarnSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      inventoryRecId: accountInformation.vehRecId!,
      enableWarn: false,
      toneType: passtimeToneTypes[0],
      warnDays: 2,
    },
  });

  const warnSubmit = (data: any) => {
    setWarnSubmitting(true);

    const payload = {
      inventoryRecId: data.inventoryRecId,
      enableWarn: data.enableWarn,
      toneType: data.toneType.value,
      warnDays: data.warnDays,
    };

    systemService
      .sendPasstimeWarningNoDisable(payload)
      .then(() => {
        toast.success('Warning sent');
      })
      .finally(() => {
        setWarnSubmitting(false);
        onClose();
      });
  };

  const warnEnabled = watch('enableWarn');

  return (
    <Modal centerModal isOpen closeButton onCloseButtonClick={onClose} onDimmerClick={onClose}>
      <>
        <h2>Send warning (no disable)</h2>
        <Spacer size={20} />
        <form onSubmit={handleSubmit(warnSubmit)} className={styles.form}>
          <Controller
            name="enableWarn"
            control={control}
            render={({ field }) => <Checkbox label="Enable Warning" {...field} />}
          />
          {warnEnabled && (
            <>
              <Controller
                name="toneType"
                control={control}
                rules={{ required: warnEnabled }}
                render={({ field }) => (
                  <DropdownInput
                    label="Tone"
                    required={warnEnabled}
                    data={passtimeToneTypes}
                    dataItemKey="value"
                    textField="displayValue"
                    errors={!!errors.toneType}
                    {...field}
                  />
                )}
              />
              <Controller
                name="warnDays"
                control={control}
                rules={{ required: warnEnabled, min: 2, max: 49 }}
                render={({ field }) => (
                  <NumberInput
                    label="# of days (2-49)"
                    required={warnEnabled}
                    min={2}
                    max={49}
                    errors={!!errors.warnDays}
                    {...field}
                  />
                )}
              />
            </>
          )}
          <Button
            label="Submit"
            loading={warnSubmitting}
            fullWidth={false}
            style={{ float: 'right' }}
          />
        </form>
      </>
    </Modal>
  );
};
