import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Button from '@/mui/components/MuiKendoButton';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

/** ###  */
const WsPaymentsHeaderButtonGroup: FC = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="row" gap={3.5}>
      <Button
        sx={{ px: 6, backgroundColor: DCS_BLUE }}
        // cannot use navUp here - `payments` would need its own <Routes /> impl
        onClick={() => navigate('./')}
      >
        Wholesale Management
      </Button>
      {/* Add other buttons here... */}
    </Grid>
  );
};

export default WsPaymentsHeaderButtonGroup;
