import CreditEducation from '@/components/creditEducation/CreditEducation';
import salesTabStyles from '../SalesTab.module.scss';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { FC, useEffect, useState } from 'react';
import { CreditEducationDBInfo, CreditEducationInfo } from '@/interfaces/CreditEducation';
import { Loader } from '@progress/kendo-react-all';
import { applicationService } from '@/services/applicationService';
import { useParams } from 'react-router-dom';
import NavTabs from './NavTabs';

type Props = {
  isCobuyer: boolean;
};

const CreditEducationDetail: FC<Props> = ({ isCobuyer }) => {
  const params = useParams();
  const appRecId = Number(params.id);
  const { saleData, hasCobuyer } = useSalesSelector((s) => s);
  const [saleDataLoading, setSaleDataLoading] = useState(false);
  const [creditEducationInfo, setCreditEducationInfo] = useState<CreditEducationInfo | null>(null);
  const [creditEducationDBInfo, setCreditEducationDBInfo] = useState<CreditEducationDBInfo | null>(
    null
  );

  useEffect(() => {
    if (
      saleData == null ||
      (!isCobuyer && saleData.buyer == null) ||
      (isCobuyer && !hasCobuyer) ||
      (isCobuyer && hasCobuyer && saleData.coBuyer == null)
    ) {
      return;
    }

    const fetchData = async (appRecId: number) => {
      setSaleDataLoading(true);
      const getCreditEducationRes = await applicationService.getCreditEducationInfo(appRecId);

      if (!getCreditEducationRes)
        throw new Error(`No credit education information found for appRecId: ${appRecId}`);

      setCreditEducationDBInfo(getCreditEducationRes);

      const buyerInfo =
        hasCobuyer && isCobuyer ? getCreditEducationRes?.coBuyer : getCreditEducationRes?.buyer;
      const buyerCreditInfo =
        hasCobuyer && isCobuyer
          ? getCreditEducationRes?.coBuyerCreditEducation
          : getCreditEducationRes?.buyerCreditEducation;
      const creditInfo = {
        firstName: buyerInfo?.b_FIRST,
        middleName: buyerInfo?.b_MID,
        lastName: buyerInfo?.b_LAST,
        dateOfBirth: buyerInfo?.b_DOB,
        SSNLast4: buyerInfo?.b_SSN!.slice(-4),
        phoneNumber: buyerInfo?.b_CPHONE,
        homePhoneNumber: buyerInfo?.b_HPHONE,
        workPhoneNumber: buyerInfo?.b_LLPHONE,
        faxNumber: buyerInfo?.b_LLFAX,
        email: buyerInfo?.b_EMAIL,
        currentAddress: buyerInfo?.b_ADDR,
        currentCity: buyerInfo?.b_CITY,
        currentState: buyerInfo?.b_STATE,
        currentZipCode: buyerInfo?.b_ZIP,
        appBuyersRecID: buyerInfo?.rec_Id!.toString(),
        creditRepairCloudID: buyerCreditInfo?.creditRepairCloudID,
        trackingToken: buyerCreditInfo?.smartCreditTrackingToken,
        customerToken: buyerCreditInfo?.smartCreditCustomerToken,
      } as CreditEducationInfo;
      setCreditEducationInfo(creditInfo);

      setSaleDataLoading(false);
    };

    fetchData(appRecId).catch(console.error);
  }, [saleData]);

  useEffect(() => {
    const buyerInfo =
      hasCobuyer && isCobuyer ? creditEducationDBInfo?.coBuyer : creditEducationDBInfo?.buyer;
    const buyerCreditInfo =
      hasCobuyer && isCobuyer
        ? creditEducationDBInfo?.coBuyerCreditEducation
        : creditEducationDBInfo?.buyerCreditEducation;
    const creditInfo = {
      firstName: buyerInfo?.b_FIRST,
      middleName: buyerInfo?.b_MID,
      lastName: buyerInfo?.b_LAST,
      dateOfBirth: buyerInfo?.b_DOB,
      SSNLast4: buyerInfo?.b_SSN!.slice(-4),
      phoneNumber: buyerInfo?.b_CPHONE,
      homePhoneNumber: buyerInfo?.b_HPHONE,
      workPhoneNumber: buyerInfo?.b_LLPHONE,
      faxNumber: buyerInfo?.b_LLFAX,
      email: buyerInfo?.b_EMAIL,
      currentAddress: buyerInfo?.b_ADDR,
      currentCity: buyerInfo?.b_CITY,
      currentState: buyerInfo?.b_STATE,
      currentZipCode: buyerInfo?.b_ZIP,
      appBuyersRecID: buyerInfo?.rec_Id!.toString(),
      creditRepairCloudID: buyerCreditInfo?.creditRepairCloudID,
      trackingToken: buyerCreditInfo?.smartCreditTrackingToken,
      customerToken: buyerCreditInfo?.smartCreditCustomerToken,
    } as CreditEducationInfo;
    setCreditEducationInfo(creditInfo);
  }, [isCobuyer]);

  return (
    <>
      <div className={salesTabStyles.container}>
        <header className={salesTabStyles.headerContainer}>
          <h2 className={salesTabStyles.header}>Credit Education</h2>
          {hasCobuyer && <NavTabs />}
        </header>
        {saleDataLoading ? (
          <div className={salesTabStyles.loading}>
            <Loader size="large" />
          </div>
        ) : (
          <main className={salesTabStyles.body}>
            <CreditEducation formData={creditEducationInfo} />
          </main>
        )}
      </div>
    </>
  );
};

export default CreditEducationDetail;
