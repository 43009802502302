import { FC } from 'react';
// mui
import Typography from '@mui/material/Typography';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';

/** ###  */
const TextCell: FC<GridCellProps> = ({ dataItem, field }) => {
  const cellValue: string = dataItem[field!];

  // @note Temporary error handling - need to better accommodate for row-values that are intentionally 'nullish'
  if (cellValue === undefined) {
    throw Error(`<TextCell /> - Invalid field: "${field}" - dataItem: ${JSON.stringify(dataItem)}`);
  }

  const textFmt = cellValue === '' || cellValue === null ? '-' : cellValue;

  return (
    <td className="k-table-td" style={{ textWrap: 'nowrap', textOverflow: 'ellipsis' }}>
      <Typography component="span" variant="tableCell">
        {textFmt}
      </Typography>
    </td>
  );
};

export default TextCell;
