const filterFields = [
  'appRecId',
  'appStatus',
  'purPrice',
  'soldPrice',
  'stockNum',
  'totExp',
] as const;

export type ApplicationsFilterKey = (typeof filterFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const applicationsFilterFields: ApplicationsFilterKey[] = [...filterFields];

const sortFields = ['appRecId', 'purPrice', 'soldPrice', 'saleDate', 'stockNum', 'totExp'] as const;

export type ApplicationsSortKey = (typeof sortFields)[number];
// @note creating a mutable type helps us use the variable with the above generated type
export const applicationsSortFields: ApplicationsSortKey[] = [...sortFields];

const appStatusFilterOptions = ['Declined', 'Inactive', 'Pending'] as const;
export type AppStatusFilterKey = (typeof appStatusFilterOptions)[number];
export const appStatusFilterMap = new Map<AppStatusFilterKey, string>(
  appStatusFilterOptions.map((o) => [o, o])
);
