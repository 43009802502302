import { useCallback, useMemo } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';
// kendo
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modal/Modal';

/** keywords: navaway, navAway, preventNav, preventNavigate */
export const useNavigationConfirm = (shouldBlock: string | boolean) => {
  const blocker = useBlocker(
    // without useCallBack, this had issues in dev when the page got rerendered after saving
    // it was preventing navigation when not even on a page that uses `useNavigationConfirm` / `useBlocker`
    // https://github.com/remix-run/react-router/issues/10073#issuecomment-1614725142
    useCallback(
      ({ currentLocation, nextLocation }) => {
        // returning true prevents navigation and sets `blocker`'s state to `blocked`
        return currentLocation.pathname !== nextLocation.pathname && !!shouldBlock;
      },
      [shouldBlock]
    ) as BlockerFunction
  );

  const NavigationConfirm = useMemo(() => {
    if (blocker?.state !== 'blocked') return null;
    const reason =
      typeof shouldBlock === 'string'
        ? shouldBlock
        : 'This page may have unsaved data. Do you want to proceed without saving?';

    return (
      <Modal isOpen={true} centerModal title="Navigation blocked">
        <h3>{reason}</h3>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 50, justifyContent: 'center' }}>
          <Button label="Cancel" onClick={blocker.reset} />
          <Button label="Proceed" themeColor="warning" onClick={blocker.proceed} />
        </div>
      </Modal>
    );
  }, [blocker?.state]);

  return {
    NavigationConfirm,
    blocker, // this may never be needed, just including it in case a component that uses this needs a handle on the blocker state / methods
  };
};
