import MaskedInput from 'react-text-mask';
import { forwardRef } from 'react';
import { MaskedTextInput, MaskedTextInputProps } from '@components';

type PhoneInputProps = Omit<MaskedTextInputProps, 'mask'>;

export const PhoneInput = forwardRef<MaskedInput, PhoneInputProps>((props, ref) => (
  <MaskedTextInput
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholder="(000) 000-0000"
    ref={ref}
    {...props}
  />
));
