import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { Button } from '@components';
import { Icons } from '@/components/icons';
import { Grid, GridColumn, GridNoRecords, Loader } from '@progress/kendo-react-all';
import styles from './SavedPaymentMethods.module.scss';
import { FC, useEffect, useState } from 'react';
import { Column } from '@/components/table/TableInterface';
import { paymentService } from '@/services/paymentService';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { SavedPaymentMethod } from '@/interfaces';
import { toast } from 'react-toastify';

const SavedPaymentMethods: FC = () => {
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const [savedPayMethods, setSavedPayMethods] =
    useState<(SavedPaymentMethod & { select: boolean })[]>();
  const [loadingSavedPaymentMethods, setLoadingSavedPaymentMethods] = useState(true);

  const updateSavedPaymentList = () => {
    setLoadingSavedPaymentMethods(true);
    paymentService
      .getSavedPaymentMethods(appRecId!)
      .then((res) => {
        const paymentMethods = res.map((pm) => {
          return {
            ...pm,
            select: false,
          };
        });
        setSavedPayMethods(paymentMethods);
      })
      .finally(() => {
        setLoadingSavedPaymentMethods(false);
      });
  };

  const savedPaymentMethodsList: Column[] = [
    {
      field: 'acctType',
      title: 'Type',
    },
    {
      field: 'custName',
      title: 'Name',
    },
    {
      field: 'cardProcessor',
      title: 'Processor',
    },
    {
      field: 'last4',
      title: 'Last 4',
    },
    {
      field: 'reccuringStatus',
      title: 'Recurring',
      cell: (props) => {
        const isRecurring = props.dataItem.recurringStatus;
        return (
          <td>
            <div className={styles.tdCenter}>
              {isRecurring !== 'Active' ? undefined : <Icons.Checkmark />}
            </div>
          </td>
        );
      },
    },
    {
      field: '',
      title: 'Actions',
      cell: (props) => {
        const { mpdId, recurringStatus } = props.dataItem;
        const [isDeleting, setIsDeleting] = useState(false);
        const handleDelete = () => {
          if (recurringStatus === 'Active') {
            toast.error('Cannot delete payment method used for recurring payments');
            return;
          }
          setIsDeleting(true);
          paymentService
            .deleteSavedPaymentMethods(appRecId!, mpdId)
            .then(() => {
              updateSavedPaymentList();
            })
            .finally(() => {
              setIsDeleting(false);
            });
        };
        return (
          <td>
            <div className={styles.tdCenter}>
              <Button
                themeColor="error"
                loading={isDeleting}
                label="Delete"
                fullWidth={false}
                onClick={handleDelete}
              />
            </div>
          </td>
        );
      },
    },
  ];

  useEffect(() => {
    updateSavedPaymentList();
  }, []);

  return (
    <AccountsMainPanel navBarTitle="Saved Payment Methods">
      <div className={styles.container}>
        <Grid
          data={savedPayMethods}
          selectable={{ enabled: true, drag: false, cell: false, mode: 'multiple' }}
        >
          <GridNoRecords>
            {loadingSavedPaymentMethods ? <Loader size="large" /> : 'No payment methods found'}
          </GridNoRecords>
          {savedPaymentMethodsList.map((column, index) => (
            <GridColumn
              key={index}
              field={column.field}
              title={column.title}
              cell={column.cell}
              width={column.width}
              headerSelectionValue={undefined}
            />
          ))}
        </Grid>
      </div>
    </AccountsMainPanel>
  );
};

export default SavedPaymentMethods;
