import { FC } from 'react';
import { useMatch, resolvePath } from 'react-router-dom';
// mui
import LinkButton from '@/mui/components/LinkButton';

/** ### Button to toggle between User and Role views */
const UserMgmtViewToggleButton: FC = () => {
  const match = useMatch({ path: '/:root/:viewRoot/:currentView' });
  const currentView = match?.params.currentView;

  if (match === null) return <></>;

  const isUsersView = currentView === 'users';
  const newRoutePathEnd = isUsersView ? 'roles' : 'users';

  const buttonLabel = isUsersView ? 'Role Management' : 'User Management';

  const newPath = resolvePath({ pathname: `../${newRoutePathEnd}` }, match.pathname);

  return <LinkButton to={newPath}>Show {buttonLabel}</LinkButton>;
};

export default UserMgmtViewToggleButton;
