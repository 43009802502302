import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatPercentage } from '@/utils/helpers/general';

const DelinquencyRatioCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const delqAccts = useDashboardViewCtx((s) => s.dashboardData?.delinquency?.delinquentAccounts);
  const totalAccts = useDashboardViewCtx((s) => s.dashboardData?.delinquency?.totalAccounts);
  const isRatioNumeric =
    typeof delqAccts === 'number' && typeof totalAccts === 'number' && totalAccts > 0;

  const delinquencyRatio = isRatioNumeric ? formatPercentage(delqAccts / totalAccts) : '- %';
  const secondary = isRatioNumeric
    ? 'Total across all locations as of date'
    : 'Data unavailable for this range';

  return (
    <DashboardCardLayout
      header="Delinquency"
      primary={delinquencyRatio}
      secondary={isLoading ? '' : secondary}
      isLoading={isLoading}
    />
  );
};

export default DelinquencyRatioCard;
