import { useAuthSelector } from '@/features/auth/authSlice';
import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
import { SalesSubviewListKey } from '@/features/Sales/enums';
import { getDealData, getQuoteData, setDealData } from '@/features/Sales/salesActionCreator';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
import { useSalesViewCtx } from '@/features/Sales/SalesViewProvider';
import { DealData, QuoteData } from '@/interfaces';
import { salesService } from '@/services/salesService';
import { useAppDispatch } from '@/store/store';
import {
  calculateAdjCapCost,
  calculateLeasePayment,
  calculateFutureValue,
  calculateFinancePayment,
  calculateLeasePayoff,
} from '@/utils/helpers/sales';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

interface FormProps {
  fullName: string;
  phoneNumber: string;
  dateOfQuote: string;
  grossAmountFinanced: number;
  downPayment: number;
  netAmountFinanced: number;
  apr: number;
  term: number;
  daysToFirstPayment: number;
  afterNumberOfMonths: number;
  estimatedApr: number;
  leaseConversionTerm: number;
  monthlyPayments: number;
  leaseSellingPrice: number;
  leaseMmr: number;
  leaseDueAtInception: number;
  leaseTerm: number;
  leaseApr: number;
  leaseResidualRate: number;
  leaseResidual: number;
  leaseDownPayment: number;
  leaseTaxRate: number;
  moneyDueAtInceptionField1: number;
  moneyDueAtInceptionField2: number;
  moneyDueAtInceptionField3: number;
  securityDeposit: number;
  customField1: number;
  customField2: number;
  customField3: number;
  customField4: number;
  customField5: number;
  serviceContract: number;
  leaseAddedCapCost: number;
  financePayment: number;
  reFinancePayment: number;
  leasePayment: number;
}

/** @deprecated convert to context */
export const useComparisonTool = () => {
  const {
    leaseSellingPrice,
    leaseMmr,
    leaseDueAtInception,
    leaseApr,
    leaseTaxRate,
    leasePurchaseOption,
    leaseDownPayment,
    leaseQuoteName,
    leaseQuotePhoneNumber,
    leaseQuoteDate,
    leaseSecurityDeposit,
    leaseResidualRate,
    leaseServiceContract,
    leaseTerm,
    customField1,
    customField2,
    customField3,
    customField4,
    customField5,
    moneyDueAtInceptionField1,
    moneyDueAtInceptionField2,
    moneyDueAtInceptionField3,
    refinanceAfterNumMonths,
    refinanceEstimatedApr,
    refinanceTerm,
    financeApr,
    financeDownPayment,
    financeTerm,
    financeTotal,
  } = useSalesSelector((s) => s);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const setIsLoading = useSalesViewCtx((s) => s.setIsLoading);
  const isLoading = useSalesViewCtx((s) => s.isLoading);
  const { quoteData, dealData, setDealData, isQuoteInfoLoading } = useDealsQuotesDetailCtx(
    (s) => s
  );
  const [showGraphic, setShowGraphic] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [financeDownPaymentState, setFinanceDownPaymentState] = useState(0);
  const [financeMonthlyPayments, setFinanceMonthlyPayments] = useState(0);
  const [totalFinancePayments, setTotalFinancePayments] = useState(0);
  const [financeTermState, setFinanceTermState] = useState(0);
  const [refinanceAfterNumberOfMonths, setRefinanceAfterNumberOfMonths] = useState(0);
  const [dueAtInception, setDueAtInception] = useState(leaseDueAtInception);
  const [leaseMonthlyPayments, setLeaseMonthlyPayments] = useState(0);
  const [leaseTermState, setLeaseTermState] = useState(0);
  const [leaseResidual, setLeaseResidual] = useState(0);
  const [leaseResidualTax, setLeaseResidualTax] = useState(0);
  const [refinanceApr, setRefinanceApr] = useState(0);
  const [leaseConversionTerm, setLeaseConversionTerm] = useState(0);
  const [refinancePayment, setRefinancePayment] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [payoffAfterMonthsFinance, setPayoffAfterMonthsFinance] = useState(0);
  const [leasePayoff, setLeasePayoff] = useState(0);
  const [totalLeaseBuyout, setTotalLeaseBuyout] = useState(0);
  const [totalCostOfOwnershipFinance, setTotalCostOfOwnershipFinance] = useState(0);
  const [totalCostOfOwnershipRefinance, setTotalCostOfOwnershipRefinance] = useState(0);
  const [totalCostOfOwnershipLease, setTotalCostOfOwnershipLease] = useState(0);
  const [leaseBuyoutResidual, setLeaseBuyoutResidual] = useState(0);
  const [showFullLeaseOptions, setShowFullLeaseOptions] = useState(false);
  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [moneyDueAtInceptionField1Name, setMoneyDueAtInceptionField1Name] = useState('');
  const [moneyDueAtInceptionField2Name, setMoneyDueAtInceptionField2Name] = useState('');
  const [moneyDueAtInceptionField3Name, setMoneyDueAtInceptionField3Name] = useState('');
  const [leaseDoc, setLeaseDoc] = useState(0);
  const [leaseQuoteData, setLeaseQuoteData] = useState<QuoteData | undefined>(undefined);
  const [isQuoteDataLoading, setIsQuoteDataLoading] = useState(true);
  const [is30Days, setIs30Days] = useState(true);
  const [is45Days, setIs45Days] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm<FormProps>({
    defaultValues: {
      fullName: '',
      phoneNumber: '',
      dateOfQuote: dayjs().format('YYYY-MM-DD'),
      grossAmountFinanced: 0,
      downPayment: 0,
      netAmountFinanced: 0,
      apr: 0,
      term: 0,
      daysToFirstPayment: 30,
      afterNumberOfMonths: 0,
      estimatedApr: 0,
      leaseConversionTerm: 0,
      leaseSellingPrice: 0,
      leaseMmr: 0,
      leaseDueAtInception: 0,
      leaseTerm: 36,
      leaseApr: 0,
      leaseResidualRate: 0.35,
      leaseResidual: 0,
      leaseDownPayment: 0,
      leaseTaxRate: 0,
      moneyDueAtInceptionField1: 0,
      moneyDueAtInceptionField2: 0,
      moneyDueAtInceptionField3: 0,
      securityDeposit: 0,
      customField1: 0,
      customField2: 0,
      customField3: 0,
      customField4: 0,
      customField5: 0,
      serviceContract: 0,
      leaseAddedCapCost: 0,
      financePayment: 0,
      reFinancePayment: 0,
      leasePayment: 0,
    },
    mode: 'onChange',
  });

  const setGlobalState = (data: FormProps) => {
    dispatch(salesActions.setRefinanceAfterNumMonths(data.afterNumberOfMonths));
    dispatch(salesActions.setRefinanceEstimatedApr(data.estimatedApr));
    dispatch(salesActions.setRefinanceTerm(data.leaseConversionTerm));
    dispatch(salesActions.setLeaseSellingPrice(data.leaseSellingPrice));
    dispatch(salesActions.setLeaseMmr(data.leaseMmr));
    dispatch(salesActions.setLeaseDueAtInception(data.leaseDueAtInception));
    dispatch(salesActions.setLeaseApr(data.leaseApr));
    dispatch(salesActions.setLeaseTerm(data.leaseTerm));
    dispatch(salesActions.setLeaseResidualRate(data.leaseResidualRate));
    dispatch(salesActions.setFinanceTotal(data.grossAmountFinanced));
    dispatch(salesActions.setFinanceDownPayment(data.downPayment));
    dispatch(salesActions.setFinanceApr(data.apr));
    dispatch(salesActions.setFinanceTerm(data.term));
    dispatch(salesActions.setLeaseTaxRate(data.leaseTaxRate));
    dispatch(salesActions.setCustomField1(data.customField1));
    dispatch(salesActions.setCustomField2(data.customField2));
    dispatch(salesActions.setCustomField3(data.customField3));
    dispatch(salesActions.setCustomField4(data.customField4));
    dispatch(salesActions.setCustomField5(data.customField5));
    dispatch(salesActions.setMoneyDueAtInceptionField1(data.moneyDueAtInceptionField1));
    dispatch(salesActions.setMoneyDueAtInceptionField2(data.moneyDueAtInceptionField2));
    dispatch(salesActions.setMoneyDueAtInceptionField3(data.moneyDueAtInceptionField3));
    dispatch(salesActions.setLeaseServiceContract(data.serviceContract));
    dispatch(salesActions.setLeaseSecurityDeposit(data.securityDeposit));
  };

  useEffect(() => {
    if (!quoteData) return;

    const leaseData = quoteData.find((value) => value.stype === 'Lease Sale');
    setLeaseQuoteData(leaseData);
    const totalSalesTax = leaseData?.totalTaxRate || 0;

    reset({
      fullName: dealData?.customerName ?? '',
      phoneNumber: dealData?.customerPhone ?? '',
      dateOfQuote: dealData?.quoteDate
        ? dayjs(dealData?.quoteDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      grossAmountFinanced: dealData?.totalSalesPrice ?? financeTotal,
      downPayment: dealData?.downPayment ?? financeDownPayment,
      netAmountFinanced: dealData?.amountFinanced ?? 0,
      apr: dealData?.interestRate ?? financeApr,
      term: dealData?.term ?? financeTerm,
      daysToFirstPayment: dealData?.daysToFirstPayment ?? 30,
      afterNumberOfMonths: dealData?.afterNumberOfMonths ?? refinanceAfterNumMonths,
      estimatedApr: dealData?.estimatedApr ?? refinanceEstimatedApr,
      leaseConversionTerm: dealData?.leaseConversionTerm ?? refinanceTerm,
      leaseSellingPrice: dealData?.sellingPrice ?? leaseSellingPrice,
      leaseMmr: dealData?.mmr ?? leaseMmr,
      leaseDueAtInception:
        dealData?.moneyDueAtInception ??
        (leaseDueAtInception
          ? leaseDueAtInception
          : (leaseData?.pricE2 || 0) +
            (leaseData?.pricE3 || 0) +
            (leaseData?.pricE4 || 0) +
            (leaseData?.lease_SecurityDeposit || 0)),
      leaseTerm: dealData?.leaseTerm ?? (leaseTerm ? leaseTerm : dealData?.leaseTerm ?? 36),
      leaseApr:
        dealData?.aPR ?? (leaseApr ? leaseApr : leaseData?.def_APR ? leaseData!.def_APR! / 100 : 0),
      leaseResidualRate:
        dealData?.leaseResidualRate ?? (leaseResidualRate ? leaseResidualRate : 0.35),
      leaseResidual: dealData?.leaseResidual ?? 0,
      leaseDownPayment: dealData?.downPayment ?? leaseDownPayment,
      leaseTaxRate: dealData?.taxRate ?? (leaseTaxRate || totalSalesTax),
      moneyDueAtInceptionField1:
        dealData?.moneyDueAtInceptionField1 ??
        (moneyDueAtInceptionField1 !== undefined ? moneyDueAtInceptionField1 : leaseData?.pricE2),
      moneyDueAtInceptionField2:
        dealData?.moneyDueAtInceptionField2 ??
        (moneyDueAtInceptionField2 !== undefined ? moneyDueAtInceptionField2 : leaseData?.pricE3),
      moneyDueAtInceptionField3:
        dealData?.moneyDueAtInceptionField3 ??
        (moneyDueAtInceptionField3 !== undefined ? moneyDueAtInceptionField3 : leaseData?.pricE4),
      securityDeposit:
        dealData?.securityDeposit ??
        (leaseSecurityDeposit !== undefined
          ? leaseSecurityDeposit
          : leaseData?.lease_SecurityDeposit),
      customField1:
        dealData?.customField1 ?? (customField1 !== undefined ? customField1 : leaseData?.pricE5),
      customField2:
        dealData?.customField2 ?? (customField2 !== undefined ? customField2 : leaseData?.pricE6),
      customField3:
        dealData?.customField3 ?? (customField3 !== undefined ? customField3 : leaseData?.pricE7),
      customField4:
        dealData?.customField4 ?? (customField4 !== undefined ? customField4 : leaseData?.pricE8),
      customField5:
        dealData?.customField5 ?? (customField5 !== undefined ? customField5 : leaseData?.pricE9),
      serviceContract:
        dealData?.serviceContract ??
        (leaseServiceContract !== undefined ? leaseServiceContract : leaseData?.pricE10),
      leaseAddedCapCost: dealData?.leaseAddedCapCost ?? 0,
    });
    setCustomField1Name(leaseData?.adD5 ?? '');
    setCustomField2Name(leaseData?.adD6 ?? '');
    setCustomField3Name(leaseData?.adD7 ?? '');
    setCustomField4Name(leaseData?.adD8 ?? '');
    setCustomField5Name(leaseData?.adD9 ?? '');
    setMoneyDueAtInceptionField1Name(leaseData?.adD2 ?? '');
    setMoneyDueAtInceptionField2Name(leaseData?.adD3 ?? '');
    setMoneyDueAtInceptionField3Name(leaseData?.adD4 ?? '');
    setIsQuoteDataLoading(false);
    setIs30Days(dealData?.daysToFirstPayment ? dealData?.daysToFirstPayment == 30 : true);
    setIs45Days(dealData?.daysToFirstPayment ? dealData?.daysToFirstPayment == 45 : false);
  }, [quoteData, dealData]);

  useEffect(() => {
    setValue(
      'leaseDownPayment',
      watch('leaseDueAtInception') -
        watch('moneyDueAtInceptionField1') -
        watch('moneyDueAtInceptionField2') -
        watch('moneyDueAtInceptionField3') -
        watch('securityDeposit')
    );
  }, [
    watch('leaseDueAtInception'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('securityDeposit'),
  ]);

  useEffect(() => {
    setValue(
      'leaseAddedCapCost',
      watch('customField1') +
        watch('customField2') +
        watch('customField3') +
        watch('customField4') +
        watch('customField5') +
        watch('serviceContract')
    );
  }, [
    watch('customField1'),
    watch('customField2'),
    watch('customField3'),
    watch('customField4'),
    watch('customField5'),
    watch('serviceContract'),
  ]);

  useEffect(() => {
    setValue('netAmountFinanced', watch('grossAmountFinanced') - watch('downPayment'));
  }, [watch('grossAmountFinanced'), watch('downPayment')]);

  useEffect(() => {
    setValue('leaseResidual', watch('leaseMmr') * watch('leaseResidualRate'));
  }, [watch('leaseMmr'), watch('leaseResidualRate')]);

  useEffect(() => {
    let monthlyPayments = 0;
    if (watch('daysToFirstPayment') === 30) {
      monthlyPayments = calculateFinancePayment(
        watch('netAmountFinanced'),
        watch('apr') / 12,
        watch('term')
      );
    } else {
      monthlyPayments = calculateFinancePayment(
        watch('netAmountFinanced') + (watch('netAmountFinanced') * watch('apr') * 15) / 365,
        watch('apr') / 12,
        watch('term')
      );
    }
    setValue('financePayment', monthlyPayments);
  }, [watch('daysToFirstPayment'), watch('netAmountFinanced'), watch('apr'), watch('term')]);

  useEffect(() => {
    const leaseExtraFees = [
      watch('customField1'),
      watch('customField2'),
      watch('customField3'),
      watch('customField4'),
      watch('customField5'),
      watch('serviceContract'),
    ];

    const adjCapCost = calculateAdjCapCost(
      watch('leaseSellingPrice') + calculateAddedSalesTax(),
      watch('leaseDownPayment'),
      leaseExtraFees
    );

    setValue(
      'leasePayment',
      calculateLeasePayment(
        watch('leaseResidual'),
        adjCapCost,
        watch('leaseTerm'),
        watch('leaseApr'),
        watch('leaseTerm')
      )
    );

    // Refinance Payments
    const leasePayoff = calculateLeasePayoff(
      adjCapCost,
      watch('afterNumberOfMonths'),
      watch('leaseResidual'),
      watch('leaseApr'),
      watch('leaseTerm'),
      watch('securityDeposit')
    );

    const totalLeaseBuyout = leasePayoff + leasePurchaseOption;

    const refinanceTax =
      (totalLeaseBuyout + watch('moneyDueAtInceptionField3')) * watch('leaseTaxRate');

    const refinancePayments = calculateFinancePayment(
      totalLeaseBuyout + refinanceTax + watch('moneyDueAtInceptionField3'),
      watch('estimatedApr') / 12,
      watch('leaseConversionTerm')
    );

    setValue('reFinancePayment', refinancePayments);
  }, [
    watch('customField1'),
    watch('customField2'),
    watch('customField3'),
    watch('customField4'),
    watch('customField5'),
    watch('serviceContract'),
    watch('leaseSellingPrice'),
    watch('leaseDownPayment'),
    watch('leaseResidual'),
    watch('leaseTerm'),
    watch('leaseApr'),
    watch('afterNumberOfMonths'),
    watch('securityDeposit'),
    watch('leaseConversionTerm'),
    watch('estimatedApr'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('leaseTaxRate'),
  ]);

  const calculateAddedSalesTax = () => {
    const taxableFees = [];

    if (leaseQuoteData?.staX2) {
      taxableFees.push(watch('moneyDueAtInceptionField1'));
    }
    if (leaseQuoteData?.staX3) {
      taxableFees.push(watch('moneyDueAtInceptionField2'));
    }
    if (leaseQuoteData?.staX4) {
      taxableFees.push(watch('moneyDueAtInceptionField3'));
    }
    if (leaseQuoteData?.staX5) {
      taxableFees.push(watch('customField1'));
    }
    if (leaseQuoteData?.staX6) {
      taxableFees.push(watch('customField2'));
    }
    if (leaseQuoteData?.staX7) {
      taxableFees.push(watch('customField3'));
    }
    if (leaseQuoteData?.staX8) {
      taxableFees.push(watch('customField4'));
    }
    if (leaseQuoteData?.staX9) {
      taxableFees.push(watch('customField5'));
    }
    if (leaseQuoteData?.staX10) {
      taxableFees.push(watch('serviceContract'));
    }

    let addedTax = watch('leaseSellingPrice') * watch('leaseTaxRate');

    taxableFees.forEach((item) => {
      addedTax += item * watch('leaseTaxRate');
    });

    return addedTax;
  };

  const calculateComparison = (data: FormProps) => {
    if (isSaving) {
      onSaveButton(data);
      return;
    }

    setShowGraphic(true);
    let monthlyPayments = 0;

    if (data.daysToFirstPayment === 30) {
      monthlyPayments = calculateFinancePayment(data.netAmountFinanced, data.apr / 12, data.term);
    } else {
      monthlyPayments = calculateFinancePayment(
        data.netAmountFinanced + (data.netAmountFinanced * data.apr * 15) / 365,
        data.apr / 12,
        data.term
      );
    }

    const addedSalesTax = calculateAddedSalesTax();

    const leaseExtraFees = [
      data.customField1,
      data.customField2,
      data.customField3,
      data.customField4,
      data.customField5,
      data.serviceContract,
    ];

    const adjCapCost = calculateAdjCapCost(
      data.leaseSellingPrice + addedSalesTax,
      data.leaseDownPayment,
      leaseExtraFees
    );

    const monthlyLeasePayments = calculateLeasePayment(
      data.leaseResidual,
      adjCapCost,
      data.leaseTerm,
      data.leaseApr,
      data.leaseTerm
    );

    const leasePayoff = calculateLeasePayoff(
      adjCapCost,
      data.afterNumberOfMonths,
      data.leaseResidual,
      data.leaseApr,
      data.leaseTerm,
      data.securityDeposit
    );

    const totalLeaseBuyout = leasePayoff + leasePurchaseOption;
    const totalFinancePayments = monthlyPayments * data.term;

    const refinanceTax = (totalLeaseBuyout + data.moneyDueAtInceptionField3) * data.leaseTaxRate;

    const refinancePayments = calculateFinancePayment(
      totalLeaseBuyout + refinanceTax + data.moneyDueAtInceptionField3,
      data.estimatedApr / 12,
      data.leaseConversionTerm
    );

    const leaseResidualTax =
      (data.leaseResidual -
        data.securityDeposit +
        leasePurchaseOption +
        data.moneyDueAtInceptionField3) *
      data.leaseTaxRate;

    const leaseResidualBuyout =
      data.leaseResidual -
      data.securityDeposit +
      leasePurchaseOption +
      leaseResidualTax +
      data.moneyDueAtInceptionField3;

    const totalRefianceCostOfOwnership =
      data.leaseDueAtInception +
      refinancePayments * data.leaseConversionTerm +
      monthlyLeasePayments * data.afterNumberOfMonths;

    setFinanceDownPaymentState(data.downPayment);
    setFinanceMonthlyPayments(monthlyPayments);
    setFinanceTermState(data.term);
    setTotalFinancePayments(totalFinancePayments);
    setRefinanceAfterNumberOfMonths(data.afterNumberOfMonths);
    setDueAtInception(data.leaseDueAtInception);
    setLeaseMonthlyPayments(monthlyLeasePayments);
    setLeaseTermState(data.leaseTerm);
    setLeaseResidual(data.leaseResidual);
    setRefinanceApr(data.estimatedApr);
    setLeaseConversionTerm(data.leaseConversionTerm);
    setRefinancePayment(refinancePayments);
    setLeaseResidualTax(leaseResidualTax);
    setDownPayment(data.leaseDownPayment);
    setPayoffAfterMonthsFinance(
      data.daysToFirstPayment === 30
        ? -calculateFutureValue(
            data.apr / 12,
            data.afterNumberOfMonths,
            -monthlyPayments,
            data.netAmountFinanced,
            0
          )
        : -calculateFutureValue(
            data.apr / 12,
            data.afterNumberOfMonths,
            -monthlyPayments,
            (data.netAmountFinanced * 15 * data.apr) / 365 + data.netAmountFinanced,
            0
          )
    );
    setLeasePayoff(leasePayoff);
    setTotalLeaseBuyout(totalLeaseBuyout);
    setTotalCostOfOwnershipFinance(data.downPayment + totalFinancePayments);
    setTotalCostOfOwnershipRefinance(totalRefianceCostOfOwnership);
    setTotalCostOfOwnershipLease(
      data.leaseDueAtInception + monthlyLeasePayments * data.leaseTerm + leaseResidualBuyout
    );
    setLeaseBuyoutResidual(leaseResidualBuyout);
    setLeaseDoc(data.moneyDueAtInceptionField3);
    setGlobalState(data);
  };

  const onSaveButton = async (currentForm: FormProps) => {
    const updateData: DealData = {
      recId: dealData?.recId,
      quoteDate: currentForm.dateOfQuote,
      customerName: currentForm.fullName,
      customerPhone: currentForm.phoneNumber,

      //Finance properties
      salesPrice: dealData?.salesPrice,
      salesTax: dealData?.salesTax,
      license: dealData?.license,
      title: dealData?.title,
      registration: dealData?.registration,
      serviceContract: dealData?.serviceContract,
      credityInsurance: dealData?.credityInsurance,
      creditDisability: dealData?.creditDisability,
      tradeAllowance: dealData?.tradeAllowance,
      tradePayoff: dealData?.tradePayoff,
      cashOnDelivery: dealData?.cashOnDelivery,
      totalDown: dealData?.totalDown,
      schedule: dealData?.schedule,
      firstPaymentDue: dealData?.firstPaymentDue,
      numberOfPayments: dealData?.numberOfPayments,
      finalPayment: dealData?.finalPayment,
      finalPaymentDue: dealData?.finalPaymentDue,
      financeCharge: dealData?.financeCharge,
      totalPayments: dealData?.totalPayments,
      customField1Label: dealData?.customField1Label,
      customField1: dealData?.customField1,
      customField2Label: dealData?.customField2Label,
      customField2: dealData?.customField2,
      customField3Label: dealData?.customField3Label,
      customField3: dealData?.customField3,
      customField4Label: dealData?.customField4Label,
      customField4: dealData?.customField4,
      customField5Label: dealData?.customField5Label,
      customField5: dealData?.customField5,
      customField6Label: dealData?.customField6Label,
      customField6: dealData?.customField6,
      specialPaymentNum1: dealData?.specialPaymentNum1,
      specialPaymentNum2: dealData?.specialPaymentNum2,
      specialPaymentNum3: dealData?.specialPaymentNum3,
      specialPaymentNum4: dealData?.specialPaymentNum4,
      specialPaymentAmount1: dealData?.specialPaymentAmount1,
      specialPaymentAmount2: dealData?.specialPaymentAmount2,
      specialPaymentAmount3: dealData?.specialPaymentAmount3,
      specialPaymentAmount4: dealData?.specialPaymentAmount4,
      specialPaymentBeginningDate1: dealData?.specialPaymentBeginningDate1,
      specialPaymentBeginningDate2: dealData?.specialPaymentBeginningDate2,
      specialPaymentBeginningDate3: dealData?.specialPaymentBeginningDate3,
      specialPaymentBeginningDate4: dealData?.specialPaymentBeginningDate4,
      specialPaymentSchedule1: dealData?.specialPaymentSchedule1,
      specialPaymentSchedule2: dealData?.specialPaymentSchedule2,
      specialPaymentSchedule3: dealData?.specialPaymentSchedule3,
      specialPaymentSchedule4: dealData?.specialPaymentSchedule4,

      totalSalesPrice: currentForm.grossAmountFinanced,
      amountFinanced: currentForm.netAmountFinanced,
      depositDown: currentForm.downPayment,
      interestRate: currentForm.apr,
      term: currentForm.term,
      daysToFirstPayment: currentForm.daysToFirstPayment,
      paymentAmount: currentForm.financePayment,

      //Lease Properties
      term1: dealData?.term1,
      term1EstPayment: dealData?.term1EstPayment,
      term1ResidualRate: dealData?.term1ResidualRate,
      term1Residual: dealData?.term1Residual,
      term1GuaranteedTradeOption: dealData?.term1GuaranteedTradeOption,
      term1EstimatedNetPayoff: dealData?.term1EstimatedNetPayoff,
      term2: dealData?.term2,
      term2EstPayment: dealData?.term2EstPayment,
      term2ResidualRate: dealData?.term2ResidualRate,
      term2Residual: dealData?.term2Residual,
      term2GuaranteedTradeOption: dealData?.term2GuaranteedTradeOption,
      term2EstimatedNetPayoff: dealData?.term2EstimatedNetPayoff,
      term3: dealData?.term3,
      term3EstPayment: dealData?.term3EstPayment,
      term3ResidualRate: dealData?.term3ResidualRate,
      term3Residual: dealData?.term3Residual,
      term3GuaranteedTradeOption: dealData?.term3GuaranteedTradeOption,
      term3EstimatedNetPayoff: dealData?.term3EstimatedNetPayoff,
      tradeOption: dealData?.tradeOption,

      sellingPrice: currentForm.leaseSellingPrice,
      mmr: currentForm.leaseMmr,
      moneyDueAtInception: currentForm.leaseDueAtInception,
      moneyDueAtInceptionField1: currentForm.moneyDueAtInceptionField1,
      moneyDueAtInceptionField2: currentForm.moneyDueAtInceptionField2,
      moneyDueAtInceptionField3: currentForm.moneyDueAtInceptionField3,
      securityDeposit: currentForm.securityDeposit,
      downPayment: currentForm.downPayment,
      taxRate: currentForm.leaseTaxRate,
      aPR: currentForm.leaseApr,
      customField11Label: customField1Name,
      customField11: currentForm.customField1,
      customField12Label: customField2Name,
      customField12: currentForm.customField2,
      customField13Label: customField3Name,
      customField13: currentForm.customField3,
      customField14Label: customField4Name,
      customField14: currentForm.customField4,
      customField15Label: customField5Name,
      customField15: currentForm.customField5,
      leaseTerm: currentForm.leaseTerm,
      leaseResidualRate: currentForm.leaseResidualRate,
      leaseResidual: currentForm.leaseResidual,
      leaseAddedCapCost: currentForm.leaseAddedCapCost,
      leasePayment: currentForm.leasePayment,

      //Lease Conversion Properties
      afterNumberOfMonths: currentForm.afterNumberOfMonths,
      estimatedApr: currentForm.estimatedApr,
      leaseConversionTerm: currentForm.leaseConversionTerm,
      reFinancePayment: currentForm.reFinancePayment,
    };

    setIsLoading(true);
    await salesService
      .postDealQuotes(updateData)
      .then((res) => {
        setDealData(res.data);

        if (dealData?.recId != res.data.recId) {
          const editRoute = `/sales/${SalesSubviewListKey.dealsQuotes}/${res.data.recId}/comparison`;
          navigate(editRoute);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    control,
    errors,
    financeDownPaymentState,
    financeMonthlyPayments,
    handleSubmit,
    calculateComparison,
    financeTermState,
    totalFinancePayments,
    refinanceAfterNumberOfMonths,
    dueAtInception,
    leaseMonthlyPayments,
    leaseTermState,
    leasePurchaseOption,
    leaseResidual,
    refinanceApr,
    showGraphic,
    leaseConversionTerm,
    refinancePayment,
    leaseResidualTax,
    downPayment,
    payoffAfterMonthsFinance,
    leasePayoff,
    leaseQuoteName,
    leaseQuotePhoneNumber,
    leaseQuoteDate,
    totalLeaseBuyout,
    totalCostOfOwnershipFinance,
    totalCostOfOwnershipLease,
    totalCostOfOwnershipRefinance,
    leaseSecurityDeposit,
    leaseBuyoutResidual,
    setGlobalState,
    showFullLeaseOptions,
    setShowFullLeaseOptions,
    moneyDueAtInceptionField1Name,
    moneyDueAtInceptionField2Name,
    moneyDueAtInceptionField3Name,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    leaseDoc,
    setIsSaving,
    isDirty,
    isLoading,
    reset,
    isQuoteDataLoading,
    isQuoteInfoLoading,
    is30Days,
    is45Days,
  };
};
