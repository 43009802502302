import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseFormReturn, useForm } from 'react-hook-form';
// utils
import { companyService } from '@/services/companyService';
import { customerService } from '@/services/customerService';
import { formatDate } from '@/utils/helpers/general';
import { getYearlyCpiRate } from '@/utils/helpers/payment';
import { calcCpiRateDisabled } from '@/features/Sales/detailView/tabs/ApplicationTab/subviews/insurance/utils';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { CpiSettings } from '@/interfaces/applications';
import { InsuranceClaim, AcctInsuranceForm, AcctsInsuranceRes } from './interfaces';

type ICtx = IAcctInsuranceCtx;
export interface IAcctInsuranceCtx {
  isInsuranceReqLoading: boolean;
  isCpiReqLoading: boolean;
  isClaimsModalOpen: boolean;
  setIsClaimsModalOpen: SetState<ICtx['isClaimsModalOpen']>;

  insuranceInfoForm: UseFormReturn<AcctInsuranceForm>;

  insuranceInfo: AcctsInsuranceRes | null;
  insuranceClaims: InsuranceClaim[];

  cpiSettings: CpiSettings | null;
  cpiRate: number | null;
}

const AcctInsuranceCtx = createContext<ICtx | null>(null);

const AcctInsuranceProvider: FC<PropsWithChildren> = ({ children }) => {
  const colRecId = getRouteParamNum(useParams().colRecId);

  const [isInsuranceReqLoading, setIsInsuranceReqLoading] = useState(false);
  const [isCpiReqLoading, setIsCpiReqLoading] = useState(false);
  const [isClaimsModalOpen, setIsClaimsModalOpen] = useState(false);

  const [cpiSettings, setCpiSettings] = useState<ICtx['cpiSettings']>(null);
  const [insuranceInfo, setInsuranceInformation] = useState<ICtx['insuranceInfo']>(null);
  const [insuranceClaims, setInsuranceClaims] = useState<ICtx['insuranceClaims']>([]);

  const insuranceInfoForm = useForm<AcctInsuranceForm>({
    mode: 'onChange',
    defaultValues: new AcctInsuranceForm(),
  });
  const onCpi = insuranceInfoForm.watch('onCpi');
  const cpiSched = insuranceInfo?.cpiSched || insuranceInfo?.schedule;

  const loadCpiInfo = async () => {
    try {
      setIsCpiReqLoading(true);
      const cpiSettingsRes = await companyService.getCpiSettings();
      setCpiSettings(cpiSettingsRes || null);
    } finally {
      setIsCpiReqLoading(false);
    }
  };
  const loadInsuranceInfo = async (colRecId: number | null) => {
    if (!colRecId) return;

    try {
      setIsInsuranceReqLoading(true);

      const insuranceInfoRes = await customerService.getAcctInsuranceInfo(colRecId);
      setInsuranceInformation(insuranceInfoRes || null);
      insuranceInfoForm.reset(new AcctInsuranceForm(insuranceInfoRes));
      insuranceInfoForm.trigger();
    } finally {
      setIsInsuranceReqLoading(false);
    }
  };
  const loadInsuranceClaims = async (colRecId: number | null) => {
    if (!colRecId) return;

    try {
      const claimsRes = await customerService.getInsuranceClaims(colRecId);
      const newClaims = claimsRes.map((claim) => {
        return {
          ...claim,
          claimDate: formatDate(claim.claimDate),
          setDate: formatDate(claim.setDate),
        };
      });

      setInsuranceClaims(newClaims);
    } finally {
    }
  };

  useEffect(() => {
    loadCpiInfo();
    loadInsuranceInfo(colRecId);
    loadInsuranceClaims(colRecId);
  }, [colRecId]);
  const cpiYearlyPremium = insuranceInfoForm.watch('cpiYearlyPremium');

  const cpiYearlyPremiumEnabled = cpiSettings?.cpiYearlyPremiumEnabled;
  const isCalcAvailable = onCpi && cpiSched && !!insuranceInfo && !!cpiSettings;
  const newCpiRateCalc = cpiYearlyPremiumEnabled
    ? getYearlyCpiRate(cpiYearlyPremium ?? 0, cpiSched || 'Weekly')
    : calcCpiRateDisabled(cpiSettings, cpiSched || null);
  const cpiRate = isCalcAvailable ? newCpiRateCalc : null;

  return (
    <AcctInsuranceCtx.Provider
      value={{
        isInsuranceReqLoading,
        isCpiReqLoading,
        isClaimsModalOpen,
        setIsClaimsModalOpen,

        insuranceInfoForm,

        insuranceInfo,
        insuranceClaims,

        cpiSettings,
        cpiRate,
      }}
    >
      {children}
    </AcctInsuranceCtx.Provider>
  );
};

export default AcctInsuranceProvider;

export const useAcctInsuranceCtx = <T,>(selector: (state: ICtx) => T): T => {
  const ctx = useContext(AcctInsuranceCtx);
  if (!ctx) {
    throw new Error('useAcctInsuranceCtx must be used within AcctInsuranceProvider');
  }
  return selector(ctx);
};
