import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
// components
import { Icons } from '@/components/icons';
// style
import styles from './Modal.module.scss';

/** @deprecated use `CommonModal` */
export const Modal: FC<
  PropsWithChildren & {
    onDimmerClick?: () => void;
    onCloseButtonClick?: () => void;
    title?: string;
    isOpen: boolean;
    centerModal?: boolean;
    backgroundStyleAddl?: CSSProperties;
    dimmerStyle?: CSSProperties;
    panelStyle?: CSSProperties;
    panelChildrenStyle?: CSSProperties;
    closeButton?: boolean;
    headerButton?: ReactNode;
    backButton?: ReactNode;
  }
> = ({
  children,
  onDimmerClick,
  onCloseButtonClick,
  title,
  isOpen,
  centerModal,
  backgroundStyleAddl = {},
  dimmerStyle = {},
  panelStyle = {},
  panelChildrenStyle = {},
  closeButton = false,
  headerButton,
  backButton,
}) => {
  const backgroundStyle = isOpen ? styles.background : `${styles.background} ${styles.hidden}`;

  let panelClass = styles.panel;
  if (centerModal) panelClass = ` ${styles.panelCenter}`;

  return (
    <div className={backgroundStyle} style={backgroundStyleAddl}>
      <div
        className={styles.dimmer}
        onClick={onDimmerClick ? onDimmerClick : () => null}
        style={onDimmerClick ? { ...dimmerStyle, cursor: 'pointer' } : dimmerStyle}
      />
      <div className={panelClass} style={panelStyle}>
        {!!title && (
          <div className={styles.header}>
            <div style={{ display: 'flex', flexFlow: 'row', flex: 1 }}>
              {backButton}
              <div className={styles.title} style={{ justifySelf: 'flex-end' }}>
                {title}
              </div>
            </div>
            <div>
              {headerButton}
              {closeButton && (
                <div className={styles.closeButton} onClick={onCloseButtonClick}>
                  <Icons.X color="#000" />
                </div>
              )}
            </div>
          </div>
        )}
        {!title && closeButton && (
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icons.X color="#000" />
          </div>
        )}

        <div className={styles.children} style={panelChildrenStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};
