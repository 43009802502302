import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { IWsPmtsViewCtx, useWsPmtsViewCtx } from '../WsPmtsViewProvider';
import { useWholesaleFormCtx } from '../../WholesaleFormProvider';
// utils
import { useSalesParams } from '../../../utils';
import { getAllowedPaymentTypes, getCpiDueDate } from '@/utils/helpers/payment';
import { formatAddress, lowerCaseLetters } from '@/utils/helpers/general';
import { defaultPaymentFormValues } from '../default';
// interfaces
import {
  Employee,
  GetPaymentData,
  PostPaymentPayload,
  getDefaultPostPaymentPayloadNew,
} from '@/services/paymentService';
import { PaymentType as PaymentTypeEnum, TransactionType } from '@/enums';
import { SavedPaymentMethod } from '@/interfaces';
import { SalesSubviewListKey } from '@/features/Sales/enums';

const defaultValues = getDefaultPostPaymentPayloadNew(defaultPaymentFormValues as GetPaymentData);

export interface IWsPmtsFormCtx {
  wsPmtsForm: UseFormReturn<PostPaymentPayload, any, undefined>;
  resetFormState: () => void;

  // Render controllers
  showPendingRewriteModal: boolean;
  setShowPendingRewriteModal: (v: IWsPmtsFormCtx['showPendingRewriteModal']) => void;
  cancellingRewrite: boolean;
  setCancellingRewrite: (v: IWsPmtsFormCtx['cancellingRewrite']) => void;
  openEdgeCCModalOpen: boolean;
  setOpenEdgeCCModalOpen: (v: IWsPmtsFormCtx['openEdgeCCModalOpen']) => void;
  paymentLogRecId: number;
  setPaymentLogRecId: (v: IWsPmtsFormCtx['paymentLogRecId']) => void;
  repayIframeUrl: string;
  setRepayIframeUrl: (v: IWsPmtsFormCtx['repayIframeUrl']) => void;

  // Form fields
  shouldUseSavedPaymentMethod: boolean;
  setShouldUseSavedPaymentMethod: (v: IWsPmtsFormCtx['shouldUseSavedPaymentMethod']) => void;

  savedPaymentMethods: IWsPmtsViewCtx['savedPaymentMethodsRes'];
  savedPaymentMethod: SavedPaymentMethod | null;
  setSavedPaymentMethod: (v: IWsPmtsFormCtx['savedPaymentMethod']) => void;

  transactionType: TransactionType;
  setTransactionType: (v: IWsPmtsFormCtx['transactionType']) => void;

  totalPayment: number;
  setTotalPayment: (v: IWsPmtsFormCtx['totalPayment']) => void;

  employeeName: string | null;
  setEmployeeName: (v: IWsPmtsFormCtx['employeeName']) => void;
  employee: Employee | null;

  paymentTypes: PaymentTypeEnum[];

  // Derived values
  fullBillingAddress: string | null;
  isPrincipalOnly: boolean;
  isRegularPayment: boolean;
  isCc: boolean;
  isAch: boolean;
  totalPaymentCalc: number;
  cpiDueDateFmt: string | null;
}

const WsPmtsFormCtx = createContext<IWsPmtsFormCtx | null>(null);

const WsPmtsFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { appRecId, subview } = useSalesParams();
  // External state
  const savedPaymentMethodsRes = useWsPmtsViewCtx((s) => s.savedPaymentMethodsRes);
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const paymentProviderData = useWsPmtsViewCtx((s) => s.paymentProviderData);
  const provider = useWsPmtsViewCtx((s) => s.provider);

  const wsPmtsForm = useForm<PostPaymentPayload>({ defaultValues, mode: 'onChange' });
  const { CpiPaid, PaidBy, PaymentType } = useWatch({ control: wsPmtsForm.control });

  const [shouldUseSavedPaymentMethod, setShouldUseSavedPaymentMethod] =
    useState<IWsPmtsFormCtx['shouldUseSavedPaymentMethod']>(false);
  const [showPendingRewriteModal, setShowPendingRewriteModal] = useState(false);
  const [cancellingRewrite, setCancellingRewrite] = useState(false);
  const [openEdgeCCModalOpen, setOpenEdgeCCModalOpen] = useState(false);
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [repayIframeUrl, setRepayIframeUrl] = useState('');

  const [savedPaymentMethod, setSavedPaymentMethod] =
    useState<IWsPmtsFormCtx['savedPaymentMethod']>(null);

  // Employee state
  const userName = useAuthSelector((s) => s.userName!);
  const salespeople = useWholesaleFormCtx((s) => s.formAddlData?.salespeople);
  const [employeeName, setEmployeeName] = useState<IWsPmtsFormCtx['employeeName']>(userName);

  // Unsent form values
  const [transactionType, setTransactionType] = useState<IWsPmtsFormCtx['transactionType']>(
    TransactionType.Payment
  );
  const [totalPayment, setTotalPayment] = useState<IWsPmtsFormCtx['totalPayment']>(0);

  /** Reset all states to default */
  const resetFormState = () => {
    wsPmtsForm.reset({ ...defaultValues });
    setShouldUseSavedPaymentMethod(false);
    setSavedPaymentMethod(null);
    setEmployeeName(userName);
    setTransactionType(TransactionType.Payment);
    setTotalPayment(0);
  };

  const getEmployee = (employeeName: string | null) =>
    salespeople?.find((s) => s.shortName === employeeName) || null;

  // Effects
  // - Fetch and update col-rec-id state WHEN new app is loaded. Re-runs when app-rec-id changes.
  useEffect(() => {
    // Cleanup - reset all states to default WHEN form-state (which wraps form-component) is de-rendered
    return () => resetFormState();
  }, [appRecId]);

  // - Set the form state WHEN payment-data state is set with a valid value (contains col-rec-id).
  useEffect(() => {
    if (paymentData) {
      const employee = getEmployee(employeeName);
      const employeeFieldValues = {
        TakenBy: employee?.shortName,
        UserEmail: employee?.userId,
        UserShortName: employee?.shortName,
        UserRecId: employee?.recId,
      };

      // @note the initial value for this form field is derived from `UserEmail`
      const emailFieldValues = {
        // If (1. No buyer-email exists) OR (2. buyer-email exists AND buyer specified "no email")
        SendB: !!paymentData?.buyerEmail && !paymentData.buyerNoEmail,
        // Same as above, but for cobuyer
        SendC: !!paymentData?.cobuyerEmail && !paymentData.cobuyerNoEmail,
      };

      const paymentRes = getDefaultPostPaymentPayloadNew(paymentData as GetPaymentData); // paymentDataResponse
      wsPmtsForm.reset({
        ...defaultPaymentFormValues,
        ...paymentRes,
        ...employeeFieldValues,
        ...emailFieldValues,
      });
    }
    // @note Subscribes to changes in the colRecId (different payment), and when the balance changes (a payment is made)
  }, [paymentData?.colRecId, paymentData?.tOfPBal]);

  // Update employee field when salespeople req returns
  useEffect(() => {
    if (employeeName !== null && salespeople && salespeople.length > 0) {
      const employee = getEmployee(employeeName)!;
      wsPmtsForm.setValue('TakenBy', employee?.shortName);
      wsPmtsForm.setValue('UserEmail', employee?.userId);
      wsPmtsForm.setValue('UserShortName', employee?.shortName);
      wsPmtsForm.setValue('UserRecId', employee?.recId);
    }
    // The employee-name subscription updates when the name changes between null/not-null, NOT when the string itself changes
  }, [employeeName !== null, salespeople?.map((s) => s.recId).join()]);

  return (
    <WsPmtsFormCtx.Provider
      value={{
        resetFormState,

        // Form state
        wsPmtsForm,

        // Render controllers
        showPendingRewriteModal,
        setShowPendingRewriteModal,
        cancellingRewrite,
        setCancellingRewrite,
        openEdgeCCModalOpen,
        setOpenEdgeCCModalOpen,
        paymentLogRecId,
        setPaymentLogRecId,
        repayIframeUrl,
        setRepayIframeUrl,

        // Form fields
        shouldUseSavedPaymentMethod,
        setShouldUseSavedPaymentMethod,
        savedPaymentMethod,
        setSavedPaymentMethod,
        transactionType,
        setTransactionType,
        totalPayment,
        setTotalPayment,

        // Employee-field state
        employeeName,
        setEmployeeName,
        // This allows us to lazily get the `employee` name using the employee name (which loads instantly) from the employee-list (which is a very slow request) - without incurring any overhead
        get employee(): IWsPmtsFormCtx['employee'] {
          return getEmployee(employeeName);
        },

        // Form field options (radio/dropdown)
        get savedPaymentMethods(): IWsPmtsFormCtx['savedPaymentMethods'] {
          return savedPaymentMethodsRes.filter((pm) => {
            return (
              pm.isActive &&
              pm.fName &&
              pm.lName &&
              pm.mpdId &&
              pm.last4 &&
              lowerCaseLetters(pm.mpdType) === lowerCaseLetters(PaidBy) &&
              lowerCaseLetters(pm.cardProcessor) === lowerCaseLetters(provider || '')
            );
          });
        },
        get paymentTypes(): IWsPmtsFormCtx['paymentTypes'] {
          const allPaymentTypes = paymentProviderData
            ? getAllowedPaymentTypes(paymentProviderData)
            : [];

          if (subview === SalesSubviewListKey.wholesales)
            return allPaymentTypes.filter((p) => p !== PaymentTypeEnum.CreditCard);
          return allPaymentTypes;
        },

        // Derived values
        get fullBillingAddress(): IWsPmtsFormCtx['fullBillingAddress'] {
          if (shouldUseSavedPaymentMethod && savedPaymentMethod) {
            const { address, city, state, zip } = savedPaymentMethod;
            return formatAddress(address, city, state, zip) || null;
          }

          return null;
        },
        get isPrincipalOnly(): IWsPmtsFormCtx['isPrincipalOnly'] {
          return PaymentType === 'PrinOnly';
        },
        get isRegularPayment(): IWsPmtsFormCtx['isRegularPayment'] {
          return PaymentType === null || PaymentType === undefined || PaymentType === '';
        },
        get totalPaymentCalc(): IWsPmtsFormCtx['totalPaymentCalc'] {
          if (!paymentData?.colRecId) return 0;
          if (this.isPrincipalOnly) return 0;
          if (this.isRegularPayment) return paymentData.dmsNextDueAmount;

          return totalPayment;
        },
        get isCc(): boolean {
          return PaidBy === PaymentTypeEnum.CreditCard;
        },
        get isAch(): boolean {
          return PaidBy === PaymentTypeEnum.Ach;
        },
        get cpiDueDateFmt(): string | null {
          if (paymentData === null) return null;

          const { cpiRate, cpiSchedule, cpiTotalPaid, cpiFirstDueDate } = paymentData;
          if (!cpiRate || !cpiSchedule || !cpiTotalPaid || !cpiFirstDueDate) return null;

          const cpiPaid = CpiPaid || 0;
          return getCpiDueDate(cpiRate, cpiSchedule, cpiTotalPaid!, cpiFirstDueDate, cpiPaid);
        },
      }}
    >
      {children}
    </WsPmtsFormCtx.Provider>
  );
};

export default WsPmtsFormProvider;

export const useWsPmtsFormCtx = <T,>(selector: (state: IWsPmtsFormCtx) => T): T => {
  const ctx = useContext(WsPmtsFormCtx);
  if (!ctx) {
    throw new Error('useWsPmtsFormCtx must be used within WsPmtsFormProvider');
  }
  return selector(ctx);
};
