import { FC } from 'react';
import { AxiosError } from 'axios';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Button from '@/mui/components/MuiKendoButton';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';
// state
import { useWholesaleCompanyFormCtx } from './WholesaleCompanyFormProvider';
import { useWholesaleFormCtx } from '../../WholesaleFormProvider';
// utils
import { salesService } from '@/services/salesService';
// interfaces
import { CreateWsCompanyReq, UpdateWsCompanyReq } from './interfaces';
import { toast } from 'react-toastify';

const BackButton: FC = () => {
  const setWsCompanyForm = useWholesaleCompanyFormCtx((s) => s.setWsCompanyForm);
  const handleResetForm = () => setWsCompanyForm(null);

  return (
    <Grid>
      <Button
        onClick={handleResetForm}
        variant="outlined"
        sx={{ backgroundColor: undefined, borderColor: DCS_BLUE }}
        style={{ color: undefined }}
      >
        Cancel
      </Button>
    </Grid>
  );
};

/** ###  */
const SubmitButtons: FC = () => {
  const wsCompanyRes = useWholesaleCompanyFormCtx((s) => s.wsCompanyRes);
  const wsCompanyForm = useWholesaleCompanyFormCtx((s) => s.wsCompanyForm!);
  const isFormEdit = useWholesaleCompanyFormCtx((s) => s.isFormEdit);
  const isFormDirty = useWholesaleCompanyFormCtx((s) => s.isFormDirty);
  const setWsCompanyRes = useWholesaleCompanyFormCtx((s) => s.setWsCompanyRes);
  const setWsCompanyForm = useWholesaleCompanyFormCtx((s) => s.setWsCompanyForm);
  // Wholesale Form
  const salespeople = useWholesaleFormCtx((s) => s.formAddlData?.salespeople);
  const setIsWsCompanyModalOpen = useWholesaleFormCtx((s) => s.setIsWsCompanyModalOpen);
  const setFormAddlDataRes = useWholesaleFormCtx((s) => s.setFormAddlDataRes);
  const setWsFormField = useWholesaleFormCtx((s) => s.setWsFormField);

  // Event handlers
  const handleSubmit = async () => {
    if (!isFormDirty) return;
    try {
      if (wsCompanyForm === null) throw new Error('wsCompanyForm cannot be null');

      if (!wsCompanyForm.companyName) {
        toast.error('Company name is required');
        return;
      }

      if (isFormEdit) {
        await salesService.updateWholesaleCompany(
          UpdateWsCompanyReq.toReq(wsCompanyForm, wsCompanyRes!.recId)
        );
      } else {
        await salesService.createWholesaleCompany(CreateWsCompanyReq.toReq(wsCompanyForm));
      }
      setWsCompanyRes(null);
      setWsCompanyForm(null);
      setIsWsCompanyModalOpen(false);

      // Update the companies (and vehicles) list
      const addlInfoRes = await salesService.getWholesaleFormAddlLists();
      setFormAddlDataRes(addlInfoRes, salespeople);

      // select the wholesale company that was just edited or modified
      const newOrUpdatedCompany =
        addlInfoRes.companies?.find((company) => company.companyName === wsCompanyForm.companyName)
          ?.recId ?? null;
      setWsFormField('wsBuyer', newOrUpdatedCompany);
    } catch (e) {
      const err = e as unknown as AxiosError;
      console.error(err);
    }
  };

  const text = isFormEdit ? 'Submit Updated Company' : 'Submit New Company';

  return (
    <Grid container direction="row" justifyContent="end" alignItems="center" gap={1}>
      <BackButton />
      <Grid>
        <Button onClick={handleSubmit} disabled={!isFormDirty}>
          {text}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmitButtons;
