import { FC } from 'react';
// kendo
import { Modal, OpenEdgeIframe } from '@/components';
// state
import { useWsPmtsViewCtx } from '../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from './WsPmtsFormProvider';
// utils
import { config } from '@/config';

/** ###  */
const OpenEdgeCcModal: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const paymentProviderData = useWsPmtsViewCtx((s) => s.paymentProviderData);
  const openEdgeCCModalOpen = useWsPmtsFormCtx((s) => s.openEdgeCCModalOpen);
  const setOpenEdgeCCModalOpen = useWsPmtsFormCtx((s) => s.setOpenEdgeCCModalOpen);
  const paymentLogRecId = useWsPmtsFormCtx((s) => s.paymentLogRecId);

  if (!openEdgeCCModalOpen) return <></>;
  return (
    <Modal
      centerModal
      isOpen={openEdgeCCModalOpen}
      closeButton
      panelChildrenStyle={{ overflow: 'hidden', minWidth: '406px' }}
      onCloseButtonClick={() => setOpenEdgeCCModalOpen(false)}
    >
      <OpenEdgeIframe
        paymentLogRecId={paymentLogRecId}
        onComplete={onSuccess}
        apiKey={paymentData!.openedgeApiKey}
        openEdgeEnv={
          paymentProviderData!.openEdgeEnv
            ? paymentProviderData!.openEdgeEnv
            : config.openEdgeEnvironment
        }
      />
    </Modal>
  );
};

export default OpenEdgeCcModal;
