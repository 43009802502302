import { FC, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// interfaces
import { ReversiblePaymentPostReq } from '../../interfaces';

/** ###  */
export const ReversalSuccessNoti: FC<{ req: ReversiblePaymentPostReq }> = ({ req }) => {
  const isCc = ['chargeBack', 'reapply', 'refund'].includes(req.reversalType);
  const msg = req.systemOnly && isCc && (
    <>
      <Divider />
      <Grid fontSize="14px">Refund must be submitted through payment provider</Grid>
    </>
  );

  return (
    <Grid container direction="column" gap={1}>
      <Grid component={Typography} fontSize="18px" fontWeight={700}>
        Successfully reversed payent{isCc ? ':' : ''}
      </Grid>
      {msg}
    </Grid>
  );
};

export const RepayErrorNoti: ReactNode = (
  <Grid container direction="column" gap={1}>
    <Grid component={Typography} fontSize="18px" fontWeight={700}>
      This payment cannot be reversed:
    </Grid>
    <Divider />
    <Grid fontSize="14px">Payment was not submitted via Repay.</Grid>
    <Grid container direction="column">
      <Grid fontSize="14px" fontWeight={700}>
        To reverse this payment:
      </Grid>
      <Grid fontSize="14px">1. Select "System Only".</Grid>
      <Grid fontSize="14px">2. Manually reverse payment through the provider.</Grid>
    </Grid>
  </Grid>
);
