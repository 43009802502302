import { FC, useState } from 'react';
import { toast } from 'react-toastify';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Button } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from '@/components/icons';
import { CreditEducationProps } from '@/interfaces/CreditEducation';
import { useCreditEducationCtx } from '../CreditEducationProvider';
import TitleHeader from './TitleHeader';
import CreditRepairCloudForm from '../creditRepairCloud/CreditRepairCloud';

const IntegrationControl: FC<CreditEducationProps> = (props) => {
  const { formData } = props;
  const [isUpdate, setIsUpdate] = useState(false);
  const { showCreditRepairForm, setShowCreditRepairForm } = useCreditEducationCtx((s) => s);

  return (
    <Grid container direction="column" flex={1} gap={3}>
      <TitleHeader label="Integration Control" />

      {!showCreditRepairForm && (
        <Grid container direction="row" flex={1} gap={4}>
          <Grid container direction="column" flex={1} gap={1}>
            <Grid container direction="row" flex={1} gap={2}>
              <Grid
                container
                direction="column"
                flex={1}
                alignItems={'center'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Icons.CreditRepairCloud />
              </Grid>
            </Grid>
            <Spacer size={5} />
            <Grid container direction="row" flex={1} gap={2} marginX={12}>
              {!props.formData?.creditRepairCloudID && (
                <Grid container direction="column" flex={1} gap={2}>
                  <Button
                    label="Enroll"
                    type="button"
                    onClick={() => {
                      setIsUpdate(false);
                      setShowCreditRepairForm(true);
                    }}
                  />
                </Grid>
              )}
              {props.formData?.creditRepairCloudID && (
                <Grid container direction="column" flex={1} gap={2}>
                  <Button
                    label="Update"
                    type="button"
                    onClick={() => {
                      setIsUpdate(true);
                      setShowCreditRepairForm(true);
                    }}
                  />
                </Grid>
              )}
              <Grid container direction="column" flex={1} gap={2}>
                <Button
                  label="Delete"
                  onClick={() => {
                    if (!props.formData?.creditRepairCloudID) toast.info('Not yet registered.');
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column" flex={1} gap={1}>
            <Grid container direction="row" flex={1} gap={2}>
              <Grid
                container
                direction="column"
                flex={1}
                alignItems={'center'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Icons.SmartCredit />
              </Grid>
            </Grid>
            <Spacer size={11} />
            <Grid container direction="row" flex={1} gap={2} marginX={12}>
              <Grid container direction="column" flex={1} gap={2}>
                <Button label="Enroll" />
              </Grid>
              <Grid container direction="column" flex={1} gap={2}>
                <Button label="Credentials" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {showCreditRepairForm && <CreditRepairCloudForm formData={formData} isUpdate={isUpdate} />}
    </Grid>
  );
};

export default IntegrationControl;
