import { FC } from 'react';
// kendo
import { Button } from '@/components';
// utils
import { useNavUp } from '@/utils/routing/useNavUpEvent';
// style
import styles from '@features/Sales/components/salesTabs/saleManagement/SaleManagement.module.scss';

/** ###  */
const FormBackButton: FC = () => {
  return (
    <div className={styles.collectButton}>
      <Button fillMode="flat" label="< Go Back" onClick={useNavUp()} />
    </div>
  );
};

export default FormBackButton;
