import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// kendo
import { Hint } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { TextInput } from '@/components/inputs/text/TextInput';
// state
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { useAppDispatch } from '@/store/store';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
// utils
import { customerService } from '@/services/customerService';
import useFetchAndNavAccountDetail from '@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/useFetchAndNav';
import { emailRegex } from '@/utils/helpers/formValidation';
// interfaces
import { CreateAppointmentReq, CreateAppointmentForm } from './interfaces';
// style
import styles from './AddAppointment.module.scss';

/** ### Modal-form for setting an "Appointment" on the `customer` table
 * - Renders within Account-detail view
 * - Accessed thru "Quick Actions" dropdown
 * @note `accountInformation !== null` is validated in parent component: `Quick-Actions-Modal`
 */
const AddAppointment: FC = () => {
  const loc = useLocation();
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const fetchAndNav = useFetchAndNavAccountDetail();
  const dispatch = useAppDispatch();
  // Account state
  const postAppointmentLoading = useAccountSelector((s) => s.postAppointmentLoading);
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const appBuyerRecId = useAccountSelector((s) => s.accountInformation!.buyer.appBuyerRecId);
  const buyerEmail = useAccountSelector((s) => s.accountInformation!.buyer.email);
  const customerContacts = useAccountSelector((s) => s.customerContacts);
  // Build buyer-name
  const buyerFirstName = useAccountSelector((s) => s.accountInformation!.buyer.firstName);
  const buyerLastName = useAccountSelector((s) => s.accountInformation!.buyer.lastName);
  const buyerName = `${buyerFirstName} ${buyerLastName}`;
  // SAM-hotlist state
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);

  // Build list of customer contacts
  const customerContactDisplay = customerContacts.map((value, index) => {
    if (index === 0) return buyerName;

    return `${value.contact} - ${value.contactType}`;
  });

  // Init form
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CreateAppointmentForm>({
    defaultValues: {
      speakingTo: customerContactDisplay[0] ?? buyerName,
      appointmentDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      appointmentTime: '09:00',
      note: '',
      documentsToBring: '',
      sendBuyerEmail: true,
      buyerEmail,
    },
  });

  /** Contains all of the logic of the redux method: `postAppointment`
   * Domain-specific logic should be grouped together to avoid adding ambiguity to common/generic/abstracted/general use code.
   * `postAppointment` is only used here, so it doesn't warrant moving to a differerent part of the codebase.
   * Additionally, putting the request here allows us to better handle the possible outcomes.
   */
  const handleSubmitCreateAppointment = async (data: CreateAppointmentForm) => {
    // Validate
    const firstPaymentDateAndTime = new Date(`${data.appointmentDate} ${data.appointmentTime}`);
    const now = new Date();

    // Validate date: Exit fxn if invalid
    if (firstPaymentDateAndTime <= now) {
      setError('appointmentDate', {
        type: 'valueAsDate',
        message: "Date/time can't be in the past",
      });

      return;
    }

    // Build payload
    const payload: CreateAppointmentReq = {
      colRecId: colRecId,
      inAt: 'at',
      dateTime: firstPaymentDateAndTime,
      speakingTo: data.speakingTo,
      note: data.note,
      documents: data.documentsToBring,
      // @todo we should name this property more accurately
      userRecId: appBuyerRecId,
      sendBuyerEmail: data.sendBuyerEmail,
      buyerEmail: data.buyerEmail,
      sendCoBuyerEmail: false,
      coBuyerEmail: '',
    };

    try {
      // Set state to "loading"
      dispatch(accountActions.setPostAppointmentLoading(true));

      // Send request and await its response
      await customerService.postAppointment(payload);

      toast.success('Appointment Created');
      dispatch(accountActions.setQuickActionsModalOpened(false));
      dispatch(accountActions.setQuickActionType(''));

      // Update relevant SAM hotlist AFTER response is received - is is necessary since left and right containers update differently
      fetchHotlist(appRecId!, 'Account', 'Active');
      // Fetch info, update state and navigate to acct-detail base-route
      const browserPath = loc.pathname.replace(params['*'] || ' ', '');
      fetchAndNav(browserPath, colRecId);
    } catch {
      toast.error('There was an error creating your appointment');
    } finally {
      // Revert state to "not loading"
      dispatch(accountActions.setPostAppointmentLoading(false));
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleSubmitCreateAppointment)}>
      <Controller
        name="speakingTo"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DropdownInput
            required
            errors={errors.speakingTo ? 'Please add a name' : undefined}
            label="Speaking To"
            horizontalLabel={false}
            data={customerContacts.length !== 0 ? customerContactDisplay : [buyerName]}
            {...field}
          />
        )}
      />
      <div className={styles.row}>
        <Controller
          name="appointmentDate"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DateInput
              required
              label="Appointment Date"
              // horizontalLabel={false}
              minDate={dayjs()}
              errors={
                errors.appointmentDate
                  ? errors.appointmentDate.message
                    ? errors.appointmentDate.message
                    : 'Enter a valid date'
                  : undefined
              }
              {...field}
            />
          )}
        />
        <Controller
          name="appointmentTime"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              type="time"
              label="Appointment Time"
              horizontalLabel={false}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="note"
          control={control}
          render={({ field }) => <TextArea label="Appointment Notes" rows={3} {...field} />}
        />
        <Hint>This will print on the confirmation email</Hint>
      </div>
      <div>
        <Controller
          name="documentsToBring"
          control={control}
          render={({ field }) => (
            <TextArea label="Documents Required from Customer" rows={3} {...field} />
          )}
        />
        <Hint>This will print on the confirmation email</Hint>
      </div>
      <Controller
        name="buyerEmail"
        control={control}
        rules={{ required: true, pattern: emailRegex }}
        render={({ field }) => (
          <TextInput
            required
            errors={errors.buyerEmail ? 'Enter a valid email' : undefined}
            label="Email"
            horizontalLabel={false}
            {...field}
          />
        )}
      />
      <Controller
        name="sendBuyerEmail"
        control={control}
        render={({ field }) => <Checkbox label="Send buyer email" {...field} />}
      />
      <Button type="submit" label="Schedule Appointment" loading={postAppointmentLoading} />
    </form>
  );
};

export default AddAppointment;
