import { FC, ReactNode, createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// interfaces
import { IKendoPaginationState, SortKendo } from '@/interfaces/kendo';
import { DtoFilter } from '@/interfaces/requests';
import { ISalesSubviewDb, SaleTypeKey } from '../../interfaces';
import { dateRangeOptions, formatDateTz, getLocalDateFormat } from '@/general/dates';
import { ICommonTableCtx, IDateRangeFilter } from '@/interfaces/tableState';
import { AppStatusFilterKey } from '../subviewConfigs/applications/dataModelConfig';
import { AppStatus } from '../../enums';
import { useSalesListCtx } from '../SalesListProvider';

dayjs.extend(utc);

const initSort: SortKendo<keyof ISalesSubviewDb>[] = [];
export const initPage: IKendoPaginationState = { skip: 1, take: 25 };

const { dateFormatStr } = getLocalDateFormat();

const defaultDateRange = dateRangeOptions['This Month-to-date'];

export interface ITableCtx<
  SortKey extends keyof ISalesSubviewDb = keyof ISalesSubviewDb,
  FilterKey extends keyof ISalesSubviewDb = keyof ISalesSubviewDb
> extends Omit<ICommonTableCtx<SortKey, FilterKey>, 'textSearchFilter'> {
  textSearchFilter: string | null;
  setTextSearchFilter: (textSearchFilter: ITableCtx['textSearchFilter']) => void;
  page: IKendoPaginationState;
  setPage: (page: ITableCtx['page']) => void;
  pageSizeValue: number;
  setPageSizeValue: (pageSizeValue: ITableCtx['pageSizeValue']) => void;
  queryPageNumber: number;
  sorts: SortKendo<SortKey>[];
  setSorts: (sorts: ITableCtx['sorts']) => void;
  // Updated date range filters
  dateRangeFilter: IDateRangeFilter | null;
  setDateRangeFilter: (filter: ITableCtx['dateRangeFilter']) => void;
  initDateRangeFilter: (filter?: ITableCtx['dateRangeFilter']) => ITableCtx['dateRangeFilter'];
  updateDateRangeFilter: (
    newDateFilterItem: dayjs.Dayjs,
    filterKey: keyof Omit<IDateRangeFilter, 'dateRange'>
  ) => void;
  getDateRangeDtoFilters: <TFilterKey extends string = string>(field: TFilterKey) => DtoFilter[];
  isDateFilterError: boolean;
  /** Table-column filters, unrelated to the text-search-filter */
  filters: DtoFilter<FilterKey>[];
  resetTableState: (sortField?: SortKey) => void;
  addAppStatusOption: (newAppStatusOption: AppStatusFilterKey) => void;
  removeAppStatusOption: (appStatusOptionToRemove: AppStatusFilterKey) => void;
  resetAppStatusFilter: () => void;
  /** Column-filter for "Sale Type" (label in table: "Type") */
  activeSaleType: SaleTypeKey;
  /** Setter for column-filter: "Sale Type" (label in table: "Type") */
  setActiveSaleType: (activeSaleType: ITableCtx['activeSaleType']) => void;
  // Column filter #2
  activeAppStatus: AppStatusFilterKey[];
}

const TableCtx = createContext<ITableCtx | null>(null);

type SalesSubviewColumnKey = keyof ISalesSubviewDb;
type SalesSort<SortKey extends SalesSubviewColumnKey = SalesSubviewColumnKey> = SortKendo<SortKey>;

const TableProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Table variables
  const [textSearchFilter, setTextSearchFilter_] = useState<ITableCtx['textSearchFilter']>('');
  const [page, setPage] = useState<ITableCtx['page']>(initPage);
  const [sorts, setSorts_] = useState<ITableCtx['sorts']>(initSort);
  const [dateRangeFilter, setDateRangeFilter] = useState<ITableCtx['dateRangeFilter']>(null);
  const [activeSaleType, setActiveSaleType] = useState<ITableCtx['activeSaleType']>('All');
  const [activeAppStatus, setActiveAppStatus] = useState<ITableCtx['activeAppStatus']>(['Pending']);

  const setSalesListRows = useSalesListCtx((s) => s.setSalesListRows);

  const setSorts = <SortKey extends SalesSubviewColumnKey = SalesSubviewColumnKey>(
    sortsToSet: (Omit<SalesSort<SortKey>, 'dir'> & { dir?: SalesSort<SortKey>['dir'] })[]
  ) => {
    const newSortsArr = sortsToSet.map((s) => new SortKendo<SortKey>(s.field, s.dir));
    setSorts_(newSortsArr);
  };

  const initDateRangeFilter = (dateRange?: ITableCtx['dateRangeFilter']) => {
    if (dateRange === undefined) {
      const newDateRange: IDateRangeFilter = {
        from: dayjs(defaultDateRange.beginDate),
        to: dayjs(defaultDateRange.endDate),
        dateRange: 'This Month-to-date',
      };
      setDateRangeFilter(newDateRange);
      return newDateRange;
    } else {
      setDateRangeFilter(dateRange);
      return dateRange;
    }
  };
  const updateDateRangeFilter: ITableCtx['updateDateRangeFilter'] = (
    newDate: dayjs.Dayjs,
    filterKey: keyof Omit<IDateRangeFilter, 'dateRange'>
  ) => {
    if (dateRangeFilter === null) return;
    const updatedDateRangeFilter = { ...dateRangeFilter } as IDateRangeFilter;
    updatedDateRangeFilter[filterKey] = newDate;
    updatedDateRangeFilter.dateRange = 'Custom';

    setDateRangeFilter(updatedDateRangeFilter);
  };

  // Convert date-range filter in table to DTO filter array
  const getDateRangeDtoFilters: ITableCtx['getDateRangeDtoFilters'] = (field) => {
    if (dateRangeFilter === null) {
      const initDateRange = initDateRangeFilter()!;
      const fromDateFilter = DtoFilter.new({
        field,
        operator: '>=',
        value: formatDateTz(initDateRange.from),
      });
      const toDateFilter = DtoFilter.new({
        field,
        operator: '<=',
        value: formatDateTz(initDateRange.to),
      });
      return [fromDateFilter, toDateFilter];
    }

    const fromDateFilter = DtoFilter.new({
      field,
      operator: '>=',
      value: formatDateTz(dateRangeFilter.from),
    });
    const toDateFilter = DtoFilter.new({
      field,
      operator: '<=',
      value: formatDateTz(dateRangeFilter.to),
    });

    return [fromDateFilter, toDateFilter];
  };

  return (
    <TableCtx.Provider
      value={{
        get filters(): DtoFilter<keyof ISalesSubviewDb>[] {
          const filters: DtoFilter<keyof ISalesSubviewDb>[] = [];

          // Add the App-Status filter
          const appStatusMapped = activeAppStatus.map((s) => `'${s}'`);
          const appStatusFilterValue = ` ( ${appStatusMapped.join(',')} ) `;
          const appStatusFilter = DtoFilter.new<keyof ISalesSubviewDb>({
            field: 'appStatus',
            operator: 'IN',
            value: appStatusFilterValue,
          });
          filters.push(appStatusFilter);

          // Add the Sale-Type filter
          if (activeSaleType !== 'All') {
            const saleTypeFilter = DtoFilter.new<keyof ISalesSubviewDb>({
              field: 'saleType',
              operator: '=',
              value: activeSaleType,
            });
            filters.push(saleTypeFilter);
          }
          return filters;
        },

        dateRangeFilter,
        setDateRangeFilter,
        initDateRangeFilter,
        updateDateRangeFilter,
        getDateRangeDtoFilters,
        get isDateFilterError(): boolean {
          if (dateRangeFilter === null) return false;
          if (!dayjs(dateRangeFilter.from).isValid() || !dayjs(dateRangeFilter.to).isValid())
            return true;

          return dateRangeFilter.from > dateRangeFilter.to;
        },
        addAppStatusOption(newAppStatusOption: AppStatusFilterKey) {
          const filteredActiveAppStatus = activeAppStatus.filter((as) => as !== newAppStatusOption);
          const updatedActiveAppStatus = [...filteredActiveAppStatus, newAppStatusOption];
          setActiveAppStatus(updatedActiveAppStatus);
        },
        removeAppStatusOption(appStatusOptionToRemove: AppStatusFilterKey) {
          // If there will be no items after removing, exit
          if (activeAppStatus.length <= 1) return;

          const filteredActiveAppStatus = activeAppStatus.filter(
            (as) => as !== appStatusOptionToRemove
          );
          setActiveAppStatus(filteredActiveAppStatus);
        },
        resetAppStatusFilter() {
          setActiveAppStatus(['Pending']);
        },

        textSearchFilter,
        setTextSearchFilter(newTextSearchFilter: ITableCtx['textSearchFilter']) {
          setPage({ ...page, skip: 1 });
          setTextSearchFilter_(newTextSearchFilter);
        },

        activeAppStatus,
        activeSaleType,
        setActiveSaleType,

        sorts,
        setSorts,

        page,
        setPage,

        get pageSizeValue(): number {
          return this.page.take!;
        },
        setPageSizeValue(take: number) {
          setPage({ ...page, take });
        },
        // @todo remove once backend calc for `LIMIT` clause (the 'per-page' value) is removed
        get queryPageNumber(): number {
          return Math.round(this.page.skip / this.page.take) + 1;
        },

        resetTableState<SortKey extends SalesSubviewColumnKey = SalesSubviewColumnKey>(
          sortField?: SortKey
        ) {
          setSorts(sortField ? [new SortKendo<SortKey>(sortField, 'desc')] : []);
          setPage({ ...initPage, take: page.take });
          setTextSearchFilter_(null);
          setSalesListRows([]);

          // Reset filters
          setActiveAppStatus(['Pending']);
          setActiveSaleType('All');
        },
      }}
    >
      {children}
    </TableCtx.Provider>
  );
};

export default TableProvider;

export const useTableCtx = <T,>(selector: (state: ITableCtx) => T): T => {
  const ctx = useContext(TableCtx);
  if (!ctx) throw new Error('useTableCtx must be used within TableProvider');

  return selector(ctx);
};
