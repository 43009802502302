import { AppConfig } from './env/interfaces';

export const prodConfig: AppConfig = {
  localUrl: import.meta.env.MODE.includes('standalone')
    ? 'https://app.mydcstech.com'
    : 'https://www.myafstech.com',
  apiUrl: 'https://api.dclteam.com', // nocommit
  openEdgeEnvironment: 'prod', // nocommit
  signalrUrl: 'https://afs-signalrhub.azurewebsites.net/sms', // nocommit
  // @todo move to secrets-manager
  googleMapsApiKey: 'AIzaSyDr-UamFrwCy_ROiuvfSfj8pIO8MvlWKc4', // nocommit
  idsUrl: 'https://auth.mydcstech.com',
};
