import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

const useTotalReceivedListener = () => {
  const { isAch, isCc } = useWsPmtsFormCtx((s) => s);
  const { setValue, trigger, control } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { CpiPaid, DdPmt, LcPaid, NsfPaid, CarPmt, WaiveConvFee, ConvFee } = useWatch({ control });

  useEffect(() => {
    // TotalReceived is user-editable but needs to be recalculated on changes to other fields
    const newTotalReceived =
      (CpiPaid || 0) + (DdPmt || 0) + (LcPaid || 0) + (NsfPaid || 0) + (CarPmt || 0);
    if ((isCc || isAch) && !WaiveConvFee) {
      setValue('TotalReceived', newTotalReceived + (ConvFee ?? 0));
    } else {
      setValue('TotalReceived', newTotalReceived);
    }
    trigger('TotalReceived');
  }, [CpiPaid, DdPmt, LcPaid, NsfPaid, CarPmt, ConvFee, isCc, isAch, WaiveConvFee]);
};

export default useTotalReceivedListener;
