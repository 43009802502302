import { GridCustomCellProps } from "@progress/kendo-react-all";
import dayjs from "dayjs";
import styles from "./KendoCells.module.scss";

interface KendoDateCellProps extends GridCustomCellProps {
  dateFormat: string;
  useUtc?: boolean;
}

/** @deprecated use `DateCell` */
export const KendoDateCell = (props: KendoDateCellProps) => {
  const { dateFormat, useUtc = false, ...restProps } = props;
  const dateValue = restProps.children?.toString();

  const formattedDate = !dateValue ? "" : useUtc ? dayjs(dateValue).utc().format(dateFormat) : dayjs(dateValue).format(dateFormat);

  return (
    <td className={[styles.tableDataContainer, styles.tableDateContainer].join(" ")} {...restProps.tdProps}>
      {formattedDate}
    </td>
  );
};
