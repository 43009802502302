import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { TextInput } from '@/components/inputs/text/TextInput';
// components
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// utils
import { formatAddress, getAddressFromGoogleMaps } from '@/utils/helpers/general';
import { usaStateCodes } from '@/general/regions';

/** ###  */
const BillingInfoFieldGroup: FC = () => {
  const { control, setValue } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);
  const savedPaymentMethod = useWsPmtsFormCtx((s) => s.savedPaymentMethod);
  const useSavedPaymentMethod = useWsPmtsFormCtx((s) => Number(s.shouldUseSavedPaymentMethod));
  const isCc = useWsPmtsFormCtx((s) => s.isCc);

  if (!(isCc && !useSavedPaymentMethod)) return <></>;

  const fullBillingAddress = useSavedPaymentMethod
    ? formatAddress(
        savedPaymentMethod?.address,
        savedPaymentMethod?.city,
        savedPaymentMethod?.state,
        savedPaymentMethod?.zip
      )
    : undefined;

  // Event handlers
  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('BillAddress', fullAddress.address);
    setValue('BillCity', fullAddress.city);
    setValue('BillState', fullAddress.state);
    setValue('BillZip', fullAddress.zip);
  };

  return (
    <>
      <Controller
        name="BillAddress"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <AddressAutocomplete
            label="Address"
            required
            errors={!!errors.BillAddress}
            {...field}
            onPlaceSelected={handlePlaceSelected}
            fullAddress={fullBillingAddress}
          />
        )}
      />
      <Controller
        name="BillCity"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextInput label="City" required errors={!!errors.BillCity} {...field} />
        )}
      />
      <Controller
        name="BillState"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DropdownInput
            label="State"
            data={usaStateCodes}
            required
            errors={!!errors.BillState}
            {...field}
          />
        )}
      />
      <Controller
        name="BillZip"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextInput label="Zip" required errors={!!errors.BillZip} {...field} />
        )}
      />
    </>
  );
};

export default BillingInfoFieldGroup;
