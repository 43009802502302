import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Button from '@/mui/components/MuiKendoButton';
// kendo
import { BasicTable } from '@/components/table/Table';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// state
import { useSidenoteCtx } from './SidenoteProvider';
// utils
import { tableColumns } from './tableColumns';

/** ###  */
const SidenoteTable: FC = () => {
  const sidenoteRows = useSidenoteCtx((s) => s.sidenoteRows);

  // Event handlers
  // @todo implement
  const handleClick = () => {};

  return (
    <AccountsMainPanel navBarTitle="Sidenotes" loading={false}>
      <BasicTable data={sidenoteRows} columns={tableColumns} pageable={true} sortable={true} />

      <Grid container justifyContent="end" py={1}>
        <Button onClick={handleClick}>Add New Sidenote</Button>
      </Grid>
    </AccountsMainPanel>
  );
};

export default SidenoteTable;
