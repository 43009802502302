import React, { useEffect, useState } from 'react';
import { Modal} from '@components';
import { Spacer } from "@/components/spacer/Spacer";
import { customerService } from '@/services/customerService';
import { EcomRecord } from '@/interfaces';
import { CircleSpinner } from 'react-spinners-kit';
import styles from './EmailContentsModal.module.scss';
import DOMPurify from 'dompurify';

type Props = {
  onCloseButtonClick: () => void;
  isOpen: boolean;
  emailRecId: number;
};

export const EmailContentsModal: React.FC<Props> = (props) => {
  const [ecomRecord, setEcomRecord] = useState<EcomRecord | null>(null);
  const [ecomError, setEcomError] = useState(false);
  const [ecomLoading, setEcomLoading] = useState(false);
  const { onCloseButtonClick, isOpen, emailRecId } = props;

  useEffect(() => {
    setEcomLoading(true);
    customerService
      .getEcomRecordByRecId(emailRecId)
      .then((ecomRec) => {
        setEcomRecord(ecomRec!);
      })
      .catch(() => {
        setEcomError(true);
      })
      .finally(() => {
        setEcomLoading(false);
      });
  }, []);

  let modalContents;

  if (ecomError) {
    modalContents = <div>Unable to load email contents</div>;
  } else if (ecomLoading) {
    modalContents = (
      <div className={styles.loading}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );
  } else if (ecomRecord) {
    modalContents = (
      <div className={styles.emailContainer}>
        <div className={styles.addressRow}>
          <div className={styles.fromTo}>From:</div>
          <div className={styles.addressInfoBox}>
            {!!ecomRecord.fromName && <span className={styles.name}>{ecomRecord.fromName}</span>}
            <span className={styles.emailAddress}>
              {'<'}
              {ecomRecord.fromAddress}
              {'>'}
            </span>
          </div>
        </div>
        <Spacer size={14} />
        <div className={styles.addressRow}>
          <div className={styles.fromTo}>To:</div>
          <div className={styles.addressInfoBox}>
            {!!ecomRecord.toName && <span className={styles.name}>{ecomRecord.toName}</span>}
            <span className={styles.emailAddress}>
              {'<'}
              {ecomRecord.toAddress}
              {'>'}
            </span>
          </div>
        </div>
        <Spacer size={22} />
        <div className={styles.tr} />
        <div className={styles.subject}>{ecomRecord.whatSubject}</div>
        <div className={styles.tr} />
        <Spacer size={24} />
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ecomRecord.body!) }} />
      </div>
    );
  }

  return (
    <Modal
      closeButton={true}
      title="Email"
      isOpen={isOpen}
      onCloseButtonClick={() => {
        //resetModal();
        onCloseButtonClick();
      }}
      centerModal={true}
    >
      {modalContents}
    </Modal>
  );
};
