import {
  AddNewInventoryPayload,
  AddNewInventoryResponse,
  AssignableCompany,
  BuyersGuideCurrent,
  BuyersGuideOptions,
  BuyersGuideUpdate,
  CheckInItem,
  CheckInSubmission,
  ColorListItem,
  CurrentInspection,
  GeneralInformation,
  InventoryExpense,
  InventoryExpenseDocument,
  InventoryListRow,
  InventoryVehicle,
  OptionsItem,
  OptionsSubmission,
  PrintBuyersGuide,
  SoldVehicleInfo,
  SuggestedSalesInfo,
  TitleAndTagInformation,
  TransferOption,
  VehicleCost,
  VehicleDetails,
  VehicleInspector,
  VinAuditData,
} from '@/interfaces/Inventory';
import { AxiosService } from './axiosService';
import {
  ApiErrorMessage,
  ApiResponse,
  GetGpsInventoryHistoryPayload,
  GpsHistoryItemDeprec,
  GpsInformation,
  GridData,
  OdometerTrackingData,
  VendorListItem,
  VendorReq,
} from '@/interfaces';
import { ListItem } from '@/features/inventory/inventoryDetails/components/inventoryVehicle/interfaces';
import { toast } from 'react-toastify';
import { store } from '@/store/store';
import { GetInventoryPayload } from '@/features/inventory/Tables/interfaces';
import { MasterLog } from '@/interfaces/MasterLog';
import { GridResponse, ServerGridOptions } from '@/interfaces/ServerGrids';
import { AxiosError } from 'axios';
import { DateFormat, formatDate } from '@/utils/helpers/general';

class InventoryService extends AxiosService {
  public constructor() {
    super();
  }

  async getVendors() {
    try {
      const { data } = await this.axios.get<ApiResponse<VendorListItem[]>>('/Inventory/Vendors');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVendorsForGrid(options: ServerGridOptions) {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<VendorListItem>>>(
        '/Inventory/VendorsForGrid',
        { params: { ...options.toQueryParams() } }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getColors() {
    try {
      const { data } = await this.axios.get<ApiResponse<ColorListItem[]>>('/Inventory/Colors');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** @deprecated use `companyService.getCompanies()`  */
  async getAssignableCompanies(): Promise<AssignableCompany[]> {
    try {
      const { data } = await this.axios.get<ApiResponse<AssignableCompany[]>>(
        '/Inventory/Companies'
      );

      return data.data || [];
    } catch (e) {
      console.error(e);
      toast.error('Unable to fetch companies');
      throw e;
    }
  }

  async vinAuditDecode(vin: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<VinAuditData>>('/Inventory/DecodeVin', {
        params: { vin },
      });

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async addNewInventory(addNewPayload: AddNewInventoryPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<AddNewInventoryResponse>>(
        '/Inventory/Add',
        addNewPayload
      );

      return data.data!;
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to add inventory');
      }
      throw e;
    }
  }

  async getUnsoldList() {
    const state = store.getState();
    const { locId } = state.auth;
    try {
      const { data } = await this.axios.get<ApiResponse<InventoryListRow[]>>(
        '/Inventory/ListUnsold',
        { params: { locId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch unsold inventory list');
      console.error(e);
      throw e;
    }
  }

  async getInventoryList(req: GetInventoryPayload) {
    const state = store.getState();
    const { compId } = state.auth;
    try {
      const res = await this.axios.post<ApiResponse<GridData<InventoryListRow>>>(
        `/Inventory/GetInventoryList?compId=${compId}`,
        req
      );
      return res.data.data!;
    } catch (e) {
      toast.error('Unable to fetch unsold inventory list');
      throw e;
    }
  }

  async getInventoryVehicle(recId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<InventoryVehicle>>(
        '/Inventory/InventoryVehicle',
        { params: { recId } }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch inventory vehicle');
      console.error(e);
      throw e;
    }
  }

  async getGeneralInformation(recId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<GeneralInformation>>(
        '/Inventory/InventoryGeneralInformation',
        { params: { recId } }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch inventory general information');
      console.error(e);
      throw e;
    }
  }

  async getPhysicalLocationOptions(orgId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<string[]>>(
        '/Inventory/PhysicalLocationOptions',
        { params: { orgId } }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch physical location options');
      console.error(e);
      throw e;
    }
  }

  async getPhysicalLocationLogs(recId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<MasterLog[]>>(
        '/Inventory/PhysicalLocationLogs',
        {
          params: { recId },
        }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch physical location logs');
      console.error(e);
      throw e;
    }
  }

  async updateGeneralInformation(recId: string | number, generalInformation: GeneralInformation) {
    try {
      const { data } = await this.axios.post<ApiResponse<GeneralInformation>>(
        `/Inventory/UpdateInventoryGeneralInformation?recId=${recId}`,
        generalInformation
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to update general information');
      console.error(e);
      throw e;
    }
  }

  async getBuyersGuideOptions(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<BuyersGuideOptions[]>>(
        `/Inventory/BuyersGuide`,
        {
          params: { compId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load buyers guide data');
      throw e;
    }
  }

  async getBuyersGuideCurrent(vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<BuyersGuideCurrent>>(
        `/Inventory/GetVehicleBuyersGuide`,
        {
          params: { vehRecId },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load current user buyers guide data');
      throw e;
    }
  }

  async updateBuyersGuide(bgUpdate: BuyersGuideUpdate) {
    try {
      const { data } = await this.axios.put<ApiResponse<BuyersGuideCurrent>>(
        '/Inventory/UpdateVehicleBuyersGuide',
        bgUpdate
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to update inventory buyers guide');
      console.error(e);
      throw e;
    }
  }

  async getTransferOptions(orgId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<TransferOption[]>>(
        '/Inventory/TransferOptions',
        {
          params: { orgId },
        }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch transfer options');
      console.error(e);
      throw e;
    }
  }

  async assignVehicle(vehicleRecId: number, compRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>('/Inventory/AssignVehicle', {
        params: { vehicleRecId, compRecId },
      });
      return data.data;
    } catch (e) {
      toast.error('Unable to assign vehicle');
      console.error(e);
      throw e;
    }
  }

  async getVehicleCostDetails(recId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<VehicleCost>>('/Inventory/VehicleCost', {
        params: { recId },
      });
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch vehicle cost details');
      console.error(e);
      throw e;
    }
  }

  async getVehicleDetails(recId: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<VehicleDetails>>(
        '/Inventory/VehicleDetails',
        {
          params: { recId },
        }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch vehicle details');
      console.error(e);
      throw e;
    }
  }

  async getBodyStyles() {
    try {
      const { data } = await this.axios.get<ApiResponse<ListItem[]>>('/Inventory/BodyStyles');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVehicleTypes() {
    try {
      const { data } = await this.axios.get<ApiResponse<ListItem[]>>('/Inventory/VehicleTypes');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getTransmissions() {
    try {
      const { data } = await this.axios.get<ApiResponse<ListItem[]>>('/Inventory/Transmissions');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getFuelTypes() {
    try {
      const { data } = await this.axios.get<ApiResponse<ListItem[]>>('/Inventory/FuelTypes');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVehicleSizes() {
    try {
      const { data } = await this.axios.get<ApiResponse<ListItem[]>>('/Inventory/VehicleSizes');

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCheckInList(vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CheckInItem[]>>(
        '/Inventory/FullCheckInOptions',
        {
          params: { vehRecId },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVehicleOptionsList(vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<OptionsItem[]>>(
        '/Inventory/FullVehicleOptions',
        {
          params: { vehRecId },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCurrentInspection(vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CurrentInspection>>(
        '/Inventory/GetCurrentInspection',
        { params: { vehRecId } }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateCheckInOption(rowToUpdate: CheckInItem): Promise<CheckInItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<CheckInItem[]>>(
        '/Inventory/UpdateCheckInOption',
        rowToUpdate
      );

      return data.data!;
    } catch (e) {
      toast.error('Unable to update a check in item');
      console.error(e);
      throw e;
    }
  }

  async updateVehicleOption(rowToUpdate: OptionsItem): Promise<OptionsItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<OptionsItem[]>>(
        '/Inventory/UpdateVehicleOption',
        rowToUpdate
      );

      return data.data!;
    } catch (e) {
      toast.error('Unable to update a vehicle option item');
      console.error(e);
      throw e;
    }
  }

  async insertCheckInItem(rowToAdd: CheckInItem): Promise<CheckInItem[]> {
    try {
      const { data } = await this.axios.put<ApiResponse<CheckInItem[]>>(
        '/Inventory/InsertCheckInOption',
        rowToAdd
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to insert a check in item');
      console.error(e);
      throw e;
    }
  }

  async insertVehicleOptionItem(rowToAdd: OptionsItem): Promise<OptionsItem[]> {
    try {
      const { data } = await this.axios.put<ApiResponse<OptionsItem[]>>(
        '/Inventory/InsertVehicleOption',
        rowToAdd
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to insert a vehicle option item');
      console.error(e);
      throw e;
    }
  }

  async repopulateCheckInOptions(vehRecId: number, orgId: number): Promise<CheckInItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<CheckInItem[]>>(
        '/Inventory/RepopulateCheckInOptions',
        { vehRecId, orgId }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to repopulate the check in list');
      console.error(e);
      throw e;
    }
  }

  async repopulateVehicleOptions(
    vehRecId: number,
    orgId: number,
    vin: string,
    locId: number,
    compId: number
  ): Promise<OptionsItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<OptionsItem[]>>(
        '/Inventory/RepopulateVehicleOptions',
        { vehRecId, orgId, vin, locId, compId }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to repopulate the vehicle options list');
      console.error(e);
      throw e;
    }
  }

  async deleteSingleCheckInOption(recId: number, vehRecId: number): Promise<CheckInItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<CheckInItem[]>>(
        '/Inventory/DeleteSingleCheckInOption',
        { recId, vehRecId }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to delete the check in item');
      console.error(e);
      throw e;
    }
  }

  async deleteSingleVehicleOption(recId: number, vehRecId: number): Promise<OptionsItem[]> {
    try {
      const { data } = await this.axios.patch<ApiResponse<OptionsItem[]>>(
        '/Inventory/DeleteSingleVehicleOption',
        { recId, vehRecId }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to delete the vehicle option item');
      console.error(e);
      throw e;
    }
  }

  async getInspectors() {
    try {
      const res = await this.axios.get<ApiResponse<VehicleInspector[]>>('/Inventory/GetInspectors');

      return res.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async submitCheckInInspector(rowToUpdate: CheckInSubmission) {
    try {
      await this.axios.patch('/Inventory/SubmitCheckInInspector', rowToUpdate);
      toast.success('Inspector added successfully.');
      return;
    } catch (e) {
      toast.error('Unable to update a vehicle check in inspector');
      console.error(e);
      throw e;
    }
  }

  async submitOptionsInspector(rowToUpdate: OptionsSubmission) {
    try {
      await this.axios.patch('/Inventory/SubmitOptionsInspector', rowToUpdate);
      toast.success('Inspector added successfully.');
      return;
    } catch (e) {
      toast.error('Unable to update a vehicle option inspector');
      console.error(e);
      throw e;
    }
  }

  async updateVehicleCostDetails(recId: string, vehicleCostDetails: VehicleCost) {
    try {
      const res = await this.axios.post<ApiResponse<VehicleCost>>(
        '/Inventory/UpdateVehicleCost',
        vehicleCostDetails,
        { params: { recId } }
      );
      toast.success('Vehicle cost updated');
      return res.data.data;
    } catch (e) {
      toast.error('Unable to update vehicle cost details');
      console.error(e);
      throw e;
    }
  }

  async updateVehicleDetails(recId: string, vehicleDetails: VehicleDetails) {
    try {
      const { data } = await this.axios.post<ApiResponse<VehicleDetails>>(
        '/Inventory/UpdateVehicleDetails',
        vehicleDetails,
        {
          params: { recId },
        }
      );
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to update vehicle details');
      }
      throw e;
    }
  }

  async deleteVehicle(recId: number, reason: string) {
    try {
      const { data } = await this.axios.delete<ApiResponse<VehicleDetails>>(
        '/Inventory/DeleteVehicle',
        {
          params: { recId, reason },
        }
      );
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to delete vehicle');
      }
      throw e;
    }
  }

  async getSuggestedSalesInfo(invRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SuggestedSalesInfo>>(
        '/Inventory/SuggestedSalesInfo',
        { params: { invRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Suggested Sales Information');
      throw e;
    }
  }

  async getCurrentExpenses(
    recId: string,
    options: ServerGridOptions
  ): Promise<GridResponse<InventoryExpense>> {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<InventoryExpense>>>(
        '/Inventory/CurrentExpenses',
        {
          params: { recId, ...options.toQueryParams() },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch current expenses');
      throw e;
    }
  }

  async getHistoricalExpenses(
    recId: string,
    options: ServerGridOptions
  ): Promise<GridResponse<InventoryExpense>> {
    try {
      const { data } = await this.axios.get<ApiResponse<GridResponse<InventoryExpense>>>(
        '/Inventory/HistoricalExpenses',
        {
          params: { recId, ...options.toQueryParams() },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch historical expenses');
      throw e;
    }
  }

  async updateSuggestedSalesInfo(payload: SuggestedSalesInfo) {
    try {
      await this.axios.put<ApiResponse<SuggestedSalesInfo>>(
        '/Inventory/SuggestedSalesInfo',
        payload
      );
    } catch (e) {
      console.error(e);
      toast.error('Unable to update Suggested Sales Information');
      throw e;
    }
  }

  async getSoldVehicleInfo(vehRecId: number) {
    const { data } = await this.axios.get<ApiResponse<SoldVehicleInfo>>(
      '/Inventory/SoldVehicleInfo',
      { params: { vehRecId } }
    );
    return data.data!;
  }

  async getTitleAndTagInformation(invRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<TitleAndTagInformation>>(
        '/Inventory/TitleAndTagInformation',
        { params: { invRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to retrieve title and tag information');
      throw e;
    }
  }

  async getHeaderText(recId: string): Promise<string> {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>(
        '/Inventory/InventoryDetailsHeaderText',
        {
          params: { recId },
        }
      );

      return data.data!;
    } catch (e) {
      toast.error('Failed to fetch vehicle header text');
      console.error(e);
      throw e;
    }
  }

  async updateTitleAndTagInformation(payload: TitleAndTagInformation) {
    try {
      await this.axios.put('/Inventory/TitleAndTagInformation', payload);
    } catch (e) {
      console.error(e);
      toast.error('Unable to update title and tag information');
      throw e;
    }
  }

  async createVendor(vendor: VendorReq) {
    try {
      await this.axios.post('/Inventory/Vendors', vendor);
      toast.success('Vendor created!');
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to create new vendor');
      }
      throw e;
    }
  }

  async updateVendor(vendor: VendorReq) {
    try {
      await this.axios.put(`/Inventory/Vendors/${vendor.recId}`, vendor);

      toast.success('Vendor updated!');
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to update vendor');
      }
      throw e;
    }
  }

  async createInventoryExpense(expense: InventoryExpense) {
    try {
      const { data } = await this.axios.post<ApiResponse<number>>('/Inventory/Expenses', expense);
      return data?.data;
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to create new inventory expense');
      }
      throw e;
    }
  }

  async updateInventoryExpense(recId: number | string, expense: InventoryExpense) {
    try {
      await this.axios.put(`/Inventory/Expenses/${recId}`, expense);
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to update inventory expense');
      }
      throw e;
    }
  }

  async saveDocument(recId: number | string, doc: InventoryExpenseDocument) {
    try {
      await this.axios.put(`/Inventory/Expenses/${recId}/document`, doc);
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to save expense document');
      }
      throw e;
    }
  }

  async deleteDocument(recId: number | string, orgId: string | number) {
    try {
      await this.axios.delete(`/Inventory/Expenses/${recId}/document?orgId=${orgId}`);
    } catch (e) {
      const error = e as AxiosError;
      const responseData = error.response?.data as ApiErrorMessage;
      if (error.response?.status === 400 && responseData?.message) {
        toast.error(responseData?.message);
      } else {
        toast.error('Unable to delete expense document');
      }
      throw e;
    }
  }

  async getGpsInfoFromInvRecId(invRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GpsInformation>>('/Inventory/Gps', {
        params: { invRecId },
      });
      if (data.data && data.data.expires) {
        data.data.expires = formatDate(data.data.expires, { pattern: DateFormat.DateInput });
      }
      return data.data!;
    } catch (err) {
      toast.error('Unable to fetch GPS information');
      throw err;
    }
  }

  async getGpsHistory(req: GetGpsInventoryHistoryPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<GridData<GpsHistoryItemDeprec>>>(
        '/Inventory/GpsHistory',
        req
      );
      return data.data!;
    } catch (err) {
      toast.error('Unable to fetch GPS history');
      throw err;
    }
  }

  async getOdometerTracking(vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<OdometerTrackingData>>(
        '/Inventory/OdometerTracking',
        {
          params: { vehRecId },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch odometer tracking data');
      console.error(e);
      throw e;
    }
  }

  async printBuyersGuideDoc(payload: PrintBuyersGuide) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>(
        '/Inventory/PrintBuyersGuide',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export const inventoryService = new InventoryService();
