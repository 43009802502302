import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Grid2Props from '@mui/material/Unstable_Grid2/Grid2Props';

/** ###  */
const WsFormBody: FC<Grid2Props.Grid2Props> = ({ children, sx: sxProps, ...props }) => {
  return (
    <Grid
      container
      direction="column"
      flex={1}
      wrap="nowrap"
      gap={2}
      sx={{ overflowX: 'auto', overflowY: 'scroll', p: 2, py: 0, pr: 1.25, ...sxProps }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default WsFormBody;
