import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '../../accountSlice';
// utils
import { useBackListener } from '@/hooks';
import { inCollectionsRoute } from '@/utils/helpers/general';
// interfaces
import { AccountStatus, ColType, QuickActions } from '@/enums';
import { AccountTabRoutes } from './interfaces';
import useEmailFeatureFlag from '@/env/useEmailFeatureFlag';
import { currentEnv } from '@/env';

const customerAdminList = [
  { text: 'Legal Account', route: AccountTabRoutes.LegalAccount },
  { text: 'CBR - Credit Reporting Information', route: AccountTabRoutes.Cbr },
  { text: 'Warranty Request', route: AccountTabRoutes.WarrantyRequest },
  { text: 'Sidenotes', route: AccountTabRoutes.Sidenotes },
  { text: 'Print Documents', route: AccountTabRoutes.FormsAndDocuments },
  { text: 'Payment History', route: AccountTabRoutes.PaymentHistory },
  { text: 'GPS', route: AccountTabRoutes.Gps },
  { text: 'Odometer Tracking', route: AccountTabRoutes.OdometerTracking },
  { text: 'Saved Payment Methods', route: AccountTabRoutes.SavedPaymentMethods },
  { text: 'Payment Reversal', route: AccountTabRoutes.PaymentReversal },
  { text: 'Custom Fields', route: AccountTabRoutes.CustomFields },
];

/** ### @deprecated @todo implement correctly */
const useAccountDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const emailFeatureFlag = useEmailFeatureFlag();
  const isEnvQaOrDev = currentEnv.key === 'development' || currentEnv.key === 'qa';

  const handleBackPress = useCallback(() => {
    // if in accounts, go back to the accounts list
    // if in collections, go back to the selected collections queue
    inCollectionsRoute() ? navigate('../..', { relative: 'path' }) : navigate('../..');
  }, []);
  useBackListener(handleBackPress);

  const isLease = accountInformation?.colType === ColType.Lease;
  const isLoan = accountInformation?.colType === ColType.Finance;
  const isDeferredDown = accountInformation?.colType === ColType.DeferredDown;
  const isSidenote = accountInformation?.colType === ColType.Sidenote;
  const isInactive =
    (accountInformation?.status === 'Charged Off' && accountInformation?.colType === 'LS') ||
    accountInformation?.status === 'Inactive';

  /* missing the following manage options, possibly among others:
      - Post NSF Check
      - Credit Card Chargeback
      - Reverse Payment
      - Request Customer Assistance
      - MyCarPayInformation
      - Account Adjustment
  */

  if (!isInactive) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.CpiRefund)) {
      customerAdminList.push({
        text: 'CPI Refund',
        route: AccountTabRoutes.CpiRefund,
      });
    }
  }

  if (isLease) {
    if (emailFeatureFlag || isEnvQaOrDev) {
      if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.CreditEducation)) {
        customerAdminList.push({
          text: 'Credit Education',
          route: AccountTabRoutes.CreditEducation,
        });
      }
    }

    if (!isInactive) {
      if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.LeaseAmendment)) {
        customerAdminList.push({
          text: 'Lease Amendment',
          route: AccountTabRoutes.LeaseAmendment,
        });
      }
    }
  } else {
    let creditEducationIndex = customerAdminList.findIndex(
      (cal) => cal.route == AccountTabRoutes.CreditEducation
    );
    if (creditEducationIndex) {
      customerAdminList.splice(creditEducationIndex, 1);
    }
  }

  if (isLoan && !isInactive) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.PrincipalPayment)) {
      customerAdminList.push({
        text: 'Principal Payment',
        route: AccountTabRoutes.PrincipalPayment,
      });
    }
  }

  if (!accountInformation?.iopStatus) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.TakePossession)) {
      customerAdminList.push({
        text: 'Take Possession of Vehicle',
        route: AccountTabRoutes.TakePossession,
      });
    }
  }

  if (accountInformation?.iopStatus) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.ReleasePossession)) {
      customerAdminList.push({
        text: 'Release Possession of Vehicle',
        route: AccountTabRoutes.ReleasePossession,
      });
    }
  }

  if (!isInactive) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.OutForRepo)) {
      customerAdminList.push({
        text: 'Order For Repo',
        route: AccountTabRoutes.OutForRepo,
      });
    }
  }

  if (
    accountInformation?.status !== AccountStatus.ChargedOff &&
    (isLease || isLoan || isDeferredDown || isSidenote)
  ) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.ChargeOff)) {
      customerAdminList.push({
        text: isLease ? 'Terminate Lease' : 'Charge Off',
        route: AccountTabRoutes.ChargeOff,
      });
    }
  }

  if (
    accountInformation?.status === AccountStatus.ChargedOff &&
    (isLease || isLoan || isDeferredDown || isSidenote)
  ) {
    if (!customerAdminList.some((cal) => cal.route == AccountTabRoutes.ReactivateChargeOff)) {
      customerAdminList.push({
        text: isLease ? 'Reactivate Lease' : 'Reactivate Charge Off',
        route: AccountTabRoutes.ReactivateChargeOff,
      });
    }
  }

  customerAdminList.sort((a, b) => (a.text < b.text ? -1 : 1));

  const handleClick = (action: QuickActions) => {
    dispatch(accountActions.setQuickActionsListOpen(false));
    dispatch(accountActions.setQuickActionType(action));
    dispatch(accountActions.setQuickActionsModalOpened(true));
  };
  let quickActionList = [];
  if (isInactive) {
    quickActionList = [
      {
        text: QuickActions.SendTextToOptIn,
        onClick: () => handleClick(QuickActions.SendTextToOptIn),
      },
      {
        text: QuickActions.AddAppointment,
        onClick: () => handleClick(QuickActions.AddAppointment),
      },
      {
        text: QuickActions.LedgerCard,
        onClick: () => navigate(`ledger`),
      },
    ].sort((a, b) => (a.text < b.text ? -1 : 1));
  } else {
    quickActionList = [
      {
        text: QuickActions.SendTextToPay,
        onClick: () => handleClick(QuickActions.SendTextToPay),
      },
      {
        text: QuickActions.SendTextToOptIn,
        onClick: () => handleClick(QuickActions.SendTextToOptIn),
      },
      {
        text: QuickActions.SetUpPaymentArrangement,
        onClick: () => handleClick(QuickActions.SetUpPaymentArrangement),
      },
      {
        text: QuickActions.AddAppointment,
        onClick: () => handleClick(QuickActions.AddAppointment),
      },
      {
        text: QuickActions.LedgerCard,
        onClick: () => navigate(`ledger`),
      },
    ].sort((a, b) => (a.text < b.text ? -1 : 1));
  }

  return { customerAdminList, quickActionList };
};

/** @deprecated use context */
export default useAccountDetails;
