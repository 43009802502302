import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
// state
import { useWholesaleFormCtx } from './WholesaleFormProvider';
// utils

/** ### Displays title */
const WsDetailHeader: FC = () => {
  const wsForm = useWholesaleFormCtx((s) => s.wsForm);
  const wsFormVehicle = useWholesaleFormCtx((s) => s.wsFormVehicle);
  const code3 = useWholesaleFormCtx((s) => s.wsFormRes?.code3);

  const lotHeaderText = code3 ? `${code3} ` : '';
  const stockNumHeaderText = wsFormVehicle?.stockNum ? `${wsFormVehicle.stockNum} ` : '';
  const saleStatusHeaderText = wsForm?.saleStatus ? ` ${wsForm.saleStatus} ` : ' Pending ';
  const ymmHeaderText = wsFormVehicle?.ymm ? ` ${wsFormVehicle.ymm} ` : '';
  const vinHeaderText = wsFormVehicle?.vin ? ` - VIN: ${wsFormVehicle.vin}` : '';

  const headerTitle = `${lotHeaderText}${stockNumHeaderText}-${saleStatusHeaderText}-${ymmHeaderText}${vinHeaderText}`;

  return (
    <Grid>
      <Typography
        variant="h5"
        fontWeight={700}
        px={2.5}
        py={2}
        fontSize={24}
        textOverflow="ellipsis"
        noWrap
      >
        {headerTitle}
      </Typography>
    </Grid>
  );
};

export default WsDetailHeader;
