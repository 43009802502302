import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// interfaces
import {
  WsFormVehicle,
  IWsFormAddlDataRes,
  IWsFormRes,
  IWsSalespersonDb,
  WsFormAddlData,
  WsCompany,
} from './interfaces';
import { WsFormErrors, WsForm, WsFormBase } from './formInterfaces';
// data
import { WholesaleFormTabKey, WsTabItem, tabMap, tabItems } from './default';

export interface IWholesaleFormCtx {
  // View controls
  tabItems: WsTabItem[];
  tabMap: Map<WholesaleFormTabKey, WsTabItem>;
  currentTab: WsTabItem;
  navTab: WholesaleFormTabKey;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isWsCompanyModalOpen: boolean;
  setIsWsCompanyModalOpen: (isWsCompanyModalOpen: boolean) => void;

  // Form data
  /** Original API response data for `wsForm`, used for reference/resetting. */
  wsFormRes: IWsFormRes | null;
  /** Set original API response to state - this is used as reference. The state should only be updated with API response data. */
  setWsFormRes: (wsFormRes: IWholesaleFormCtx['wsFormRes']) => void;
  /** Active form state used in request */
  wsForm: WsForm | null;
  setWsForm: (wsForm: WsForm | null) => void;
  /** Set a single field on the active form */
  setWsFormField<T extends keyof WsFormBase = keyof WsFormBase>(field: T, value: WsForm[T]): void;

  /** Original API response data for `formAddlData`, used for reference/resetting. */
  formAddlDataRes: WsFormAddlData | null;
  setFormAddlDataRes: (
    wsFormAddlInfo: IWsFormAddlDataRes | null,
    salespeople?: IWsSalespersonDb[]
  ) => void;
  /** Additional data used in form, from separate API fetch. */
  formAddlData: IWholesaleFormCtx['formAddlDataRes'];

  wsFormVehicle: WsFormVehicle | null;
  selectedWsCompany: WsCompany | null;
  selectedSalesperson: IWsSalespersonDb | null;

  wsFormErrors: WsFormErrors;
  // initWsFormErrors: (wsForm: IWsFormRes) => void;
  setWsFormFieldError: (field: keyof WsFormErrors, msg: string) => void;

  // Getters
  isFormLoaded: boolean;
  isFormEdit: boolean;
}

const WholesaleFormCtx = createContext<IWholesaleFormCtx | null>(null);

const WholesaleFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const routeParams = useParams();
  const userId = useAuthSelector((s) => s.userId!);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWsCompanyModalOpen, setIsWsCompanyModalOpen] = useState(false);
  // Form data
  const [wsFormRes, setWsFormRes_] = useState<IWsFormRes | null>(null);
  const [wsForm, setWsForm] = useState<WsForm | null>(WsForm.fromRes(null));
  const [formAddlDataRes, setFormAddlDataRes_] = useState<WsFormAddlData | null>(null);
  // Validation
  const [wsFormErrors, setWsFormErrors] = useState<WsFormErrors>(new WsFormErrors());

  const setFormAddlDataRes = (
    wsFormAddlInfo: IWsFormAddlDataRes | null,
    salespeople?: IWsSalespersonDb[]
  ) => {
    if (wsFormAddlInfo === null) {
      setFormAddlDataRes_(null);
    } else {
      const wsFormAddlData = new WsFormAddlData(
        wsFormAddlInfo.companies,
        wsFormAddlInfo.vehicles,
        salespeople
      );
      setFormAddlDataRes_(wsFormAddlData);
    }
  };

  const setWsFormRes = (wsFormRes: IWsFormRes | null) => {
    if (wsFormRes === null) {
      setWsFormRes_(null);
      const newForm = WsForm.fromRes(null);
      newForm.salesMan = userId;
      setWsForm(newForm);
    } else {
      setWsFormRes_(wsFormRes);
      setWsForm(WsForm.fromRes(wsFormRes));
    }
  };
  /** Set a single field on the active form */
  const setWsFormField = <T extends keyof WsFormBase = keyof WsFormBase>(
    field: T,
    value: WsForm[T]
  ) => {
    const updatedWsForm = WsForm.setField(field, value, wsForm!);
    setWsForm(updatedWsForm);
  };

  useEffect(() => {
    if (wsForm !== null && formAddlDataRes?.vehicles) {
      // Get vehicle
      const wsVehicle = WsFormVehicle.fromWsFormState(wsForm, formAddlDataRes.vehicles);
      // only override form values when vehicle exists and sale status is "pending"
      if (!wsVehicle || wsForm.saleStatus.toLowerCase() !== 'pending') {
        return;
      }
      // Get vehicle-props for the form
      const { exceedsLim, exempt, notActual, milesIn, milesOut } = wsVehicle;
      const updatedWsFormWithVehicle = WsForm.new({
        ...wsForm,
        exceedsLim: exceedsLim || false,
        exempt: exempt || false,
        notActual: notActual || false,
        milesIn: milesIn || '0',
        milesOut: milesOut || '0',
      });

      setWsForm(updatedWsFormWithVehicle);
    }
  }, [wsForm?.vehRecId]);

  const setWsFormFieldError = (field: keyof WsFormErrors, msg: string) => {
    setWsFormErrors(WsFormErrors.setField(field, msg, wsFormErrors));
  };

  return (
    <WholesaleFormCtx.Provider
      value={{
        tabItems,
        tabMap,
        get currentTab(): WsTabItem {
          return tabMap.get(this.navTab)!;
        },
        navTab: (tabMap.get(routeParams['*'] as WholesaleFormTabKey)
          ? routeParams['*']
          : // default to the first item in the list
            tabItems[0]!.value) as WholesaleFormTabKey,
        isModalOpen,
        setIsModalOpen,
        isWsCompanyModalOpen,
        setIsWsCompanyModalOpen,

        wsFormRes,
        setWsFormRes,
        wsForm,
        setWsForm,
        setWsFormField,

        formAddlDataRes,
        setFormAddlDataRes,
        get formAddlData(): IWholesaleFormCtx['formAddlDataRes'] {
          // Return orig lists
          if (wsFormRes === null || formAddlDataRes === null) return formAddlDataRes;

          // Add the vehicle provided in the form to the lookup list
          const updatedVehicles = [
            ...formAddlDataRes.vehicles,
            WsFormVehicle.new({ ...wsFormRes, recId: wsFormRes.vehRecId || undefined }),
          ];

          // Add the wholesale company provided in the form to the lookup list
          const updatedCompanies = [
            ...formAddlDataRes.companies,
            WsCompany.new({ ...wsFormRes, recId: wsFormRes.wsBuyer || undefined }),
          ];

          return {
            ...formAddlDataRes,
            vehicles: updatedVehicles,
            companies: updatedCompanies,
          };
        },

        get wsFormVehicle(): WsFormVehicle | null {
          const vehicle = WsFormVehicle.fromWsFormState(wsForm, this.formAddlData?.vehicles);
          if (!vehicle || !wsForm?.vehRecId || !vehicle.recId) return null;
          return vehicle;
        },
        get selectedWsCompany(): WsCompany {
          return WsCompany.fromWsFormState(wsForm, this.formAddlData?.companies);
        },
        get selectedSalesperson(): Required<IWsSalespersonDb> | null {
          const selectedSalesperson =
            this.formAddlData!.salespeople.find((s) => s.recId === wsForm?.salesMan) || null;
          return selectedSalesperson;
        },

        wsFormErrors,
        setWsFormFieldError,

        // Getters
        get isFormLoaded(): boolean {
          return wsForm !== null;
        },
        get isFormEdit(): boolean {
          return wsFormRes !== null && !isNaN(Number(wsFormRes.appRecId));
        },
      }}
    >
      {children}
    </WholesaleFormCtx.Provider>
  );
};

export default WholesaleFormProvider;

export const useWholesaleFormCtx = <T,>(selector: (state: IWholesaleFormCtx) => T): T => {
  const ctx = useContext(WholesaleFormCtx);
  if (!ctx) {
    throw new Error('useWholesaleFormCtx must be used within WholesaleFormProvider');
  }
  return selector(ctx);
};
