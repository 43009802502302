import { ICompanyLookup } from "@/interfaces";
import { z } from "zod";
import { RoleRow } from "../../interfaces/role";

export const UserAuthForm = z.object({
  active: z.boolean().default(false),
  dms_Accounts: z.boolean().default(false),
  dms_Collections: z.boolean().default(false),
  dms_Inventory: z.boolean().default(false),
  dms_Sales: z.boolean().default(false),
  // @todo implement properly
  defCompId: z.preprocess((comp) => {
    if (typeof comp === "number") return comp;

    return comp ? (comp as ICompanyLookup)?.recId : null;
  }, z.number()),
  // @todo implement properly
  roleRecId: z.preprocess((role) => {
    if (typeof role === "number") return role;

    return role ? (role as RoleRow)?.recId : null;
  }, z.number()),
});
export type UserAuthForm = z.infer<typeof UserAuthForm>;

export const UpdateUserAuthReq = UserAuthForm.extend({
  userRecId: z.number(),
});
export type UpdateUserAuthReq = z.infer<typeof UpdateUserAuthReq>;

export const AddUserAuthReq = UserAuthForm;
export type AddUserAuthReq = z.infer<typeof AddUserAuthReq>;
