import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';

import { DCS_BLUE } from '@/mui/theme/componentDefaults';
// components
import ColumnLayout from './ColumnLayout';
import ColumnStats from './ColumnStats';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { ColumnData } from './interfaces';
import { formatPercent } from '@/utils/helpers/formatting';

/** ###  */
const DataColumn: FC<ColumnData & { isAlt?: boolean }> = ({
  title = '',
  percent,
  accountCount,
  payDelq,
  ar,
  isAlt = false,
}) => {
  return (
    <ColumnLayout title={title} minWidth={80} sx={{ overflowX: 'hidden' }}>
      <Grid container direction="column" flex={1} width="100%" justifyContent="end">
        <Grid
          container
          flex={percent / 100}
          mx={0.5}
          sx={{ backgroundColor: isAlt ? 'rgb(156, 156, 156)' : DCS_BLUE }}
        ></Grid>

        <ColumnStats
          percent={formatPercent(percent, { is0to100: true })}
          accountCount={accountCount}
          payDelq={formatCurrency(payDelq)}
          ar={formatCurrency(ar)}
        />
      </Grid>
    </ColumnLayout>
  );
};

export default DataColumn;
