import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
// state
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
// interfaces
import { LhPhDetail, LhPhRes } from './interfaces';
import { useSalesViewCtx } from '../../SalesViewProvider';
import { salesService } from '@/services/salesService';
import { useSalesParams } from '../utils';
import { useSalesDetailCtx } from '../SalesDetailProvider';
import LhPhDetailHeader from './LhPhDetailHeader';
import { SalesSubviewListKey } from '../../enums';
import { DtoFilter, DtoPage, PfsSubviewReqDto } from '@/interfaces/requests';
import { LhPhFundingFilterKey } from '../../SalesListView/subviewConfigs/lhPhFunding/dataModelConfig';
import { LhPhSubmissionModel } from '@/interfaces';
import dayjs from 'dayjs';
import { getLocalDateFormat } from '@/general/dates';

const { dateFormatStr } = getLocalDateFormat();

export interface ILhPhDetailCtx {
  lhPhForm: UseFormReturn<LhPhDetail>;
  lhPhRes: LhPhRes | null;
  setLhPhRes: (lhPhRes: LhPhRes | null) => void;
  lhPhDetail: LhPhDetail | null;
  setLhPhDetail: (lhPhDetail: LhPhDetail | null) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  saveLhPhData: (data: LhPhDetail) => void;
  fundAllowance: number;
  getFundAllowance: (
    purprice: number,
    lhphFundRate: number,
    fundExpAllow: number | undefined,
    totexp: number
  ) => number;
}

const LhPhDetailCtx = createContext<ILhPhDetailCtx | null>(null);

const LhPhDetailProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { appRecId } = useSalesParams();
  const setIsLoading = useSalesViewCtx((s) => s.setIsLoading);
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lhPhRes, setLhPhRes] = useState<LhPhRes | null>(null);
  const [lhPhDetail, setLhPhDetail] = useState<LhPhDetail | null>(new LhPhDetail());
  const lhPhForm = useForm<LhPhDetail>({ mode: 'onChange', defaultValues: new LhPhDetail() });

  const { fundExpAllow, totexp, purprice, lhphFundRate } = useWatch({ control: lhPhForm.control });

  const getFundAllowance = (
    purprice: number = 0,
    lhphFundRate: number = 0,
    fundExpAllow?: number,
    totexp: number = 0
  ) => {
    if (fundExpAllow) {
      return purprice * (lhphFundRate / 100) + Math.min(fundExpAllow, totexp);
    } else {
      return purprice * (lhphFundRate / 100) + totexp;
    }
  };

  const saveLhPhData = async (data: LhPhDetail) => {
    const payload: LhPhSubmissionModel = {
      fundExpAllow: data.fundExpAllow,
      fundRequested: data.fundRequested,
      fundDone: data.funded ? data.fundDone : 'PENDING',
      fundRepoSwap: data.fundRepoSwap,
      fundDate: dayjs(data.fundDate).format(dateFormatStr),
      fundAmt: data.fundAmt,
      fundNum: Math.min(data.lhphMaxTerm ?? 0, data.term ?? 0),
      fundPmt: data.fundPmt,
      fundDueDate: dayjs(data.fundDueDate).format(dateFormatStr),
      funded: data.funded ? data.funded : false,
      fundedon: !data.fundedon ? null : dayjs(data.fundedon).format(dateFormatStr),
      fundPO: data.fundPO,
      fundPODate: !data.fundPODate ? null : dayjs(data.fundPODate).format(dateFormatStr),
      fundPOAmt: data.fundPOAmt,
      appRecId: data.appRecId,
    };
    try {
      await salesService.updateLhPhDetailData(payload);
      fetchLhPhData();
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLhPhData = async () => {
    const isNumber = !isNaN(Number(appRecId));
    if (!isNumber) return;
    try {
      setIsLoading(true);
      const lhPhDetailRes = await salesService.getLhPhDetailData(Number(appRecId!));
      const appRecIdFilter = DtoFilter.new<LhPhFundingFilterKey>({
        field: 'appRecId',
        operator: '=',
        value: appRecId!,
      });
      const dtoPage: DtoPage = { take: 1, skip: 1 };
      const dto = PfsSubviewReqDto.new<SalesSubviewListKey, any, LhPhFundingFilterKey>({
        subview: SalesSubviewListKey.lhPhFunding,
        filters: [appRecIdFilter],
        sorts: [],
        page: dtoPage,
      });

      const sales = await salesService.getSalesSubviewList(dto);
      setLhPhRes(sales.data[0] ?? null);
      setLhPhDetail(lhPhDetailRes.data ?? null);
      if (lhPhDetailRes !== null) {
        lhPhForm.reset({
          ...lhPhDetailRes.data,
          fundExpAllow: lhPhDetailRes.data?.fundExpAllow
            ? lhPhDetailRes.data?.fundExpAllow
            : lhPhDetailRes.data?.totexp,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    fetchLhPhData();
    setHeaderTitle(<LhPhDetailHeader />);
  }, [appRecId]);

  return (
    <LhPhDetailCtx.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        lhPhRes,
        setLhPhRes,
        lhPhDetail,
        setLhPhDetail,
        lhPhForm,
        saveLhPhData,
        get fundAllowance(): number {
          return getFundAllowance(purprice, lhphFundRate, fundExpAllow, totexp);
        },
        getFundAllowance,
      }}
    >
      {children}
    </LhPhDetailCtx.Provider>
  );
};

export default LhPhDetailProvider;

export const useLhPhDetailCtx = <T,>(selector: (state: ILhPhDetailCtx) => T): T => {
  const ctx = useContext(LhPhDetailCtx);
  if (!ctx) {
    throw new Error('useLhPhDetailCtx must be used within LhPhDetailProvider');
  }
  return selector(ctx);
};
