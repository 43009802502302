import { FC } from 'react';
// kendo
import { Button, Modal } from '@/components';
import { Spacer } from "@/components/spacer/Spacer";
// utils
import { SetState } from '@/interfaces/utilityTypes';
// style
import styles from './confirmationModal.module.scss';

/** ###  */
const ConfirmationModal: FC<{
  opened: boolean;
  setOpened: SetState<boolean>;
  confirmationAction: () => void;
  message: string;
  cancelAction?: () => void;
  backgroundStyleAddl?: React.CSSProperties;
  dimmerStyle?: React.CSSProperties;
  panelStyle?: React.CSSProperties;
  panelChildrenStyle?: React.CSSProperties;
  closeButton?: boolean;
}> = ({
  opened,
  setOpened,
  confirmationAction,
  message,
  cancelAction,
  backgroundStyleAddl = {},
  dimmerStyle = {},
  panelStyle = {},
  panelChildrenStyle = {},
  closeButton = true,
}) => {
  // Event handlers
  const handleClickCancel = () => {
    cancelAction && cancelAction();
    setOpened(false);
  };
  const handleClickConfirm = () => {
    confirmationAction();
    setOpened(false);
  };

  return (
    <Modal
      isOpen={opened}
      onCloseButtonClick={handleClickCancel}
      closeButton={closeButton}
      centerModal
      backgroundStyleAddl={backgroundStyleAddl}
      dimmerStyle={dimmerStyle}
      panelStyle={panelStyle}
      panelChildrenStyle={panelChildrenStyle}
    >
      <h2 className={styles.header}>{message}</h2>
      <Spacer size={16} />
      <div className={styles.confirmationModalButtons}>
        <Button
          type="button"
          fillMode="outline"
          secondary
          label="Cancel"
          onClick={handleClickCancel}
        />
        <Button type="button" themeColor="warning" label="Confirm" onClick={handleClickConfirm} />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
