import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';
// state
import { useWholesaleFormCtx } from './WholesalesForm/WholesaleFormProvider';
// utils
import { useSalesParams } from './utils';
// interfaces
import { WholesaleFormTabKey } from './WholesalesForm/default';

const useRouteMatch = (patterns: readonly WholesaleFormTabKey[]) => {
  const tabRoute = useParams()['*'];
  for (let idx = 0; idx < patterns.length; idx++) {
    const origRoute = patterns[idx]!;

    if (origRoute === tabRoute) return origRoute;
  }
  return null;
};

/** ### Spans the height of the body and handles form navigation
 * @todo In a future iteration, implement router: https://mui.com/material-ui/integrations/routing/#tabs
 */
const NavSidebar: FC = () => {
  const { appRecId } = useSalesParams();
  const isAppRecId = !isNaN(Number(appRecId));
  const tabItems = useWholesaleFormCtx((s) =>
    s.tabItems.filter((t) => isAppRecId || t.value !== 'documents')
  );

  const currentTab = useRouteMatch(tabItems.map((t) => t.value)) || tabItems[0]!.value;

  // Build tabs
  const tabElems = tabItems.map((t) => {
    const navRoute = t.value;

    return (
      <Tab
        label={t.label}
        value={t.value}
        role="navigation"
        component={Link}
        to={navRoute}
        sx={{
          // Container styles
          alignItems: 'start',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          padding: '20px 30px',
          // Text styles
          textTransform: 'none',
          fontSize: '12px',
          fontWeight: 700,
          // Selected tab styles
          '&.MuiTab-root.Mui-selected': {
            color: DCS_BLUE,
            backgroundColor: 'rgba(41, 70, 149, 0.2)',
          },
        }}
        key={t.label}
      />
    );
  });

  return (
    <Grid
      container
      direction="column"
      height="100%"
      component={Paper}
      wrap="nowrap"
      overflow="hidden"
    >
      <Typography variant="h5" fontWeight={700} fontSize="24px" sx={{ py: 1, px: 4 }}>
        Sales
      </Typography>
      <Divider />
      <Tabs
        value={currentTab}
        orientation="vertical"
        variant="scrollable"
        aria-label="sidebar-nav-tabs"
        TabIndicatorProps={{ sx: { display: 'none' } }}
      >
        {tabElems}
      </Tabs>
    </Grid>
  );
};

export default NavSidebar;
