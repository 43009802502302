import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// style
import styles from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PaymentForm.module.scss';

/** ###  */
const NsfPaidFieldGroup: FC = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);

  return (
    <div className={styles.inlineInputContainer}>
      <span className={styles.inlineInputLabel}>NSF Charges</span>
      <div className={styles.inlineInputItems}>
        <Controller
          name="NsfPaid"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CurrencyInput
              label="Paid"
              required
              horizontalLabel={false}
              errors={!!errors.NsfPaid}
              {...field}
            />
          )}
        />
        <CurrencyInput label="Due" readOnly horizontalLabel={false} value={paymentData?.nsfDue} />
      </div>
    </div>
  );
};

export default NsfPaidFieldGroup;
