import { ExcludeMethods } from '@/interfaces/utilityTypes';
import { IWsFormRes } from './interfaces';

export type WsFormBase = ExcludeMethods<WsForm>;
export type WsFormReq = Omit<
  WsFormBase,
  'milesIn' | 'salesMan' | 'vehRecId' | 'wsBuyer' | 'saleStatus'
> & {
  salesMan: number;
  vehRecId: number;
  wsBuyer: number;
};
export class WsForm {
  constructor(
    public appRecId: number | null,
    public exceedsLim: boolean,
    public exempt: boolean,
    public milesIn: string,
    public milesOut: string,
    public notActual: boolean,
    public saleDate: Date,
    public salesMan: number | null,
    public salesPrice: string,
    public saleStatus: 'Posted' | 'Pending' = 'Pending',
    public vehRecId: number | null,
    public wsBuyer: number | null
  ) {}

  static new(wsForm: WsFormBase & { saleStatus?: WsForm['saleStatus'] }): WsForm {
    return new WsForm(
      wsForm.appRecId || null,
      wsForm.exceedsLim,
      wsForm.exempt,
      wsForm.milesIn,
      wsForm.milesOut,
      wsForm.notActual,
      wsForm.saleDate,
      wsForm.salesMan!,
      wsForm.salesPrice,
      wsForm.saleStatus || 'Pending',
      wsForm.vehRecId!,
      wsForm.wsBuyer!
    );
  }
  // fromResponse
  static fromRes(wsFormRes: IWsFormRes | null): WsForm {
    if (!['Pending', 'Posted', undefined, null].includes(wsFormRes?.saleStatus))
      throw new Error(`Invalid saleStatus: ${wsFormRes?.saleStatus}`);

    return new WsForm(
      wsFormRes?.appRecId || null,
      wsFormRes?.exceedsLim || false,
      wsFormRes?.exempt || false,
      wsFormRes?.milesIn || '0',
      wsFormRes?.milesOut || '0',
      wsFormRes?.notActual || false,
      wsFormRes?.saleDate || new Date(),
      wsFormRes?.salesMan || null,
      wsFormRes?.salesPrice || '0',
      (wsFormRes?.saleStatus as 'Pending' | 'Posted') || 'Pending',
      wsFormRes?.vehRecId || null,
      wsFormRes?.wsBuyer || null
    );
  }
  static setField<T extends keyof WsFormBase>(
    fieldToUpdate: T,
    newValue: WsFormBase[T],
    wsFormToUpdate: WsForm
  ): WsForm {
    return WsForm.new({ ...wsFormToUpdate, [fieldToUpdate]: newValue } as WsForm);
  }

  toReq(): WsFormReq {
    if (!this.salesMan || !this.vehRecId || !this.wsBuyer)
      throw new Error(`Must provide valid salesMan, vehicle & wholesale company`);
    const isSalesPriceNumeric =
      !isNaN(this.salesPrice as any) && !isNaN(parseFloat(this.salesPrice));
    const isMilesOutNumeric = !isNaN(this.milesOut as any) && !isNaN(parseFloat(this.milesOut));
    if (!isSalesPriceNumeric || !isMilesOutNumeric)
      throw new Error(
        `'salesPrice' (${this.salesPrice}) and 'milesOut' (${this.milesOut}) must be numeric.`
      );

    return {
      appRecId: this.appRecId || null,
      exceedsLim: this.exceedsLim,
      exempt: this.exempt,
      milesOut: this.milesOut,
      notActual: this.notActual,
      saleDate: this.saleDate,
      salesMan: this.salesMan!,
      salesPrice: this.salesPrice,
      vehRecId: this.vehRecId!,
      wsBuyer: this.wsBuyer!,
    };
  }
}

export class WsFormErrors {
  constructor(
    public appRecId: FieldErrorInfo = new FieldErrorInfo(),
    public bNames: FieldErrorInfo = new FieldErrorInfo(),
    public codBal: FieldErrorInfo = new FieldErrorInfo(),
    public saleDate: FieldErrorInfo = new FieldErrorInfo(),
    public salesMan: FieldErrorInfo = new FieldErrorInfo(),
    public salesPrice: FieldErrorInfo = new FieldErrorInfo(),
    public saleStatus: FieldErrorInfo = new FieldErrorInfo(),
    public stockNum: FieldErrorInfo = new FieldErrorInfo(),
    public vehRecId: FieldErrorInfo = new FieldErrorInfo(),
    public wsBuyer: FieldErrorInfo = new FieldErrorInfo(),
    public exceedsLim: FieldErrorInfo = new FieldErrorInfo(),
    public exempt: FieldErrorInfo = new FieldErrorInfo(),
    public make: FieldErrorInfo = new FieldErrorInfo(),
    public milesIn: FieldErrorInfo = new FieldErrorInfo(),
    public milesOut: FieldErrorInfo = new FieldErrorInfo(),
    public model: FieldErrorInfo = new FieldErrorInfo(),
    public notActual: FieldErrorInfo = new FieldErrorInfo(),
    public vin: FieldErrorInfo = new FieldErrorInfo(),
    public year: FieldErrorInfo = new FieldErrorInfo(),
    public address: FieldErrorInfo = new FieldErrorInfo(),
    public city: FieldErrorInfo = new FieldErrorInfo(),
    public companyName: FieldErrorInfo = new FieldErrorInfo(),
    public contactEmail: FieldErrorInfo = new FieldErrorInfo(),
    public fax: FieldErrorInfo = new FieldErrorInfo(),
    public phone: FieldErrorInfo = new FieldErrorInfo(),
    public state: FieldErrorInfo = new FieldErrorInfo(),
    public taxId: FieldErrorInfo = new FieldErrorInfo(),
    public zip: FieldErrorInfo = new FieldErrorInfo()
  ) {}
  static new(newWsFormErrors: WsFormErrors) {
    return new WsFormErrors(
      newWsFormErrors.appRecId,
      newWsFormErrors.bNames,
      newWsFormErrors.codBal,
      newWsFormErrors.saleDate,
      newWsFormErrors.salesMan,
      newWsFormErrors.salesPrice,
      newWsFormErrors.saleStatus,
      newWsFormErrors.stockNum,
      newWsFormErrors.vehRecId,
      newWsFormErrors.wsBuyer,
      newWsFormErrors.exceedsLim,
      newWsFormErrors.exempt,
      newWsFormErrors.make,
      newWsFormErrors.milesIn,
      newWsFormErrors.milesOut,
      newWsFormErrors.model,
      newWsFormErrors.notActual,
      newWsFormErrors.vin,
      newWsFormErrors.year,
      newWsFormErrors.address,
      newWsFormErrors.city,
      newWsFormErrors.companyName,
      newWsFormErrors.contactEmail,
      newWsFormErrors.fax,
      newWsFormErrors.phone,
      newWsFormErrors.state,
      newWsFormErrors.taxId,
      newWsFormErrors.zip
    );
  }
  static setField(
    fieldToUpdate: keyof WsFormErrors,
    newMsg: string,
    wsFormErrorsToUpdate: WsFormErrors
  ): WsFormErrors {
    const updatedWsFormErrors: WsFormErrors = {
      ...wsFormErrorsToUpdate,
      [fieldToUpdate]: { msg: newMsg },
    };

    return WsFormErrors.new(updatedWsFormErrors);
  }
}

export class FieldErrorInfo {
  constructor(public msg: string = '') {}
  get isError() {
    return this.msg.length > 0;
  }
}
