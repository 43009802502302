import { FC, PropsWithChildren, createContext } from "react";
import { useParams } from "react-router-dom";
// state
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { useSettingsViewCtx } from "../../AuthSettingsViewProvider";
import useValidatedForm from "@/utils/forms/useValidatedForm";
import { AddUserAuthReq, UpdateUserAuthReq, UserAuthForm } from "./form";

const useCtxState = () => {
  const userRecIdParam = useParams().userRecId;
  const userRecId = getRouteParamNum(userRecIdParam);
  const isNew = userRecIdParam === "new";

  const activeUser = useSettingsViewCtx((s) => s.activeUser);

  const formState = useValidatedForm(
    UserAuthForm,
    // @todo fix
    {
      ...activeUser,
      active: activeUser?.active,
      dms_Accounts: activeUser?.dms_Accounts,
      dms_Collections: activeUser?.dms_Collections,
      dms_Inventory: activeUser?.dms_Inventory,
      dms_Sales: activeUser?.dms_Sales,
      defCompId: activeUser?.company?.recId,
      roleRecId: activeUser?.role?.recId,
    },
    isNew ? AddUserAuthReq : UpdateUserAuthReq,
    (form) => ({ ...form, userRecId })
  );
  console.log("active user:", activeUser?.company, "formcomp:", formState.form.defCompId);

  return {
    isNew,
    formState,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export const useUserAuthDetailCtx = useCtxFactory(Ctx);
const UserAuthDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default UserAuthDetailProvider;
