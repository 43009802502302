import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { CreditEducationProps } from '@/interfaces/CreditEducation';
import CreditEducationProvider from './CreditEducationProvider';
import CustomerInformation from './components/CustomerInformation';
import IntegrationInformation from './components/IntegrationInformation';
import IntegrationControl from './components/IntegrationControl';

const CreditEducation: FC<CreditEducationProps> = (props) => {
  return (
    <Grid container direction="column" flex={1} gap={3}>
      <CustomerInformation {...props} />
      <IntegrationInformation {...props} />
      <IntegrationControl {...props} />
    </Grid>
  );
};

export default (props: CreditEducationProps) => (
  <CreditEducationProvider>
    <CreditEducation {...props} />
  </CreditEducationProvider>
);
