import { ChangeEvent, FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
// utils
import { SetState } from '@/interfaces/utilityTypes';

export const borderRadius = '10px';
export const topBorderRadius = `${borderRadius} ${borderRadius} 0px 0px`;

type ITextSearchFilterState = {
  textSearchFilter: string | null;
  setTextSearchFilter: SetState<string | null>;
};

/** ###  */
const TableToolbar: FC<{
  placeholder: string;
  disabled: boolean;
  ButtonGroup?: FC;
  /** Optionally provide external state. */
  textSearchFilterState?: ITextSearchFilterState;
}> = ({ placeholder, disabled, ButtonGroup, textSearchFilterState }) => {
  // Event handlers
  const handleSearch = textSearchFilterState
    ? (e: ChangeEvent<HTMLInputElement>) =>
        textSearchFilterState.setTextSearchFilter(e.target.value)
    : undefined;

  return (
    <Grid component={GridToolbarContainer} container direction="row" justifyContent="space-between">
      <GridToolbarQuickFilter
        variant="outlined"
        placeholder={placeholder}
        size="small"
        disabled={disabled}
        // @todo move these props and styles to default theme - componentDefaults.tsx
        InputProps={{
          startAdornment: undefined,
          endAdornment: undefined,
          sx: {
            fontSize: 14,
            width: '200px',
            '& .MuiInputBase-input': { p: '7px 10px', m: 0 },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0,0,0,0.08)',
              borderRadius: '8px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgb(204, 204, 204)' },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'rgb(204, 204, 204)' },
          },
        }}
        value={textSearchFilterState?.textSearchFilter}
        onChangeCapture={handleSearch}
      />
      {ButtonGroup && <ButtonGroup />}
    </Grid>
  );
};

export default TableToolbar;
