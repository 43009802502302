// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// components
import SaleTypeColumnMenu from '../../SalesListViewTable/SaleTypeColumnMenu';
import LinkCell from '@/components/kendoCells/LinkCell';
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { IPostedSalesDb } from './interfaces';
import TextCell from '@/components/kendoCells/TextCell';

const buildDetailRoute: (dataItem: IPostedSalesDb) => string = ({ appRecId }) => {
  return `${appRecId}/`;
};

type FxnType = typeof newColumnDef<keyof IPostedSalesDb>;
const optColumnDef: FxnType = (field, title, width, cell, sortable = true, display = true) => {
  const cellComponent = (p: Omit<GridCellProps, 'dataItem'> & { dataItem: IPostedSalesDb }) => {
    if (['Lease', 'Finance'].includes(p.dataItem.saleType!)) {
      return <LinkCell {...p} navRouteBuilder={buildDetailRoute} key={`lc-${field}${title}`} />;
    } else {
      return <TextCell {...p} key={`${field}${title}`} />;
    }
  };

  return {
    field,
    title,
    width,
    sortable,
    display,
    cell: cellComponent,
    cells: { data: cellComponent },
  };
};

export const postedSalesTableColumns: KendoColumnDef<keyof IPostedSalesDb>[] = [
  newColumnDef('stockNum', 'Stk. #', 80),
  // @note disabled while detail views not implemented - keeping for reference
  optColumnDef('bNames', 'Name', 'auto'),
  newColumnDef('vehYmm', 'Year/Make/Model', 'auto'),
  { field: 'saleType', title: 'Type', width: 110, columnMenu: SaleTypeColumnMenu },
  newColumnDef('saleDate', 'Date Sold', 120, 'date'),
  newColumnDef('term', 'Term', 90),
  newColumnDef('invDays', 'Days', 90, 'number'),
  newColumnDef('ror', 'ROR', 'auto', 'percent'),
  newColumnDef('shortName', 'Sales Person', 'auto'),
  newColumnDef('cod', 'COD', 90, 'accounting'),
  newColumnDef('totalDown', 'Total Down', 90, 'accounting'),
  newColumnDef('hasSpecialPmts', 'SP', 90),
  newColumnDef('defDownBal', 'DD Bal.', 'auto', 'accounting'),
  newColumnDef('defDownDaysLate', 'DD Late', 90, 'number'),
  newColumnDef('realDown', 'Real Down', 'auto', 'accounting'),
  newColumnDef('code3', 'Lot', 'auto'),
];
