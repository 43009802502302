import { FC, PropsWithChildren, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import MuiKendoButton from '@/mui/components/MuiKendoButton';
// kendo
import { Loader } from '@/components';
import { ReactComponent as BarChart } from '@assets/icons/chart-simple-solid.svg';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

const borderRadius = '12px';

const CardBody: FC<{ primary: ReactNode; secondary: ReactNode; isLoading: boolean }> = ({
  primary,
  secondary,
  isLoading,
}) => {
  return (
    <Grid container direction="column" p="5px" flex={1} justifyContent="space-between">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        fontSize={22}
        fontWeight={500}
        flex={1}
        p="15px 0px"
      >
        {isLoading ? <Loader size="medium" /> : primary}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={0.5}
        gap={1}
        flex={0}
      >
        <Grid fontSize="12px" flex={1} sx={{ opacity: isLoading ? 0 : 1 }} minHeight="100%">
          {secondary}
        </Grid>
        <MuiKendoButton
          disabled={isLoading}
          color="info"
          sx={{
            maxHeight: 35,
            maxWidth: 35,
            minHeight: 35,
            minWidth: 35,
            borderRadius: 2,
            p: 0,
            m: 0,
          }}
        >
          <BarChart style={{ height: '25px', width: '250px' }} />
        </MuiKendoButton>
      </Grid>
    </Grid>
  );
};

const DashboardCardLayout: FC<
  PropsWithChildren & {
    header: string;
    primary: ReactNode;
    secondary: ReactNode;
    isLoading?: boolean;
  }
> = ({ header, primary, secondary, isLoading = false, children }) => {
  return (
    <Grid
      container
      direction="column"
      flex={1}
      borderRadius={borderRadius}
      sx={{ backgroundColor: '#fff' }}
    >
      <Grid
        component={Typography}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        borderRadius={`${borderRadius} ${borderRadius} 0 0`}
        py={1}
        fontSize={18}
        fontWeight={500}
        color="#fff"
        sx={{ backgroundColor: DCS_BLUE }}
      >
        {header}
      </Grid>

      <CardBody primary={primary} secondary={secondary} isLoading={isLoading} />
    </Grid>
  );
};

export default DashboardCardLayout;
