import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { IWsPmtsFormCtx, useWsPmtsFormCtx } from '../WsPmtsFormProvider';
import { IWsPmtsViewCtx, useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
// style
import styles from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PaymentForm.module.scss';

const handleChangeLcWaived =
  (wsPmtsViewState: IWsPmtsViewCtx, wsPmtsFormState: IWsPmtsFormCtx) =>
  (e: NumericTextBoxChangeEvent) => {
    const { paymentData } = wsPmtsViewState;
    const { isPrincipalOnly, wsPmtsForm } = wsPmtsFormState;
    const { setValue, getValues } = wsPmtsForm;
    const { LcPaid, LcOwed } = getValues();

    if (isPrincipalOnly) return;

    // recalculate late charges when amount to waive changes
    let LcWaived = e.value ?? 0;
    let newWaived = LcWaived;
    let newPaid = LcPaid;

    const maxLateCharge = paymentData?.lcDue ?? 0;
    const newOwed = maxLateCharge - LcPaid - LcWaived;
    if (newOwed !== LcOwed) setValue('LcOwed', newOwed);

    if (LcWaived > maxLateCharge) {
      newPaid = 0;
      newWaived = maxLateCharge;
    } else if (LcWaived < 0) {
      newPaid = maxLateCharge;
      newWaived = 0;
    } else if (LcPaid + LcWaived > maxLateCharge) {
      newPaid = maxLateCharge - LcWaived;
    }

    if (newPaid !== LcPaid) setValue('LcPaid', newPaid);
    if (newWaived !== LcWaived || e.value === null) setValue('LcWaived', newWaived);
  };
const handleChangeLcPaid =
  (wsPmtsViewState: IWsPmtsViewCtx, wsPmtsFormState: IWsPmtsFormCtx) =>
  (e: NumericTextBoxChangeEvent) => {
    const { paymentData } = wsPmtsViewState;
    const { isPrincipalOnly, wsPmtsForm } = wsPmtsFormState;
    const { setValue, getValues } = wsPmtsForm;
    const { LcOwed, LcWaived } = getValues();

    if (isPrincipalOnly) return;

    let LcPaid = e.value ?? 0;
    // Recalculate late charges when amount to pay changes - @todo revisit the infinity check, this seems unnecessary
    // if (1 / LcPaid === -Infinity) setValue('LcPaid', 0);

    const maxLateCharge = paymentData?.lcDue ?? 0;
    const waived = LcWaived ?? 0;

    let newPaid = LcPaid;
    let newWaived = waived;
    let newOwed = LcOwed ?? 0;

    if (LcPaid < 0) {
      newPaid = 0;
      newWaived = 0;
    } else if (LcPaid > maxLateCharge) {
      newPaid = maxLateCharge;
      newWaived = 0;
    } else if (LcPaid + waived > maxLateCharge) {
      newWaived = maxLateCharge - newPaid;
    }

    newOwed = maxLateCharge - LcPaid - waived;

    if (newPaid !== LcPaid) setValue('LcPaid', newPaid);
    if (newWaived !== LcWaived) setValue('LcWaived', newWaived);
    if (newOwed !== LcOwed) setValue('LcOwed', newOwed);
  };

/** ###  */
const LateChargeField: FC = () => {
  const wsPmtsViewState = useWsPmtsViewCtx((s) => s);
  const wsPmtsFormState = useWsPmtsFormCtx((s) => s);
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);

  return (
    <div className={styles.inlineInputContainer}>
      <span className={styles.inlineInputLabel}>Late Charges</span>
      <div className={styles.inlineInputItems}>
        <Controller
          name="LcPaid"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }) => (
            <CurrencyInput
              label="Paid"
              required
              horizontalLabel={false}
              onChange={handleChangeLcPaid(wsPmtsViewState, wsPmtsFormState)}
              errors={!!errors.LcPaid}
              {...field}
            />
          )}
        />
        <Controller
          name="LcWaived"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }) => (
            <CurrencyInput
              label="Waived"
              required
              horizontalLabel={false}
              onChange={handleChangeLcWaived(wsPmtsViewState, wsPmtsFormState)}
              errors={!!errors.LcWaived}
              {...field}
            />
          )}
        />
        <Controller
          name="LcOwed"
          control={control}
          render={({ field }) => (
            <CurrencyInput label="Owed" readOnly horizontalLabel={false} {...field} />
          )}
        />
      </div>
    </div>
  );
};

export default LateChargeField;
