import { FC, MouseEventHandler } from 'react';
// kendo
import {  Button, Modal, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useWsPmtsFormCtx } from './WsPmtsFormProvider';
import { useWsPmtsViewCtx } from '../WsPmtsViewProvider';
// utils
import { accountsService } from '@/services/accountsService';
// style
import styles from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PaymentForm.module.scss';

/** ###  */
const PendingRewriteModal: FC = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const showPendingRewriteModal = useWsPmtsFormCtx((s) => s.showPendingRewriteModal);
  const setShowPendingRewriteModal = useWsPmtsFormCtx((s) => s.setShowPendingRewriteModal);
  const cancellingRewrite = useWsPmtsFormCtx((s) => s.cancellingRewrite);
  const setCancellingRewrite = useWsPmtsFormCtx((s) => s.setCancellingRewrite);

  // Event handlers
  const cancelRewriteAndProceed: MouseEventHandler<HTMLButtonElement> = async (_) => {
    setCancellingRewrite(true);
    try {
      await accountsService.cancelPendingRewrite(paymentData!.appRecId);
      setShowPendingRewriteModal(false);
    } finally {
      setCancellingRewrite(false);
    }
  };

  if (!showPendingRewriteModal) return <></>;

  return (
    <Modal centerModal isOpen={showPendingRewriteModal}>
      <div>There is a pending rewrite on this account.</div>
      <div>In order to proceed with the transaction, the rewrite must be canceled</div>
      <Spacer size={20} />
      <div className={styles.pendingRewriteButtonContainer}>
        <Button
          label="Cancel Transaction"
          fullWidth={false}
          secondary
          onClick={() => setShowPendingRewriteModal(false)}
        />
        <Button
          label="Cancel Rewrite & Complete Payment"
          fullWidth={false}
          themeColor="warning"
          loading={cancellingRewrite}
          onClick={cancelRewriteAndProceed}
        />
      </div>
    </Modal>
  );
};

export default PendingRewriteModal;
