import { Components } from "@mui/material/styles/components";
import { Theme } from "@mui/material/styles/createTheme";
import { alpha } from "@mui/system/colorManipulator";
import { gridClasses } from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type {} from "@mui/x-date-pickers/themeAugmentation";
// components
import CustomPagination from "../components/dataTable/CustomPagination";
// import Grid from '@mui/material/Unstable_Grid2';
// import CommonModal from './components/CommonModal';

// @todo move colors to palette
export const DCS_BLUE = "#294695";
export const LINK_BLUE = "#294695";
export const COLOR_DANGER = "#c60000";
export const COLOR_SUCCESS = "#129a38";
export const TEXT_PRIMARY1 = "#111827"; // $textPrimary1
export const BG_HIGHLIGHT = "#eff0f4"; // $backgroundHighlight
export const HOVER_TRANSITION_SPEED = "0.15s"; // $hoverTransitionSpeed: 0.15s;
export const COLOR_INACTIVE = "#878787";

const ODD_OPACITY = 0.08;

export const componentDefaults: Components<Omit<Theme, "components">> = {
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      root: { textTransform: "none" },
      // root: ({ theme }) => theme.unstable_sx({}),
      // colorPrimary: DCL_BLUE,
      // colorInfo: DCL_BLUE,
    },
  },
  // MuiTab
  MuiFormGroup: {
    styleOverrides: {
      root: ({ theme }) => theme.unstable_sx({ px: 1 }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => theme.unstable_sx({ mt: 0 }),
    },
  },
  MuiTextField: {
    defaultProps: { size: "small", margin: "none" },
    styleOverrides: {
      // root: ({ theme }) => theme.unstable_sx({ mt: 0 }),
    },
  },
  MuiFormControl: {
    defaultProps: {
      component: "fieldset",
      margin: "none",
      size: "small",
    },
  },
  MuiModal: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflowY: "hidden",
          wrap: "nowrap",
        }),
    },
  },
  MuiDataGrid: {
    defaultProps: {
      getRowClassName: (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"),
      disableRowSelectionOnClick: true,
      autoHeight: false,
      sx: {
        maxHeight: 500,
        minHeight: 200,
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
      pageSizeOptions: [10, 25, 50],
      pagination: true,
      disableVirtualization: true,
      autoPageSize: false,
      slots: { pagination: CustomPagination },
    },
    styleOverrides: {
      toolbarContainer: {
        backgroundColor: "rgb(250, 250, 250)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        "& .MuiFormControl-root.MuiTextField-root": { paddingBottom: 0 },
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        padding: 8,
      },
      columnHeaders: ({ theme, ownerState }) => {
        const doesToolbarExist = !!ownerState?.slots?.toolbar;

        return theme.unstable_sx({
          borderTopLeftRadius: doesToolbarExist ? "0px" : "9px",
          borderTopRightRadius: doesToolbarExist ? "0px" : "9px",
          backgroundColor: "#e9e9e9",
          fontSize: 14,
        });
      },
      columnHeader: {
        "&:focus, .MuiDataGrid-cell": { outline: "none" },
        "&:focus-within, .MuiDataGrid-cell": { outline: "none" },
      },
      columnHeaderTitle: {
        color: "#111827",
        fontSize: 14,
        fontWeight: 700,
        fontFamily: "Inter, sans-serif",
        textAlign: "start",
        alignSelf: "start",
      },
      footerContainer: {
        fontSize: 14,
        lineHeight: "20px",
      },
      cell: {
        fontSize: 14,
        lineHeight: "20px",
        "& :focus, :focus-within": { outline: "none" },
      },
      cellContent: {
        fontSize: 14,
        lineHeight: "20px",
        color: "rgb(115, 115, 115)",
        fontWeight: 400,
        textOverflow: "ellipsis",
      },
      overlayWrapper: ({ ownerState }) => {
        // @todo finding a fix for this
        return { minHeight: ownerState?.loading ? 300 : undefined };
      },

      root: ({ theme }) => {
        return theme.unstable_sx({
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",

          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: theme.palette.grey[100],
            "&:hover, &.Mui-hovered": {
              backgroundColor: alpha(theme.palette.action.hover, ODD_OPACITY),
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
            "&.Mui-selected": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
              "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                  theme.palette.primary.dark,
                  ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                  backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity
                  ),
                },
              },
            },
          },
          [`& .${gridClasses.row}.even`]: {
            "&:hover, &.Mui-hovered": {
              backgroundColor: alpha(theme.palette.action.hover, ODD_OPACITY),
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
            "&.Mui-selected": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
              "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                  theme.palette.primary.dark,
                  ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                  backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity
                  ),
                },
              },
            },
          },

          "& *::-webkit-scrollbar, *::-webkit-scrollbar-thumb, *::-webkit-scrollbar-track": {
            height: "3px",
            width: "3px",
          },

          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: `1px solid ${theme.palette.mode === "light" ? "rgba(0,0,0,0.08)" : "#303030"}`,
            color: "#111827",
          },
          "& .MuiDataGrid-columnHeader:last-child, .MuiDataGrid-cell:last-child": {
            borderRight: "0px solid rgba(0,0,0,0)",
          },
        });
      },
    },
  },
  MuiLocalizationProvider: {
    defaultProps: { dateAdapter: AdapterDayjs },
  },
};

const asdf = componentDefaults.MuiTabs;
