import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { Modal } from '@/components/modal/Modal';
// state
import { useInventoryDetailCtx } from '../../../InventoryDetailProvider';
// utils
import { inventoryService } from '@/services/inventoryService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { TransferOption } from '@/interfaces/Inventory';

/** ###  */
const TransferToModal: FC<{ opened: boolean; setOpened: SetState<boolean> }> = ({
  opened,
  setOpened,
}) => {
  const navigate = useNavigate();
  const invRecId = getRouteParamNum(useParams().invRecId);

  const orgId = useInventoryDetailCtx((s) => s.generalInformation?.orgId);

  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [transferringVehicle, setTransferringVehicle] = useState<boolean>(false);
  const [transferOptions, setTransferOptions] = useState<TransferOption[]>([]);
  const [selectedTransferOption, setSelectedTransferOption] = useState<TransferOption | null>(null);

  const loadTransferOptions = async () => {
    if (!orgId) return;

    try {
      setLoadingOptions(true);
      const options = await inventoryService.getTransferOptions(orgId);
      setTransferOptions(options ?? []);
    } finally {
      setLoadingOptions(false);
    }
  };

  const transferVehicle = async () => {
    if (!selectedTransferOption || !invRecId) return;

    setTransferringVehicle(true);
    await inventoryService.assignVehicle(invRecId, selectedTransferOption?.recId);
    setTransferringVehicle(false);

    navigate('/inventory'); // TODO: update to be same list
  };

  useEffect(() => {
    if (opened && transferOptions.length === 0) {
      loadTransferOptions();
    }
  }, [opened]);

  return (
    <Modal
      isOpen={opened}
      centerModal
      closeButton
      onCloseButtonClick={() => setOpened(false)}
      onDimmerClick={() => setOpened(false)}
    >
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Assign Vehicle</div>
      {loadingOptions ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" p="40px 0px">
          <Loader themeColor="primary" />
        </Grid>
      ) : (
        <div>
          <DropdownInput
            label="Assign Vehicle to"
            textField="shortName"
            dataItemKey="recId"
            data={transferOptions}
            onChange={(e) => setSelectedTransferOption(e.value)}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingTop: '20px',
          gap: '10px',
        }}
      >
        <Button label="Cancel" secondary onClick={() => setOpened(false)} />

        <Button
          label="Transfer"
          onClick={() => transferVehicle()}
          loading={transferringVehicle}
          secondary={selectedTransferOption == null}
          disabled={selectedTransferOption == null}
        />
      </div>
    </Modal>
  );
};

export default TransferToModal;
