import { FC, RefObject, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FaMap } from 'react-icons/fa';
// kendo
import { TextInput } from '@/components/inputs/text/TextInput';
// utils
import { config } from '@/config';
// interfaces
import { TextInputProps } from '@/components/inputs/text/interfaces';
// style
import styles from './AddressAutocomplete.module.scss';

type IOnPlaceSelected = (place: google.maps.places.PlaceResult) => void;

/** @deprecated use the MUI implementation */
const AddressAutocomplete: FC<
  TextInputProps & { onPlaceSelected?: IOnPlaceSelected; fullAddress?: string }
> = ({ onPlaceSelected, fullAddress, ...restProps }) => {
  const [prevPlace, setPrevPlace] = useState<google.maps.places.PlaceResult | undefined>(undefined);
  const [mapsUrl, setMapsUrl] = useState<string | undefined>('');

  const options: google.maps.places.AutocompleteOptions = {
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'url'],
    types: [],
  };

  const { ref } = usePlacesWidget({
    apiKey: config.googleMapsApiKey,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (prevPlace !== place) {
        onPlaceSelected && onPlaceSelected(place);
        setMapsUrl(place.url);
        setPrevPlace(place);
      }
    },
    options: options,
  });

  const inputRef = ref as unknown as RefObject<HTMLInputElement>;

  useEffect(() => {
    if (fullAddress) {
      const baseUrl = 'https://maps.google.com/maps?q=';
      const addressString = encodeURIComponent(fullAddress);
      const url = `${baseUrl}${addressString}`;
      setMapsUrl(url);
    }
  }, [fullAddress]);

  return (
    <div className={styles.container}>
      <TextInput ref={inputRef} {...restProps} />

      <div className={styles.mapIconContainer}>
        <FaMap
          className={styles.mapIcon}
          onClick={() => {
            if (!fullAddress || !mapsUrl) {
              fullAddress
                ? toast.error('Unable to open map location')
                : toast.error('No address to open map location');
            } else {
              window.open(mapsUrl);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AddressAutocomplete;
