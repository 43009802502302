import { FC, useMemo } from "react";
// kendo
import { Label, TimePicker, TimePickerProps } from "@progress/kendo-react-all";
// style
import styles from "./TimeInput.module.scss";
import textStyles from "@components/text/Text.module.scss";

export const TimeInput: FC<
  TimePickerProps & { label?: string; errors?: string | boolean; value?: Date }
> = ({ name, label, errors, value, required, ...rest }) => {
  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  const Input = (
    <TimePicker
      id={editorId}
      className={errors ? `${styles.errors} ${styles.input}` : styles.input}
      value={value}
      {...rest}
    />
  );

  return (
    <div className={styles.textContainer}>
      {label ? (
        <div>
          <Label editorId={editorId} className={textStyles.label}>
            {`${label} ${required ? "*" : ""}`}
          </Label>
          {Input}
        </div>
      ) : (
        Input
      )}
      {!!errors && typeof errors === "string" && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
};
