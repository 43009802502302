import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modal/Modal';
// state
// utils
import { inventoryService } from '@/services/inventoryService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { PhysicalLocationLog } from '@/interfaces/Inventory';

dayjs.extend(utc);

/** ###  */
const PhysicalLocationLogModal: FC<{ opened: boolean; setOpened: SetState<boolean> }> = ({
  opened,
  setOpened,
}) => {
  const invRecId = getRouteParamNum(useParams().invRecId);

  const [loading, setLoading] = useState<boolean>(false);
  const [phyiscalLocationLogs, setPhsyicalLocationLogs] = useState<PhysicalLocationLog[]>([]);

  const loadLogs = async () => {
    if (!opened || !invRecId) return;

    setLoading(true);
    const logs = await inventoryService.getPhysicalLocationLogs(invRecId.toString());
    setPhsyicalLocationLogs(
      logs?.map((data): PhysicalLocationLog => {
        const localDate = dayjs.utc(data.logDT).format('MM/DD/YYYY');
        let logText = (data.logWhat ?? '').replace('from  to', 'from "" to').trimEnd();
        if (logText.endsWith('to')) {
          logText += ' ""';
        }
        return { date: localDate, text: logText };
      }) ?? []
    );
    setLoading(false);
  };

  useEffect(() => {
    loadLogs();
  }, [opened]);

  return (
    <Modal
      isOpen={opened}
      centerModal
      closeButton
      onCloseButtonClick={() => setOpened(false)}
      onDimmerClick={() => setOpened(false)}
    >
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Physical Location Log</div>
      {loading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" p="40px 0px">
          <Loader themeColor="primary" />
        </Grid>
      ) : (
        <>
          {phyiscalLocationLogs.length > 0 ? (
            <div>
              <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  {phyiscalLocationLogs.map((log, index) => (
                    <tr
                      key={index}
                      style={
                        index != phyiscalLocationLogs.length - 1
                          ? {
                              borderBottomWidth: '1px',
                              borderBottomColor: 'black',
                              borderBottomStyle: 'solid',
                            }
                          : {}
                      }
                    >
                      <td style={{ paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}>
                        {log.date}
                      </td>
                      <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{log.text}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>No data</div>
          )}
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingTop: '20px',
        }}
      >
        <Button label="Close" secondary onClick={() => setOpened(false)} />
      </div>
    </Modal>
  );
};

export default PhysicalLocationLogModal;
