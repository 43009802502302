import { toast } from 'react-toastify';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
// utils
import { config } from '@/config';
import { systemService } from '@/services/systemService';
// interfaces
import { Events } from '@/enums';
import { ThunkDispatch } from '@reduxjs/toolkit';

export const disconnectSignalR = async (
  connection: HubConnection | null,
  setConnection: (conn: HubConnection | null) => void
) => {
  // Avoid throwing unnecessary errors
  if (!connection) return;

  connection.off('updateMessageCount');

  await connection.stop();
  setConnection(null);
};

export const connectSignalR = async (
  connection: HubConnection | null,
  setConnection: (conn: HubConnection | null) => void,
  locId: number | undefined,
  dispatch: ThunkDispatch<any, any, any>,
  loadUnreadSms: () => Promise<void>
) => {
  // Prevent restarting the connection every time a file is saved in development
  if (connection || !locId) return;

  const token = await systemService.getSignalrToken();

  // Create service-connection instance
  const newConnection = new HubConnectionBuilder()
    .withUrl(config.signalrUrl, { accessTokenFactory: () => token })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.None)
    .build();

  if (!newConnection) {
    toast.warning('Unable to connect to SMS client');
    return;
  }
  setConnection(newConnection);

  // Connect to service
  try {
    await newConnection.start();
    await newConnection.send('RegisterUserByLocationId', locId);
    newConnection.on('updateMessageCount', async (count, originNumber = '') => {
      console.info('unread sms update', count, originNumber);
      loadUnreadSms();

      if (originNumber) {
        // In addition to receiving this event when a new message comes in, we also receive this event for other instances
        // We receive one immediately upon connecting, and maybe also when a message is marked as read
        // I *think* the presence of an originNumber means that it's actually a new message event
        toast.info(`New text message from ${originNumber}`);
        const smsEvent = new Event(Events.ReceivedSMS);
        window.dispatchEvent(smsEvent);
      }
    });
  } catch (err) {
    const errMsg = JSON.stringify(err, null, 2);
    console.error('SignalR: Unable to connect to SMS client', errMsg);
    toast.warning('Unable to connect to SMS client');
  }
};
