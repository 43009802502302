import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

const useCarPaymentListener = () => {
  const { isPrincipalOnly, totalPayment } = useWsPmtsFormCtx((s) => s);
  const { control, setValue, trigger } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { CpiPaid, DdPmt, LcPaid, NsfPaid, TotalReceived } = useWatch({ control });

  useEffect(() => {
    // In regular payments, CarPmt is not a user-editable field - it depends on the values in the other fields
    // For principal only payment, it is editable
    if (!isPrincipalOnly) {
      setValue('CarPmt', totalPayment - (CpiPaid! + LcPaid! + DdPmt! + NsfPaid!));
      trigger('CarPmt');
    }
  }, [totalPayment, CpiPaid, LcPaid, DdPmt, NsfPaid, TotalReceived]);
};

export default useCarPaymentListener;
