import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// utils
// interfaces
import { PaymentType } from '@/enums';

const usePaymentInfoListener = () => {
  const setSavedPaymentMethod = useWsPmtsFormCtx((s) => s.setSavedPaymentMethod);
  const savedPaymentMethod = useWsPmtsFormCtx((s) => s.savedPaymentMethod);
  const useSavedPaymentMethod = useWsPmtsFormCtx((s) => Number(s.shouldUseSavedPaymentMethod));
  const { control, setValue } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { Mpd, PaidBy } = useWatch({ control });

  useEffect(() => {
    const isCC = PaidBy === PaymentType.CreditCard;
    const isACH = PaidBy === PaymentType.Ach;
    setValue('IsNewCard', isCC || isACH ? !useSavedPaymentMethod : false);
    setValue('SaveCard', isCC || isACH ? !useSavedPaymentMethod : false);
    if (useSavedPaymentMethod && savedPaymentMethod) {
      setValue('BillFirstName', savedPaymentMethod.fName ?? '');
      setValue('BillLastName', savedPaymentMethod.lName ?? '');
      setValue('BillAddress', savedPaymentMethod.address ?? '');
      setValue('BillCity', savedPaymentMethod.city ?? '');
      setValue('BillState', savedPaymentMethod.state ?? '');
      setValue('BillZip', savedPaymentMethod.zip ?? '');
      setValue('Mpd.Token', savedPaymentMethod.mpdId!);
    } else if (Mpd!.Token) {
      // Only clear out these values if a saved payment method was already selected (which we can tell based on Mpd.Token being set)
      // Otherwise we're clearing out default values when we don't want to
      setValue('BillFirstName', '');
      setValue('BillLastName', '');
      setValue('BillAddress', '');
      setValue('BillCity', '');
      setValue('BillState', '');
      setValue('BillZip', '');
      setValue('Mpd.Token', null);
      setSavedPaymentMethod(null);
    }
  }, [savedPaymentMethod, useSavedPaymentMethod, PaidBy]);
};

export default usePaymentInfoListener;
