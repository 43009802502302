// components
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { ISalesQuotesDb } from './interfaces';
import { SalesSubviewListKey } from '@/features/Sales/enums';

const buildDetailRoute: (dataItem: ISalesQuotesDb) => string = (dataItem) => {
  return `/sales/${SalesSubviewListKey.dealsQuotes}/${dataItem.recId}`;
};

export const SalesQuotesTableColumns: KendoColumnDef<keyof ISalesQuotesDb>[] = [
  newColumnDef('recId', 'Quote #', 120, 'link', true, true, buildDetailRoute),
  newColumnDef('quoteDate', 'Date of Quote', 'auto', 'date'),
  newColumnDef('customerName', 'Customer', 'auto'),
  newColumnDef('customerPhone', 'Phone Number', 'auto'),
  newColumnDef('location', 'Location', 'auto'),
  newColumnDef('code3', 'Lot', 'auto'),
];
