import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { Checkbox, DropdownInput, RadioGroupInput, TextInput } from '@/components';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

/** ###  */
const PaymentInfoFieldGroup: FC = () => {
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);

  // Form field state values
  const shouldUseSavedPaymentMethod = useWsPmtsFormCtx((s) => s.shouldUseSavedPaymentMethod);
  const useSavedPaymentMethod = Number(shouldUseSavedPaymentMethod);
  const setUseSavedPaymentMethod = useWsPmtsFormCtx((s) => s.setShouldUseSavedPaymentMethod);
  const savedPaymentMethods = useWsPmtsFormCtx((s) => s.savedPaymentMethods);
  const { isAch, savedPaymentMethod, setSavedPaymentMethod } = useWsPmtsFormCtx((s) => s);

  return (
    <>
      {savedPaymentMethods.length > 0 && (
        <RadioGroupInput
          label="Payment Method"
          data={[
            { label: `New ${isAch ? 'Account' : 'Card'}`, value: 0 },
            { label: `${isAch ? 'Account' : 'Card'} on file`, value: 1 },
          ]}
          layout="horizontal"
          value={useSavedPaymentMethod}
          onChange={(e) => setUseSavedPaymentMethod(e.value)}
        />
      )}
      {shouldUseSavedPaymentMethod ? (
        <DropdownInput
          label={isAch ? 'Accounts' : 'Cards'}
          required
          data={savedPaymentMethods}
          dataItemKey="recId"
          textField="last4"
          onChange={(e) => setSavedPaymentMethod(e.value)}
          value={savedPaymentMethod}
        />
      ) : (
        <Controller
          name="SaveCard"
          control={control}
          render={({ field }) => (
            <Checkbox label={`Save ${isAch ? 'Account' : 'Card'}`} {...field} />
          )}
        />
      )}

      {(!shouldUseSavedPaymentMethod || !!savedPaymentMethod) && (
        <>
          <Controller
            name="BillFirstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                label="First Name"
                required
                readOnly={!!useSavedPaymentMethod}
                errors={!!errors.BillFirstName}
                {...field}
              />
            )}
          />
          <Controller
            name="BillLastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                label="Last Name"
                required
                readOnly={!!useSavedPaymentMethod}
                errors={!!errors.BillLastName}
                {...field}
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default PaymentInfoFieldGroup;
