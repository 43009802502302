import { FC } from 'react';
// components
import ViewHeader from './ViewHeader';
import SalesListViewTable from './SalesListViewTable';
// utils
import { useSalesParams } from '../detailView/utils';
// style
import styles from './Sales.module.scss';

/** ### General-use table for any top-level list-view within `Sales` */
const SalesListViewLayout: FC = () => {
  const routeParams = useSalesParams();

  return (
    <div className={styles.container}>
      <ViewHeader />
      <SalesListViewTable />
    </div>
  );
};

export default SalesListViewLayout;
