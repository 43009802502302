import { FC } from 'react';
import { FaPlus } from 'react-icons/fa6';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { GridRenderCellParams } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
// state
import { useCollectionQueuesCtx } from '../CollectionQueuesProvider';
// utils
import { CollectionQueue } from '@/services/collectionsService';

/** ###  */
const ExpandCell: FC<GridRenderCellParams<CollectionQueue>> = ({ row, ...props }) => {
  const expandedRowIdx = useCollectionQueuesCtx((s) => s.expandedRowIdx);
  const setExpandedRow = useCollectionQueuesCtx((s) => s.setExpandedRow);
  const setExpandedRowIdx = useCollectionQueuesCtx((s) => s.setExpandedRowIdx);

  const handleExpand = () => {
    if (expandedRowIdx === props.id) {
      setExpandedRowIdx(null);
      setExpandedRow(null);
    } else {
      setExpandedRowIdx(props.id);
      setExpandedRow(row);
    }
  };

  return (
    <Grid container flex={1}>
      <IconButton onClick={handleExpand} size="small">
        <FaPlus />
      </IconButton>
    </Grid>
  );
};

export default ExpandCell;
