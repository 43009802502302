import createTheme from '@mui/material/styles/createTheme';
// local
import { componentDefaults } from './componentDefaults';
import { kendoMuiTypography } from '../typography';

export const lightTheme = createTheme({
  palette: {},
  typography: kendoMuiTypography,
  components: componentDefaults,
});

// unused
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
