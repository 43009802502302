import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { alpha } from "@mui/system/colorManipulator";
import { DCS_BLUE } from "@/mui/theme/componentDefaults";
// components
import Section from "@/mui/components/form/Section";
import SectionHeader from "@/mui/components/form/Section/SectionHeader";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import AlertHeaderItem from "./AlertHeaderItem";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
// utils
import { getRecommendations } from "./utils";
// interfaces
import { Alert, AlertType } from "./interfaces";
import { AiUnderwritingReportDb } from "../interfaces";

const AlertTypeList: FC<{ title: string; items: Alert[]; bgcolor: string }> = ({
  title,
  items,
  bgcolor,
}) => {
  const listElems = items.map((item) => (
    <ListItem divider sx={{ bgcolor: alpha(bgcolor, 0.1) }} key={item.message}>
      <ListItemText primary={item.message} />
    </ListItem>
  ));

  return (
    <List dense subheader={<ListSubheader sx={{ bgcolor: "unset" }}>{title}</ListSubheader>}>
      {listElems}
    </List>
  );
};

const Recommendations: FC<{ report: AiUnderwritingReportDb | null | undefined }> = ({ report }) => {
  const recommendations = getRecommendations(report);

  return <AlertTypeList title="Recommendations" items={recommendations} bgcolor={DCS_BLUE} />;
};
// const Errors: FC<{ report: AiUnderwritingReportDb | null | undefined }> = ({ report }) => {
//   const errors: string[] = []; // report?.___ ?? []
//   const alerts = errors?.map((alert) => new Alert('error', alert));

//   return <AlertTypeList title="Errors" items={alerts} bgcolor="#ff0000" />;
// };
const Warnings: FC<{ report: AiUnderwritingReportDb | null | undefined }> = ({ report }) => {
  const warnings = report?.finalReviewAlerts ?? [];
  const alerts = warnings?.map((alert) => new Alert("warning", alert));

  return <AlertTypeList title="Warnings" items={alerts} bgcolor="#ffff00" />;
};
const Notices: FC<{ report: AiUnderwritingReportDb | null | undefined }> = ({ report }) => {
  const notices = report?.finalPositiveContributors ?? [];
  const alerts = notices?.map((alert) => new Alert("notice", alert));

  return <AlertTypeList title="Notices" items={alerts} bgcolor="#008000" />;
};
// const OtherAlerts: FC<{ report: AiUnderwritingReportDb | null | undefined }> = ({ report }) => {
//   const otherAlerts: string[] = []; // report?.___ ?? []
//   const alerts = otherAlerts?.map((alert) => new Alert('other', alert));

//   return <AlertTypeList title="Other" items={alerts} bgcolor="#ff0000" />;
// };

/** ###  */
const AlertsSection: FC<{ isInModal?: boolean }> = ({ isInModal = false }) => {
  const currentAppEval = useAiUnderwritingCtx((s) =>
    isInModal ? s.selectedAppEval : s.latestAppEval
  );

  // @note how should we handle these?
  // const applicantAlerts =
  //   currentAppEval?.applicantAlerts
  //     ?.filter((a) => !!a && a !== '')
  //     .map((a) => new Alert('warning', a)) || [];
  // const coApplicantAlerts =
  //   currentAppEval?.coApplicantAlerts
  //     ?.filter((a) => !!a && a !== '')
  //     .map((a) => new Alert('warning', a)) || [];

  const recommendations = getRecommendations(currentAppEval);
  const notices = currentAppEval?.finalPositiveContributors;
  const warnings = currentAppEval?.finalReviewAlerts;

  // Count the number of alert types
  const reportAlertsAggSum: { [key in AlertType]: number } = {
    recommendation: recommendations.length ?? 0,
    error: 0,
    warning: warnings?.length ?? 0,
    notice: notices?.length ?? 0,
    other: 0,
  };

  return (
    <Section isExpandable>
      <SectionHeader label="Alerts" wrap="nowrap" isExpandable>
        <Grid
          container
          direction="row"
          flex={1}
          px={2}
          gap={1}
          justifyContent="center"
          wrap="nowrap"
        >
          <Divider sx={{ alignSelf: "center", flex: 1, maxHeight: "1px", mr: 1, opacity: 0 }} />
          <AlertHeaderItem
            label="Recommendations"
            amount={reportAlertsAggSum.recommendation}
            color={DCS_BLUE}
          />
          <AlertHeaderItem label="Errors" amount={reportAlertsAggSum.error} color="red" />
          <AlertHeaderItem label="Warnings" amount={reportAlertsAggSum.warning} color="#c7a312" />
          <AlertHeaderItem label="Notice" amount={reportAlertsAggSum.notice} color="green" />
          <AlertHeaderItem label="Other" amount={reportAlertsAggSum.other} />

          <Divider sx={{ alignSelf: "center", flex: 1, maxHeight: "1px", ml: 1, opacity: 0 }} />
        </Grid>
      </SectionHeader>

      <SectionBody gap={0}>
        <Recommendations report={currentAppEval} />
        {/* <Errors report={currentAppEval} /> */}
        <Warnings report={currentAppEval} />
        <Notices report={currentAppEval} />
        {/* <OtherAlerts report={currentAppEval} /> */}
      </SectionBody>
    </Section>
  );
};

export default AlertsSection;
