// kendo
import { GridCellsSettings } from "@progress/kendo-react-all";
// components
import { DaysLateCell, StatusCell, StockNumCell, BoolCell, CurrencyCell, DateCell } from "./cells";
// interfaces
import { CellType, GridColumnProps } from "./interfaces";

export const cellTypeDict: { cellType: CellType; cell: GridCellsSettings }[] = [
  { cellType: "StockNumCell", cell: { data: StockNumCell } },
  { cellType: "BoolCell", cell: { data: BoolCell } },
  { cellType: "CurrencyCell", cell: { data: CurrencyCell } },
  { cellType: "DateCell", cell: { data: DateCell } },
  { cellType: "DaysLateCell", cell: { data: DaysLateCell } },
  { cellType: "StatusCell", cell: { data: StatusCell } },
];

export const tableColumns: GridColumnProps[] = [
  { order: 0, field: "selected", display: true, width: 50 },
  {
    order: 1,
    field: "stockNum",
    title: "Stock #",
    display: true,
    width: 85,
    cellType: "StockNumCell",
    cell: StockNumCell,
    cells: { data: StockNumCell },
  },
  { order: 2, field: "recId", title: "RecId", display: true, width: 85 },
  { order: 3, field: "collectionType", title: "Type", display: true, width: "70" },
  {
    order: 4,
    field: "gps",
    title: "GPS",
    display: true,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
  {
    order: 5,
    field: "contactStatus",
    title: "Status",
    display: true,
    width: "80",
    cellType: "StatusCell",
    cells: { data: StatusCell },
    cell: StatusCell,
  },
  { order: 6, field: "buyers", title: "Buyers", width: "auto", display: true },
  {
    order: 7,
    field: "lastPaymentAmount",
    title: "Last Pmt $",
    display: true,
    width: "110",
    cellType: "CurrencyCell",
    cells: { data: CurrencyCell },
    cell: CurrencyCell,
  },
  {
    order: 8,
    field: "lastPaymentDate",
    title: "Last Pmt",
    display: true,
    width: "120",
    cellType: "DateCell",
    cells: { data: DateCell },
    cell: DateCell,
  },
  { order: 9, field: "paymentSchedule", title: "Schedule", display: true, width: "115" },
  {
    order: 10,
    field: "paymentAmount",
    title: "Pmt Amt",
    display: true,
    width: "95",
    cellType: "CurrencyCell",
    cells: { data: CurrencyCell },
    cell: CurrencyCell,
  },
  {
    order: 11,
    field: "principalBalance",
    title: "Principal",
    display: true,
    width: "130",
    cellType: "CurrencyCell",
    cells: { data: CurrencyCell },
    cell: CurrencyCell,
  },
  {
    order: 12,
    field: "paymentDue",
    title: "Pmt Due",
    display: true,
    width: "110",
    cellType: "CurrencyCell",
    cells: { data: CurrencyCell },
    cell: CurrencyCell,
  },
  {
    order: 13,
    field: "daysLate",
    title: "Days Late",
    display: true,
    width: "105",
    cellType: "DaysLateCell",
    cells: { data: DaysLateCell },
    cell: DaysLateCell,
  },
  {
    order: 14,
    field: "called",
    title: "Call",
    display: true,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
  {
    order: 15,
    field: "emailed",
    title: "Email",
    display: true,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
  {
    order: 16,
    field: "texted",
    title: "SMS",
    display: true,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
  {
    order: 17,
    field: "statement",
    title: "TTP",
    display: true,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
  {
    order: 18,
    field: "roboCalled",
    title: "Robo Called",
    display: false,
    width: "80",
    cellType: "BoolCell",
    cells: { data: BoolCell },
    cell: BoolCell,
  },
];
