import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { ZipInput } from '@/components/inputs/zipInput/ZipInput';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// state
import SalesInsuranceProvider, { useSalesInsuranceCtx } from './SalesInsuranceProvider';
// utils
import { salesService } from '@/services/salesService';
import { optionalPhoneValidation } from '@/utils/helpers/formValidation';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { UsaStateCode } from '@/general/regions';
import { handlePlaceSelected } from './utils';
// interfaces
import { SalesInsuranceForm, SalesInsurancePutReq } from './interfaces';
import { InsuranceCoverageOption } from '@/enums/general';

const SubsectionHeader: FC<{ text: string }> = ({ text }) => (
  <Grid component="h3" fontSize={16} fontWeight={700} m={0}>
    {text}
  </Grid>
);

/** @deprecated Needs refactor */
const SalesInsuranceView: FC = () => {
  const insuranceState = useSalesInsuranceCtx((s) => s);
  const fullAddress = useSalesInsuranceCtx((s) => s.fullAddress);
  const canHaveCpi = useSalesInsuranceCtx((s) => s.canHaveCpi);
  const isDisabled = useSalesInsuranceCtx((s) => s.isDisabled);
  const isLoading = useSalesInsuranceCtx((s) => s.isLoading);
  const loadingError = useSalesInsuranceCtx((s) => s.loadingError);

  const cpiSettings = useSalesInsuranceCtx((s) => s.cpiSettings);
  const insuranceInfo = useSalesInsuranceCtx((s) => s.insuranceInfo);
  const cpiRate = useSalesInsuranceCtx((s) => s.cpiRate);

  const { control, watch, handleSubmit } = useSalesInsuranceCtx((s) => s.insuranceInfoForm);
  const isSubmitting = useSalesInsuranceCtx((s) => s.insuranceInfoForm.formState.isSubmitting);

  const appRecId = getRouteParamNum(useParams().id);

  const submitForm = async (formBody: SalesInsuranceForm) => {
    if (!appRecId || cpiSettings === null || !insuranceInfo) return;

    try {
      const reqBody = new SalesInsurancePutReq(formBody, appRecId, cpiSettings);
      await salesService.updateInsuranceInfo(reqBody);
    } catch (_) {}
  };

  const onCpi = watch('onCpi');

  if (isLoading) return <Loader size="large" />;
  if (loadingError) return <div>Error loading data</div>;

  // @todo move nested components to separate files
  return (
    <main>
      <SectionHeader title="Insurance" />
      <Grid
        container
        direction="column"
        gap={5}
        component="form"
        onSubmit={handleSubmit(submitForm)}
      >
        <Grid container direction="column" gap={2}>
          <SubsectionHeader text="General Information" />

          <Controller
            name="insRequired"
            control={control}
            render={({ field }) => (
              <Checkbox {...field} label="Insurance Required" disabled={isDisabled} />
            )}
          />
          <Controller
            name="insPolicyNumber"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Policy Number"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insCoverage"
            control={control}
            render={({ field, fieldState }) => (
              <DropdownInput
                {...field}
                label="Coverage"
                data={InsuranceCoverageOption.options}
                disabled={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insExpire"
            control={control}
            render={({ field, fieldState }) => (
              <DateInput
                {...field}
                label="Expires On"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insComprehensiveDeductible"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                label="Comprehensive Deductible"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insCollisionDeductible"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                label="Collision Deductible"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insFireTheftDeductible"
            control={control}
            render={({ field, fieldState }) => (
              <CurrencyInput
                {...field}
                label="Fire and Theft Deductible"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
        </Grid>

        {canHaveCpi && (
          <Grid container direction="column" gap={2}>
            <SubsectionHeader text="Physical Damage Insurance Requirement" />

            <Controller
              name="onCpi"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label="Cpi Option"
                  disabled={isDisabled}
                  // errors={fieldState.error?.message}
                />
              )}
            />
            {onCpi && (
              <>
                {cpiSettings?.cpiYearlyPremiumEnabled && (
                  <Controller
                    name="cpiYearlyPremium"
                    control={control}
                    rules={{
                      max: {
                        value: 10000,
                        message: 'Exceeds the maximum value',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <CurrencyInput
                        {...field}
                        label="Yearly Premium"
                        readOnly={isDisabled}
                        errors={fieldState.error?.message}
                      />
                    )}
                  />
                )}
                <TextInput readOnly label="Schedule" />
                <CurrencyInput readOnly label="Rate" value={cpiRate} placeholder="-" />
              </>
            )}
          </Grid>
        )}

        <Grid container direction="column" gap={2} flex={1}>
          <SubsectionHeader text="Insurance Company" />

          <Controller
            name="insInsuranceCompany"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Insurance Company Name"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insAddress"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <AddressAutocomplete
                {...field}
                label="Address"
                readOnly={isDisabled}
                onPlaceSelected={handlePlaceSelected(insuranceState)}
                fullAddress={fullAddress || ''}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insCity"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="City"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insState"
            control={control}
            render={({ field, fieldState }) => (
              <DropdownInput
                {...field}
                label="State"
                suggest
                data={UsaStateCode.options}
                disabled={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insZip"
            control={control}
            rules={{ maxLength: 5, minLength: 5 }}
            render={({ field, fieldState }) => (
              <ZipInput
                {...field}
                label="Zip"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="insPhone"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field, fieldState }) => (
              <PhoneInput
                {...field}
                label="Phone"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="insFax"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field, fieldState }) => (
              <PhoneInput
                {...field}
                label="Company Fax"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="insEmail"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Email Address"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insContact"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Contact"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="insContactPhone"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field, fieldState }) => (
              <PhoneInput
                {...field}
                label="Contact Phone"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
                value={field.value || ''}
              />
            )}
          />
          <Controller
            name="insContactFax"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field, fieldState }) => (
              <PhoneInput
                {...field}
                label="Contact Fax"
                readOnly={isDisabled}
                errors={fieldState.error?.message}
                value={field.value || ''}
              />
            )}
          />
        </Grid>

        <Grid container direction="row" justifyContent="end">
          <Button type="submit" label="Submit" loading={isSubmitting} disabled={isDisabled} />
        </Grid>
      </Grid>
    </main>
  );
};

const SalesInsurance: FC = () => (
  <SalesInsuranceProvider>
    <SalesInsuranceView />
  </SalesInsuranceProvider>
);

export default SalesInsurance;
