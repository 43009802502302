export type WholesaleFormTabLabel = 'Wholesale Management' | 'Vehicle' | 'Documents';
export type WholesaleFormTabKey = 'wholesale-management' | 'vehicle' | 'documents';
export type WsTabItem = { label: WholesaleFormTabLabel; value: WholesaleFormTabKey };

export const tabItems: WsTabItem[] = [
  { label: 'Wholesale Management', value: 'wholesale-management' },
  { label: 'Vehicle', value: 'vehicle' },
  { label: 'Documents', value: 'documents' },
];
export const tabMap: Map<WholesaleFormTabKey, WsTabItem> = new Map<WholesaleFormTabKey, WsTabItem>(
  tabItems.map((t) => [t.value, t])
);
