import { FC } from 'react';
// components
import AcctMiscCard from './AcctMiscCard';
import VehicleStatusCard from './VehicleStatusCard';
import AcctStatusCard from './AcctStatusCard';
import GpsCard from '../../components/GpsView/GpsCard';
// style
import styles from './LeftContainer.module.scss';

/** ### Layout for left container of account-detail view */
const LeftContainer: FC = () => (
  <div className={styles.leftContainer}>
    <AcctMiscCard />
    <VehicleStatusCard />
    <AcctStatusCard />
    <GpsCard />
  </div>
);

export default LeftContainer;
