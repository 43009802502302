import { FC } from 'react';
// components
import WsFormBody from '../layoutComponents/WsFormBody';
import GeneralInfoForm from './GeneralInfoForm';
import WsCompanyInfoForm from './WsCompanyInfoForm';
import WholesaleCompanyFormProvider from './WsCompanyModal/WholesaleCompanyFormProvider';
// state
import { useWholesaleFormCtx } from '../WholesaleFormProvider';

/** ### Wholesale Management Tab Layout */
const WholesaleMgmtTab: FC = () => {
  const isFormLoaded = useWholesaleFormCtx((s) => s.isFormLoaded);
  const isAddlDataLoaded = useWholesaleFormCtx((s) => s.formAddlData !== null);

  if (!isAddlDataLoaded || !isFormLoaded) return <></>;

  return (
    <WsFormBody>
      <GeneralInfoForm />
      <WsCompanyInfoForm />
    </WsFormBody>
  );
};

export default () => (
  <WholesaleCompanyFormProvider>
    <WholesaleMgmtTab />
  </WholesaleCompanyFormProvider>
);
