import { toast } from 'react-toastify';
import { AxiosError, isAxiosError } from 'axios';
// state
import { store } from '@/store/store';
import { paymentActions } from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice';
import { oldPaymentActions } from '@/features/old/payment/oldPaymentSlice';
// utils
import { AxiosService } from './axiosService';
import { displayErrors, displayMessage } from '@/utils/helpers/general';
// interfaces
import { ApiResponse } from '@/interfaces/Api';
import { PaymentStatusResponse, SavedPaymentMethod } from '@/interfaces/CreditCard';
import {
  UpdateEmailPostReq,
  UpdateEmailOptOutStatusPostReq,
  ReversalType,
  TextToPayNumberPayload,
} from '@/interfaces/payment';
import {
  CardProcessor,
  PaymentAcceptedIn,
  PaymentInterval,
  PaymentType,
  RepayFeeModel,
} from '@/enums/payment';
import { IPmtSubviewReq, IPmtSubviewKeyRes } from '@/features/Accounts/pmtSubviews/interfaces';
import {
  IReversiblePaymentRes,
  ReversiblePaymentPostReq,
} from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentReversal/interfaces';
import {
  ReversalSuccessNoti,
  RepayErrorNoti,
} from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentReversal/PmtReversalDetail/PmtReversalForm/ReversalToastNotis';

class PaymentService extends AxiosService {
  public constructor() {
    super();
  }

  async getPaymentDetails(colRecId?: number, appRecId?: number, colType?: string) {
    try {
      const { data } = await this.axios.get<GetPaymentData>('/Payment/Data', {
        params: { colRecId, appRecId, colType },
      });
      if (data) {
        // Temp fix so we don't have to update all references to convenienceFee and achConvenienceFee
        // Turns out convenienceFee is the openedge CC convenience fee for *mycarpay* and may be different in DMS
        // Same thing with achConvenienceFee
        // When we get a chance we should just update all references to the old fields to use the new fields
        data.convenienceFee = data.openEdgeDmsCcConvFee ?? data.convenienceFee;
        data.achConvenienceFee = data.openEdgeDmsAchConvFee ?? data.achConvenienceFee;
      }
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  /**
   * @deprecated move to users service (create usersService if not yet created)
   * @note this does not have any auth on the backend
   */
  async getUsersByCompanyId(compId: number) {
    try {
      const res = await this.axios.get<Employee[]>('/Users/GetByCompanyId', {
        params: { compId },
      });
      return res.data;
    } catch (err: any) {
      console.error(err);
      displayErrors('Error fetching users' + err.message);
      throw err;
    }
  }

  async getRepoCompaniesByCompanyId(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<RepoCompany[]>>(
        '/Users/GetRepoCompsByCompanyId',
        { params: { compId } }
      );
      return res.data.data;
    } catch (err) {
      isAxiosError(err) && displayErrors('Error fetching repo companies:' + err.message);
      throw err;
    }
  }

  async postPaymentSubmit(payload: PostPaymentPayload) {
    try {
      // We should split these out to new/old functions
      store.dispatch(oldPaymentActions.setPostPaymentLoading(true));
      store.dispatch(paymentActions.setPostPaymentLoading(true));
      const res = await this.axios.post('/Payment/Submit', payload);
      toast.info('Payment posted');
      return res?.data;
    } catch (err) {
      console.error(err);

      // @todo handle error properly
      if (isAxiosError(err)) {
        const toastStr = err.response?.status === 403 ? err.response.data : err.message;
        toast.error(toastStr);
      }
      throw err;
    } finally {
      store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
      store.dispatch(paymentActions.setPostPaymentLoading(false));
    }
  }
  async getSavedPaymentMethods(appRecId: number) {
    try {
      const { data } = await this.axios.get<SavedPaymentMethod[]>('/Payment/SavedPaymentMethods', {
        params: { appRecId },
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async deleteSavedPaymentMethods(appRecId: number, mpdId: string) {
    try {
      await this.axios.delete('/Payment/SavedPaymentMethod', {
        params: { appRecId, mpdId },
      });
    } catch (err: any) {
      toast.error('Unable to delete saved payment method.');
      throw err;
    }
  }

  async postUpdatedEmail(payload: UpdateEmailPostReq) {
    try {
      const { data } = await this.axios.post('/Users/UpdateEmail', payload);
      return data;
    } catch (err: any) {
      console.error(err.message);
      throw err;
    }
  }

  async postUpdatedOptOutStatus(payload: UpdateEmailOptOutStatusPostReq) {
    try {
      const { data } = await this.axios.post('/Users/UpdateEmailOptOut', payload);
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async getMiscCategories(orgId: number) {
    try {
      const { data } = await this.axios.get<string[]>('/Payment/MiscCategories', {
        params: { orgId },
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async postOpenEdgeCCPayment(tempToken: string, paymentLogRecId: number) {
    try {
      const { data } = await this.axios.post(`/Payment/Complete`, {
        PaymentLogRecId: paymentLogRecId,
        TemporaryToken: tempToken,
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async getReceiptUrl(pmtRecId: number) {
    try {
      store.dispatch(oldPaymentActions.setPostPaymentLoading(true));
      store.dispatch(paymentActions.setPostPaymentLoading(true));
      const { data } = await this.axios.get<string>('/Payment/ReceiptURL', {
        params: { pmtRecId },
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      console.error(`Error: getReceiptUrl ${pmtRecId}`, err);
      toast.error(`Error: getReceiptUrl ${pmtRecId}`);
      throw err;
    } finally {
      store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
      store.dispatch(paymentActions.setPostPaymentLoading(false));
    }
  }

  async getPaymentStatus(paymentLogId: number) {
    try {
      const res = await this.axios.get<PaymentStatusResponse>('/Payment/PaymentStatus', {
        params: { paymentLogId },
      });
      return res.data;
    } catch (err: any) {
      displayErrors(err.message);
      console.error(`Error: getPaymentStatus ${paymentLogId}`, err);
      toast.error(`Error: getPaymentStatus ${paymentLogId}`);
      throw err;
    }
  }

  async sendTextToPay(appRecId: number, colRecId: number, email: string, phone: string) {
    try {
      const { data } = await this.axios.post('/Payment/SendTextToPayMessage', {
        appRecId,
        colRecId,
        email,
        phone,
      });
      displayMessage('Text message successfully sent');
      return data;
    } catch (err: any) {
      if (err?.response?.status === 422 && err?.response?.data) {
        displayErrors(err.response.data);
      } else {
        displayErrors('Unable to send text-to-pay message');
      }
      throw err;
    }
  }

  async postToggleTextToPay(payload: { colRecId: number; enabled: boolean }) {
    try {
      const { data } = await this.axios.post('/Users/ToggleTextToPay', payload);
      return data;
    } catch (err: any) {
      displayErrors('Unable to update opt out status');
      throw err;
    }
  }

  async updateTextToPayNumber(payload: TextToPayNumberPayload) {
    try {
      const { data } = await this.axios.post('/Users/UpdateTextToPayNumber', payload);
      return data;
    } catch (err: any) {
      displayErrors('Unable to update Text-To-Pay phone number');
      throw err;
    }
  }

  async sendReceipt(paymentRecId: number) {
    try {
      const { data } = await this.axios.post('/Payment/EmailReceipt', {
        paymentRecId,
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPaymentProviders(colRecId: number) {
    try {
      const { data } = await this.axios.get<PaymentProviders>('/Payment/Providers', {
        params: { colRecId },
      });
      return data;
    } catch (err) {
      toast.error(`Error fetching payment providers. Collection ID: ${colRecId}`);
      console.error(err);
      throw err;
    }
  }

  async getPaymentProvidersByCompany(compId: number) {
    const { data } = await this.axios.get<PaymentProviders>('/Payment/ProvidersByCompany', {
      params: { compId },
    });
    return data;
  }

  async getPaymentSubviewList(req: IPmtSubviewReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<IPmtSubviewKeyRes>>(
        '/Payment/GetPaymentSubviewList',
        req
      );
      return data.data!;
    } catch (err: any) {
      toast.error('Unable to get payment subview list');
      throw err;
    }
  }

  async getReversiblePayments(colRecId: number, reversalType: ReversalType) {
    try {
      const { data } = await this.axios.get<ApiResponse<IReversiblePaymentRes[]>>(
        '/Payment/GetReversiblePayments',
        { params: { colRecId, reversalType } }
      );
      return data.data || [];
    } catch (err: any) {
      toast.error('Unable to fetch reversible payments');
      throw err;
    }
  }

  async submitPaymentReversal(req: ReversiblePaymentPostReq): Promise<IReversiblePaymentRes[]> {
    try {
      const res = await this.axios.post<ApiResponse<IReversiblePaymentRes[]>>(
        '/Payment/ReversePayment',
        req
      );
      if (res instanceof AxiosError) throw res;
      const asdf = toast.success(() => <ReversalSuccessNoti req={req} />);

      return res.data.data || [];
    } catch (e: any) {
      const err: AxiosError = e;
      // @ts-ignore
      const errMsg: string = err.response?.data?.Message;
      if (errMsg.includes('`pnRefs` < 1')) {
        toast.error(RepayErrorNoti);
      } else {
        toast.error(`Unable to submit payment reversal: ${errMsg}`);
      }
      throw err;
    }
  }
}

/**
 * @todo move to interface file - potential cyclical import
 * @note these fields are not returned from request `GET /Payment/Data?colRecId` but exist on this interface:
 * 'repayCfeeModelTest' | 'repayCfeeModelProd' | 'cobuyerDob' | 'GpiCustStatmentEnabled'
 */
export interface GetPaymentData {
  compId: number;
  orgId: number;
  locId: number;
  colType: string;
  accountNum: string;
  dpa: boolean | null;
  pmtDue: number;
  paAmt: number;
  lcDue: number;
  nsfDue: number;
  miscDue: number;
  cpiDueNow: number;
  ddDueNow: number;
  ddNextAmt: number;
  ddNextDue: Date | string | null;
  defDownBal: number;
  payOff: number;
  maxPayment: number;
  allowPartialPaymentWhenPastDue: boolean | null;
  dateSold: Date | string | null;
  intRate: number;
  snBalance: number;
  prinBal: number;
  accrued: number;
  snDueNow: number;
  cpiStatus: string;
  nextDueAmount: number;
  nextDueDate: Date | string | null;
  nextPaymentDue?: any;
  // buyerNoCall: boolean, // @note this field is sent from backend
  // buyerNoText: boolean, // @note this field is sent from backend
  // vehRecId: number, // @note this field is sent from backend
  dmsNextDueAmount: number;
  dmsNextDueDate: Date | string | null;
  appRecId: number;
  colRecId: number;
  lastPmtAmt: number;
  lastPmtPaid: Date | string | null;
  stockNum: string;
  minCreditCardAmount: number;
  convenienceFee: number;
  minAchAmount: number;
  achConvenienceFee: number;
  nowLocal: Date | string | null;
  daysLate: number | null;
  dmsDaysLate: number | null;
  /** @deprecated */
  repayCfeeModelTest: RepayFeeModel;
  /** @deprecated */
  repayCfeeModelProd: RepayFeeModel; // @note this can be an empty string on backend
  repayConvFeeTest: number;
  repayConvFeeProd: number;
  repayCanWaiveFeeTest: boolean;
  repayCanWaiveFeeProd: boolean;
  buyerRecId: number; // @note this is nullible on backend
  buyerFirstName: string;
  buyerLastName: string;
  buyerAddr: string;
  buyerCity: string;
  buyerState: string;
  buyerZip: string;
  buyerHPhone: string;
  buyerCPhone: string;
  buyerWPhone: string;
  buyerEmployer: string;
  buyerEmail: string;
  buyerNoEmail: boolean;
  buyerDob: string; // @note this is nullible on backend
  buyerSsn: string;
  coBuyerRecId: number | null;
  cobuyerFirstName: string;
  cobuyerLastName: string;
  cobuyerAddr: string;
  cobuyerCity: string;
  cobuyerState: string;
  cobuyerZip: string;
  cobuyerHPhone: string;
  cobuyerCPhone: string;
  cobuyerWPhone: string;
  cobuyerEmployer: string;
  cobuyerEmail: string;
  cobuyerNoEmail: boolean;
  /** @deprecated */
  cobuyerDob: string;
  cobuyerSsn: string;
  /** @deprecated */
  GpiCustStatmentEnabled: boolean;
  gpiCustStatmentEnabled: boolean;
  hasCobuyer: boolean | null;
  year: string;
  make: string;
  model: string;
  vin: string;
  color: string;
  trim: string;
  vehSize: string;
  amountFinanced: number | null;
  tOfPBal: number | null;
  originalBal: number | null;
  insCName: string;
  insPolicyNum: string;
  insExpire: Date | string | null;
  insCanceled: boolean | null;
  insCanDate: Date | string | null;
  insCoverage: string;
  insCompDed: number | null;
  insFireDed: number | null;
  onCpi: boolean | null;
  cpiRate: number | null;
  cpiSchedule: PaymentInterval | null;
  cpiTotalPaid: number | null;
  cpiFirstDueDate: string | null;
  cpiHold: number | null;
  cpiAvailable: number | null;
  openedgeApiKey: string;
  repayTestMode: boolean;
  openEdgeDmsAchConvFee: number;
  openEdgeDmsCcConvFee: number;
}

/** @todo move to interface file */
export interface PostPaymentPayload {
  SendB: boolean;
  SendC: boolean;
  EmailB: string;
  EmailC: string;
  EmailBOLD: string;
  EmailCOLD: string;
  PmtType: string;
  WaiveCCConvFee: boolean;
  WaiveAchConvFee: boolean;
  WaiveConvFee: boolean;
  CcConvFee: number;
  AchConvFee: number;
  ConvFee: number;
  OrgId: number;
  LocId: number;
  CompId: number;
  ColType: string;
  CarPmt: number;
  PayNote: string;
  PaidBy: string;
  PaidRef: string;
  Change: number;
  TotalReceived: number;
  PaidIn: string;
  PayToFrom: string;
  UsePrintServer: boolean;
  TakenBy: string;
  ColRecId?: number;
  AppRecId: number;
  StockNum: string;
  AcctNum: string;
  LcPaid: number;
  LcWaived: number;
  LcOwed?: number;
  NsfPaid: number;
  MiscPaid: number;
  CpiPaid: number;
  DdPmt: number;
  SnPmt: number;
  AchAcctType: number | null;
  PaymentType: string;
  IsAch: boolean;
  IsNewCard: boolean;
  // MpdRecId: boolean; TODO: Get more info
  Mpd: {
    Token: string | null;
    AccountNumber?: string;
    RoutingNumber?: string;
  };
  BillFirstName: string;
  BillLastName: string;
  BillEmail: string;
  BillAddress: string;
  BillCity: string;
  BillState: string;
  BillZip: string;
  ProcessorType: number | null;
  // PoNum: string; TODO: Need more info
  // CustomerId: string;
  SaveCard: boolean;
  Source: string;
  IsRecurring: boolean;
  PmtContext: string;
  //TODO: More info on this
  // TransactionResult: null;
  ProcessorTestMode: boolean;
  UserRecId: number; // user who took the payment
  UserEmail: string;
  UserShortName: string;
  // TODO: Need more info
  MCat: string;
  // UxOpID: null;
  IsMyCarPay: boolean;
  TakenByPassword: string; // password of user who took the payment
}

/** @todo move to static method for class - this fxn is specific to one domain */
export const getDefaultPostPaymentPayload = (
  inPersonPayment: GetPaymentData
): PostPaymentPayload => ({
  SendB: false,
  SendC: false,
  EmailB: inPersonPayment.buyerEmail || '',
  EmailC: inPersonPayment.cobuyerEmail || '',
  EmailBOLD: '',
  EmailCOLD: '',
  PmtType: '',
  WaiveCCConvFee: false,
  WaiveAchConvFee: false,
  WaiveConvFee: false,
  CcConvFee: inPersonPayment.convenienceFee,
  AchConvFee: inPersonPayment.achConvenienceFee,
  ConvFee: 0,
  OrgId: inPersonPayment.orgId,
  LocId: inPersonPayment.locId,
  CompId: inPersonPayment.compId,
  ColType: inPersonPayment.colType,
  CarPmt: 0,
  PayNote: '',
  PaidBy: '',
  PaidRef: '',
  Change: 0,
  TotalReceived: 0,
  PaidIn: '',
  PayToFrom: `${inPersonPayment.buyerFirstName} ${inPersonPayment.buyerLastName}`,
  UsePrintServer: false,
  TakenBy: '',
  ColRecId: 0,
  AppRecId: inPersonPayment.appRecId,
  StockNum: inPersonPayment.stockNum,
  AcctNum: inPersonPayment.accountNum,
  LcPaid: 0,
  LcWaived: 0,
  LcOwed: 0,
  NsfPaid: 0,
  MiscPaid: 0,
  CpiPaid: 0,
  DdPmt: 0,
  SnPmt: 0,
  AchAcctType: 0,
  PaymentType: '',
  IsAch: false,
  IsNewCard: false,
  Mpd: { Token: null },
  BillFirstName: inPersonPayment.buyerFirstName,
  BillLastName: inPersonPayment.buyerLastName,
  BillEmail: inPersonPayment.buyerEmail,
  BillAddress: inPersonPayment.buyerAddr,
  BillCity: inPersonPayment.buyerCity,
  BillState: inPersonPayment.buyerState,
  BillZip: inPersonPayment.buyerZip,
  ProcessorType: null,
  SaveCard: false,
  Source: '',
  IsRecurring: false,
  PmtContext: '',
  ProcessorTestMode: false,
  UserRecId: 0,
  UserEmail: '',
  UserShortName: '',
  MCat: '',
  IsMyCarPay: false,
  TakenByPassword: '',
});

/** @todo move to static method for class - this fxn is specific to one domain */
export const createMiscPaymentPayload = (): PostPaymentPayload => ({
  AcctNum: '',
  AchAcctType: 0,
  AchConvFee: 0,
  AppRecId: 0,
  BillAddress: '',
  BillCity: '',
  BillEmail: '',
  BillFirstName: '',
  BillLastName: '',
  BillState: '',
  BillZip: '',
  CarPmt: 0,
  CcConvFee: 0,
  Change: 0,
  ColRecId: 0,
  ColType: 'MI',
  CompId: 0,
  ConvFee: 0,
  CpiPaid: 0,
  DdPmt: 0,
  EmailB: '',
  EmailBOLD: '',
  EmailC: '',
  EmailCOLD: '',
  IsAch: false,
  IsMyCarPay: false,
  IsNewCard: false,
  IsRecurring: false,
  LcOwed: 0,
  LcPaid: 0,
  LcWaived: 0,
  LocId: 0,
  MCat: '',
  MiscPaid: 0,
  Mpd: { Token: null },
  NsfPaid: 0,
  OrgId: 0,
  PaidBy: '',
  PaidIn: '',
  PaidRef: '',
  PayNote: '',
  PayToFrom: '',
  PaymentType: '',
  PmtContext: '',
  PmtType: '',
  ProcessorTestMode: false,
  ProcessorType: null,
  SaveCard: false,
  SendB: false,
  SendC: false,
  SnPmt: 0,
  Source: '',
  StockNum: '',
  TakenBy: '',
  TakenByPassword: '',
  TotalReceived: 0,
  UsePrintServer: false,
  UserEmail: '',
  UserRecId: 0,
  UserShortName: '',
  WaiveAchConvFee: false,
  WaiveCCConvFee: false,
  WaiveConvFee: false,
});

/** @todo move to static method for class - this fxn is specific to one domain */
export const getDefaultPostPaymentPayloadNew = (
  paymentData: GetPaymentData
): PostPaymentPayload => {
  // This assumes a regular payment
  // So things like CpiPaid are defaulted to the amount they have due
  // In the case of e.g. principal only payment, you will need to reset those
  return {
    AcctNum: paymentData.accountNum,
    AchAcctType: 0,
    AchConvFee: paymentData.achConvenienceFee,
    AppRecId: paymentData.appRecId,
    BillAddress: paymentData.buyerAddr,
    BillCity: paymentData.buyerCity,
    BillEmail: paymentData.buyerEmail,
    BillFirstName: paymentData.buyerFirstName,
    BillLastName: paymentData.buyerLastName,
    BillState: paymentData.buyerState,
    BillZip: paymentData.buyerZip,
    CarPmt: 0,
    CcConvFee: paymentData.convenienceFee,
    Change: 0,
    ColRecId: paymentData.colRecId || 0,
    ColType: paymentData.colType,
    CompId: paymentData.compId,
    ConvFee: 0,
    CpiPaid: paymentData.cpiDueNow || 0,
    DdPmt: paymentData.ddDueNow || 0,
    EmailB: paymentData.buyerEmail || '',
    EmailBOLD: '',
    EmailC: paymentData.cobuyerEmail || '',
    EmailCOLD: '',
    IsAch: false,
    IsMyCarPay: false,
    IsNewCard: false,
    IsRecurring: false,
    LcOwed: 0,
    LcPaid: paymentData.lcDue || 0,
    LcWaived: 0,
    LocId: paymentData.locId,
    MCat: '',
    MiscPaid: 0,
    Mpd: { Token: null },
    NsfPaid: paymentData.nsfDue || 0,
    OrgId: paymentData.orgId,
    PaidBy: PaymentType.Cash,
    PaidIn: PaymentAcceptedIn.InPerson,
    PaidRef: '',
    PayNote: '',
    PayToFrom: `${paymentData.buyerFirstName} ${paymentData.buyerLastName}`,
    PaymentType: '',
    PmtContext: 'NEW_UI',
    PmtType: '',
    ProcessorTestMode: false,
    ProcessorType: null,
    SaveCard: false,
    SendB: !!paymentData.buyerEmail && !paymentData.buyerNoEmail,
    SendC: !!paymentData.cobuyerEmail && !paymentData.cobuyerNoEmail,
    SnPmt: 0,
    Source: '',
    StockNum: paymentData.stockNum,
    TakenBy: '',
    TakenByPassword: '',
    TotalReceived: paymentData.nextDueAmount ?? 0,
    UsePrintServer: false,
    UserEmail: '',
    UserRecId: 0,
    UserShortName: '',
    WaiveAchConvFee: false,
    WaiveCCConvFee: false,
    WaiveConvFee: false,
  };
};

/** @todo move to interface file */
export interface Employee {
  recId: number;
  shortName: string;
  userId: string;
}

export interface RepoCompany {
  recId: number;
  company: string;
}

/** @todo move to interface file */
export interface PaymentProviders {
  achEnabled: boolean;
  allowPaymentProviderSelection: boolean;
  blytzpayEnabled: boolean;
  openEdgeDmsCcConvFee: number;
  openEdgeDmsAchConvFee: number;
  openedgeEnabled: boolean;
  openEdgeEnv: string;
  payfieldsApiKey: string; // OpenEdge API key
  preferredPaymentProvider: CardProcessor;
  repayEnabled: boolean;
  repayCfeeModelTest: RepayFeeModel;
  repayCfeeModelProd: RepayFeeModel;
  repayConvFeeTest: number;
  repayConvFeeProd: number;
  repayCanWaiveFeeTest: boolean;
  repayCanWaiveFeeProd: boolean;
  repayTestMode: boolean;
}

export const paymentService = new PaymentService();
