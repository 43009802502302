/** If value is not numeric, return null */
export const getRouteParamNum = (routeParam: string | number | undefined | null): number | null => {
  return isNaN(Number(routeParam)) ? null : Number(routeParam);
};

/** Get typed route parameters, non-numeric */
// export const getRouteParams = <TOutput extends object>(
//   params: Readonly<{ [key in keyof TOutput]?: string | undefined }>
// ): Nullable<TOutput> => {
//   const rtn: TOutput = {} as TOutput;
//   const entries = Object.entries(params) as [keyof TOutput, string][];

//   entries.forEach(([key, value]) => {
//     rtn[key] = value || null;
//   });
//   return isNaN(Number(routeParam)) ? null : Number(routeParam);
// };
