import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { cloneDeep } from "lodash-es";
import { toast } from "react-toastify";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { ConfirmButton } from "@/components/confirmButton/ConfirmButton";
import { Modal } from "@/components/modal/Modal";
import { OpenEdgeIframe } from "@/components/openEdgeIframe/OpenEdgeIframe";
import { RepayIframe } from "@/components/repayIframe/RepayIframe";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { RadioGroupInput } from "@/components/inputs/radioGroupInput/RadioGroupInput";
import { TextInput } from "@/components/inputs/text/TextInput";
// components
import AccountsContainer from "./AccountsContainer";
// utils
import {
  Employee,
  PaymentProviders,
  PostPaymentPayload,
  createMiscPaymentPayload,
  paymentService,
} from "@/services/paymentService";
import {
  getAllowedPaymentTypes,
  getCanWaiveFee,
  getConvenienceFeeByProviderData,
  getPaymentProviderArray,
  getPreferredPaymentProviderName,
  getProcessorIntByName,
  pollForReceipt,
} from "@/utils/helpers/payment";
import { getInitials } from "@/utils/helpers/general";
import { CardProcessorName, PaymentAcceptedIn, PaymentType } from "@/enums";
import { usePaymentSelector } from "../../accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice";
import { useAuthSelector } from "@/features/auth/authSlice";
import { config } from "@/config";
// style
import styles from "./MiscPaymentDetail.module.scss";
import { usaStateCodes } from "@/general/regions";

const MiscPaymentDetail: FC = () => {
  const navigate = useNavigate();
  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);
  const locId = useAuthSelector((s) => s.locId);
  const userId = useAuthSelector((s) => s.userId);
  const { postPaymentLoading } = usePaymentSelector((s) => s);
  const [paymentProviderData, setPaymentProviderData] = useState<PaymentProviders>(
    {} as PaymentProviders
  );
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [paymentDataError, setPaymentDataError] = useState("");
  const [enabledProviders, setEnabledProviders] = useState<CardProcessorName[]>([]);
  const [provider, setProvider] = useState<CardProcessorName>();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [repayIframeUrl, setRepayIframeUrl] = useState("");
  const [openEdgeCCModalOpen, setOpenEdgeCCModalOpen] = useState(false);
  const [paymentLogRecId, setPaymentLogRecId] = useState(0);
  const [miscCategories, setMiscCategories] = useState<string[]>([]);

  const realSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const onClose = () => navigate("..", { relative: "path" });
  const onSuccess = onClose;

  const init = async () => {
    try {
      setPaymentDataLoading(true);
      setPaymentDataError("");

      const defaultPayload = createMiscPaymentPayload();
      defaultPayload.CompId = compId!;
      defaultPayload.LocId = locId!;
      defaultPayload.OrgId = orgId!;
      defaultPayload.PaidBy = PaymentType.Cash;
      defaultPayload.PaidIn = "In-Person";
      defaultPayload.PmtContext = "NEW_UI_MISC";

      reset(defaultPayload);

      const paymentProviders = await paymentService.getPaymentProvidersByCompany(compId!);
      const enabledProviders = getPaymentProviderArray(paymentProviders);
      const preferredProviderName = getPreferredPaymentProviderName(
        paymentProviders.preferredPaymentProvider,
        enabledProviders
      );
      setPaymentProviderData(paymentProviders);
      setEnabledProviders(enabledProviders);
      setProvider(preferredProviderName);

      const users = await paymentService.getUsersByCompanyId(compId!);
      setEmployees(users);

      const categories = await paymentService.getMiscCategories(orgId!);
      setMiscCategories(categories);
      if (categories && categories[0]) {
        setValue("MCat", categories[0]);
      }
    } catch (err) {
      console.error(err);
      setPaymentDataError("Unable to load payment data");
    } finally {
      setPaymentDataLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm<PostPaymentPayload>();

  // These fields are already defaulted in createDefaultMiscPaymentPayload, but without providing default values it turns them into <T> | undefined
  // Probably because the form fields could be deleted, turning them into undefined
  // Just defaulting them again here so it doesn't break any calculations
  const {
    PaidIn,
    CarPmt = 0,
    PaidBy,
    ConvFee = 0,
    TotalReceived = 0,
    AchAcctType = 0,
    WaiveConvFee,
  } = useWatch({ control });

  useEffect(() => {
    // Set the default employee once we have a list of employees
    if (!employees.length) return;
    const defaultEmployee = employees.find((emp) => emp.recId === userId);
    if (defaultEmployee) {
      setEmployee(defaultEmployee);
    } else {
      setEmployee(employees[0]);
    }
  }, [employees]);

  useEffect(() => {
    // Employee object was updated by dropdown or useEffect defaulting - update the relevant submit payload fields
    if (!employee) return;
    setValue("TakenBy", employee.shortName);
    setValue("UserEmail", employee.userId);
    setValue("UserRecId", employee.recId);
  }, [employee]);

  const isCC = PaidBy === PaymentType.CreditCard;
  const isACH = PaidBy === PaymentType.Ach;
  const isRepay = provider === CardProcessorName.Repay;
  const isOpenEdge = provider === CardProcessorName.OpenEdge;

  useEffect(() => {
    // Add conv fee if needed for payment type
    if (isCC || isACH) {
      setValue("TotalReceived", CarPmt + (ConvFee ?? 0));
      trigger("TotalReceived");
    }
    if (CarPmt > TotalReceived) {
      setValue("TotalReceived", CarPmt);
      trigger("TotalReceived");
    }
  }, [CarPmt, isCC, isACH]);

  useEffect(() => {
    // Can't have change due if paying with card / ACH
    if (isCC || isACH) {
      setValue("Change", 0);
    } else if (TotalReceived > CarPmt) {
      setValue("Change", TotalReceived - CarPmt);
    } else {
      setValue("Change", 0);
    }
  }, [TotalReceived, CarPmt, PaidBy]);

  useEffect(() => {
    // always going to be a new "card" if it's CC/ACH
    // clear out any irrelevant fields that may have been set when it was a different payment method
    if (isCC) {
      setValue("IsNewCard", true);
    } else if (isACH) {
      setValue("IsNewCard", true);
      setValue("BillAddress", "");
      setValue("BillCity", "");
      setValue("BillState", "");
      setValue("BillZip", "");
    } else {
      setValue("IsNewCard", false);
      setValue("BillFirstName", "");
      setValue("BillLastName", "");
      setValue("BillAddress", "");
      setValue("BillCity", "");
      setValue("BillState", "");
      setValue("BillZip", "");
    }
  }, [PaidBy]);

  useEffect(() => {
    if ((isCC || isACH) && !WaiveConvFee) {
      setValue("TotalReceived", CarPmt + ConvFee);
    } else {
      setValue("TotalReceived", CarPmt);
    }
    trigger("TotalReceived");
  }, [CarPmt, ConvFee]);

  useEffect(() => {
    if (!Object.keys(paymentProviderData).length || !provider) return;
    setValue(
      "ConvFee",
      getConvenienceFeeByProviderData(paymentProviderData, provider, PaidBy!, !!WaiveConvFee)
    );
    setValue("WaiveConvFee", !!WaiveConvFee);
    setValue("IsAch", isACH);

    // Handle the possibility that they could have entered OE ACH details and then changed the payment method / provider
    if (provider !== CardProcessorName.OpenEdge || PaidBy !== PaymentType.Ach) {
      setValue("Mpd.AccountNumber", undefined);
      setValue("Mpd.RoutingNumber", undefined);
    }

    // None of these fields should matter but just to be safe, keep them up to date with the current selections
    setValue("WaiveAchConvFee", isACH ? !!WaiveConvFee : false);
    setValue("WaiveCCConvFee", isCC ? !!WaiveConvFee : false);
    setValue(
      "CcConvFee",
      isCC
        ? getConvenienceFeeByProviderData(
            paymentProviderData,
            provider,
            PaymentType.CreditCard,
            !!WaiveConvFee
          )
        : 0
    );
    setValue(
      "AchConvFee",
      isACH
        ? getConvenienceFeeByProviderData(
            paymentProviderData,
            provider,
            PaymentType.Ach,
            !!WaiveConvFee
          )
        : 0
    );
  }, [provider, PaidBy, WaiveConvFee]);

  useEffect(() => {
    if (CarPmt < 0 && [PaymentType.CreditCard, PaymentType.Ach].includes(PaidBy as PaymentType))
      setValue("PaidBy", "");
  }, [CarPmt]);

  let paymentTypes = paymentProviderData ? getAllowedPaymentTypes(paymentProviderData) : [];

  if (CarPmt < 0) {
    paymentTypes = paymentTypes.filter(
      (type) => ![PaymentType.CreditCard, PaymentType.Ach].includes(type)
    );
  }

  const onSubmit = (paymentPayload: PostPaymentPayload) => {
    const takenBy = getInitials(employee!.shortName);
    const processorType = getProcessorIntByName(provider!);

    const finalPayload = cloneDeep(paymentPayload);
    finalPayload.TakenBy = takenBy;
    finalPayload.ProcessorType = processorType;
    finalPayload.PmtType = finalPayload.PaidBy;
    finalPayload.ColType = CarPmt >= 0 ? "MI" : "MO";
    finalPayload.UserShortName = employee!.shortName!;

    if ((!isCC && !isACH) || (isACH && isOpenEdge)) {
      // Used to show a confirmation modal - do we still want that?
      // @todo use async/await
      paymentService.postPaymentSubmit(finalPayload).then((res) => {
        pollForReceipt(res.paymentLogRecId, onSuccess);
      });
    } else if (isRepay) {
      // @todo use async/await
      paymentService.postPaymentSubmit(finalPayload).then((res) => {
        if (!res.success) {
          toast.error("Unable to load repay payment form");
          return;
        }
        setPaymentLogRecId(res.paymentLogRecId);
        setRepayIframeUrl(res.iFrameUrl);
      });
    } else if (isOpenEdge && isCC) {
      // @todo use async/await
      paymentService
        .postPaymentSubmit(finalPayload!)
        .then((res) => {
          setPaymentLogRecId(res.paymentLogRecId);
          setOpenEdgeCCModalOpen(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const canWaiveFee = getCanWaiveFee(provider!, paymentProviderData);

  // @todo move nested components to separate files
  return (
    <>
      <AccountsContainer title="Miscellaneous Payment" backLabel="Back to Payments List">
        <div className={styles.miscPaymentBody}>
          {paymentDataLoading ? (
            <Loader size="large" />
          ) : paymentDataError ? (
            <div>{paymentDataError}</div>
          ) : (
            <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.column}>
                {enabledProviders && enabledProviders.length > 1 && (
                  <DropdownInput
                    label="Processor"
                    data={enabledProviders}
                    value={provider}
                    onChange={(e) => setProvider(e.target.value as CardProcessorName)}
                    errors={!provider}
                  />
                )}

                <Controller
                  name="PaidIn"
                  control={control}
                  render={({ field }) => {
                    // eslint-disable-next-line
                    const { value, onChange, ...restField } = field;
                    return (
                      <RadioGroupInput
                        label="Paid In"
                        data={Object.values(PaymentAcceptedIn).map((type) => ({
                          label: type,
                          value: type,
                        }))}
                        layout="horizontal"
                        value={PaidIn}
                        onChange={(e) => setValue("PaidIn", e.value)}
                        {...restField}
                      />
                    );
                  }}
                />

                <Controller
                  name="CarPmt"
                  control={control}
                  rules={{
                    required: "Amount is required",
                    validate: (value) => value !== 0,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      label={`Amount ${CarPmt >= 0 ? "(Pay In)" : "(Pay Out)"}`}
                      required
                      errors={errors.CarPmt?.message || !!errors.CarPmt}
                      allowNegative
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="PaidBy"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Payment Type"
                      required
                      data={paymentTypes}
                      errors={!!errors.PaidBy}
                      {...field}
                    />
                  )}
                />

                {(isCC || isACH) && (
                  <div className={styles.inlineInputContainer}>
                    <span className={styles.inlineInputLabel}>Conv. Fee</span>
                    <div className={styles.inlineInputItems}>
                      <Controller
                        name="ConvFee"
                        control={control}
                        render={({ field }) => <CurrencyInput readOnly {...field} />}
                      />
                      {canWaiveFee && (
                        <Controller
                          name="WaiveConvFee"
                          control={control}
                          render={({ field }) => <Checkbox label="Waive Fee" {...field} />}
                        />
                      )}
                    </div>
                  </div>
                )}

                <Controller
                  name="TotalReceived"
                  control={control}
                  rules={{
                    required: !isCC && !isACH,
                    min: {
                      value: CarPmt,
                      message: "Amount tendered cannot be less than the payment amount",
                    },
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Amount Tendered"
                      required={!isCC && !isACH}
                      readOnly={isCC || isACH || CarPmt < 0}
                      errors={errors.TotalReceived?.message || !!errors.TotalReceived}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="Change"
                  control={control}
                  render={({ field }) => <CurrencyInput label="Change Due" readOnly {...field} />}
                />

                <Controller
                  name="PayToFrom"
                  control={control}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <TextInput label="Name" required errors={!!errors.PayToFrom} {...field} />
                  )}
                />

                {isACH && isRepay && (
                  <Controller
                    name="BillEmail"
                    control={control}
                    rules={{ required: "Field is required" }}
                    render={({ field }) => (
                      <TextInput label="Email" required errors={!!errors.BillEmail} {...field} />
                    )}
                  />
                )}

                <Controller
                  name="MCat"
                  control={control}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Category"
                      required
                      data={miscCategories}
                      errors={!!errors.MCat}
                      {...field}
                    />
                  )}
                />

                <Spacer expand />
              </div>
              <div className={styles.divider} />
              <div className={styles.column} style={{ width: "380px" }}>
                {(isCC || isACH) && (
                  <>
                    <Controller
                      name="BillFirstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          label="First Name"
                          required
                          errors={!!errors.BillFirstName}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="BillLastName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          label="Last Name"
                          required
                          errors={!!errors.BillLastName}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}

                {isOpenEdge && isACH && (
                  <>
                    <Controller
                      name="Mpd.AccountNumber"
                      control={control}
                      rules={{
                        required: true,
                        pattern: { value: /^\d{5,17}$/, message: "Format is incorrect" },
                      }}
                      render={({ field }) => (
                        <TextInput
                          label="Account Number"
                          required
                          errors={errors.Mpd?.AccountNumber?.message || !!errors.Mpd?.AccountNumber}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="Mpd.RoutingNumber"
                      control={control}
                      rules={{
                        required: true,
                        pattern: { value: /^\d{9}$/, message: "Format is incorrect" },
                      }}
                      render={({ field }) => (
                        <TextInput
                          label="Routing Number"
                          required
                          errors={errors.Mpd?.RoutingNumber?.message || !!errors.Mpd?.RoutingNumber}
                          {...field}
                        />
                      )}
                    />
                  </>
                )}

                {isCC && (
                  <>
                    <Controller
                      name="BillAddress"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          label="Address"
                          required
                          errors={!!errors.BillAddress}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="BillCity"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput label="City" required errors={!!errors.BillCity} {...field} />
                      )}
                    />
                    <Controller
                      name="BillState"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DropdownInput
                          label="State"
                          data={usaStateCodes}
                          required
                          errors={!!errors.BillState}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="BillZip"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput label="Zip" required errors={!!errors.BillZip} {...field} />
                      )}
                    />
                  </>
                )}

                {isACH && (
                  <>
                    <Controller
                      name="AchAcctType"
                      control={control}
                      render={({ field }) => {
                        // eslint-disable-next-line
                        const { value, onChange, ...restField } = field;
                        return (
                          <RadioGroupInput
                            label="Account Type"
                            data={[
                              { label: "Checking", value: 0 },
                              { label: "Savings", value: 1 },
                            ]}
                            layout="horizontal"
                            value={AchAcctType}
                            onChange={(e) => setValue("AchAcctType", e.value)}
                            {...restField}
                          />
                        );
                      }}
                    />
                  </>
                )}
                <Spacer expand />
                <DropdownInput
                  label="Employee"
                  required
                  data={employees}
                  dataItemKey="recId"
                  textField="shortName"
                  onChange={(e) => setEmployee(e.value)}
                  defaultValue={employee}
                  value={employee}
                  errors={!employee}
                />

                <Controller
                  name="TakenByPassword"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput
                      label="Password"
                      required
                      errors={!!errors.TakenByPassword}
                      type="password"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="PaidRef"
                  control={control}
                  render={({ field }) => <TextInput label="Reference #" {...field} />}
                />

                <Controller
                  name="PayNote"
                  control={control}
                  render={({ field }) => <TextInput label="Payment Note" {...field} />}
                />

                <div className={styles.buttonContainer}>
                  <ConfirmButton
                    triggerElement={(onClick) => (
                      <Button
                        label="Cancel"
                        themeColor="error"
                        secondary
                        disabled={postPaymentLoading}
                        style={{ width: "100px" }}
                        onClick={onClick}
                      />
                    )}
                    confirmOnTriggerClick={!isDirty}
                    confirmButtonProps={{
                      onClick: onClose,
                    }}
                    cancelButtonProps={{}}
                    modalContents="Any data entered will be lost"
                  />
                  <ConfirmButton
                    triggerElement={(onClick) => (
                      <Button
                        label="Post Payment"
                        disabled={postPaymentLoading}
                        loading={postPaymentLoading}
                        style={{ width: "211px" }}
                        onClick={onClick}
                      />
                    )}
                    confirmButtonProps={{
                      onClick: () => realSubmitButtonRef.current!.click(),
                      type: "submit",
                    }}
                    cancelButtonProps={{}}
                    modalContents="Please confirm that you want to post this payment"
                  />
                  <button type="submit" style={{ display: "none" }} ref={realSubmitButtonRef} />
                </div>
              </div>
            </form>
          )}
        </div>
      </AccountsContainer>
      {!!repayIframeUrl && (
        <Modal
          centerModal
          isOpen={!!repayIframeUrl}
          closeButton
          panelStyle={{ height: "90vh" }}
          panelChildrenStyle={{ overflow: "hidden" }}
          onCloseButtonClick={() => setRepayIframeUrl("")}
        >
          <div className={styles.repayIframeContainer}>
            <RepayIframe
              iframeUrl={repayIframeUrl}
              paymentLogRecId={paymentLogRecId}
              onComplete={onSuccess}
            />
          </div>
        </Modal>
      )}
      {openEdgeCCModalOpen && (
        <Modal
          centerModal
          isOpen={openEdgeCCModalOpen}
          closeButton
          panelChildrenStyle={{ overflow: "hidden", minWidth: "406px" }}
          onCloseButtonClick={() => setOpenEdgeCCModalOpen(false)}
        >
          <OpenEdgeIframe
            paymentLogRecId={paymentLogRecId}
            onComplete={onSuccess}
            apiKey={paymentProviderData.payfieldsApiKey}
            openEdgeEnv={config.openEdgeEnvironment}
          />
        </Modal>
      )}
    </>
  );
};

export default MiscPaymentDetail;
