import { Navigate, NavigateProps } from 'react-router-dom';

const defaultNavProps: NavigateProps = { to: '.', relative: 'route', replace: true } as const;

/** ### Returns a `<Navigate />` component which navigates up one level when the provided condition = `true`
 * @note Only for use in component body
 * @note For use in DOM-element event-handlers: see `useNavUpEvent()` component in `useNavUpEvent.tsx`
 *
 * @todo add ability to enter number of levels - `(numberOfLevels: number = 1)`
 */
const useNavUp = (
  condition: boolean,
  {
    to = defaultNavProps.to,
    relative = defaultNavProps.relative,
    replace = defaultNavProps.replace,
    ...navProps
  }: NavigateProps = defaultNavProps
): JSX.Element | void => {
  if (condition === true) {
    return <Navigate to={to} relative={relative} replace={replace} {...navProps} />;
  }
};

export default useNavUp;
