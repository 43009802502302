import { toast } from 'react-toastify';
// state
import { AppDispatch } from '@/store/store';
import { accountActions } from './accountSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { systemService } from '@/services/systemService';
// interfaces
import { GpsInformation, WarrantyRequestPayload } from '@/interfaces/Accounts';
import { GpsDevice } from '@/enums/gps';

export const getAccountInformation =
  (colRecId: number | undefined | null) => async (dispatch: AppDispatch) => {
    if (!colRecId) {
      console.warn(
        `accountActionCreators > getAccountInformation(): Invalid colRecId: ${colRecId}`
      );
      return;
    }

    try {
      dispatch(accountActions.reset());
      dispatch(accountActions.setAccountInformationLoading(true));
      const response = await accountsService.getAccountInformation(colRecId);
      dispatch(accountActions.setAccountInformation(response));
      return response;
    } catch (e) {
      throw e;
    } finally {
      dispatch(accountActions.setAccountInformationLoading(false));
    }
  };

export const getWarrantyLogs = (colRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountActions.setGetWarrantyLogsLoading(true));
    const response = await accountsService.getWarrantyLogs(colRecId);
    return response;
  } catch (e) {
    toast.error("There was an issue receiving the customer's warranty request logs");
    throw e;
  } finally {
    dispatch(accountActions.setGetWarrantyLogsLoading(false));
  }
};

export const postWarrantyRequest =
  (payload: WarrantyRequestPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountActions.setPostWarrantyRequestLoading(true));
      await accountsService
        .postWarrantyLog(payload)
        .then(() => toast.success('Warranty Request Submitted'));
    } catch {
      toast.error('There was an error posting the warranty request');
    } finally {
      dispatch(accountActions.setPostWarrantyRequestLoading(false));
    }
  };

export const updateWarrantyRequest =
  (payload: WarrantyRequestPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountActions.setPostWarrantyRequestLoading(true));
      await accountsService
        .updateWarrantyLog(payload)
        .then(() => toast.success('Warranty Request Updated'));
    } catch {
      toast.error('There was an error posting the warranty request');
    } finally {
      dispatch(accountActions.setPostWarrantyRequestLoading(false));
    }
  };

export const getWarrantyInformation = (colRecId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountActions.setGetWarrantyLogsLoading(true));
    const response = await accountsService.getWarrantyInformation(colRecId);
    return response;
  } catch (e) {
    toast.error("There was an issue receiving the customer's warranty information");
    throw e;
  } finally {
    dispatch(accountActions.setGetWarrantyLogsLoading(false));
  }
};
