import { FC, SyntheticEvent } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@/mui/components/MuiKendoButton';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// interfaces
import { IFilterCompany } from '../interfaces';

/** ###  */
const LocationDropdown: FC = () => {
  const filterCompanyList = useDashboardViewCtx((s) => s.filterCompanyList);
  const selectedCompany = useDashboardViewCtx((s) => s.selectedCompany);
  const setSelectedCompany = useDashboardViewCtx((s) => s.setSelectedCompany);

  // Event handlers
  const handleClearSelection = () => {
    setSelectedCompany(filterCompanyList[0]!);
  };

  const handleChangeLocation = (
    _event: SyntheticEvent<Element, Event>,
    location: IFilterCompany | null,
    _reason: any,
    _details: any
  ) => {
    setSelectedCompany(location || filterCompanyList[0]!);
  };

  return (
    <Grid container flexDirection="row" gap={1}>
      <Autocomplete
        sx={{ minWidth: '200px' }}
        options={filterCompanyList}
        getOptionLabel={(option) => option.shortName ?? ''}
        disableClearable
        value={selectedCompany}
        onChange={handleChangeLocation}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Location"
            InputProps={{ ...params.InputProps, sx: { fontSize: '14px' } }}
            InputLabelProps={{ sx: { fontSize: '14px' } }}
          />
        )}
      />
      <Button onClick={handleClearSelection}>Clear</Button>
    </Grid>
  );
};

export default LocationDropdown;
