import { MouseEvent, useState } from 'react';
// kendo
import { Button, CustomButtonProps, Modal } from '@components';
// style
import styles from './ConfirmButton.module.scss';

interface ConfirmButtonProps {
  triggerElement: (onClick: (e: any) => void) => JSX.Element;
  modalTitle?: string;
  modalContents?: string | JSX.Element;
  // Pass if you want to bypass the confirmation modal and just perform the confirm action when the trigger element is clicked
  // E.g. if a form is not dirty you don't need to show a confirmation modal before navigating away
  confirmOnTriggerClick?: boolean;
  // If you want your confirm button to submit a react-hook-form <form>, calling handlesubmit won't work
  // You'll need to create a hidden button in the form, get a ref to it, and trigger a click to it in confirmButtonProps' onClick handler
  confirmButtonProps: CustomButtonProps;
  cancelButtonProps?: CustomButtonProps;
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
  const {
    triggerElement,
    confirmButtonProps,
    cancelButtonProps = {},
    confirmOnTriggerClick = false,
    modalTitle = 'Are you sure?',
    modalContents,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const {
    onClick: confirmOnClick,
    label: confirmLabel = 'Yes',
    themeColor: confirmThemeColor = 'error',
    ...restConfirmButtonProps
  } = confirmButtonProps;
  const fullConfirmOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    confirmOnClick && confirmOnClick(e);

    // @note This eliminates the need to use a ref in parent
    !confirmOnClick && confirmButtonProps.type === 'submit' && e.currentTarget.click();

    setModalOpen(false);
  };

  const {
    onClick: cancelOnClick,
    label: cancelLabel = 'No',
    ...restCancelButtonProps
  } = cancelButtonProps;
  const fullCancelOnClick = (e: any) => {
    !!cancelOnClick && cancelOnClick(e);
    setModalOpen(false);
  };

  const triggerElementOnClick = (e: any) => {
    // Might be used in a form - prevent it from submitting that by default
    e.preventDefault();
    e.stopPropagation();
    if (confirmOnTriggerClick && !!confirmOnClick) {
      !!confirmOnClick && confirmOnClick(e);
    } else {
      setModalOpen(true);
    }
    return false;
  };

  return (
    <>
      {triggerElement(triggerElementOnClick)}
      {modalOpen && (
        <Modal centerModal isOpen={modalOpen} closeButton={false} title={modalTitle}>
          <div className={styles.container}>
            <div>{modalContents}</div>
            <div className={styles.buttonContainer}>
              <Button
                onClick={fullCancelOnClick}
                secondary
                fillMode="outline"
                label={cancelLabel}
                {...restCancelButtonProps}
              />
              <Button
                onClick={fullConfirmOnClick}
                label={confirmLabel}
                {...restConfirmButtonProps}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
