import { FC } from "react";
import { useParams } from "react-router";
import { AxiosError, isAxiosError } from "axios";
import _ from "lodash";
// icons
import { FiInfo } from "react-icons/fi";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@/mui/components/MuiKendoButton";
import { alpha } from "@mui/system/colorManipulator";
// components
import TooltipLayout from "./TooltipLayout";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
// utils
import { scienapticService } from "@/services/scienapticService";
import { getRouteParamNum } from "@/utils/routing/formatting";

/** ###  */
const SubmitButton: FC = () => {
  const appRecId = getRouteParamNum(useParams().id);

  const reportsHistory = useAiUnderwritingCtx((s) => s.reportsHistory);
  const submitEvalValidationErrors = useAiUnderwritingCtx((s) => s.submitEvalValidationErrors);

  // Event handlers
  const handleClickSubmit = async () => {
    if (!appRecId) return;

    try {
      await scienapticService.generateNewAppEvaluation(appRecId);
      await reportsHistory.load();
    } catch {}
  };

  const areFieldsValid = submitEvalValidationErrors === null;

  // @todo Add other cases
  const isDisabled = reportsHistory.isLoading || !areFieldsValid;

  return (
    <TooltipLayout>
      <Grid>
        <Button
          onClick={handleClickSubmit}
          disabled={isDisabled}
          startIcon={!areFieldsValid && <FiInfo color={alpha("#ff0000", 0.5)} fontWeight={900} />}
        >
          Generate New Evaluation
        </Button>
      </Grid>
    </TooltipLayout>
  );
};

export default SubmitButton;
