import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
// interfaces
import {
  ISalesSubviewDb,
  ISalesSubviewDbCommon,
  SalesFilterKey,
  SalesSortKey,
} from '../interfaces';
import { SubviewInfo, subviewInfoMap } from './listViewConfig';
import { SalesSubviewKey } from '../enums';

export interface ISalesListCtx<TRowModel extends ISalesSubviewDbCommon = ISalesSubviewDb> {
  // Request variables
  salesListRows: TRowModel[];
  setSalesListRows: (salesListRows: TRowModel[]) => void;
  dbCt: number;
  setDbCt: (dbCt: number) => void;
  mappedCompaniesCt: number;
  setMappedCompaniesCt: (mappedCompaniesCt: number) => void;
  /** @deprecated use getSubviewInfo */
  subviewInfo: SubviewInfo;
  getSubviewInfo: (routeParams: {
    '*'?: string;
    subview?: string;
  }) => SubviewInfo<SalesSortKey, SalesFilterKey>;
}

const SalesListCtx = createContext<ISalesListCtx<ISalesSubviewDbCommon> | null>(null);

const SalesListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Route variables
  const routeParams = useParams();
  // Request variables
  const [salesListRows, setSalesListRows_] = useState<ISalesSubviewDbCommon[]>([]);
  const [dbCt, setDbCt] = useState(0);
  const [mappedCompaniesCt, setMappedCompaniesCt] = useState(0);

  const getSubviewInfoFromRoute = (
    routeParams: Readonly<Params<string>>
  ): SubviewInfo<SalesSortKey, SalesFilterKey> => {
    const subview = routeParams['*'] as SalesSubviewKey;
    if (!subview) throw new Error(`Invalid subview: '${subview}'`);

    const viewInfoItem = subviewInfoMap.get(subview);
    if (!viewInfoItem) throw new Error(`Invalid viewInfoItem- subview: '${subview}'`);
    return viewInfoItem;
  };

  const setSalesListRows = (newSalesListRows: ISalesSubviewDbCommon[]) => {
    const subviewInfo = getSubviewInfoFromRoute(routeParams);
    // Convert to subview-relevant class instances
    const validatedRows = newSalesListRows.map((r) => subviewInfo.dbRowValidationFxn(r));

    // Set validated data
    setSalesListRows_(validatedRows);
  };

  return (
    <SalesListCtx.Provider
      value={{
        // Request variables
        salesListRows,
        setSalesListRows,
        dbCt,
        setDbCt,
        mappedCompaniesCt,
        setMappedCompaniesCt,
        // Subview config variables
        getSubviewInfo(
          routeParams: Parameters<ISalesListCtx['getSubviewInfo']>['0']
        ): ReturnType<ISalesListCtx['getSubviewInfo']> {
          const subview = routeParams.subview as SalesSubviewKey;
          if (!subview) throw new Error(`Invalid subview: '${subview}'`);
          const subviewInfoItem = subviewInfoMap.get(subview);
          if (!subviewInfoItem) throw new Error(`Invalid subviewInfoItem- subview: '${subview}'`);
          return subviewInfoItem;
        },
        get subviewInfo(): SubviewInfo<SalesSortKey, SalesFilterKey> {
          return getSubviewInfoFromRoute(routeParams);
        },
      }}
    >
      {children}
    </SalesListCtx.Provider>
  );
};

export default SalesListProvider;

export const useSalesListCtx = <T,>(
  selector: (state: ISalesListCtx<ISalesSubviewDbCommon>) => T
): T => {
  const ctx = useContext(SalesListCtx);
  if (!ctx) {
    throw new Error('useSalesListCtx must be used within SalesListProvider');
  }
  return selector(ctx);
};
