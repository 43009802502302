import { useEffect, useRef } from 'react';
import styles from './LightModal.module.scss';

export type ModalProps = {
  open: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  blur?: boolean;
};
/**
 * Simple modal component
 * displays any children passed to it
 * @param open: boolean
 * @param closeModal: () => void
 * @param children: React.ReactNode
 * @param blur: boolean
 *
 * @example
 * <Modal open={open} closeModal={closeModal}>
 * <div>Modal Content</div>
 * </Modal>
 * @returns
 * Element
 */
export const Modal: React.FC<ModalProps> = (props) => {
  const { open, closeModal, children, blur = true } = props;
  const wrapperRef = useRef(null);

  const modalStyle = !blur ? styles.modalContainer : styles.modalContainerBlur;

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        event.stopPropagation();

        if (ref.current && ref.current === event.target) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  if (!open) return null;

  return (
    <div className={modalStyle} ref={wrapperRef}>
      {children}
    </div>
  );
};
