import { FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// interfaces
import { AccountDb } from '../interfaces';
// style
import { COLOR_DANGER, COLOR_SUCCESS } from '@/mui/theme/componentDefaults';

const ScCell: FC<GridCellProps> = ({ dataItem }) => {
  const { sc } = dataItem as AccountDb;

  const scFmt = typeof sc === 'string' ? (sc.trim() !== '' ? sc : '-') : '-';
  const color =
    scFmt === '-'
      ? undefined
      : ['R', 'F'].includes(scFmt)
      ? COLOR_DANGER
      : ['P', 'A'].includes(scFmt)
      ? COLOR_SUCCESS
      : 'green';

  return (
    <td className="k-table-td">
      <span style={{ fontWeight: 700, color }}>{scFmt}</span>
    </td>
  );
};

export default ScCell;
