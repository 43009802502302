import { GridCustomCellProps } from '@progress/kendo-react-all';
import { BsFiletypePdf } from 'react-icons/bs';
import styles from './KendoCells.module.scss';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

interface KendoPdfCellProps extends GridCustomCellProps {
  onClick: () => void;
  hasDoc: boolean;
}

export const KendoPdfCell = (props: KendoPdfCellProps) => {
  const { onClick, hasDoc, ...restProps } = props;

  return (
    <td {...restProps.tdProps}>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        {hasDoc && (
          <BsFiletypePdf
            onClick={onClick}
            color={DCS_BLUE}
            size={20}
            className={styles.tableActionContainer}
          />
        )}
      </div>
    </td>
  );
};
