import { AppConfig } from './env/interfaces';

export const stagingConfig: AppConfig = {
  localUrl: import.meta.env.MODE.includes('standalone')
    ? 'https://staging.dcstest1.com'
    : 'https://www.afsvirginia2.com',
  apiUrl: 'https://api-staging.dclteam.com', // nocommit
  openEdgeEnvironment: 'test', // nocommit
  signalrUrl: 'https://afs-signalrhub.azurewebsites.net/sms', // nocommit
  // @todo move to secrets-manager
  googleMapsApiKey: 'AIzaSyDr-UamFrwCy_ROiuvfSfj8pIO8MvlWKc4', // nocommit
  idsUrl: 'https://auth.afsvirginia2.com',
};
