import React from 'react';
import styles from './CheckboxInput.module.scss';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  containerStyle?: React.CSSProperties;
}

export const Checkbox: React.FC<Props> = (props) => {
  const { label, containerStyle = {}, ...restProps } = props;
  return (
    <div className={styles.container} style={containerStyle}>
      <label className={styles.label}>
        <input className={styles.checkbox} type="checkbox" {...restProps} />
        {label}
      </label>
    </div>
  );
};
