// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { IWholesalesDb } from './interfaces';
import { SalesSubviewListKey } from '@/features/Sales/enums';

const buildDetailRoute: (dataItem: IWholesalesDb) => string = (dataItem) => {
  return `/sales/${SalesSubviewListKey.wholesales}/${dataItem.appRecId}`;
};

export const wholesalesTableColumns: KendoColumnDef<keyof IWholesalesDb>[] = [
  newColumnDef('stockNum', 'Stk. #', 110),
  newColumnDef('bNames', 'Name', 'auto', 'link', true, true, buildDetailRoute),
  newColumnDef('vehYmm', 'Year/Make/Model', 'auto'),
  newColumnDef('saleType', 'Type', 'auto'),
  newColumnDef('saleDate', 'Date Sold', 120, 'date'),
  newColumnDef('invDays', 'Days', 'auto', 'number'),
  newColumnDef('purPrice', 'Purchase', 'auto', 'accounting'),
  newColumnDef('totExp', 'Expense', 'auto', 'accounting'),
  newColumnDef('soldPrice', 'Sale Price', 'auto', 'accounting'),
  newColumnDef('totalCost', 'Total Cost', 'auto', 'accounting'),
  newColumnDef('profit', 'Profit', 'auto', 'accounting'),
  newColumnDef('ror', 'ROR', 'auto', 'percent'),
  newColumnDef('shortName', 'Sales Person', 'auto'),
  newColumnDef('code3', 'Lot', 80),
];
