import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import dayjs from 'dayjs';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import MuiKendoButton from '@/mui/components/MuiKendoButton';
// kendo
import { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// utils
import { customerService } from '@/services/customerService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { UpdateInsuranceReq } from './interfaces';

/** ###  */
const SubmitButtonGroup: FC = () => {
  const colRecId = getRouteParamNum(useParams().colRecId);

  const isInsuranceReqLoading = useAcctInsuranceCtx((s) => s.isInsuranceReqLoading);
  const isSubmitting = useAcctInsuranceCtx((s) => s.insuranceInfoForm.formState.isSubmitting);
  const errors = useAcctInsuranceCtx((s) => s.insuranceInfoForm.formState.errors);
  const formBody = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch());
  const insRequired = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch('insRequired'));
  const insExpire = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch('insExpire'));
  const insCancelled = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch('insCancelled'));
  const cpiYearlyPremium = useAcctInsuranceCtx((s) =>
    s.insuranceInfoForm.watch('cpiYearlyPremium')
  );
  const insCancelledDate = useAcctInsuranceCtx((s) =>
    s.insuranceInfoForm.watch('insCancelledDate')
  );
  const setError = useAcctInsuranceCtx((s) => s.insuranceInfoForm.setError);
  const insuranceInfo = useAcctInsuranceCtx((s) => s.insuranceInfo);

  // @todo remove react hook forms - we should not be needing to do this.
  const insExpireErr = insRequired === true && insExpire === null;
  const insCancelledErr = insCancelled === true && insCancelledDate === null;
  const cpiYearlyPremiumErr = cpiYearlyPremium !== null && cpiYearlyPremium > 10000;
  const manualFieldErrors = insExpireErr || insCancelledErr || cpiYearlyPremiumErr;

  const isDisabled = isSubmitting || isInsuranceReqLoading || !isEmpty(errors) || manualFieldErrors;

  // Event handler
  const submitInsuranceInfoForm = async () => {
    if (!colRecId || !insuranceInfo) {
      console.error('submitInsuranceInfoForm(): State not set:', { colRecId, insuranceInfo });
      return;
    }
    if (manualFieldErrors || !isEmpty(errors)) {
      console.error('submitInsuranceInfoForm(): Form contains errors:', errors, {
        insExpireErr,
        insCancelledErr,
        cpiYearlyPremiumErr,
      });
      return;
    }

    if (formBody.insCancelled === true && !dayjs(formBody.insCancelledDate).isValid()) {
      setError('insExpire', { message: 'Date cannot be null' });
      return;
    }
    if (formBody.insCancelled === true && !dayjs(formBody.insCancelledDate).isValid()) {
      setError('insCancelledDate', { message: 'Date cannot be null' });
      return;
    }

    const reqBody = new UpdateInsuranceReq(formBody, colRecId);

    await customerService.updateInsuranceInfo(reqBody);
  };

  return (
    <Grid container direction="row" justifyContent="end" width="100%" pb={1}>
      {/* Add cancel button */}
      <MuiKendoButton onClick={submitInsuranceInfoForm} disabled={isDisabled} sx={{ px: 3 }}>
        Submit
      </MuiKendoButton>
    </Grid>
  );
};

export default SubmitButtonGroup;
