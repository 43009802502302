import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// utils
import { getConvenienceFee } from '@/utils/helpers/payment';
// interfaces
import { CardProcessorName, PaymentType } from '@/enums';
import { GetPaymentData } from '@/services/paymentService';

const usePaymentProviderListener = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData) as GetPaymentData | null;
  const provider = useWsPmtsViewCtx((s) => s.provider);
  const { isAch, isCc } = useWsPmtsFormCtx((s) => s);
  const { setValue, control } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { PaidBy, WaiveConvFee } = useWatch({ control });

  useEffect(() => {
    if (!paymentData || !provider) return;

    setValue('ConvFee', getConvenienceFee(paymentData, provider, PaidBy!, !!WaiveConvFee));
    setValue('WaiveConvFee', !!WaiveConvFee);
    setValue('IsAch', isAch);

    // Handle the possibility that they could have entered OE ACH details and then changed the payment method / provider
    if (provider !== CardProcessorName.OpenEdge || PaidBy !== PaymentType.Ach) {
      setValue('Mpd.AccountNumber', undefined);
      setValue('Mpd.RoutingNumber', undefined);
    }

    // None of these fields should matter but just to be safe, keep them up to date with the current selections
    setValue('WaiveAchConvFee', !!WaiveConvFee);
    setValue('WaiveCCConvFee', !!WaiveConvFee);
    setValue(
      'CcConvFee',
      isCc ? getConvenienceFee(paymentData, provider, PaymentType.CreditCard, !!WaiveConvFee) : 0
    );
    setValue(
      'AchConvFee',
      isAch ? getConvenienceFee(paymentData, provider, PaymentType.Ach, !!WaiveConvFee) : 0
    );
  }, [!paymentData, provider, PaidBy, WaiveConvFee]);
};

export default usePaymentProviderListener;
