import {  } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { Column } from '@/components/table/TableInterface';
import { AccountFullInformation, NextPayment, IAccountPaymentHistoryRes } from '@/interfaces';
import { accountsService } from '@/services/accountsService';
import { formatCurrency } from '@/utils/helpers/general';
import { Grid, GridColumn } from '@progress/kendo-react-all';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

type AccountInfoData = {
  header: string;
  value: number | string;
};

export const AccountInfo = () => {
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const [accountFullInformationLoading, setAccountFullInformationLoading] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState<IAccountPaymentHistoryRes | undefined>(
    undefined
  );
  const [accountFullInformation, setAccountFullInformation] = useState<
    AccountFullInformation | undefined
  >(undefined);
  const [next6Payments, setNext6Payments] = useState<NextPayment[] | undefined>(undefined);

  const initData = async () => {
    setAccountFullInformationLoading(true);
    try {
      await accountsService
        .getAccountFullInformation(colRecId)
        .then((res) => setAccountFullInformation(res))
        .catch(() => toast.error('There was an error retrieving the full account information'));
      await accountsService
        .getPaymentHistory({
          colRecId: colRecId,
          page: 1,
          perPage: 6,
          sort: [
            {
              field: 'PmtDate',
              direction: 'DESC',
            },
          ],
        })
        .then((res) => setPaymentHistory(res))
        .catch(() => toast.error('There was an error receiving the payment history'));
      await accountsService
        .getNext6Payments(colRecId)
        .then((res) => setNext6Payments(res))
        .catch(() => toast.error('There was an error receiving the next payments'));
    } finally {
      setAccountFullInformationLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [colRecId]);

  const leaseRowData1: AccountInfoData[] = [
    {
      header: 'Lease Payoff',
      value: formatCurrency(accountFullInformation?.leasePayoff || 0),
    },
    {
      header: 'Estimated Vehicle Sale',
      value: formatCurrency(accountFullInformation?.estimatedVehicleSale || 0),
    },
    {
      header: 'Gross Lease Buyout',
      value: formatCurrency(accountFullInformation?.grossLeaseBuyout || 0),
    },
    {
      header: 'Security Deposit',
      value: formatCurrency(accountFullInformation?.securityDeposit || 0),
    },
    {
      header: 'Net Lease Buyout',
      value: formatCurrency(accountFullInformation?.netLeaseBuyout || 0),
    },
  ];

  const leaseRowData2: AccountInfoData[] = [
    {
      header: 'Date Sold',
      value: dayjs(accountFullInformation?.dateSold).utc().format('MM/DD/YYYY'),
    },
    {
      header: 'Vehicle Agreed Val.',
      value: formatCurrency(accountFullInformation?.vehicleAgreedValue || 0),
    },
    {
      header: 'Gross Cap. Cost',
      value: formatCurrency(accountFullInformation?.grossCapitalizedCost || 0),
    },
    {
      header: 'Cap. Cost Reduction',
      value: formatCurrency(accountFullInformation?.capitalizedCostReduction || 0),
    },
    {
      header: 'Adj. Cap Cost',
      value: formatCurrency(accountFullInformation?.adjustedCapitalizedCost || 0),
    },
    {
      header: 'Residual Value',
      value: formatCurrency(accountFullInformation?.residualValue || 0),
    },
    {
      header: 'Total Base Pmts.',
      value: formatCurrency(accountFullInformation?.totalBasePayments || 0),
    },
  ];

  const leaseRowData3: AccountInfoData[] = [
    {
      header: 'Lease Term',
      value: `${accountFullInformation?.termTotal ?? 0}/${accountFullInformation?.termPaid ?? 0}`,
    },
    {
      header: 'Lease Payments',
      value: `${accountFullInformation?.paymentsMade ?? 0}/${
        accountFullInformation?.paymentsTotal ?? 0
      }`,
    },
    {
      header: 'Base Payments',
      value: formatCurrency(accountFullInformation?.basePayments || 0),
    },
    {
      header: 'Sales/Use Tax',
      value: formatCurrency(accountFullInformation?.salesUseTax || 0),
    },
    {
      header: 'Total Payment',
      value: formatCurrency(accountFullInformation?.totalPayment || 0),
    },
    {
      header: 'Mileage Per Year',
      value: accountFullInformation?.mileagePerYear.toLocaleString() ?? '',
    },
    {
      header: 'Excess Mileage Charge',
      value: accountFullInformation?.excessMileageCharge.toLocaleString() ?? '',
    },
    {
      header: 'Termination Fee',
      value: formatCurrency(accountFullInformation?.terminationFee || 0),
    },
    {
      header: 'Disposition Fee',
      value: formatCurrency(accountFullInformation?.dispositionFee || 0),
    },
    {
      header: 'Purchase Option Fee',
      value: formatCurrency(accountFullInformation?.purchaseOptionFee || 0),
    },
  ];

  const leaseRowData4: AccountInfoData[] = [
    {
      header: 'Depreciation Balance',
      value: formatCurrency(accountFullInformation?.depreciationBalance || 0),
    },
    {
      header: 'Rent Balance',
      value: formatCurrency(accountFullInformation?.rentBalance || 0),
    },
    {
      header: 'Down Pmt. Due',
      value: formatCurrency(accountFullInformation?.downPaymentDue || 0),
    },
    {
      header: 'Def. Down Pmt. Due',
      value: formatCurrency(accountFullInformation?.deferredDownPaymentDue || 0),
    },
    {
      header: 'Sidenote Due',
      value: formatCurrency(accountFullInformation?.sidenoteDue || 0),
    },
    {
      header: 'CPI Due',
      value: formatCurrency(accountFullInformation?.cpiDue || 0),
    },
    {
      header: 'Sales Tax Balance',
      value: formatCurrency(accountFullInformation?.salesTaxDue || 0),
    },
    {
      header: 'NSF Fees',
      value: formatCurrency(accountFullInformation?.nsfFees || 0),
    },
    {
      header: 'Late Fees',
      value: formatCurrency(accountFullInformation?.lateFees || 0),
    },
    {
      header: 'Total Balance',
      value: formatCurrency(accountFullInformation?.totalBalance || 0),
    },
  ];

  const financeRowData1: AccountInfoData[] = [
    {
      header: 'Date Sold',
      value: dayjs(accountFullInformation?.dateSold).utc().format('MM/DD/YYYY'),
    },
    {
      header: 'Orig. Maturity',
      value: dayjs(accountFullInformation?.originalMaturity).utc().format('MM/DD/YYYY'),
    },
    {
      header: 'Interest Rate',
      value: `${accountFullInformation?.interestRate.toFixed(2)}%`,
    },
    {
      header: 'Accrued Interest',
      value: formatCurrency(accountFullInformation?.accruedInterest || 0),
    },
    {
      header: 'Payoff',
      value: formatCurrency(accountFullInformation?.leasePayoff || 0),
    },
    {
      header: 'Per Diem',
      value: formatCurrency(accountFullInformation?.perDiem || 0),
    },
  ];

  const financeRowData2: AccountInfoData[] = [
    {
      header: 'Principal Balance',
      value: formatCurrency(accountFullInformation?.principalBalance || 0),
    },
    {
      header: 'Interest Balance',
      value: formatCurrency(accountFullInformation?.interestBalance || 0),
    },
    {
      header: 'Down Pmt. Due',
      value: formatCurrency(accountFullInformation?.downPaymentDue || 0),
    },
    {
      header: 'Def. Down Pmt. Due',
      value: formatCurrency(accountFullInformation?.deferredDownPaymentDue || 0),
    },
    {
      header: 'Sidenote Due',
      value: formatCurrency(accountFullInformation?.sidenoteDue || 0),
    },
    {
      header: 'CPI Due',
      value: formatCurrency(accountFullInformation?.cpiDue || 0),
    },
    {
      header: 'Sales Tax Balance',
      value: formatCurrency(accountFullInformation?.salesTaxDue || 0),
    },
    {
      header: 'NSF Fees',
      value: formatCurrency(accountFullInformation?.nsfFees || 0),
    },
    {
      header: 'Late Fees',
      value: formatCurrency(accountFullInformation?.lateFees || 0),
    },
    {
      header: 'Total Balance',
      value: formatCurrency(accountFullInformation?.totalBalance || 0),
    },
  ];

  const cellStyle = { padding: '14px 0', textAlign: 'center' };

  const DefaultCell = (props: any) => {
    const value = props.dataItem[props.field];
    return (
      <td {...props} className={styles.cellTableData} style={cellStyle}>
        {value}
      </td>
    );
  };

  const CurrencyCell = (props: any) => {
    const value = props.dataItem[props.field];
    return (
      <td {...props} className={styles.cellTableData} style={cellStyle}>
        {formatCurrency(value || 0)}
      </td>
    );
  };

  const DateCell = (props: any) => {
    const value = props.dataItem[props.field];
    return (
      <td {...props} className={styles.cellTableData} style={cellStyle}>
        {value ? dayjs(value).utc().format('MM/DD/YYYY') : ''}
      </td>
    );
  };

  const recentPayHistoryColumns: Column[] = [
    {
      field: 'pmtDate',
      title: 'DATE',
      cells: { data: DateCell },
    },
    {
      field: 'amountDue',
      title: 'DUE',
      cell: (props: any) => {
        const { totalApplied, entryType } = props.dataItem;
        return (
          <td {...props} className={styles.cellTableData} style={cellStyle}>
            {formatCurrency(entryType === 'New Pmt Due' ? totalApplied : 0 || 0)}
          </td>
        );
      },
    },
    {
      field: 'totalReceived',
      title: 'PAID',
      cell: (props: any) => {
        const { totalApplied, entryType } = props.dataItem;
        return (
          <td {...props} className={styles.cellTableData} style={cellStyle}>
            {formatCurrency(entryType === 'New Pmt Due' ? 0 : totalApplied || 0)}
          </td>
        );
      },
    },
    {
      field: 'entryType',
      title: 'PAYMENT TYPE',
      cells: { data: DefaultCell },
    },
  ];

  const nextPaymentColumns: Column[] = [
    {
      field: 'dueDate',
      title: 'DATE',
      cells: { data: DateCell },
    },
    {
      field: 'paymentType',
      title: 'TYPE',
      cells: { data: DefaultCell },
    },
    {
      field: 'paymentBalance',
      title: 'AMOUNT',
      cells: { data: CurrencyCell },
    },
  ];

  const AccountInfoRow = (props: { data: AccountInfoData[] }) => {
    const { data } = props;
    return (
      <table className={styles.accountInfoRow}>
        <thead className={styles.accountInfoRowHeader}>
          <tr>
            {data.map((data) => (
              <th className={styles.cellData}>{data.header.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.accountInfoRowBody}>
          <tr>
            {data.map((data) => (
              <td className={styles.cellData}>{data.value}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const LeaseInformationRows = () => {
    return (
      <>
        <AccountInfoRow data={leaseRowData1} />
        <AccountInfoRow data={leaseRowData2} />
        <AccountInfoRow data={leaseRowData3} />
        <AccountInfoRow data={leaseRowData4} />
      </>
    );
  };

  const FinanceOrOtherInformationRows = () => {
    return (
      <>
        <AccountInfoRow data={financeRowData1} />
        <AccountInfoRow data={financeRowData2} />
      </>
    );
  };

  return (
    <AccountsMainPanel navBarTitle="" loading={accountFullInformationLoading}>
      <div className={styles.accountInfo}>
        {accountFullInformation?.colType === 'LS' ? (
          <LeaseInformationRows />
        ) : (
          <FinanceOrOtherInformationRows />
        )}
        <div className={styles.gridContainer}>
          <div className={`${styles.grid} ${styles.leftGrid}`}>
            <h2 className={styles.subHeading}>Next 6 Payments Due</h2>
            <Spacer size={18} />
            <Grid data={next6Payments}>
              {nextPaymentColumns.map((column, index) => (
                <GridColumn
                  key={index}
                  field={column.field}
                  title={column.title ? column.title : ' '}
                  cells={column.cells}
                  width={column.width}
                />
              ))}
            </Grid>
          </div>
          <div className={`${styles.grid} ${styles.rightGrid}`}>
            <h2 className={styles.subHeading}>Recent Payment History</h2>
            <Spacer size={18} />
            <Grid data={paymentHistory}>
              {recentPayHistoryColumns.map((column, index) => (
                <GridColumn
                  key={index}
                  field={column.field}
                  title={column.title ? column.title : ' '}
                  cells={column.cells}
                  cell={column.cell}
                  width={column.width}
                />
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </AccountsMainPanel>
  );
};
