import { FC } from 'react';
import { NavLink } from 'react-router-dom';
// mui
import { GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@/mui/components/MuiKendoButton';

/** ### Button-link-cell for MUI Data-Grid
 * @todo add generic for grid render cell params
 */
const ButtonLinkCell: FC<GridRenderCellParams<any>> = ({ row }) => (
  <NavLink to={`./${row.recId}/`} relative="path">
    <Button
      size="small"
      sx={{ alignSelf: 'center', height: 30, borderRadius: 8 }}
      style={{ fontWeight: 500, fontSize: 12.25 }}
    >
      Edit
    </Button>
  </NavLink>
);

export default ButtonLinkCell;
