import React, { forwardRef, useMemo } from 'react';
import styles from './RadioGroupInput.module.scss';
import { Label } from '@progress/kendo-react-all';
import textStyles from '@components/text/Text.module.scss';
import { RadioGroup, RadioGroupHandle, RadioGroupProps } from '@progress/kendo-react-inputs';

export interface RadioGroupInputProps extends RadioGroupProps {
  label?: string;
  errors?: string | boolean;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  inputStyles?: React.CSSProperties;
  required?: boolean;
}

export const RadioGroupInput = forwardRef<RadioGroupHandle, RadioGroupInputProps>((props, ref) => {
  const {
    name,
    label,
    errors,
    value,
    boldLabel = true,
    horizontalLabel = true,
    inputStyles,
    required,
    ...rest
  } = props;
  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  const inputClassName = `${styles.input} ${errors ? styles.errors : ''}`;

  const labelStyle = { fontWeight: boldLabel ? 700 : 400 };

  return (
    <div className={styles.textContainer}>
      {label ? (
        horizontalLabel ? (
          <div className={textStyles.row}>
            <Label editorId={editorId} className={textStyles.label} style={labelStyle}>
              {label}
              {required ? '*' : ''}
            </Label>
            <div className={inputClassName}>
              <RadioGroup value={value} ref={ref} style={inputStyles} {...rest} />
            </div>
          </div>
        ) : (
          <Label className={textStyles.label} style={labelStyle}>
            {label}
            {required ? '*' : ''}
            <RadioGroup
              className={inputClassName}
              value={value}
              ref={ref}
              style={inputStyles}
              {...rest}
            />
          </Label>
        )
      ) : (
        <RadioGroup
          className={inputClassName}
          value={value}
          style={inputStyles}
          ref={ref}
          {...rest}
        />
      )}
      {!!errors && typeof errors === 'string' && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
