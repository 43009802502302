import { FC } from 'react';
// mui
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// utils
import { formFieldStyle } from './default';

const selectorLinkStyle: SxProps = {
  overflow: 'hidden',
  alignSelf: 'end',
  mr: 2,
  color: 'rgb(41, 70, 149)',
  width: formFieldStyle.width,
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 600,
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  lineHeight: '20px',
};
const ListSelectorLink: FC<{ setIsModalOpen: (tf: boolean) => void; text: string }> = ({
  setIsModalOpen,
  text,
}) => {
  return (
    <Link component={FormHelperText} sx={selectorLinkStyle} onClick={() => setIsModalOpen(true)}>
      {text}
    </Link>
  );
};

export default ListSelectorLink;
