import dayjs from 'dayjs';
import { IDefaultFormValues } from '@/features/Sales/detailView/WholesalesForm/VehicleTab/default';
import { SaleData } from '@/interfaces';
import { SaleType } from '@/enums';

export const buildSalesSam = (saleData: SaleData, vehicle?: IDefaultFormValues) => {
  /*
    * Sam content is usually coming from the `hotlist` table, e.g. when used in Accounts
    * That looks to initially have been the idea in Sales as well - using `where transtype = 'Application'` as a filter
    * However this appears to have been abandoned in favor of creating them in SaleInfo.a5wcmp and LeaseInfo.a5wcmp's xbasic functions
    * So we have to recreate that here

    * Unlike alpha, this also includes the logic that was disabling the "Post Lease" / "Post Sale" buttons
    * If something is required before being able to post, it can be marked as required for the samItem,
    *   then we can visually communicate that to the user (bolding the text, etc) and disable the button
    * It does seem like more of these should be required - verify that
    */
  const { sale, buyer, coBuyer } = saleData;

  const samItems: { text: string; required: boolean }[] = [];

  if (!sale) return samItems;

  const {
    totalDown = 0,
    depositDown = 0,
    tradeAllow = 0,
    cod = 0,
    tradePayoff = 0,
    salesPrice = 0,
  } = sale;

  const appendSam = (condition: boolean, text: string, required = false) => {
    if (condition) samItems.push({ text, required });
  };

  const isLease = sale.saletype === SaleType.Lease;
  const isFinance = sale.saletype === SaleType.Finance;

  const hasCobuyer = !!sale?.isCobuyer;
  // looks to be checking if the down payment is covered, but this condition blocks posting, and you can't do a down payment until it's posted...
  // SaleInfo xbasic functions line 290 seem to indicate that this is just another way of checking if the terms are still valid
  const termsStillCalculate = totalDown - depositDown - tradeAllow - cod - tradePayoff === 0;
  const termsStillValid = (sale.quoteDate && dayjs.utc(sale.quoteDate).isToday()) || false;
  const hasSalesPrice = !!salesPrice;
  const milesOut = saleData.milesInMilesOut?.milesOut;
  const hasMilesOut = milesOut !== null && milesOut !== undefined;
  const hasVehicle = !!sale.vehRecID;
  const hasSalesman = !!sale.salesman;
  const hasBuyerInfo = buyer && !!buyer?.b_LAST && !!buyer.b_ADDR;
  const hasQuote = sale.hasQuote;
  const hasBuyerCreditAuth = !!buyer?.b_SIGNED; // alpha was checking `application.cab` which is never populated - b_signed should work
  const hasCobuyerCreditAuth = !!coBuyer?.b_SIGNED; // alpha was checking `application.cac` which is never populated - b_signed should work
  const hasAdequateReferences = (sale.numRef ?? 0) >= 8;
  const hasAdequateVerifiedRefences = (sale.numvRef ?? 0) >= 4;
  const hasVerifiedApplication = !!sale.verified;
  const hasApprovedApplication = !!sale.approved;
  const canCalcDaysToFirstPayment =
    sale.firstpmtdue &&
    sale.saledate &&
    dayjs(sale.firstpmtdue).isValid() &&
    dayjs(sale.saledate).isValid();
  const daysToFirstPayment = canCalcDaysToFirstPayment
    ? dayjs(sale.firstpmtdue).diff(dayjs(sale.saledate), 'days')
    : null;

  appendSam(!hasVehicle, 'Need to select a vehicle', true);
  appendSam(!hasSalesPrice, 'Need to enter a sales price', isLease);
  appendSam(!hasMilesOut, 'Need miles out', true);
  appendSam(
    isFinance && (!termsStillValid || !termsStillCalculate || !hasQuote),
    'Need to print/reprint quote',
    true
  );
  appendSam(isLease && !hasQuote, 'Need to print/reprint quote');
  appendSam(!hasVerifiedApplication, 'Need to verify application', true);
  appendSam(!hasApprovedApplication, 'Need to approve application', true);
  appendSam(!hasSalesman, 'Need to select a salesman');
  appendSam(!hasBuyerInfo, "Need buyer's information");
  appendSam(!hasBuyerCreditAuth, 'Need credit authorization for buyer');
  appendSam(hasCobuyer && !hasCobuyerCreditAuth, 'Need credit authorization for co-buyer');
  appendSam(!hasAdequateReferences, `Need 8 personal references, have ${sale.numRef ?? 0}`);
  appendSam(!hasAdequateVerifiedRefences, `Need 4 verified references, have ${sale.numvRef ?? 0}`);
  appendSam(!!canCalcDaysToFirstPayment, `Days to first payment: ${daysToFirstPayment}`);
  appendSam(!!vehicle, `VIN: ${vehicle?.vin}`);
  /* There were several additional fields checking for:
   * `samstat` and `titstat` from the apptrades table (related to title status)
   * `poeb`, `poec`, `porb`, `porc` from the application table (related to buyer/cobuyer employment and residency verification)
   * These fields were never populated in the database so it seems to have been a partially implemented feature
   * Likely should be added in at some point
   */
  return samItems.sort((a, b) => (b.required ? 1 : 0) - (a.required ? 1 : 0));
};
