import { FC, useEffect } from 'react';
// components
import { Modal } from '@/components';
import ExportForms from './ExportForms';
import ModalExportButtons from './ModalExportButtons';
// utils
import { useReportsViewCtx } from '../state/view';
import { useReportsCtx } from '../ReportsList/defaultState';
import { IModalFilterParams, newModalFilterParams } from '../interfaces';
import { formatFormDate } from '../../../general/dates';
import { ReportsSubviews } from '../viewInfo';

/** ### Shows fields to customize reports
 *
 * These fields render based on certain conditions from the `report` payload.
 * - Modifying these fields updates the "Reports" view state:
 *    - These report-modifier values are used in the request.
 * - Button is used to submit request to generate report.
 */
const ExportFormModal: FC = () => {
  const subview = useReportsViewCtx((s) => s.subview);
  const isModalOpen = useReportsViewCtx((s) => s.isModalOpen);
  const setIsModalOpen = useReportsViewCtx((s) => s.setIsModalOpen);
  const selectedReport = useReportsCtx(s => s.selectedReport);
  const modalFilterParams = useReportsCtx(s => s.modalFilterParams);
  const setModalFilterParams = useReportsCtx(s => s.setModalFilterParams);


  // Event handlers
  const handleCloseExportModal = () => {
    setIsModalOpen(false);
  };

  // Effects
  // Initialize filter params to defaults on first render
  useEffect(() => {
    // Set default fields for modal params on first render of form
    if (selectedReport && !modalFilterParams) {
      const todayFmt = formatFormDate(new Date());
      const updatedModalFilterParams: IModalFilterParams = {
        ...newModalFilterParams(null),
        beginDate: todayFmt,
        endDate: todayFmt,
        int: 0,
        amt: 0,
      };
      setModalFilterParams(updatedModalFilterParams);
    }
  }, [selectedReport]);

  const isDefaultModalOpen =
    isModalOpen && subview === ReportsSubviews.default && !!selectedReport && !!modalFilterParams;

  return isDefaultModalOpen ? (
    <Modal
      title={selectedReport.reportNameOrg}
      centerModal
      closeButton
      onCloseButtonClick={handleCloseExportModal}
      onDimmerClick={handleCloseExportModal}
      isOpen={isModalOpen}
    >
      <ExportForms />
      <ModalExportButtons />
    </Modal>
  ) : (
    <div />
  );
};

export default ExportFormModal;
