import { FC } from 'react';
// kendo
import { Modal } from '@components';
// components
import SendTextToPay from './quickActionsItems/SendTextToPay';
import SendTextToOptIn from './quickActionsItems/SendTextToOptIn';
import PaymentArrangement from './quickActionsItems/paymentArrangement/PaymentArrangement';
import AddAppointment from './quickActionsItems/addApointment/AddAppointment';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
// interfaces
import { QuickActions } from '@/enums';
import { useLocation, useParams } from 'react-router-dom';

/** */
const QuickActionsModal: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const loc = useLocation();

  // Redux state
  const isOpen = useAccountSelector((s) => s.quickActionsModalOpened);
  const title = useAccountSelector((s) => s.quickActionType);
  const quickAction = useAccountSelector((s) => s.quickActionType);
  const isAccountInformationNull = useAccountSelector((s) => s.accountInformation === null);
  if (isAccountInformationNull) return <></>;

  // Event handlers
  const onCloseButtonClick = () => dispatch(accountActions.setQuickActionsModalOpened(false));
  const resetModal = () => dispatch(accountActions.setSendTextToPayResponse(''));

  const handleDimmerClick = () => {
    resetModal();
    onCloseButtonClick();
  };
  const handleCloseButtonClick = () => {
    resetModal();
    onCloseButtonClick();
  };

  // Select modal to display
  const modalComponentDisplay = () => {
    if (quickAction === QuickActions.SendTextToPay) {
      return <SendTextToPay />;
    } else if (quickAction === QuickActions.SendTextToOptIn) {
      return <SendTextToOptIn />;
    } else if (quickAction === QuickActions.SetUpPaymentArrangement) {
      return <PaymentArrangement />;
    } else if (quickAction === QuickActions.AddAppointment) {
      return <AddAppointment />;
    }

    return <div>Quick Action Not Found</div>;
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      closeButton={true}
      centerModal={true}
      onDimmerClick={handleDimmerClick}
      onCloseButtonClick={handleCloseButtonClick}
    >
      {modalComponentDisplay()}
    </Modal>
  );
};

export default QuickActionsModal;
