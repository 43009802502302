import { FC } from 'react';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import SectionColumn from '@/mui/components/form/Section/SectionColumn';
// kendo
import { Checkbox } from '@/components/checkbox/Checkbox';
import { TextInput } from '@/components/inputs/text/TextInput';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAcctInsuranceCtx } from './AcctInsuranceProvider';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';

/** ###  */
const PhysicalDamageColumn: FC = () => {
  const cpiSettings = useAcctInsuranceCtx((s) => s.cpiSettings);

  const insuranceInfo = useAcctInsuranceCtx((s) => s.insuranceInfo);
  const cpiRate = useAcctInsuranceCtx((s) => s.cpiRate);

  const insuranceInfoForm = useAcctInsuranceCtx((s) => s.insuranceInfoForm);
  const watch = useAcctInsuranceCtx((s) => s.insuranceInfoForm.watch);
  const control = useAcctInsuranceCtx((s) => s.insuranceInfoForm.control);

  const onCpi = watch('onCpi');
  const cpiYearlyPremium = watch('cpiYearlyPremium');

  const cpiOnDateFmt = formatDate(insuranceInfo?.cpiOnDate || dayjs());
  const cpiRateFmt = formatCurrency(cpiRate) || '';
  const cpiFirstDueFmt = formatDate(insuranceInfo?.cpiFirstDue || dayjs());

  return (
    <SectionColumn p={0}>
      <Grid component="h3">Physical Damage Insurance Requirement</Grid>

      <Controller
        name="onCpi"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            label="CPI Option"
            defaultChecked={insuranceInfo?.onCpi || false}
            onChange={(e) => {
              field.onChange(e.value);
              if (cpiSettings && insuranceInfo) {
                const cpiYearlyPremiumEnabled = cpiSettings.cpiYearlyPremiumEnabled;
                if (e.value === false) {
                  insuranceInfoForm.setValue(
                    'cpiYearlyPremium',
                    cpiYearlyPremiumEnabled ? 0 : null
                  );
                }
                if (cpiYearlyPremiumEnabled) {
                  insuranceInfoForm.setValue('cpiYearlyPremium', insuranceInfo?.cpiYearlyPremium);
                }
              }
            }}
          />
        )}
      />

      {onCpi && (
        <>
          {cpiSettings?.cpiYearlyPremiumEnabled && (
            <Controller
              name="cpiYearlyPremium"
              control={control}
              rules={{ max: { value: 10000, message: 'Exceeds the maximum value' } }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  label="Yearly Premium"
                  errors={
                    cpiYearlyPremium !== null &&
                    cpiYearlyPremium > 10000 &&
                    'Exceeds the maximum value'
                  }
                />
              )}
            />
          )}

          <TextInput
            readOnly
            label="Schedule"
            value={insuranceInfo?.cpiSched || insuranceInfo?.schedule || '-'}
          />

          <CurrencyInput readOnly label="Rate" value={cpiRate} placeholder="-" />

          <Grid container direction="column" fontSize={14} pt={1}>
            <Grid>Effective {cpiOnDateFmt}:</Grid>
            <Grid>
              {cpiRateFmt} {insuranceInfo?.cpiSched || '(Schedule)'}, beginning {cpiFirstDueFmt}
            </Grid>
          </Grid>
        </>
      )}
    </SectionColumn>
  );
};

export default PhysicalDamageColumn;
