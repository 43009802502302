import { FC, ReactNode, forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
// mui
import Grid2Props from "@mui/material/Unstable_Grid2/Grid2Props";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { FormControlProps } from "@mui/material/FormControl";
import { FormLabelProps } from "@mui/material/FormLabel";
import { FormHelperTextProps } from "@mui/material/FormHelperText";
import { MKTextInput, MuiKendoHelperText } from "./MuiKendoTextField";
// local
import MuiKendoFormControl from "./MuiKendoFormControl";

const NumericFormatCustom = forwardRef<
  NumericFormatProps<HTMLInputElement>,
  {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  } & NumericFormatProps<HTMLInputElement>
>(({ onChange, name, prefix, suffix, thousandSeparator = true, ...other }, _) => {
  return (
    <NumericFormat
      {...other}
      // getInputRef={ref}
      onValueChange={({ value }) => onChange({ target: { name, value } })}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      suffix={suffix}
      name={name}
      // @note this prop comes from the example, but does not exist
      // isNumericString
    />
  );
});

/** ###  */
const MuiKendoNumericField: FC<
  OutlinedInputProps & {
    errorText?: string;
    helperText?: string;
    helperTextProps?: FormHelperTextProps;
    keepHelperTextSpacing?: boolean;
    helperComponent?: ReactNode;
    containerProps?: Grid2Props.Grid2Props;
    formControlProps?: FormControlProps;
    labelProps?: FormLabelProps;
    numericProps?: NumericFormatProps;
  }
> = ({
  label,
  disabled,
  errorText,
  helperText,
  helperTextProps,
  keepHelperTextSpacing,
  helperComponent,
  formControlProps,
  containerProps,
  labelProps,
  numericProps,
  ...inputProps
}) => {
  return (
    <>
      <MuiKendoFormControl
        {...formControlProps}
        label={label}
        disabled={disabled}
        containerProps={containerProps}
        labelProps={{ ...labelProps, sx: { flex: 1, ...labelProps?.sx } }}
        error={!!(errorText || inputProps.error)}
      >
        <MKTextInput
          {...inputProps}
          disabled={disabled}
          error={!!(errorText || inputProps.error)}
          inputComponent={NumericFormatCustom as any}
          inputProps={numericProps as any}
          sx={{ ...inputProps.sx, flex: 1.25 }}
        />
      </MuiKendoFormControl>
      <MuiKendoHelperText
        {...helperTextProps}
        errorText={errorText}
        helperText={helperText}
        helperComponent={helperComponent}
        keepHelperTextSpacing={keepHelperTextSpacing}
      />
    </>
  );
};

export default MuiKendoNumericField;
