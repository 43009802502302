import { FC } from 'react';
// components
import DashboardCardLayout from './DashboardCardLayout';
// state
import { useDashboardViewCtx } from '../DashboardViewProvider';
// utils
import { formatCurrency } from '@/utils/helpers/general';

/** ###  */
const UnpaidSalesCard: FC = () => {
  const isLoading = useDashboardViewCtx((s) => s.isLoading);
  const unpaidSales = useDashboardViewCtx((s) => s.dashboardData?.unpaidSales);
  const unpaidSalesCt = unpaidSales?.unpaidSalesCt;
  const unpaidSalesTotal = unpaidSales?.unpaidSalesTotal;

  const areInputsValid = !isNaN(Number(unpaidSalesCt)) && !isNaN(Number(unpaidSalesTotal));
  const secondary = areInputsValid
    ? `${formatCurrency(unpaidSalesTotal || 0)} Unpaid as of Date`
    : 'Data unavailable';

  return (
    <DashboardCardLayout
      header="Unpaid"
      primary={unpaidSalesCt?.toString() || 'N/A'}
      secondary={secondary}
      isLoading={isLoading}
    />
  );
};

export default UnpaidSalesCard;
