import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
// kendo
import { RadioGroup } from '@progress/kendo-react-inputs';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { Button, Checkbox, DropdownInput, Modal, NumberInput, TextInput, PhoneInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { shortZipRegex, emailRegex, optionalPhoneValidation } from '@/utils/helpers/formValidation';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { GetReferencesData, Reference } from '@/interfaces';
import { BuyerType } from '@/enums';
// style
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

// @todo move component to file
const ReferenceForm: FC<{
  reference?: Reference;
  isNewReference?: boolean;
  hasCoBuyer?: boolean;
  onSubmitSuccess?: () => void;
}> = ({
  onSubmitSuccess = () => null,
  reference = {} as Reference,
  isNewReference,
  hasCoBuyer,
}) => {
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const [referenceSubmitting, setReferenceSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Reference>({
    defaultValues: {
      // @todo use class constructor function w/ validation
      recId: reference.recId || 0,
      refFor: reference.refFor || 'Buyer',
      name: reference.name || '',
      shortAddress: reference.shortAddress || '',
      city: reference.city || '',
      state: reference.state || '',
      zip: reference.zip || '',
      knownForMonths: reference.knownForMonths || 0,
      knownForYears: reference.knownForYears || 0,
      relationship: reference.relationship || '',
      homePhone: reference.homePhone || '',
      cellPhone: reference.cellPhone || '',
      email: reference.email || '',
      currentCustomer: reference.currentCustomer || 'N/A',
      currentCustomerLot: reference.currentCustomerLot || '',
      noCalls: reference.noCalls || false,
      noTexts: reference.noTexts || false,
      noEmails: reference.noEmails || false,
    },
  });

  const onSubmit = (updatedReference: Reference) => {
    setReferenceSubmitting(true);
    if (isNewReference) {
      updatedReference.colRecId = colRecId;
      // @todo use async/await
      accountsService
        .createReference(updatedReference)
        .then(() => {
          onSubmitSuccess();
          reset();
        })
        .finally(() => {
          setReferenceSubmitting(false);
        });
    } else {
      // @todo use async/await
      accountsService
        .updateReference(updatedReference)
        .then(() => {
          onSubmitSuccess();
        })
        .finally(() => {
          setReferenceSubmitting(false);
        });
    }
  };

  const buyerOptions = ['Buyer', 'Co-Buyer'].map((bo) => ({ label: bo, value: bo }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.referencesForm}>
      {isNewReference && hasCoBuyer && (
        <h3 style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          New reference for:
          <RadioGroup
            data={buyerOptions}
            layout="horizontal"
            value={watch('refFor')}
            onChange={(e) => setValue('refFor', e.value)}
          />
        </h3>
      )}
      <div className={styles.columns}>
        <div className={styles.column}>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextInput label="Name" errors={errors.name?.message} {...field} />
            )}
          />
          <Controller
            name="shortAddress"
            control={control}
            render={({ field }) => (
              <TextInput label="Address" errors={errors.shortAddress?.message} {...field} />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextInput label="City" errors={errors.city?.message} {...field} />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <DropdownInput
                label="State"
                data={usaStateCodes}
                errors={errors.state?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="zip"
            control={control}
            rules={{
              pattern: {
                value: shortZipRegex,
                message: 'Zip must be 5 numbers',
              },
            }}
            render={({ field }) => (
              <TextInput label="Zip" errors={errors.zip?.message} {...field} />
            )}
          />
          <Controller
            name="cellPhone"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field }) => (
              <PhoneInput
                label="Cell Phone"
                errors={errors.cellPhone?.message || !!errors.cellPhone}
                {...field}
              />
            )}
          />
          <Controller
            name="homePhone"
            control={control}
            rules={optionalPhoneValidation}
            render={({ field }) => (
              <PhoneInput
                label="Home Phone"
                errors={errors.homePhone?.message || !!errors.homePhone}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              pattern: {
                value: emailRegex,
                message: 'Invalid format',
              },
            }}
            render={({ field }) => (
              <TextInput label="Email" errors={errors.email?.message} {...field} />
            )}
          />
        </div>
        <div className={styles.column}>
          <Controller
            name="relationship"
            control={control}
            render={({ field }) => (
              <TextInput label="Relationship" errors={errors.relationship?.message} {...field} />
            )}
          />
          <Controller
            name="knownForYears"
            control={control}
            render={({ field }) => (
              <NumberInput label="Years known" errors={errors.knownForYears?.message} {...field} />
            )}
          />
          <Controller
            name="knownForMonths"
            control={control}
            render={({ field }) => (
              <NumberInput
                label="Months known"
                errors={errors.knownForMonths?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="currentCustomer"
            control={control}
            render={({ field }) => (
              <DropdownInput
                label="Current Customer?"
                data={['N/A', 'Current', 'Previous']}
                {...field}
              />
            )}
          />
          {!!watch('currentCustomer') && watch('currentCustomer') !== 'N/A' && (
            <Controller
              name="currentCustomerLot"
              control={control}
              render={({ field }) => <TextInput label="Dealership" {...field} />}
            />
          )}
          <div className={styles.optOutCheckboxes}>
            <div>
              <Controller
                name="noCalls"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Call" {...field} />}
              />
            </div>
            <div>
              <Controller
                name="noTexts"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Text" {...field} />}
              />
            </div>
            <div>
              <Controller
                name="noEmails"
                control={control}
                render={({ field }) => <Checkbox label="Do Not Email" {...field} />}
              />
            </div>
          </div>
          <Spacer expand />
          <div style={{ width: '130px' }}>
            <Button
              label={isNewReference ? 'Submit' : 'Update'}
              loading={referenceSubmitting}
              disabled={referenceSubmitting || !isDirty}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

// @todo move component to file
export const References: FC = () => {
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const [buyerType, setBuyerType] = useState<BuyerType>(BuyerType.Buyer);
  const [references, setReferences] = useState<GetReferencesData>();
  const [referencesLoading, setReferencesLoading] = useState(false);
  const [newReferenceModalOpen, setNewReferenceModalOpen] = useState(false);

  const { accountInformation, accountInformationLoading } = useAccountSelector((state) => state);

  const hasCoBuyer = !!(
    accountInformation?.coBuyer?.appBuyerRecId && accountInformation?.coBuyer?.firstName
  );

  const navBarItems = hasCoBuyer
    ? [
        {
          title: BuyerType.Buyer,
          isActive: buyerType === BuyerType.Buyer,
          onClick: () => setBuyerType(BuyerType.Buyer),
        },
        {
          title: BuyerType.CoBuyer,
          isActive: buyerType === BuyerType.CoBuyer,
          onClick: () => setBuyerType(BuyerType.CoBuyer),
        },
      ]
    : [];

  const loadReferences = () => {
    setReferencesLoading(true);
    // @todo use async/await
    accountsService
      .getReferences(colRecId)
      .then((res) => setReferences(res))
      .finally(() => setReferencesLoading(false));
  };

  useEffect(() => {
    if (!accountInformation) return;
    loadReferences();
    // @todo deps arr must use stable ref
  }, [accountInformation]);

  const { buyer, coBuyer } = references ?? {};

  const currentReferences = (buyerType === BuyerType.Buyer ? buyer : coBuyer) ?? [];

  // @todo move nested components to separate files
  return (
    <AccountsMainPanel
      navBarTitle="References"
      navBarItems={navBarItems}
      loading={referencesLoading || accountInformationLoading}
    >
      {currentReferences.map((reference) => (
        <ReferenceForm reference={reference} key={reference.recId} />
      ))}
      <Modal
        centerModal
        isOpen={newReferenceModalOpen}
        closeButton
        onCloseButtonClick={() => setNewReferenceModalOpen(false)}
        onDimmerClick={() => setNewReferenceModalOpen(false)}
      >
        <ReferenceForm
          onSubmitSuccess={() => {
            loadReferences();
            setNewReferenceModalOpen(false);
          }}
          hasCoBuyer={hasCoBuyer}
          isNewReference
        />
      </Modal>
      <div className={styles.stickyBottom}>
        <div className={styles.link} onClick={() => setNewReferenceModalOpen(true)}>
          + Add a reference
        </div>
      </div>
    </AccountsMainPanel>
  );
};
