import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
// style
import styles from '../../AccountDetail.module.scss';

/** ###  */
const NavTab: FC<NavLinkProps & { name: string }> = ({ name, ...props }) => (
  <NavLink
    {...props}
    style={{ padding: '10px 20px' }}
    className={({ isActive }) => (isActive ? styles.tabActive : styles.tab)}
  >
    {name}
  </NavLink>
);

export default NavTab;
