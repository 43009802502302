import { FC, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
// style
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

const kendoHeaderStyle: SxProps = {
  fontSize: '26px',
  letterSpacing: '-0.52px',
  fontWeight: 700,
  alignItems: 'center',
  fontFamily: 'inter',
};
const kendoTitleStyle: SxProps = {
  fontFamily: 'inherit',
  fontSize: 'inherit',
  letterSpacing: 'inherit',
  fontWeight: 'inherit',
};

/** Leave `dbCt` unset if you do not want to render a number */
const MuiViewHeader: FC<{
  primaryText?: string;
  secondaryText?: string;
  centerText?: ReactNode;
  isLoading?: boolean;
  dbCt?: number | null;
  viewSelector?: JSX.Element;
  centered?: boolean;
}> = ({
  primaryText,
  secondaryText,
  centerText,
  isLoading = true,
  dbCt,
  viewSelector,
  centered = true,
}) => {
  let title = primaryText !== undefined ? primaryText : '';
  if (primaryText !== undefined && secondaryText !== undefined) {
    title = `${title}: ${secondaryText}`;
  }

  let dbTableCountFmt: string = '';
  // If provided a dbCt, wrap the string in parentheses, use '-' to indicate loading
  if (dbCt !== undefined) {
    dbTableCountFmt = isLoading === true || dbCt === null ? '-' : `${dbCt}`;
    dbTableCountFmt = `(${dbTableCountFmt})`;
  }

  return (
    <Grid container direction="row" alignItems="center" pb="37px" justifyContent="space-between">
      <Grid container direction="row" gap={1} sx={kendoHeaderStyle}>
        <Typography sx={kendoTitleStyle} noWrap flex={centered ? 1 : undefined}>
          {title}
        </Typography>
        {dbCt !== undefined && (
          <Typography sx={kendoTitleStyle} color={DCS_BLUE}>
            {dbTableCountFmt}
          </Typography>
        )}
      </Grid>
      <Grid flex={1}>{centerText}</Grid>

      <Grid container justifyContent="end" flex={centered ? 1 : undefined}>
        {viewSelector}
      </Grid>
    </Grid>
  );
};

export default MuiViewHeader;
