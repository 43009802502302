import { DCS_BLUE } from '@/mui/theme/componentDefaults';
import { FC } from 'react';

interface GridCardProps {
  header: string;
  subText: string;
  children: React.ReactNode;
}

const supplementContainerStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '5px',
  alignItems: 'center',
  fontWeight: '700',
  fontSize: '12px',
};

export const GridCard: FC<GridCardProps> = ({ header, subText, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '5px',
        borderRadius: '12px',
        background: '#fff',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: DCS_BLUE,
          color: '#fff',
          borderRadius: '12px 12px 0px 0px',
          padding: '10px',
          fontSize: '18px',
          fontWeight: '500',
        }}
      >
        {header}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          {children}
        </div>
        <div style={supplementContainerStyles}>{subText}</div>
      </div>
    </div>
  );
};
