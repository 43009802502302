import { FC, PropsWithChildren, ReactNode, createContext, useEffect, useState } from "react";
// import { useMatch } from "react-router-dom";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
// utils
import { inventoryService } from "@/services/inventoryService";
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// import { getRouteParamNum } from "@/utils/routing/formatting";

const useCtxState = () => {
  // @todo to be added
  // const appRecId = getRouteParamNum(useMatch("/sales/applications/:appRecId/*")?.params.appRecId);

  /** @todo migrate req and value from redux */
  const saleData = useSalesSelector((s) => s.saleData);
  const vehRecId = saleData.sale?.vehRecID;

  /** @deprecated to be removed */
  const [headerTitle, setHeaderTitle] = useState<ReactNode>(null);

  const suggestedSalesInfo = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getSuggestedSalesInfo(vehRecId);
  });
  const vehicleCost = useReq(async () => {
    if (!vehRecId) return;
    return await inventoryService.getVehicleCostDetails(vehRecId.toString());
  });

  useEffect(() => {
    suggestedSalesInfo.load();
    vehicleCost.load();
  }, [vehRecId]);

  return {
    headerTitle,
    setHeaderTitle,

    saleData,
    suggestedSalesInfo,
    vehicleCost,
  };
};

export type ISalesDetailCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ISalesDetailCtx | null>(null);
export const useSalesDetailCtx = useCtxFactory(Ctx);

const SalesDetailProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SalesDetailProvider;
