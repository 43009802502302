import { AxiosService } from './axiosService';
import { ApiResponse } from '@/interfaces';
import { CustomFields } from '@/interfaces';
import { CustomFieldsUpdateInterface } from '@/interfaces';
import { CreditEducationDBInfo, CreditRepairCloud } from '@/interfaces/CreditEducation';
import { displayErrors } from '@/utils/helpers/general';

class ApplicationService extends AxiosService {
  public constructor() {
    super();
  }

  async getCustomFields(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CustomFields>>(
        '/Application/CustomFields',
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async setCustomFields(appRecId: number, customFields: CustomFieldsUpdateInterface) {
    try {
      const { data } = await this.axios.put<ApiResponse<CustomFieldsUpdateInterface>>(
        '/Application/CustomFields',
        customFields,
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCreditEducationInfo(appRecId: number | null | undefined) {
    try {
      if (appRecId == null) throw new Error('AppRecId cannot be null');

      const { data } = await this.axios.get<ApiResponse<CreditEducationDBInfo>>(
        '/Application/CreditEducationInfo',
        {
          params: {
            applicationId: appRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postCreditRepairCloud(req: CreditRepairCloud) {
    try {
      const { data } = await this.axios.post<ApiResponse<CreditRepairCloud>>(
        '/Application/CreditRepairCloud',
        req
      );

      return data.data!;
    } catch (err: any) {
      console.error(err);
      displayErrors(err.message);
      throw err;
    }
  }
}

export const applicationService = new ApplicationService();
