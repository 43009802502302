import { FC, SyntheticEvent, useEffect } from "react";
// mui
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MuiKendoHelperText } from "@/mui/components/form/MuiKendoTextField";
// local
import MuiKendoFormControl from "@/mui/components/form/MuiKendoFormControl";
// state
import { useWholesaleFormCtx } from "../WholesaleFormProvider";
// style
import { formFieldStyle } from "./default";

/** ###  */
const SalespersonField: FC = () => {
  const selectedSalesperson = useWholesaleFormCtx((s) => s.selectedSalesperson);
  const setWsFormField = useWholesaleFormCtx((s) => s.setWsFormField);
  const errorMsg = useWholesaleFormCtx((s) => s.wsFormErrors.salesMan.msg);
  const setWsFormFieldError = useWholesaleFormCtx((s) => s.setWsFormFieldError);
  const salespeople = useWholesaleFormCtx((s) => s.formAddlData!.salespeople);
  const salespeopleOptionLabels = salespeople.map((s) => s.shortName);
  const wsForm = useWholesaleFormCtx((s) => s.wsForm!);

  // Event handlers
  const handleChange = (_: SyntheticEvent<Element, Event>, newSalespersonLabel: string | null) => {
    const salesperson = salespeople.find((s) => s.shortName === newSalespersonLabel) || null;
    setWsFormField("salesMan", salesperson?.recId || null);
  };

  // Effects
  useEffect(() => {
    const errorMsg = selectedSalesperson === null ? "Salesperson cannot be empty" : "";
    setWsFormFieldError("salesMan", errorMsg);
  }, [selectedSalesperson === null]);

  const isPosted = wsForm.saleStatus === "Posted";

  return (
    <>
      <MuiKendoFormControl
        label="Salesperson"
        labelProps={{ sx: { flex: 1 } }}
        error={!!errorMsg}
        disabled={isPosted}
      >
        <Autocomplete
          value={selectedSalesperson?.shortName || null}
          options={salespeopleOptionLabels}
          onChange={handleChange}
          disabled={isPosted}
          renderInput={(p) => (
            <TextField
              {...p}
              placeholder="None selected"
              error={!!errorMsg}
              inputProps={{ ...p.inputProps, sx: { fontSize: 14 } }}
              disabled={isPosted}
            />
          )}
          sx={{
            ...formFieldStyle,
            m: 1,
            flex: 1.25,
            background: isPosted ? "rgb(220, 220, 220)" : "white",
          }}
        />
      </MuiKendoFormControl>
      <MuiKendoHelperText errorText={errorMsg} />
    </>
  );
};

export default SalespersonField;
