import React, { CSSProperties, forwardRef, useMemo } from 'react';
import styles from './TextArea.module.scss';
import { Label } from '@progress/kendo-react-all';
import textStyles from '@components/text/Text.module.scss';
import {
  TextArea as KendoTextArea,
  TextAreaHandle,
  TextAreaProps as KendoTextAreaProps,
} from '@progress/kendo-react-inputs';

export interface TextAreaProps extends KendoTextAreaProps {
  label?: string;
  errors?: string | boolean;
  value?: string;
  boldLabel?: boolean;
  containerStyle?: CSSProperties;
}

export const TextArea = forwardRef<TextAreaHandle, TextAreaProps>((props, ref) => {
  const { name, label, errors, value, readOnly, required, rows, containerStyle, boldLabel = true, ...rest } = props;
  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  return (
    <div className={styles.textContainer} style={containerStyle}>
      {label ? (
        <Label
          editorId={editorId}
          className={textStyles.label}
          style={{ fontWeight: boldLabel ? 700 : 400 }}
        >
          {`${label} ${required ? '*' : ''}`}
          <KendoTextArea
            id={editorId}
            readOnly={readOnly}
            rows={rows ?? 1}
            className={errors ? `${styles.errors} ${styles.input}` : styles.input}
            value={value}
            ref={ref}
            {...rest}
          />
        </Label>
      ) : (
        <KendoTextArea
          id={editorId}
          readOnly={readOnly}
          rows={rows ?? 1}
          className={errors ? `${styles.errors} ${styles.input}` : styles.input}
          value={value}
          ref={ref}
          {...rest}
        />
      )}
      {!!errors && typeof errors === 'string' && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
