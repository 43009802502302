import { useState } from "react";

/** ### Utility hook for managing request state.
 * - This hook abstracts out logic and state frequently used for GET requests.
 *
 * Used for the transition to `react-query`
 *
 * #### Usage:
 * ```tsx
 * // In `VehicleTabProvider.tsx
 * 
 * const useCtxState = () => {
 *   // Requests based on a state value
 *   const vehicleCost = useReq(async () => {
 *     if (!invRecId) return;
 *     return await inventoryService.getVehicleCostDetails(invRecId);
 *   });
 *   const vehicleDetails = useReq(async () => {
 *     if (!invRecId) return;
 *     return await inventoryService.getVehicleDetails(invRecId);
 *   });
 *   const colorOptions = useReq(() => inventoryService.getColors());
 *   const bodyStyleOptions = useReq(() => inventoryService.getBodyStyles());
 *   const vehicleTypeOptions = useReq(() => inventoryService.getVehicleTypes());
 *   // ... other states
 * 
 *   // Update when browser param changes
 *   useEffect(() => {
 *     colorOptions.load();
 *     bodyStyleOptions.load();
 *     vehicleTypeOptions.load();
 *     // ... other fetches
 *   }, [invRecId]);
 * 
 *   // ... other logic
 * 
 *   return {
 *     colorOptions,
 *     bodyStyleOptions,
 *     vehicleTypeOptions,
 *     // ... other states
 *   };
 * };
 * ```
 *
 */
const useReq = <T,>(req: () => Promise<T | undefined | null | void>, isInvalid: boolean = false, defaultValue: T | null = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<T | null>(defaultValue);

  const load = async () => {
    if (isInvalid) return;

    try {
      setIsLoading(true);
      const res = await req();
      setValue(res || null);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    load,
    value,
    setValue,
  };
};

export default useReq;
