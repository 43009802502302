import { ScannedDocument } from '@/interfaces';
import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Loader } from '@/components/loader/Loader';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modal/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { Icons } from '@/components/icons';
import { TextInput } from '@/components/inputs/text/TextInput';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
// components
import { FileInput } from '@/components/inputs/fileInput/FileInput';
import PhotoGallery from './ImageGallery';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { salesService } from '@/services/salesService';
import { systemService } from '@/services/systemService';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
// interfaces
// style
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import scannedStyles from './ScannedDocuments.module.scss';
import styles from '../inventoryExpenses/expensesGrid/expensesGrid.module.scss';

interface Photo {
  url: string;
  title: string;
}

const Photos: FC = () => {
  const { accountInformation } = useAccountSelector((s) => s);
  const vehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);
  const { orgId, compId, locId } = useAuthSelector((s) => s);
  const [scannedDocModalOpen, setScannedDocModalOpen] = useState(false);
  const [scannedDocsList, setScannedDocsList] = useState<ScannedDocument[]>([]);
  const [scannedFileDesc, setScannedFileDesc] = useState('');
  const [scannedFile, setScannedFile] = useState<File | null>(null);
  const [uploadingScannedFile, setUploadingScannedFile] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);
  const [photos, setPhotos] = useState<Photo[]>([]);

  const fetchPhotos = async (photosList: ScannedDocument[], orgId: number) => {
    const photosObject = photosList.map((file) => {
      return {
        orgId: orgId!,
        containerName: 'uploads',
        blobName: file.fileNameCloud,
      };
    });
    const photoInfoList = await systemService.getBlobReadUrls(photosObject);

    return photoInfoList;
  };

  const loadScannedDocs = async () => {
    try {
      if (Number(vehicle?.recId) < 1) {
        return;
      }
      const res = await salesService.getPhotosByVehicle(orgId!, Number(vehicle?.recId));
      if (res && res.length > 0) {
        setScannedDocsList(res);
        const photoInfoList = await fetchPhotos(res, orgId!);

        const photos: Photo[] = photoInfoList.map((photo) => {
          const foundDoc = res.find((doc) => {
            return photo.blobName === doc.fileNameCloud;
          });
          return {
            url: photo.url,
            title: foundDoc?.fileDesc!,
          };
        });

        setPhotos(photos);
      }
    } finally {
    }
  };

  const uploadScannedFile = async () => {
    if (!scannedFile) {
      setFileInputError(true);
      return;
    }
    if (!scannedFileDesc) {
      setFileDescriptionError(true);
      return;
    }

    const payload = {
      file: scannedFile!,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: accountInformation?.appRecId ?? 0,
      vehRecId: vehicle?.recId ?? 0,
      fileDesc: scannedFileDesc,
    };

    setUploadingScannedFile(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadScannedDocs(); // Ensure the list is refreshed after upload
      setScannedFile(null);
      setScannedFileDesc('');
      setScannedDocModalOpen(false);
    } finally {
      setUploadingScannedFile(false);
    }
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setScannedFileDesc(e.target.value);
  };

  const handleScannedDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setScannedFile(file);
  };

  // @todo implement photo delete button
  const deleteButtonRender = (props: any) => {
    const value = props.dataItem;
    const [deleting, setDeleting] = useState(false);

    const handleDelete = () => {
      setDeleting(true);
      try {
        salesService.deleteScannedDoc(orgId!, value.recId);
        loadScannedDocs();
      } catch (error) {
        console.error(error);
      } finally {
        setDeleting(false);
      }
    };

    return (
      <td className="k-table-td">
        {deleting ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handleDelete} className={styles.tableActionIconContainer}>
            <Icons.X style={{ color: 'red' }} />
          </div>
        )}
      </td>
    );
  };

  useEffect(() => {
    loadScannedDocs();
  }, [orgId, vehicle?.recId]);

  return (
    <section className={inventoryPageStyles.multipleSectionsChild}>
      <Grid container className={scannedStyles.container}>
        <Grid container direction="column" flex={1} className={scannedStyles.body}>
          <section className={scannedStyles.section}>
            <SectionHeader showBar title="Photos" />
            <div className={scannedStyles.buttonContainer}>
              <Button label="Upload New" onClick={() => setScannedDocModalOpen(true)} />
            </div>
            <Spacer size={25} />
            <Grid container direction="row" wrap="wrap">
              <PhotoGallery photos={photos} />
            </Grid>
          </section>
        </Grid>
        {scannedDocModalOpen && (
          <Modal
            isOpen={true}
            onCloseButtonClick={() => setScannedDocModalOpen(false)}
            closeButton
            centerModal
            title="Upload New Photo"
            panelStyle={{ width: 600 }}
          >
            <div>
              <Spacer size={5} />
              <FileInput
                onChange={handleScannedDocInputChange}
                fileName={scannedFile?.name}
                errors={fileInputError}
              />
              <Spacer size={20} />
              <TextInput
                required
                label="Photo Description"
                value={scannedFileDesc}
                horizontalLabel={false}
                onChange={handleFileDescriptionChange}
                errors={fileDescriptionError}
              />
              <Spacer size={20} />
              <div style={{ width: 100, marginLeft: 'auto' }}>
                <Button label="Submit" loading={uploadingScannedFile} onClick={uploadScannedFile} />
              </div>
            </div>
          </Modal>
        )}
      </Grid>
    </section>
  );
};

export default Photos;
