import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { Button } from '@/components/button/Button';
import { Icons } from '@/components/icons';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAppDispatch } from '@/store/store';
import { useWholesaleFormCtx } from '../WholesaleFormProvider';
import {
  getAvailableVehicles,
  getSaleData,
  getSaleVehicle,
} from '@/features/Sales/salesActionCreator';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
// utils
import { salesService } from '@/services/salesService';
import { useSalesParams } from '../../utils';
// interfaces
import { WsFormVehicle } from '../interfaces';
import { Vehicle } from '@/interfaces';
import { Column } from '@/components/table/TableInterface';
import { IDefaultFormValues, defaultFormValues } from './default';

const bodyTypeData = ['2 door', '4 door', 'Coupe', 'Hatch Back', 'Sedan', 'Truck'];

const ScCell = (props: any) => {
  const { scOpen } = props.dataItem;
  return (
    <td className="k-table-td">
      <span>{scOpen ? <Icons.Service style={{ width: 14, height: 14 }} /> : '--'}</span>
    </td>
  );
};

/** @deprecated convert to context */
export const useVehicleForm = () => {
  const { appRecId: appRecIdStr } = useSalesParams();
  const appRecId = Number(appRecIdStr);
  const isAppRecId = !isNaN(appRecId);
  const dispatch = useAppDispatch();
  const compId = useAuthSelector((s) => s.compId);
  // WS form state
  const wsFormVehicle = useWholesaleFormCtx((s) => s.wsFormVehicle);
  const setWsFormField = useWholesaleFormCtx((s) => s.setWsFormField);
  const availableVehicles = useWholesaleFormCtx((s) => s.formAddlData?.vehicles);
  const setFormAddlDataRes = useWholesaleFormCtx((s) => s.setFormAddlDataRes);
  const selectedVehicleId = useSalesSelector((s) => s.selectedVehicleId);
  // Local state
  const [listData, setListData] = useState<WsFormVehicle[]>(availableVehicles!);
  const [selectVehicleLoading, setSelectVehicleLoading] = useState(false);
  const [updateVehicleLoading, setUpdateVehicleLoading] = useState(false);

  const { control, watch, reset, handleSubmit, formState } = useForm({
    defaultValues: defaultFormValues,
  });

  // Event handlers
  const resetVehicleFields = (vehicle: IDefaultFormValues) => {
    reset({
      stockNum: vehicle.stockNum,
      vin: vehicle.vin,
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      color: vehicle.color,
      bodyType: vehicle.bodyStyle,
      transmission: 'Automatic',
      price: vehicle.sugPrice,
      downPayment: vehicle.sugDown,
      downPaymentPercentage: vehicle.sugPercent,
      schedule: vehicle.sugTerms,
      payment: vehicle.sugPmt,
      months: vehicle.sugTerm,
      tmu: vehicle.alerT1,
      salvageTitle: vehicle.alerT2,
      frameDamage: vehicle.alerT3,
      unibody: vehicle.alerT4,
      airbag: vehicle.alerT6,
      other: vehicle.alerT5,
      otherAlert: vehicle.alerT5N,
      plateNumber: vehicle.tempTagNum,
      dateExpires: dayjs(vehicle.tempTagExp).format('YYYY-MM-DD'),
      dateIssued: dayjs(vehicle.tempTagIss).format('YYYY-MM-DD'),
      milesOut: Number(vehicle.milesOut),
      // Checkboxes
      notActual: false,
      exceedsLim: false, // @todo need to validate which is the correct request field
      exceedsLimits: false, // @todo need to validate which is the correct request field
      exempt: false,
    });
  };

  const updateVehicle = async (data: IDefaultFormValues) => {
    setUpdateVehicleLoading(true);
    try {
      await salesService.updateVehicle({
        recId: wsFormVehicle!.recId!,
        sugPrice: data.price,
        stockNum: data.stockNum,
        sugTerm: data.months,
        sugTerms: data.schedule,
        sugPmt: data.payment,
        sugDown: data.downPayment,
        sugPercent: data.downPaymentPercentage,
        alerT1: data.tmu || false,
        alerT2: data.salvageTitle || false,
        alerT3: data.frameDamage || false,
        alerT4: data.unibody || false,
        alerT6: data.airbag || false,
        alerT5: data.other || false,
        alerT5N: data.otherAlert,
        tempTagExp:
          data.dateExpires && data.dateExpires !== 'Invalid Date' ? data.dateExpires : null,
        tempTagIss: data.dateIssued && data.dateIssued !== 'Invalid Date' ? data.dateIssued : null,
        tempTagNum: data.plateNumber,
        milesOut: data.milesOut ? data.milesOut.toFixed(0) : Number(0).toFixed(0),
        // Checkboxes
        notActual: data.notActual || false,
        exceedsLim: data.exceedsLim || false, // @todo need to validate which is the correct request field
        exceedsLimits: data.exceedsLimits || false, // @todo need to validate which is the correct request field
        exempt: data.exempt || false,
      });
      // Get & update vehicle list for Vehicle-tab
      dispatch(getAvailableVehicles(compId!));

      // Get & update vehicle list for WS-form
      const addlInfoRes = await salesService.getWholesaleFormAddlLists();
      setFormAddlDataRes(addlInfoRes);

      // Only get sale data if app-rec-id exists
      if (isAppRecId) dispatch(getSaleData(appRecId));
    } catch {
      console.error(
        `useVehicleForm.tsx > updateVehicle(): failed to update vehicle, fetch vehicles, or fetch addlData for appRecId: '${appRecId}'`
      );
    } finally {
      setUpdateVehicleLoading(false);
    }
  };

  const handleSearchFilter = (searchValue: string) => {
    const filterColumns: (keyof Vehicle)[] = ['stockNum', 'vin', 'year', 'make', 'model', 'color'];
    if (searchValue) {
      const lcSearchValue = searchValue.toLowerCase();
      setListData(
        availableVehicles!.filter((row) => {
          return filterColumns.find((fc) =>
            row[fc]?.toString()?.toLowerCase()?.includes(lcSearchValue)
          );
        })
      );
    } else {
      setListData(availableVehicles!);
    }
  };

  // Effects
  useEffect(() => {
    if (availableVehicles && wsFormVehicle) {
      const vehicle = availableVehicles.find((value) => value.recId === wsFormVehicle.recId)!;
      setWsFormField('vehRecId', vehicle.recId || null);
      reset({
        ...defaultFormValues,
        stockNum: vehicle.stockNum || defaultFormValues.stockNum,
        vin: vehicle.vin || defaultFormValues.vin,
        year: vehicle.year || defaultFormValues.year,
        make: vehicle.make || defaultFormValues.make,
        model: vehicle.model || defaultFormValues.model,
        color: vehicle.color || defaultFormValues.color,
        bodyType: vehicle.bodyStyle || defaultFormValues.bodyType,
        transmission: defaultFormValues.transmission,
        milesOut: vehicle.milesOut ? Number(vehicle.milesOut) : defaultFormValues.milesOut,
      });
      salesActions.setSelectedVehicleId(vehicle.recId);
    }
  }, []);

  useEffect(() => {
    // default selectedVehicleId value is -1 so we
    // just need to make sure that we have a valid
    // recId before trying to fetch it
    if (selectedVehicleId && selectedVehicleId > 0) {
      dispatch(getSaleVehicle(selectedVehicleId));
    } else {
      salesActions.setSaleVehicle(undefined);
    }
  }, [selectedVehicleId]);

  // Build components
  const SelectCell = (props: any) => {
    const vehicle: IDefaultFormValues = props.dataItem;
    return (
      <td>
        <Button
          secondary
          label="Select"
          fillMode="outline"
          loading={selectVehicleLoading}
          onClick={async () => {
            setSelectVehicleLoading(true);
            try {
              setWsFormField('vehRecId', vehicle.recId || null);
              resetVehicleFields(vehicle);
            } catch {
              toast.error('Unable to select vehicle');
            } finally {
              setSelectVehicleLoading(false);
            }
          }}
        />
      </td>
    );
  };

  // Build columns
  const vehicleColumns: Column[] = [
    { field: 'stockNum', title: 'Stock #' },
    { field: 'scOpen', title: 'Sc', cell: ScCell },
    { field: 'year', title: 'Year' },
    { field: 'make', title: 'Make' },
    { field: 'model', title: 'Model' },
    { field: 'color', title: 'Color' },
    { field: 'vin', title: 'Vin' },
    { field: 'selectButton', title: ' ', cells: { data: SelectCell } },
  ];

  return {
    vehicleColumns,
    control,
    watch,
    formState,
    bodyTypeData,
    handleSubmit,
    updateVehicle,
    updateVehicleLoading,
    listData,
  };
};
