import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// state
import { useAuthSelector } from '../auth/authSlice';
// utils
import { dashboardService } from '@/services/dashboardService';
import {
  DateRangeOptionKey,
  dateRangeOptions,
  formatDateTz,
  getLocalDateFormat,
} from '@/general/dates';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { DtoFilter } from '@/interfaces/requests';
import { IDateRangeFilter } from '@/interfaces/tableState';
import { IDashboardDataReq, IDashboardDataRes, IFilterCompany } from './interfaces';

dayjs.extend(utc);

const { dateFormatStr } = getLocalDateFormat();

const initDateRange: DateRangeOptionKey = 'This Month-to-date';
const defaultDateRange = dateRangeOptions[initDateRange];
const allCompanyOption: IFilterCompany = { recId: null, shortName: 'All Companies' };
const initDateRangeFilter = {
  from: dayjs(defaultDateRange.beginDate),
  to: dayjs(defaultDateRange.endDate),
  dateRange: initDateRange,
};

export interface IDashboardViewCtx {
  dashboardData: IDashboardDataRes | null;
  isLoading: boolean;

  // company ('location') filter
  filterCompanyList: IFilterCompany[];
  setFilterCompanyList: SetState<IDashboardViewCtx['filterCompanyList']>;
  selectedCompany: IFilterCompany;
  setSelectedCompany: SetState<IDashboardViewCtx['selectedCompany']>;

  // Updated date range filters
  dateRangeFilter: IDateRangeFilter;
  setDateRangeFilter: SetState<IDashboardViewCtx['dateRangeFilter']>;
  initDateRangeFilter: (
    filter?: IDashboardViewCtx['dateRangeFilter']
  ) => IDashboardViewCtx['dateRangeFilter'];
  updateDateRangeFilter: (
    newDateFilterItem: dayjs.Dayjs,
    filterKey: keyof Omit<IDateRangeFilter, 'dateRange'>
  ) => void;
  getDateRangeDtoFilters: <TFilterKey extends string = string>(field: TFilterKey) => DtoFilter[];
  isDateFilterError: boolean;
}

const DashboardViewCtx = createContext<IDashboardViewCtx | null>(null);

const DashboardViewProvider: FC<PropsWithChildren> = ({ children }) => {
  const orgId = useAuthSelector((s) => s.orgId);
  const [dashboardData, setDashboardData] = useState<IDashboardViewCtx['dashboardData']>(null);
  const [isLoading, setIsLoading] = useState(false);

  // company ('location') filter
  const [selectedCompany, setSelectedCompany] =
    useState<IDashboardViewCtx['selectedCompany']>(allCompanyOption);
  const [filterCompanyList, setFilterCompanyList] = useState<
    IDashboardViewCtx['filterCompanyList']
  >([]);

  // DashboardView variables
  const [dateRangeFilter, setDateRangeFilter] = useState<IDashboardViewCtx['dateRangeFilter']>({
    from: dayjs(defaultDateRange.beginDate),
    to: dayjs(defaultDateRange.endDate),
    dateRange: initDateRange,
  });

  const isDateFilterError = () => {
    if (dateRangeFilter === null) return false;
    if (!dayjs(dateRangeFilter.from).isValid() || !dayjs(dateRangeFilter.to).isValid()) return true;

    return dateRangeFilter.from > dateRangeFilter.to;
  };

  const initDateRangeFilter = (dateRange?: IDashboardViewCtx['dateRangeFilter']) => {
    if (dateRange === undefined) {
      const newDateRange: IDateRangeFilter = {
        from: dayjs(defaultDateRange.beginDate),
        to: dayjs(defaultDateRange.endDate),
        dateRange: initDateRange,
      };
      setDateRangeFilter(newDateRange);
      return newDateRange;
    } else {
      setDateRangeFilter(dateRange);
      return dateRange;
    }
  };
  const updateDateRangeFilter: IDashboardViewCtx['updateDateRangeFilter'] = (
    newDate: dayjs.Dayjs,
    filterKey: keyof Omit<IDateRangeFilter, 'dateRange'>
  ) => {
    if (dateRangeFilter === null) return;
    const updatedDateRangeFilter = { ...dateRangeFilter } as IDateRangeFilter;
    updatedDateRangeFilter[filterKey] = newDate;
    updatedDateRangeFilter.dateRange = 'Custom';

    setDateRangeFilter(updatedDateRangeFilter);
  };

  // Convert date-range filter in table to DTO filter array
  const getDateRangeDtoFilters: IDashboardViewCtx['getDateRangeDtoFilters'] = (field) => {
    if (dateRangeFilter === null) {
      const initDateRange = initDateRangeFilter()!;
      const fromDateFilter = DtoFilter.new({
        field,
        operator: '>=',
        value: formatDateTz(initDateRange.from),
      });
      const toDateFilter = DtoFilter.new({
        field,
        operator: '<=',
        value: formatDateTz(initDateRange.to),
      });
      return [fromDateFilter, toDateFilter];
    }

    const fromDateFilter = DtoFilter.new({
      field,
      operator: '>=',
      value: formatDateTz(dateRangeFilter.from),
    });
    const toDateFilter = DtoFilter.new({
      field,
      operator: '<=',
      value: formatDateTz(dateRangeFilter.to),
    });

    return [fromDateFilter, toDateFilter];
  };

  const loadCardData = async () => {
    if (!orgId || isDateFilterError()) return;
    setIsLoading(true);
    try {
      const payload: IDashboardDataReq = {
        dateRange: {
          startDate: dateRangeFilter!.from,
          endDate: dateRangeFilter!.to,
        },
        orgId,
        compId: selectedCompany?.recId,
      };

      const res = await dashboardService.fetchCardData(payload);
      setDashboardData(res);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCardData();
  }, [orgId, dateRangeFilter, selectedCompany]);

  const fetchCompanyList = async () => {
    try {
      const res = await dashboardService.getCompanies();
      setFilterCompanyList([allCompanyOption, ...res]);
    } catch (e) {
      console.error('Error fetching companies:', e);
    }
  };

  useEffect(() => {
    fetchCompanyList();
  }, []);

  return (
    <DashboardViewCtx.Provider
      value={{
        dashboardData,
        isLoading,

        // company ('location') filter
        selectedCompany,
        setSelectedCompany,
        filterCompanyList,
        setFilterCompanyList,

        dateRangeFilter,
        setDateRangeFilter,
        initDateRangeFilter,
        updateDateRangeFilter,

        getDateRangeDtoFilters,
        get isDateFilterError(): boolean {
          if (dateRangeFilter === null) return false;
          if (!dayjs(dateRangeFilter.from).isValid() || !dayjs(dateRangeFilter.to).isValid())
            return true;

          return dateRangeFilter.from > dateRangeFilter.to;
        },
      }}
    >
      {children}
    </DashboardViewCtx.Provider>
  );
};

export default DashboardViewProvider;

export const useDashboardViewCtx = <T,>(selector: (state: IDashboardViewCtx) => T): T => {
  const ctx = useContext(DashboardViewCtx);
  if (!ctx) throw new Error('useDashboardViewCtx must be used within DashboardViewProvider');

  return selector(ctx);
};
