import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// components
import SalesListViewLayout from './SalesListView';
import SalesDetailLayout from './detailView/SalesDetailLayout';
import WholesaleFormProvider from './detailView/WholesalesForm/WholesaleFormProvider';
import SaleDetailsContainer from './components/saleDetails/SaleDetailsContainer';
// state
import TableProvider from './SalesListView/SalesListViewTable/TableProvider';
import SalesViewProvider from './SalesViewProvider';
import SalesListProvider from './SalesListView/SalesListProvider';
// interfaces
import { SalesSubviewListKey, SalesSubviewDetailKey } from './enums';
import { routeBase } from './SalesListView/listViewConfig';
import SalesDetailProvider from './detailView/SalesDetailProvider';

/** ### `Sales` view */
const Sales: FC = () => {
  return (
    <Routes>
      {/* Detail-view routes */}
      <Route
        path={`/${SalesSubviewListKey.applications}/:id/*`}
        element={<SaleDetailsContainer />}
      />
      <Route
        path={`/${SalesSubviewListKey.postedSales}/:id/*`}
        element={<SaleDetailsContainer />}
      />

      {/* @todo in a future ticket: migrate above detail-routes to consistent layout set by SalesDetailLayout */}
      <Route path={`/:subview/:appRecId/*`} element={<SalesDetailLayout />} />

      {/* List-view routes */}
      <Route path="/:subview/" element={<SalesListViewLayout />} />

      {/* Fallback - defaults to Applications */}
      <Route
        path="/"
        element={
          <Navigate to={`/${routeBase}/${SalesSubviewListKey.applications}`} replace={true} />
        }
      />
      <Route
        path="*"
        // @todo redirect to an error page for the catchall/splat route
        // element={<Navigate to={`/${routeBase}/${SalesSubviewDetailKey.notFound}`} replace={true} />}
        element={
          <Navigate to={`/${routeBase}/${SalesSubviewListKey.applications}`} replace={true} />
        }
      />
    </Routes>
  );
};

export default () => (
  <SalesViewProvider>
    <SalesListProvider>
      <TableProvider>
        <SalesDetailProvider>
          <WholesaleFormProvider>
            <Sales />
          </WholesaleFormProvider>
        </SalesDetailProvider>
      </TableProvider>
    </SalesListProvider>
  </SalesViewProvider>
);
