import { FC } from 'react';
// components
import SidenoteTable from './SidenoteTable';
// state
import SidenoteProvider from './SidenoteProvider';

/** ###  */
const Sidenotes: FC = () => (
  <SidenoteProvider>
    <SidenoteTable />
  </SidenoteProvider>
);

export default Sidenotes;
