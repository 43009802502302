import { FC } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

/** ### Form subsection - 
 * ### Example usage:
 * ```tsx
  <Subsection label="Vehicle Preview" isSubsectionSplit>
    <TextFieldDisplay label="VIN" value={vehicle?.vin} isFlex1 />
    <TextFieldDisplay label="Year" value={vehicle?.year} isFlex1 />
    // ...
  </Subsection>
 * ```
 */
const Subsection: FC<
  Grid2Props & {
    label?: string;
    /** If true, arrange fields as a wrapped-row */
    isSubsectionSplit?: boolean;
    /** If true, header will have a border separating itself from the fields */
    isBorder?: boolean;
  }
> = ({
  label = '',
  isSubsectionSplit = false,
  isBorder = false,
  children,
  sx: sxProps,
  ...containerProps
}) => {
  return (
    <Grid
      className="subsection-body"
      container
      direction="column"
      {...containerProps}
      sx={{ ...sxProps }}
    >
      {/* Header */}
      <Grid
        container
        direction="row"
        mb={2}
        sx={isBorder ? { borderBottom: `1px solid #29469552` } : {}}
      >
        <Grid component={Typography} fontSize={14} fontWeight={400} color={DCS_BLUE}>
          {label}
        </Grid>

        {/* @todo add Print icon */}
      </Grid>

      {/* Body/Fields */}
      <Grid
        container
        direction={isSubsectionSplit ? 'row' : 'column'}
        justifyContent="space-between"
        flex={1}
        gap={1}
        sx={
          isSubsectionSplit
            ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gridAutoFlow: 'row' }
            : {}
        }
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Subsection;
