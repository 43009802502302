import { CSSProperties, FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// kendo
import {
  Button,
  DropDownButton,
  TextArea,
  PhoneInput,
  TextInput,
  DropdownInput,
  Checkbox,
} from '@components';
// components
import SupportButton from './SupportButton';
// state
import { useAuthCtx } from '@/AppProviders/AuthProvider';
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { companyService } from '@/services/companyService';
import { initSupport } from './utils';
// interfaces
import { supportModalDefaultValues } from './default';
import { CreateSupportPostForm } from './interfaces';
import { PopupProps } from '@progress/kendo-react-all';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';

const dropdownPopupStyle: PopupProps = {
  popupAlign: { vertical: 'bottom', horizontal: 'right' },
  anchorAlign: { vertical: 'center', horizontal: 'left' },
};

const flexRowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
};

const priorityOptions = [
  { label: 'None', id: 10001 },
  { label: 'Low', id: 4 },
  { label: 'Medium', id: 3 },
  { label: 'High', id: 2 },
  { label: 'Urgent', id: 1 },
];
const issueTypeOptions = [
  'Technical Support',
  'Sales Support',
  'Licensing and Billing',
  'Training',
  'Bug',
  'Request a Feature',
  'Report or Document',
  'Other',
];

/** ###  */
const SupportWidget: FC = () => {
  // Auth state
  /** @deprecated */
  const isLoggedInDeprec = useAuthSelector((s) => s.isLoggedInDeprec);
  const isLoggedIn = useAuthCtx((s) => s.isLoggedIn || isLoggedInDeprec);
  // Layout state
  const [supportTicketLoading, setSupportTicketLoading] = useState(false);
  const [showSupportModal, setSupportModal] = useState(false);

  // Form for support ticket
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ defaultValues: supportModalDefaultValues });

  useEffect(() => {
    initSupport(setValue, isLoggedIn);
  }, [isLoggedIn]);

  const handleSupportSubmit = async (formData: CreateSupportPostForm) => {
    setSupportTicketLoading(true);
    const reqBody = {
      ...formData,
      priority: formData.priority.id,
    };

    try {
      await companyService.createSupportRequest(reqBody);
      reset(supportModalDefaultValues);
      toast.success('Support Ticket Created');
      setSupportModal(false);
    } catch (err) {
      // Error is handled in req
    } finally {
      setSupportTicketLoading(false);
    }
  };

  return (
    <Grid
      position="fixed"
      bottom="16px"
      right="16px"
      borderRadius="50%"
      height={50}
      width={50}
      sx={{
        backgroundColor: DCS_BLUE,
        '&:hover': { backgroundColor: '#3961cf', cursor: 'pointer' },
        '&:active': { backgroundColor: '#29469550' }, // $blueActive
      }}
    >
      <DropDownButton
        popUpProps={dropdownPopupStyle}
        setOpen={setSupportModal}
        open={showSupportModal}
        label={<SupportButton />}
      >
        <Grid
          container
          direction="column"
          border={`1px solid ${DCS_BLUE}`}
          borderRadius={2}
          sx={{ backgroundColor: '#fff' }}
        >
          <Typography
            variant="h3"
            fontSize={18}
            fontWeight={700}
            m={0}
            p="10px"
            borderRadius="6px 6px 0px 0px"
            color="#fff"
            sx={{ backgroundColor: DCS_BLUE }}
          >
            Send Support Ticket
          </Typography>

          <Grid
            container
            direction="column"
            gap={1}
            px={2}
            py={1}
            component="form"
            onSubmit={handleSubmit(handleSupportSubmit)}
          >
            <p>
              <b>Phone: (941) 357-4467</b>
              <br></br>
              We look forward to helping you. Please fill out the support form below and an email
              will be sent to you with updates to your case.
            </p>
            <Controller
              name="summary"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Summary"
                  errors={errors.summary?.message}
                  required
                  horizontalLabel={false}
                  boldLabel={false}
                />
              )}
            />
            <Controller
              name="issueType"
              control={control}
              rules={{}}
              render={({ field }) => (
                <DropdownInput
                  {...field}
                  label="Issue Type"
                  data={issueTypeOptions}
                  placeholder="Technical Support"
                  required={false}
                  horizontalLabel={false}
                  boldLabel={false}
                  errors={errors.issueType?.message}
                />
              )}
            />
            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  {...field}
                  label="Priority"
                  textField="label"
                  dataItemKey="id"
                  data={priorityOptions}
                  required={false}
                  horizontalLabel={false}
                  boldLabel={false}
                  errors={errors.priority?.message}
                />
              )}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <label>Preferred Method of Contact</label>
              <span style={flexRowStyle}>
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label="Email"
                      boldLabel={false}
                      labelPlacement="after"
                      alignWithTextInputs={true}
                    />
                  )}
                />
                <Controller
                  name="contactPhone"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label="Phone"
                      boldLabel={false}
                      labelPlacement="after"
                      alignWithTextInputs={true}
                    />
                  )}
                />
              </span>
            </div>

            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value:
                    // @note linter is somehow throwing an issue with this
                    // eslint-disable-next-line
                    /^([\w\-\.]+@([\w-]+\.)+[\w-]{2,})(,([\w\-\.]+@([\w-]+\.)+[\w-]{2,}))*$/g,
                  message:
                    'Must use comma-separated emails (i.e. <id>@<domain.com>,<id>@<domain.com>)',
                },
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Email"
                  errors={errors.email?.message}
                  required={false}
                  horizontalLabel={false}
                  boldLabel={false}
                />
              )}
            />
            <div style={flexRowStyle}>
              <span style={{ display: 'flex', flex: 2 }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      label="Phone"
                      required={false}
                      horizontalLabel={false}
                      boldLabel={false}
                      errors={errors.phoneNumber?.message}
                    />
                  )}
                />
              </span>
              <span style={{ display: 'flex', flex: 1 }}>
                <Controller
                  name="extension"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label="Ext"
                      horizontalLabel={false}
                      boldLabel={false}
                      errors={errors.extension?.message}
                    />
                  )}
                />
              </span>
            </div>
            <Controller
              name="description"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <TextArea
                  {...field}
                  label="Description"
                  rows={3}
                  required
                  boldLabel={false}
                  errors={errors.description?.message}
                />
              )}
            />
            <Button
              label="Submit"
              buttonStyle={{ width: '100px', alignSelf: 'flex-end' }}
              loading={supportTicketLoading}
            />
          </Grid>
        </Grid>
      </DropDownButton>
    </Grid>
  );
};

export default SupportWidget;
