import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// state
import { store } from '@/store/store';
import { oldPaymentActions } from '@/features/old/payment/oldPaymentSlice';
import { paymentActions } from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice';
// utils
import { GetPaymentData, PaymentProviders, paymentService } from '@/services/paymentService';
import { displayErrors, formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import {
  CardProcessor,
  CardProcessorName,
  PaymentInterval,
  PaymentType,
  RepayFeeModel,
} from '@/enums/payment';

export const getCpiDueDate = (
  cpiRate: number,
  cpiSchedule: PaymentInterval,
  cpiTotalPaid: number,
  cpiFirstDueDate: Date | string,
  cpiPayment: number
) => {
  if (!dayjs(cpiFirstDueDate).isValid || cpiPayment < 0) {
    displayErrors('Unable to calculate CPI due date.');
    throw new Error('Unable to calculate CPI due date.');
  }

  let cpiDueDate = dayjs(cpiFirstDueDate).utc();
  const numPayments = Math.max(
    0,
    Math.floor((cpiTotalPaid * 100 + cpiPayment * 100) / (cpiRate * 100))
  );
  const cpiUnits = numPayments - 1;

  if (cpiSchedule === PaymentInterval.enum.Weekly) {
    cpiDueDate = cpiDueDate.add(cpiUnits, 'w');
  } else if (cpiSchedule === PaymentInterval.enum['Bi-Weekly']) {
    cpiDueDate = cpiDueDate.add(cpiUnits * 2, 'w');
  } else if (cpiSchedule === PaymentInterval.enum['Semi-Monthly']) {
    let returnDate = cpiDueDate;
    let numMonths = 0;

    if (cpiUnits - 2 * Math.floor(cpiUnits / 2) === 0) {
      numMonths = Math.floor(cpiUnits / 2);
    } else {
      const isAfterThe15th = cpiDueDate.get('D') > 15;
      if (isAfterThe15th) {
        returnDate = returnDate.subtract(15, 'day');
        numMonths = 1 + Math.floor(cpiUnits / 2);
      } else {
        returnDate = returnDate.add(15, 'day');
        numMonths = Math.floor(cpiUnits / 2);
      }
    }

    cpiDueDate = returnDate.add(numMonths, 'month');
  } else if (cpiSchedule === PaymentInterval.enum.Monthly) {
    cpiDueDate = cpiDueDate.add(cpiUnits, 'M');
  }
  return cpiDueDate.format('MM-DD-YYYY');
};

// @todo remove post-alpha
export const handleCpiDueChange = (
  cpiPayment: number,
  paymentDetails: GetPaymentData,
  setPaymentPayload: (paymentDetails: GetPaymentData) => void
) => {
  const pd = paymentDetails;

  if (
    pd?.cpiRate &&
    pd?.cpiSchedule &&
    (pd?.cpiTotalPaid || pd?.cpiTotalPaid === 0) &&
    pd?.cpiFirstDueDate
  ) {
    const newDueDate = getCpiDueDate(
      pd?.cpiRate,
      pd?.cpiSchedule,
      pd?.cpiTotalPaid,
      pd?.cpiFirstDueDate,
      cpiPayment
    );
    setPaymentPayload({
      ...pd,
      cpiStatus: 'Due on ' + newDueDate,
    });
  }
};

// @todo remove post-alpha
export const paymentInRange = (
  payment: number,
  pmtDetails: GetPaymentData,
  paymentType: string,
  maxPayment?: number
): { error: boolean; message: string } => {
  const mp = maxPayment || pmtDetails.maxPayment;

  if (payment < 0) {
    return { error: true, message: 'Payment must be greater than 0' };
  }
  if (payment > mp) {
    return { error: true, message: 'Payment must be less than the max payment' };
  }
  if (paymentType === PaymentType.CreditCard && payment < pmtDetails.minCreditCardAmount) {
    return { error: true, message: 'Payment must be greater than the min credit card payment' };
  }
  if (paymentType === PaymentType.Ach && payment < pmtDetails.minAchAmount) {
    return { error: true, message: 'Payment must be greater than the min ach payment' };
  }

  return { error: false, message: '' };
};

// @todo remove post-alpha
export const validateTotalPayment = (
  value: number | undefined,
  paymentDetails: GetPaymentData,
  paymentType: string,
  setFormErrors: (e: boolean) => void
) => {
  setFormErrors(false);

  const isInRange = paymentInRange(value || 0, paymentDetails, paymentType);
  if (isInRange.error) {
    setFormErrors(true);
  } else if (!value || value <= 0) {
    setFormErrors(true);
  }
};

export const getIsNewPayment = (paymentType: string, newCard: boolean, newAccount: boolean) => {
  if (paymentType === PaymentType.CreditCard) {
    return newCard;
  } else if (paymentType === PaymentType.Ach) {
    return newAccount;
  } else {
    return false;
  }
};

export const getSavePayment = (paymentType: string, saveCard: boolean, saveAccount: boolean) => {
  if (paymentType === PaymentType.CreditCard) {
    return saveCard;
  } else if (paymentType === PaymentType.Ach) {
    return saveAccount;
  } else {
    return false;
  }
};

export const getMpdToken = (paymentType: string, mpdId: string, achMpdId: string) => {
  if (paymentType === PaymentType.CreditCard) {
    return mpdId;
  } else if (paymentType === PaymentType.Ach) {
    return achMpdId;
  } else {
    return '';
  }
};

export const getAchAcctType = (paymentType: string, accountType: string) => {
  if (paymentType === PaymentType.Ach) {
    return accountType === 'Checking' ? 0 : 1;
  } else {
    return 0;
  }
};

export const getConvenienceFee = (
  paymentData: GetPaymentData,
  paymentProvider: string,
  paymentMethod: string,
  waiveFee: boolean
) => {
  if (waiveFee || (paymentMethod !== PaymentType.Ach && paymentMethod !== PaymentType.CreditCard))
    return 0;
  if (paymentProvider === CardProcessorName.OpenEdge) {
    if (paymentMethod === PaymentType.Ach) return paymentData.achConvenienceFee;
    if (paymentMethod === PaymentType.CreditCard) return paymentData.convenienceFee;
  }
  if (paymentProvider === CardProcessorName.Repay) {
    return paymentData.repayTestMode ? paymentData.repayConvFeeTest : paymentData.repayConvFeeProd;
  }
  throw new Error('Unable to fetch convenience fee');
};

export const getConvenienceFeeByProviderData = (
  providerData: PaymentProviders,
  paymentProvider: CardProcessorName,
  paymentMethod: string,
  waiveFee: boolean
) => {
  if (waiveFee || (paymentMethod !== PaymentType.Ach && paymentMethod !== PaymentType.CreditCard))
    return 0;
  if (paymentProvider === CardProcessorName.OpenEdge) {
    if (paymentMethod === PaymentType.Ach) return providerData.openEdgeDmsAchConvFee;
    if (paymentMethod === PaymentType.CreditCard) return providerData.openEdgeDmsCcConvFee;
  }
  if (paymentProvider === CardProcessorName.Repay) {
    return providerData.repayTestMode
      ? providerData.repayConvFeeTest
      : providerData.repayConvFeeProd;
  }
  throw new Error('Unable to fetch convenience fee');
};

export const getPaymentProviderArray = (paymentProviderData: PaymentProviders) => {
  const providers = [];
  if (paymentProviderData.openedgeEnabled) {
    providers.push(CardProcessorName.OpenEdge);
  }
  if (paymentProviderData.repayEnabled) {
    providers.push(CardProcessorName.Repay);
  }
  return providers;
};

export const getPreferredPaymentProviderName = (
  preferredProviderInt: CardProcessor,
  providerArray: CardProcessorName[]
) => {
  if (preferredProviderInt === CardProcessor.OpenEdge) return CardProcessorName.OpenEdge;
  if (preferredProviderInt === CardProcessor.Repay) return CardProcessorName.Repay;
  // Handle the possibility(?) that the preferred provider is not set or is still set to BlytzPay, but they do have a compatible payment provider enabled
  if (providerArray.length) return providerArray[0]!;
  throw new Error('Unable to get preferred payment provider');
};

export const getProcessorIntByName = (cardProcessorName: CardProcessorName) => {
  if (cardProcessorName === CardProcessorName.Repay) return CardProcessor.Repay;
  if (cardProcessorName === CardProcessorName.OpenEdge) return CardProcessor.OpenEdge;
  return null;
};

const isErrorPaymentStatus = (paymentStatus: string) => {
  // Status starts out as "New" and ends at "Done-Posted"
  // It can be in an "Approved" state in between those two
  // Any of the other (170 and counting) statuses *should* indicate an error
  return !['Done-Posted', 'Approved', 'New'].includes(paymentStatus);
};

// @todo remove post-alpha
// @note should we add isStandalone feature flag?
export const pollForReceiptOld = async (
  paymentLogRecId: number,
  onComplete: () => void = () => null,
  onError: () => void = () => null,
  pollingAttempts = 0
) => {
  store.dispatch(oldPaymentActions.setPostPaymentLoading(true));
  if (pollingAttempts >= 30) {
    displayErrors(
      'Timed out when attempting to fetch payment receipt, payment may not have been successful'
    );
    store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
    onError();
    return;
  }
  try {
    const paymentStatusResponse = await paymentService.getPaymentStatus(paymentLogRecId);
    if (isErrorPaymentStatus(paymentStatusResponse.paymentStatus)) {
      store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
      displayErrors(`Payment failed. Reason: ${paymentStatusResponse.paymentStatus}`);
      onError();
      return;
    }
    if (!paymentStatusResponse.paymentRecId) {
      throw new Error('Continue polling for receipt');
    }
    const url = await paymentService.getReceiptUrl(paymentStatusResponse.paymentRecId);
    store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
    window.open(url);
    onComplete();
  } catch (err) {
    setTimeout(
      () => pollForReceiptOld(paymentLogRecId, onComplete, onError, pollingAttempts + 1),
      1000
    );
    return;
  }
};

//@note should this always call old functionality on error?
//@note should we add isStandalone feature flag?
/** @deprecated this needs a refactor */
export const pollForReceipt = async (
  paymentLogRecId: number,
  onComplete: () => void = () => null,
  onError: () => void = () => null,
  pollingAttempts = 0
) => {
  store.dispatch(paymentActions.setPostPaymentLoading(true));
  if (pollingAttempts >= 30) {
    displayErrors(
      'Timed out when attempting to fetch payment receipt, payment may not have been successful'
    );
    store.dispatch(paymentActions.setPostPaymentLoading(false));
    onError();
    return;
  }

  try {
    const paymentStatusResponse = await paymentService.getPaymentStatus(paymentLogRecId);
    if (isErrorPaymentStatus(paymentStatusResponse.paymentStatus)) {
      store.dispatch(paymentActions.setPostPaymentLoading(false));
      toast.error(`Payment failed. Reason: ${paymentStatusResponse.paymentStatus}`);
      onError();
      return;
    }
    if (!paymentStatusResponse.paymentRecId) {
      throw new Error('Continue polling for receipt');
    }
    const url = await paymentService.getReceiptUrl(paymentStatusResponse.paymentRecId);
    store.dispatch(paymentActions.setPostPaymentLoading(false));
    window.open(url);
    onComplete();
  } catch (err) {
    // console.error('Error: Polling for receipt', err);
    // toast.error('Error: Polling for receipt');
    setTimeout(
      () => pollForReceiptOld(paymentLogRecId, onComplete, onError, pollingAttempts + 1),
      1000
    );
    return;
  }
};

export const getAllowedPaymentTypes = (paymentProviders: PaymentProviders) => {
  const enabledProviders = getPaymentProviderArray(paymentProviders);
  const ccEnabled = enabledProviders?.length;
  // In theory the server could return achEnabled as true, but with no providers enabled, so make sure that is not the case
  const achReallyEnabled = enabledProviders?.length && paymentProviders.achEnabled;
  // Trying to retain the original order of these, hence the weird array building
  let paymentTypes = [PaymentType.Cash, PaymentType.Check];
  if (ccEnabled) paymentTypes.push(PaymentType.CreditCard);
  if (achReallyEnabled) paymentTypes.push(PaymentType.Ach);
  paymentTypes = [
    ...paymentTypes,
    ...[PaymentType.ManualCC, PaymentType.MoneyOrder, PaymentType.CashiersCheck, PaymentType.Other],
  ];
  return paymentTypes;
};

export const getCanWaiveFee = (
  cardProcessor?: CardProcessorName,
  paymentData?: GetPaymentData | PaymentProviders
) => {
  if (!cardProcessor || !paymentData) return false;

  let canWaiveFee = true;

  // Only provider that is currently set up to disable waiving fees is repay
  if (cardProcessor === CardProcessorName.Repay) {
    const repayCfeeModel = paymentData.repayTestMode
      ? paymentData.repayCfeeModelTest
      : paymentData.repayCfeeModelProd;

    let repayCanWaiveFee = paymentData.repayTestMode
      ? paymentData.repayCanWaiveFeeTest
      : paymentData.repayCanWaiveFeeProd;

    // can_waive_fee_{test/prod} is nullable in the database so default it to true since it is the existing behavior
    if (repayCanWaiveFee === null || repayCanWaiveFee === undefined) {
      repayCanWaiveFee = true;
    }

    // FEE_NO_WAIVE trumps repayCanWaiveFee being true. Trying to waive a FEE_NO_WAIVE payment will fail
    if (repayCfeeModel === RepayFeeModel.FEE_NO_WAIVE) {
      canWaiveFee = false;
    } else if (!repayCanWaiveFee) {
      canWaiveFee = false;
    }
  }

  return canWaiveFee;
};

export const getYearlyCpiRate = (yearlyPremium: number, schedule: PaymentInterval) => {
  const cpiScheduleDivisor =
    schedule === PaymentInterval.enum['Bi-Weekly']
      ? 52 / 2
      : schedule === PaymentInterval.enum['Semi-Monthly']
      ? 12 * 2
      : schedule === PaymentInterval.enum.Monthly
      ? 12
      : 52;
  return yearlyPremium / cpiScheduleDivisor;
};

export const getDdDueStatus = (paymentData?: GetPaymentData) => {
  if (!paymentData) return '';
  return `${formatCurrency(paymentData.ddNextAmt)} due ${formatDate(
    paymentData.ddNextDue
  )} (${formatCurrency(paymentData.defDownBal)})`;
};
