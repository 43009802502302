import { FC } from 'react';
// kendo
import { Grid, GridColumn, Loader } from '@progress/kendo-react-all';
import {  Button, Modal, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { SectionHeader } from '@components/sectionHeader/SectionHeader';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
// state
import { useDocumentsForm } from './useDocumentsForm';
// style
import salesTabStyles from '../WholesaleTab.module.scss';
import styles from './Documents.module.scss';

const SELECTED_FIELD = 'selected';
const buttonWidth = '175px';

/** ### */
const DocumentsTab: FC = () => {
  const {
    columns,
    scannedDocumentsColumns,
    salesDoc,
    salesDocLoading,
    onSelectionChange,
    onHeaderSelectionChange,
    allSelect,
    handlePrintSelected,
    printSelectedLoading,
    handleSendSelectedToCryptoSign,
    cryptoSignLoading,
    canSendToCryptoSign,
    handleSendSelectedToDocuSign,
    canSendToDocuSign,
    docuSignLoading,
    handleSendSelectedToSecureClose,
    canSendToSecureClose,
    secureCloseLoading,
    secureCloseId,
    showSecureCloseId,
    setShowSecureCloseId,
    showPostSaleDoc,
    presaleDoc,
    canSwitchDocType,
    setShowPostsaleDoc,
    scannedDocsList,
    scannedDocModalOpen,
    setScannedDocModalOpen,
    scannedFileDesc,
    setScannedFileDesc,
    setScannedFile,
    scannedFile,
    uploadScannedFile,
    uploadingScannedFile,
    fileInputError,
    setFileInputError,
    fileDescriptionError,
    setFileDescriptionError,
  } = useDocumentsForm();

  const handleScannedDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setScannedFile(file);
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setScannedFileDesc(e.target.value);
  };

  if (salesDocLoading) {
    return (
      <div className={salesTabStyles.container}>
        <div className={salesTabStyles.loader}>
          <Loader size="large" />
        </div>
      </div>
    );
  }

  // Build table columns
  const columnElems = columns.map((c, idx) => (
    <GridColumn
      field={c.field}
      title={c.title ? c.title : ' '}
      cells={c.cells}
      width={c.width}
      headerSelectionValue={!c.title ? allSelect : undefined}
      key={idx}
    />
  ));
  const scannedDocColumnElems = scannedDocumentsColumns.map((column, index) => (
    <GridColumn
      key={index}
      field={column.field}
      title={column.title}
      cells={column.cells}
      width={column.width}
    />
  ));

  return (
    <div
      className={salesTabStyles.container}
      style={{
        display: 'flex',
        flexFlow: 'column',
        padding: '0px 16px',
        width: '100%',
        height: '100%',
        gap: '16px',
        overflowX: 'auto',
        overflowY: 'scroll',
      }}
    >
      <main className={salesTabStyles.body}>
        <section className={salesTabStyles.section}>
          <SectionHeader title="Documents/Notices" />
          <div className={styles.buttonContainer}>
            <Button
              label={showPostSaleDoc ? 'Pre-sales Documents' : 'Sales Documents'}
              disabled={!canSwitchDocType}
              onClick={() => setShowPostsaleDoc((prev) => !prev)}
            />
          </div>
          <Spacer size={25} />
          <div className={styles.grid}>
            <Grid
              data={showPostSaleDoc ? salesDoc : presaleDoc}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: 'multiple',
              }}
              selectedField={SELECTED_FIELD}
              onSelectionChange={(e) => onSelectionChange(e)}
              onHeaderSelectionChange={(e) => onHeaderSelectionChange(e)}
            >
              {columnElems}
            </Grid>
          </div>
          <Spacer size={25} />
          <div className={styles.buttonContainer}>
            <span
              title={
                !canSendToCryptoSign
                  ? 'Only valid DocuSign Documents may be sent to SecureClose'
                  : undefined
              }
            >
              <Button
                label="Send to SecureClose"
                fillMode="outline"
                secondary
                disabled={!canSendToSecureClose}
                buttonStyle={{ width: buttonWidth }}
                loading={secureCloseLoading}
                onClick={handleSendSelectedToSecureClose}
              />
            </span>

            <span
              title={
                !canSendToCryptoSign
                  ? 'Only valid DocuSign Documents may be sent to DocuSign'
                  : undefined
              }
            >
              <Button
                label="Send to DocuSign"
                fillMode="outline"
                secondary
                disabled={!canSendToDocuSign}
                buttonStyle={{ width: buttonWidth }}
                loading={docuSignLoading}
                onClick={handleSendSelectedToDocuSign}
              />
            </span>
            <span
              title={
                !canSendToCryptoSign
                  ? 'Only valid CryptoSign Documents may be sent to CryptoSign'
                  : undefined
              }
            >
              <Button
                label="Send to CryptoSign"
                fillMode="outline"
                secondary
                disabled={!canSendToCryptoSign}
                buttonStyle={{ width: buttonWidth }}
                loading={cryptoSignLoading}
                onClick={handleSendSelectedToCryptoSign}
              />
            </span>

            <Button
              label="Print Selected"
              fillMode="outline"
              secondary
              buttonStyle={{ width: buttonWidth }}
              onClick={handlePrintSelected}
              loading={printSelectedLoading}
            />
          </div>
        </section>
        <section className={salesTabStyles.section}>
          <SectionHeader title="Scanned Documents" />
          <div className={salesTabStyles.buttonContainer}>
            <Button label="Upload New" onClick={() => setScannedDocModalOpen(true)} />
          </div>
          <Spacer size={25} />
          <div className={styles.grid}>
            <Grid data={scannedDocsList}>{scannedDocColumnElems}</Grid>
          </div>
        </section>
      </main>
      {showSecureCloseId && (
        <Modal
          isOpen={showSecureCloseId}
          onCloseButtonClick={() => setShowSecureCloseId(false)}
          closeButton
          centerModal
          title="SecureClose Id"
        >
          <div className={styles.modal}>SecureClose Id: {secureCloseId}</div>
        </Modal>
      )}
      {scannedDocModalOpen && (
        <Modal
          isOpen={true}
          onCloseButtonClick={() => setScannedDocModalOpen(false)}
          closeButton
          centerModal
          title="Upload Scanned Document"
          panelStyle={{ width: 600 }}
        >
          <div>
            <Spacer size={5} />
            <FileInput
              onChange={handleScannedDocInputChange}
              fileName={scannedFile?.name}
              errors={fileInputError}
            />
            <Spacer size={20} />
            <TextInput
              required
              label="File Description"
              value={scannedFileDesc}
              horizontalLabel={false}
              onChange={handleFileDescriptionChange}
              errors={fileDescriptionError}
            />
            <Spacer size={20} />
            <div style={{ width: 100, marginLeft: 'auto' }}>
              <Button label="Submit" loading={uploadingScannedFile} onClick={uploadScannedFile} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DocumentsTab;
