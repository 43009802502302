import { FC, useState } from 'react';
// mui
import { DataGrid, GridPaginationModel, gridClasses } from '@mui/x-data-grid';
// components
import DataDisplay from '../DataDisplay';
// state
import { useDashboardViewCtx } from '../../DashboardViewProvider';
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { CustomGridParams, useServerGrid } from '@/hooks/useServerGrid/useServerGrid';
import { dashboardService } from '@/services/dashboardService';
import { useDebounceEffect } from '@/hooks/useDebounce/useDebounce';
import { convertSortToTable, getRowId, handleChangeSort } from '@/mui/components/dataTable/utils';
import { tableColumns } from './tableColumns';
import { rowHeightPx } from '../default';
// interfaces
import { ServerGridOptions } from '@/interfaces/ServerGrids';
import { CollectionActivityRowData } from '../../interfaces';

const fetchCollectionsActivity = async (
  options: ServerGridOptions,
  customParams?: CustomGridParams
) => await dashboardService.getCollectionActivity(options, customParams);

/** ###  */
const CollectionActivityTable: FC = () => {
  const startDate = useDashboardViewCtx((s) => s.dateRangeFilter?.from.toISOString());
  const endDate = useDashboardViewCtx((s) => s.dateRangeFilter?.to.toISOString());
  const compId = useDashboardViewCtx((s) => s.selectedCompany?.recId);
  const orgId = useAuthSelector((s) => s.orgId);

  const [pageModel, setPageModel] = useState<GridPaginationModel>({ page: 0, pageSize: 25 });

  const tableModel = useServerGrid<CollectionActivityRowData>({
    fetchFunction: fetchCollectionsActivity,
    initialSortOptions: [{ field: 'companyName', dir: 'desc' }],
  });

  const isLoading = tableModel.loading;
  const data = tableModel.data;
  const rowCount = tableModel.total;
  const sort = tableModel.sort;
  const onSortChange = tableModel.onSortChange;
  const setCustomParams = tableModel.setCustomParams;

  useDebounceEffect(
    () => setCustomParams({ startDate, endDate, compId, orgId }),
    [startDate, endDate, compId],
    300
  );

  const titleTotal = isLoading ? '-' : rowCount;
  const title = `Collections Activity (${titleTotal})`;
  const secondary = `Total for All Collectors for ${
    compId === null ? 'All Locations' : 'Current Location'
  } as of Date`;

  return (
    <DataDisplay title={title} secondary={secondary} maxWidth="100%" wrap="nowrap">
      <DataGrid
        loading={isLoading}
        rows={data}
        columns={tableColumns}
        getRowId={getRowId('companyName')}
        paginationModel={pageModel}
        sortModel={convertSortToTable(sort)}
        sortingMode="server"
        onPaginationModelChange={setPageModel}
        onSortModelChange={handleChangeSort(onSortChange)}
        rowHeight={rowHeightPx}
        sx={{
          maxHeight: rowHeightPx * 10,
          maxWidth: '100%',
          [`& .${gridClasses.columnHeader}, .MuiDataGrid-columnHeaders`]: {
            backgroundColor: '#f9f9f9',
          },
        }}
      />
    </DataDisplay>
  );
};

export default CollectionActivityTable;
