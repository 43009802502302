import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import {  Checkbox, } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// utils
import { getCanWaiveFee } from '@/utils/helpers/payment';
// interfaces
import { GetPaymentData } from '@/services/paymentService';
// style
import styles from '@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PaymentForm.module.scss';

/** ###  */
const FeeFieldGroup: FC = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const provider = useWsPmtsViewCtx((s) => s.provider);
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);

  const canWaiveFee = getCanWaiveFee(provider!, paymentData! as GetPaymentData);

  return (
    <div className={styles.inlineInputContainer}>
      <span className={styles.inlineInputLabel}>Conv. Fee</span>
      <div className={styles.inlineInputItems}>
        <Controller
          name="ConvFee"
          control={control}
          render={({ field }) => <CurrencyInput readOnly {...field} />}
        />
        {canWaiveFee && (
          <Controller
            name="WaiveConvFee"
            control={control}
            render={({ field }) => <Checkbox label="Waive Fee" {...field} />}
          />
        )}
      </div>
    </div>
  );
};

export default FeeFieldGroup;
