import { FC, useEffect } from "react";
// kendo
import { Loader } from "@components";
// components
import Header from "./layout/Header";
import LeftContainer from "./layout/LeftContainer";
import RightContainer from "./layout/RightContainer";
import QuickActionsModal from "./components/quickActionsModal/QuickActionsModal";
import BackButton from "./layout/BackButton";
// state
import { useAccountSelector } from "../../accountSlice";
import AcctDetailsProvider, { useAcctDetailsCtx } from "./AccountDetailProvider";
// utils
import { customerService } from "@/services/customerService";
import { formatPhoneE164, inCollectionsRoute } from "@/utils/helpers/general";
// interfaces
import { OptInData } from "../interfaces";
// style
import styles from "./AccountDetail.module.scss";

/** ### Layout for Account detail view */
const AccountDetail: FC = () => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const accountInformationLoading = useAccountSelector((s) => s.accountInformationLoading);
  const inCollections = inCollectionsRoute();
  const optIn = useAcctDetailsCtx((s) => s.optIn);
  const setOptIn = useAcctDetailsCtx((s) => s.setOptIn);
  const setOptStatus = useAcctDetailsCtx((s) => s.setOptStatus);
  const setOptInAuthorization = useAcctDetailsCtx((s) => s.setOptInAuthorization);

  // Data loaders
  const fetchOptInWorkflow = async (appRecId: number) => {
    try {
      const res = await customerService.getOptInWorkflow(appRecId);
      if (!res)
        console.warn(
          `customerService.getOptInWorkflow(): No data returned for appRecId: ${appRecId}`
        );

      res && setOptInAuthorization(res);
    } catch {}
  };
  const fetchOptIn = async (appRecId: number) => {
    if (!accountInformation?.buyer.cellPhone) {
      console.error("Buyer's cell phone is undefined");
      return;
    }
    try {
      const phoneNumber = formatPhoneE164(accountInformation.buyer.cellPhone);
      const res = await customerService.getOptIn(phoneNumber, appRecId);

      res && setOptIn(OptInData.new(res));
    } catch {}
  };

  const appRecId = accountInformation?.appRecId;
  useEffect(() => {
    if (appRecId) {
      fetchOptInWorkflow(appRecId);
      fetchOptIn(appRecId);
    }
  }, [appRecId]);

  const optInRecId = optIn.recId;
  useEffect(() => {
    if (optInRecId === null || optInRecId === undefined) {
      setOptStatus("unsent");
    } else if (optIn.phoneNumberE164 === null || optIn.orgId === null) {
      setOptStatus("error");
    } else if (optIn.dcWorkflowBeginUtc !== null && optIn.directConsentGranted === null) {
      setOptStatus("pending");
    } else if (optIn.dcWorkflowBeginUtc !== null && optIn.directConsentGranted === false) {
      setOptStatus("denied");
    } else if (optIn.dcWorkflowBeginUtc !== null && optIn.directConsentGranted === true) {
      setOptStatus("accepted");
    } else {
      setOptStatus("error");
    }
  }, [optInRecId]);

  return (
    // @todo use Grid
    <>
      <BackButton />
      <div className={styles.accountDetailsContainer} style={inCollections ? { margin: 0 } : {}}>
        <Header title="Account Overview" hasTabs={true} />

        <div className={styles.contentsContainer}>
          {accountInformationLoading ? (
            <div className={styles.loading}>
              <Loader size="large" />
            </div>
          ) : (
            <>
              <LeftContainer />
              <RightContainer />
            </>
          )}
        </div>
      </div>
      <QuickActionsModal />
    </>
  );
};

const AccountDetailLayout: FC = () => (
  <AcctDetailsProvider>
    <AccountDetail />
  </AcctDetailsProvider>
);

export default AccountDetailLayout;
