import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// kendo
import { DropDownButton, Pager } from '@components';
import { BackLink } from '@components/backLink/BackLink';
import { Icons } from '@/components/icons';
// components
import AccountDetailsRouter from '@/features/Accounts/accountsSubviews/AccountDetail';
// state
import { useCollectionsSelector } from '../collectionsSlice';
// style
import styles from './RunQueue.module.scss';

export const RunQueue = () => {
  const [accountSelectorOpen, setAccountSelectorOpen] = useState(false);
  const params = useParams();
  const { queueAccounts } = useCollectionsSelector((state) => state);
  const navigate = useNavigate();
  const colRecId = Number(params.colRecId);
  const queueRecId = Number(params.queueRecId);

  useEffect(() => {
    // If a user tries to reuse/share a link to running a queue, it won't work
    // Can't fetch the queue accounts from the API - it's supposed to just be selected accounts
    // So, just send them back to the queue
    if (!queueAccounts || !queueAccounts.length) {
      toast.error('No accounts selected from queue, please select accounts to process');
      navigate(`/collections/${queueRecId}`, { replace: true });
    }
  }, [queueAccounts]);

  if (!queueAccounts || !queueAccounts.length) {
    return null;
  }

  const currentIdx = queueAccounts.findIndex((acct) => acct.recId === colRecId);
  const currentAccount = queueAccounts[currentIdx]!;

  const handleNavigation = (idx: number) => {
    const newAccount = queueAccounts[idx]!;
    navigate(`../${newAccount.recId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackLink to={`/collections/${queueRecId}`} title="Back to Collection Queue" />
        <div className={styles.headerRight}>
          <DropDownButton
            label={
              <div className={styles.accountSelectorButton}>
                <span>
                  {currentAccount.stockNum} - {currentAccount.buyers}
                </span>
                <Icons.DownArrow />
              </div>
            }
            popUpProps={{
              popupAlign: { vertical: 'top', horizontal: 'left' },
              anchorAlign: { vertical: 'bottom', horizontal: 'left' },
            }}
            open={accountSelectorOpen}
            setOpen={setAccountSelectorOpen}
          >
            <div className={styles.accountSelector}>
              {queueAccounts.map((acct, idx) => (
                <div
                  key={acct.recId}
                  className={styles.accountSelectorItem}
                  onClick={() => {
                    setAccountSelectorOpen(false);
                    handleNavigation(idx);
                  }}
                >
                  {acct.stockNum} - {acct.buyers}
                </div>
              ))}
            </div>
          </DropDownButton>
          <Pager
            totalItems={queueAccounts.length}
            currentIdx={currentIdx}
            onBeginningClick={() => handleNavigation(0)}
            onPrevClick={() => handleNavigation(currentIdx - 1)}
            onNextClick={() => handleNavigation(currentIdx + 1)}
            onEndClick={() => handleNavigation(queueAccounts.length - 1)}
          />
        </div>
      </div>
      <div className={styles.accountsContainer}>
        <AccountDetailsRouter />
      </div>
    </div>
  );
};
