import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { SafeParseReturnType } from 'zod';
import dayjs from 'dayjs';
// mui
import { GridPaginationModel } from '@mui/x-data-grid';
// kendo
import { SortDescriptor, State } from '@progress/kendo-data-query';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import {
  CollectionQueue,
  collectionsService,
  getQueueFromIndex,
} from '@/services/collectionsService';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { CollectionsQueueForm, QueueType } from './QueueDetailForm/interfaces';

const initialSort: SortDescriptor[] = [{ field: 'queueName', dir: 'desc' }];
const initialDataState: State = { skip: 0, take: 10 };

type ICtx = ICollectionQueuesCtx;
export interface ICollectionQueuesCtx {
  isLoading: boolean;
  setIsLoading: SetState<ICtx['isLoading']>;

  isModalOpen: boolean;
  setIsModalOpen: SetState<ICtx['isModalOpen']>;

  queues: CollectionQueue[];
  setQueues: SetState<ICtx['queues']>;
  loadQueues: (
    locId: number | undefined,
    orgId: number | undefined,
    compId: number | undefined
  ) => void;

  activeQueue: CollectionQueue | null;
  setActiveQueue: (_: ICtx['activeQueue']) => void;

  // Form state
  queueForm: CollectionsQueueForm;
  setQueueForm: SetState<ICtx['queueForm']>;
  queueFormValidation: SafeParseReturnType<ICtx['queueForm'] | undefined, ICtx['queueForm']>;
  setField: (
    field: keyof CollectionsQueueForm,
    value: CollectionsQueueForm[keyof CollectionsQueueForm] | null
  ) => void;
  setFields: (_: Partial<CollectionsQueueForm>) => void;

  // Table state
  expandedRowIdx: number | string | null;
  setExpandedRowIdx: SetState<ICtx['expandedRowIdx']>;
  expandedRow: CollectionQueue | null;
  setExpandedRow: SetState<ICtx['expandedRow']>;
  pageModel: GridPaginationModel;
  setPageModel: SetState<ICtx['pageModel']>;

  /** @deprecated */
  sort: SortDescriptor[];
  /** @deprecated */
  setSort: SetState<ICtx['sort']>;
  /** @deprecated */
  page: State;
  /** @deprecated */
  setPage: SetState<ICtx['page']>;
  /** @deprecated */
  pageSizeValue: number;
  /** @deprecated */
  setPageSizeValue: SetState<ICtx['pageSizeValue']>;
}

const CollectionQueuesCtx = createContext<ICtx | null>(null);

const CollectionQueuesProvider: FC<PropsWithChildren> = ({ children }) => {
  const locId = useAuthSelector((s) => s.locId);
  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);

  const [isLoading, setIsLoading] = useState<ICtx['isLoading']>(false);
  const [queues, setQueues] = useState<ICtx['queues']>([]);
  const [isModalOpen, setIsModalOpen] = useState<ICtx['isModalOpen']>(false);
  const [activeQueue, setActiveQueue_] = useState<ICtx['activeQueue']>(null);

  // Form state
  const [queueForm, setQueueForm] = useState<ICtx['queueForm']>(CollectionsQueueForm.parse({}));
  const queueFormValidation = CollectionsQueueForm.safeParse(queueForm);
  const setActiveQueue = (newQueue: ICtx['activeQueue']) => {
    setActiveQueue_(newQueue);
    const defaultForm = CollectionsQueueForm.parse({});
    const queueType = newQueue?.queueType
      ? QueueType.options[newQueue.queueType]!
      : defaultForm.queueType;
    const newFormParsed: CollectionsQueueForm = {
      queueType,
      visibility: newQueue?.visibility ?? defaultForm.visibility,
      queueName: newQueue?.queueName ?? defaultForm.queueName,
      daysFrom: newQueue?.daysFrom ?? defaultForm.daysFrom,
      daysTo: newQueue?.daysTo ?? defaultForm.daysTo,
      alphaFrom: newQueue?.alphaFrom ?? defaultForm.alphaFrom,
      alphaTo: newQueue?.alphaTo ?? defaultForm.alphaTo,
      sortColumn: newQueue?.sortColumn ?? defaultForm.sortColumn,
      sortDirection: newQueue?.sortDirection ?? defaultForm.sortDirection,
      autoTTP: newQueue?.autoTTP ?? defaultForm.autoTTP,
      autoTTPLocalTime: newQueue?.autoTTPLocalTime
        ? dayjs(newQueue.autoTTPLocalTime).toDate()
        : defaultForm.autoTTPLocalTime,
      autoTTPTemplate: newQueue?.autoTTPTemplate ?? defaultForm.autoTTPTemplate,
      includeOutForRepo: newQueue?.includeOutForRepo ?? defaultForm.includeOutForRepo,
      includeInOurPossession:
        newQueue?.includeInOurPossession ?? defaultForm.includeInOurPossession,
      includeFieldCall: newQueue?.includeFieldCall ?? defaultForm.includeFieldCall,
      includePaymentArrangements:
        newQueue?.includePaymentArrangements ?? defaultForm.includePaymentArrangements,
      includeAppointments: newQueue?.includeAppointments ?? defaultForm.includeAppointments,
      includeFinanced: newQueue?.includeFinanced ?? defaultForm.includeFinanced,
      includeLease: newQueue?.includeLease ?? defaultForm.includeLease,
      includeSidenotes: newQueue?.includeSidenotes ?? defaultForm.includeSidenotes,
      includeDepositDown: newQueue?.includeDepositDown ?? defaultForm.includeDepositDown,
    };
    setQueueForm(newFormParsed);
  };
  const setField: ICtx['setField'] = (field, value) =>
    setQueueForm((s) => ({ ...s!, [field]: value }));
  const setFields: ICtx['setFields'] = (field) => setQueueForm((s) => ({ ...s, ...field }));

  // Table state
  const [expandedRowIdx, setExpandedRowIdx] = useState<ICtx['expandedRowIdx']>(null);
  const [expandedRow, setExpandedRow] = useState<ICtx['expandedRow']>(null);
  const [pageModel, setPageModel] = useState<ICtx['pageModel']>({ page: 0, pageSize: 10 });
  const [sort, setSort] = useState<ICtx['sort']>(initialSort);
  const [page, setPage] = useState<ICtx['page']>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<ICtx['pageSizeValue']>(4);

  const loadQueues = async (
    locId: number | undefined,
    orgId: number | undefined,
    compId: number | undefined
  ) => {
    if (!locId || !orgId || !compId) return;

    setIsLoading(true);
    try {
      const colQueueRes = await collectionsService.getCollectionsQueue(locId, orgId, compId);
      const newCollectionQueues = colQueueRes.data?.map((cq) => ({
        ...cq,
        queueTypeString: getQueueFromIndex(cq.queueType),
      }));
      setQueues(newCollectionQueues || []);
    } catch (error) {
      console.error('Error loading collection queue data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadQueues(locId, orgId, compId);
  }, [locId, orgId, compId]);

  return (
    <CollectionQueuesCtx.Provider
      value={{
        isLoading,
        setIsLoading,

        isModalOpen,
        setIsModalOpen,

        queues,
        setQueues,
        loadQueues,

        activeQueue,
        setActiveQueue,

        // Form state
        queueForm,
        setQueueForm,
        queueFormValidation,
        setField,
        setFields,

        // Table state
        expandedRowIdx,
        setExpandedRowIdx,
        expandedRow,
        setExpandedRow,
        pageModel,
        setPageModel,
        sort,
        setSort,
        page,
        setPage,
        pageSizeValue,
        setPageSizeValue,
      }}
    >
      {children}
    </CollectionQueuesCtx.Provider>
  );
};

export default CollectionQueuesProvider;

export const useCollectionQueuesCtx = <T,>(selector: (state: ICtx) => T): T => {
  const ctx = useContext(CollectionQueuesCtx);
  if (!ctx) {
    throw new Error('useCollectionQueuesCtx must be used within CollectionQueuesProvider');
  }
  return selector(ctx);
};
