import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
// utils
import { Employee, paymentService } from '@/services/paymentService';
import { useAccountDetailParams } from '../../hooks';
// interfaces
import { ReversiblePayment } from './interfaces';
import { ReversalType } from '@/interfaces/payment';
import { GridPaginationModel } from '@mui/x-data-grid';
import { useAuthSelector } from '@/features/auth/authSlice';

export interface IPmtReversalCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  rows: ReversiblePayment[];
  setRows: (rows: IPmtReversalCtx['rows']) => void;
  reversalType: ReversalType;
  setReversalType: (_: IPmtReversalCtx['reversalType']) => void;
  employees: Employee[];
  setEmployees: (_: IPmtReversalCtx['employees']) => void;
  // table state
  pageModel: GridPaginationModel;
  setPageModel: (_: IPmtReversalCtx['pageModel']) => void;
}

const PmtReversalCtx = createContext<IPmtReversalCtx | null>(null);

const PmtReversalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { colRecId: colRecIdStr } = useAccountDetailParams();
  const colRecId = Number(colRecIdStr);
  const compId = useAuthSelector((s) => s.compId);

  const [isLoading, setIsLoading] = useState(false);
  const [pageModel, setPageModel] = useState<GridPaginationModel>({ page: 0, pageSize: 25 });
  const [reversalType, setReversalType] = useState<ReversalType>('reversal');
  const [rows, setRows] = useState<IPmtReversalCtx['rows']>([]);
  const [employees, setEmployees] = useState<IPmtReversalCtx['employees']>([]);

  const loadPayments = async (colRecId: number) => {
    if (isNaN(colRecId) || !compId) return;
    try {
      const reversiblePaymentsRes =
        (await paymentService.getReversiblePayments(colRecId, reversalType)) || [];

      const employeesRes = await paymentService.getUsersByCompanyId(compId!);
      // Validate
      const reversiblePayments = reversiblePaymentsRes.map((pmt) => new ReversiblePayment(pmt));
      setRows(reversiblePayments);
      setEmployees(employeesRes);
    } catch (_) {}
  };

  useEffect(() => {
    loadPayments(colRecId);
  }, [colRecId]);

  return (
    <PmtReversalCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        rows,
        setRows,
        reversalType,
        setReversalType,
        employees,
        setEmployees,
        // table state
        pageModel,
        setPageModel,
      }}
    >
      {children}
    </PmtReversalCtx.Provider>
  );
};

export default PmtReversalProvider;

export const usePmtReversalCtx = <T,>(selector: (state: IPmtReversalCtx) => T): T => {
  const ctx = useContext(PmtReversalCtx);
  if (!ctx) {
    throw new Error('usePmtReversalCtx must be used within PmtReversalProvider');
  }
  return selector(ctx);
};
