import React, { FC } from 'react';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';
import { FaBan, FaCheck, FaExclamation, FaReply, FaSyncAlt } from 'react-icons/fa';
import { useAcctDetailsCtx } from '../../AccountDetailProvider';
import dayjs from 'dayjs';

export const UnsentButton: FC<ButtonProps> = (props) => {
  return (
    <IconButton
      type="button"
      sx={{
        color: '#FFF',
        padding: '0px',
        minWidth: '30px',
        backgroundColor: '#294694',
        border: '1px solid',
        borderColor: '#00000029',
        borderLeft: 'none',
        borderRadius: '0px 8px 8px 0px',
      }}
      {...props}
    >
      <FaReply />
    </IconButton>
  );
};

export const DeniedButton: FC = () => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);
  return (
    <Tooltip title={`Denied on ${dayjs(optIn.dcWorkflowEndUtc).utc().format('MM/DD/YYYY')}`} arrow>
      <IconButton
        type="button"
        sx={{
          color: '#FFF',
          padding: '0px',
          minWidth: '30px',
          backgroundColor: '#FBCC3E',
          border: '1px solid',
          borderColor: '#00000029',
          borderLeft: 'none',
          borderRadius: '0px 8px 8px 0px',
        }}
      >
        <FaBan />
      </IconButton>
    </Tooltip>
  );
};

export const AcceptedButton: FC = () => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);
  return (
    <Tooltip
      title={`Accepted on ${dayjs(optIn.dcWorkflowEndUtc).utc().format('MM/DD/YYYY')}`}
      arrow
    >
      <IconButton
        type="button"
        sx={{
          color: '#FFF',
          padding: '0px',
          minWidth: '30px',
          backgroundColor: '#129A38',
          border: '1px solid',
          borderColor: '#00000029',
          borderLeft: 'none',
          borderRadius: '0px 8px 8px 0px',
        }}
      >
        <FaCheck />
      </IconButton>
    </Tooltip>
  );
};

export const PendingButton: FC = () => {
  const optIn = useAcctDetailsCtx((s) => s.optIn);
  return (
    <Tooltip
      title={`Pending since ${dayjs(optIn.dcWorkflowBeginUtc).utc().format('MM/DD/YYYY')}`}
      arrow
    >
      <IconButton
        type="button"
        sx={{
          color: '#FFF',
          padding: '0px',
          minWidth: '30px',
          backgroundColor: '#DDDDDD',
          border: '1px solid',
          borderColor: '#00000029',
          borderLeft: 'none',
          borderRadius: '0px 8px 8px 0px',
        }}
      >
        <FaSyncAlt />
      </IconButton>
    </Tooltip>
  );
};

export const ErrorButton: FC = () => {
  return (
    <Tooltip title="Error" arrow>
      <IconButton
        type="button"
        sx={{
          color: '#FFF',
          padding: '0px',
          minWidth: '30px',
          backgroundColor: '#C60000',
          border: '1px solid',
          borderColor: '#00000029',
          borderLeft: 'none',
          borderRadius: '0px 8px 8px 0px',
        }}
      >
        <FaExclamation />
      </IconButton>
    </Tooltip>
  );
};
