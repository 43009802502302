import { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import WsFormHeader from './WsFormHeader';
import WholesaleMgmtTab from './WholesaleMgmtTab';
import VehicleTab from './VehicleTab';
import DocumentsTab from './DocumentsTab';
import WsPaymentsView from './WsPaymentsView';
// state
import WsPmtsViewProvider from './WsPaymentsView/WsPmtsViewProvider';
import { useSalesViewCtx } from '../../SalesViewProvider';
import { useWholesaleFormCtx } from './WholesaleFormProvider';
// utils
import { salesService } from '@/services/salesService';
import { useSalesParams } from '../utils';
import { useSalesDetailCtx } from '../SalesDetailProvider';
import WsDetailHeader from './WsDetailHeader';

const WholesalesFormLayout: FC = () => {
  const { appRecId } = useSalesParams();
  const isAppRecId = !isNaN(Number(appRecId));

  // form view state
  const setIsLoading = useSalesViewCtx((s) => s.setIsLoading);
  const setWsFormRes = useWholesaleFormCtx((s) => s.setWsFormRes);
  const setWsForm = useWholesaleFormCtx((s) => s.setWsForm);
  const setFormAddlDataRes = useWholesaleFormCtx((s) => s.setFormAddlDataRes);
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);


  const fetchWholesaleData = async () => {
    try {
      setIsLoading(true);
      const wsFormRes = await salesService.getWholesaleForm(isAppRecId ? appRecId : null);
      const { salespeople } = wsFormRes;
      setWsFormRes(Object.keys(wsFormRes).length === 1 ? null : wsFormRes);
      const addlInfoRes = await salesService.getWholesaleFormAddlLists();
      setFormAddlDataRes(addlInfoRes, salespeople);
    } catch (err) {
      // @todo properly handle error
      console.error(err);
    } finally {
      setIsLoading(false);
      setHeaderTitle(<WsDetailHeader />)
    }
  };

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    fetchWholesaleData();

    // Cleanup: reset form states and tab state
    return () => {
      setWsFormRes(null);
      setWsForm(null);
    };
  }, [appRecId]);

  return (
    <Grid container direction="column" flex={1} overflow="hidden" wrap="nowrap" p={2}>
      <WsFormHeader />
      <Outlet />
    </Grid>
  );
};

const WholesalesForm: FC = () => {
  return (
    <Routes>
      <Route element={<WholesalesFormLayout />}>
        {/* Nav-tab routes */}
        <Route path="wholesale-management/*">
          <Route path="" element={<WholesaleMgmtTab />} />

          {/* "Take a payment" routes */}
          <Route path="payments/*" element={<WsPaymentsView />} />

          <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
        </Route>
        <Route path={`vehicle`} element={<VehicleTab />} />
        <Route path={`documents`} element={<DocumentsTab />} />
        {/* Fallbacks */}
        <Route path="/" element={<Navigate to={`wholesale-management/`} replace={true} />} />
        <Route path="*" element={<Navigate to={`wholesale-management/`} replace={true} />} />
      </Route>
    </Routes>
  );
};
export default () => (
  // WsPmtsViewProvider is set here to prefetch data for 'Payment Summary' detail-view
  <WsPmtsViewProvider>
    <WholesalesForm />
  </WsPmtsViewProvider>
);
