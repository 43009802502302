import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import MuiKendoTextField from '@/mui/components/form/MuiKendoTextField';
import Divider from '@mui/material/Divider';
// components
import WsFormSection from '../layoutComponents/WsFormSection';
import FormGroupHeader from './FormGroupHeader';
import WsCompanyModal from './WsCompanyModal';
import ListSelectorLink from './ListSelectorLink';
// state
import { useWholesaleFormCtx } from '../WholesaleFormProvider';
// utils
import { formFieldStyle } from './default';

/** ###  */
const WsCompanyInfoForm: FC = () => {
  const selectedWsCompany = useWholesaleFormCtx((s) => s.selectedWsCompany);
  const setIsWsCompanyModalOpen = useWholesaleFormCtx((s) => s.setIsWsCompanyModalOpen);
  const wsForm = useWholesaleFormCtx((s) => s.wsForm!);

  return (
    <WsFormSection>
      <FormGroupHeader title="Wholesale Company Information" />
      <Grid container direction="row" flex={1} columnGap={1}>
        {/* Column 1 */}
        <Grid container direction="column" flex={1} height="100%" p={1}>
          <MuiKendoTextField
            label="Company Name"
            placeholder="None Selected"
            value={selectedWsCompany?.companyName || ''}
            helperComponent={
              wsForm.saleStatus === 'Pending' && (
                <ListSelectorLink
                  setIsModalOpen={setIsWsCompanyModalOpen}
                  text="+ Select Wholesale Company from list"
                />
              )
            }
            disabled
            sx={formFieldStyle}
          />
          <WsCompanyModal />
          <MuiKendoTextField
            label="Address"
            placeholder="-"
            value={selectedWsCompany?.address || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="City"
            placeholder="-"
            value={selectedWsCompany?.city || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Zip"
            placeholder="-"
            value={selectedWsCompany?.zip || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="State"
            placeholder="-"
            value={selectedWsCompany?.state || ''}
            disabled
            sx={formFieldStyle}
          />
        </Grid>
        {/* Column 2 */}
        <Divider orientation="vertical" />

        <Grid
          container
          direction="column"
          flex={1}
          alignItems="start"
          justifyContent="start"
          height="100%"
          p={2}
        >
          <MuiKendoTextField
            label="Contact Email"
            placeholder="-"
            value={selectedWsCompany?.contactEmail || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Phone"
            placeholder="-"
            value={selectedWsCompany?.phone || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Fax"
            placeholder="-"
            value={selectedWsCompany?.fax || ''}
            disabled
            sx={formFieldStyle}
          />
          <MuiKendoTextField
            label="Tax ID"
            placeholder="-"
            value={selectedWsCompany?.taxId || ''}
            disabled
            sx={formFieldStyle}
          />
        </Grid>
      </Grid>
    </WsFormSection>
  );
};

export default WsCompanyInfoForm;
