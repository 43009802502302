import { FC, ReactNode } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import Header from './Header';
import NavSidebar from './NavSidebar';
import WholesalesForm from './WholesalesForm';
import LhphFundingDetail from './LhphFundingDetail';
import DealsQuotesDetail from './DealsQuotesDetail';
// state
import WholesaleFormProvider from './WholesalesForm/WholesaleFormProvider';
// utils
import { useSalesParams } from './utils';
import { useNavBack } from '@/utils/routing/useNavBackEvent';
// interfaces
import { SalesSubviewListKey } from '../enums';

const detailComponentMap: Map<SalesSubviewListKey, ReactNode> = new Map<
  SalesSubviewListKey,
  ReactNode
>([
  // Add detail/form components here
  [SalesSubviewListKey.wholesales, <WholesalesForm />],
  [SalesSubviewListKey.lhPhFunding, <LhphFundingDetail />],
  [SalesSubviewListKey.dealsQuotes, <DealsQuotesDetail />],
]);

/** ### Layout for sales detail-form views
 * @note in order to leverage the URL parameter for `subview` (provided by `useParams`), the `<Routes>` component cannot be used
 */
const SalesDetailLayout: FC = () => {
  const { subview } = useSalesParams();

  if (!subview) {
    console.error(`No 'subview' provided in URL params: '${subview}'`);
    useNavBack()();
    return <></>;
  }

  // Select detail-view component
  const DetailComponent = detailComponentMap.get(subview);

  const isSidebarRendered =
    subview !== SalesSubviewListKey.lhPhFunding && subview !== SalesSubviewListKey.dealsQuotes;

  return (
    <Grid
      container
      direction="column"
      p="64px 32px"
      flex={1}
      wrap="nowrap"
      overflow="hidden"
      fontFamily="Inter"
      sx={{ '&* svg': { color: '#9e9e9e' /*$iconGrey: #9e9e9e;*/ } }}
    >
      <Header />

      {/* Body */}
      <Grid
        container
        direction="row"
        flex={1}
        sx={{ flexWrap: 'nowrap', overflowX: 'hidden', overflowY: 'hidden' }}
      >
        {isSidebarRendered && <NavSidebar />}
        {DetailComponent}
      </Grid>
    </Grid>
  );
};

export default () => (
  <WholesaleFormProvider>
    <SalesDetailLayout />
  </WholesaleFormProvider>
);
