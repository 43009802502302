import { FaChevronLeft } from 'react-icons/fa';
import styles from './BackLink.module.scss';
import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import React from 'react';

interface BackLinkProps {
  to: To;
  navigateOptions?: NavigateOptions;
  title: string;
  containerStyle?: React.CSSProperties;
}

export const BackLink: React.FC<BackLinkProps> = (props) => {
  const { to, title, navigateOptions = { relative: 'path' }, containerStyle = {} } = props;
  const navigate = useNavigate();
  return (
    <div
      className={styles.backLink}
      onClick={() => navigate(to, navigateOptions)}
      style={containerStyle}
    >
      <FaChevronLeft className={styles.icon} />
      <span className={styles.label}>{title}</span>
    </div>
  );
};
