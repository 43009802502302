import { FC } from "react";
import { Controller } from "react-hook-form";
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Loader } from "@progress/kendo-react-all";
import {
  Button,
  Checkbox,
  DropdownInput,
  NumberInput,
  PhoneInput,
  TextArea,
  TextInput,
} from "@/components";
// state
import { useSaleManagementForm } from "./useSaleManagementForm";
// utils
import { useNavigationConfirm } from "@/hooks";
import { emailRegex, optionalPhoneValidation } from "@/utils/helpers/formValidation";
// interfaces
import { SaleType } from "@/enums";
// style
import styles from "../SaleManagement.module.scss";
import { SetState } from "@/interfaces/utilityTypes";

const LoaderLayout: FC = () => (
  <div className={styles.formContainer}>
    <div className={styles.loading}>
      <Loader size="large" />
    </div>
  </div>
);

const leadTypeData = [
  "Walk-In",
  "BBC",
  "Phone",
  "On-Line",
  "Referral",
  "Previous Customer",
  "Podium",
];

// once these pages are in the router this prop will not be needed
export const SaleManagementForm: FC<{ setShowDownPayment: SetState<boolean> }> = ({
  setShowDownPayment,
}) => {
  const {
    control,
    customDateLabels,
    customFieldLabels,
    customNumLabels,
    customYNLabels,
    declineApplication,
    employeeList,
    errors,
    handleSubmit,
    isDirty,
    lienholders,
    newSale,
    postSale,
    saleManagementDataLoading,
    saleManagementHeaderLoading,
    submitNewSale,
    unPostSale,
    updateSaleManagement,
    updateSaleManagementLoading,
    watch,
    isPosted,
    showPostLease,
    enablePostLease,
    showUnpostLease,
    showPostFinance,
    enablePostFinance,
    showUnpostFinance,
    showDecline,
    samItems,
    isDisabled,
    isApplicationInactive,
    isApplicationPending,
    appStatusLoading,
    activateDeal,
    inactivateDeal,
  } = useSaleManagementForm();
  const headerButtonStyle = { width: "140px" };

  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  /* Alpha implementation would show lease and wholesale for users with UserInfo.DclAccess = 1
   * Other users would get all sale types (lease, finance, wholesale, cash)
   * Stated intention is not to carry that flag forward
   * Only showing lease and finance for now since wholesale and cash have not been addressed yet
   */
  const saleTypes = [SaleType.Lease, SaleType.Finance];

  if (saleManagementDataLoading) return <LoaderLayout />;

  return (
    <>
      <header className={styles.headerContainer}>
        <h2 className={styles.header}>Sale Management</h2>
        <div className={styles.buttonContainer}>
          {showUnpostFinance && (
            <Button
              label="Unpost Sale"
              onClick={unPostSale}
              loading={saleManagementHeaderLoading}
            />
          )}
          {showUnpostLease && (
            <Button
              label="Unpost Lease"
              onClick={unPostSale}
              loading={saleManagementHeaderLoading}
            />
          )}
          {isPosted && (
            <Button
              label="Down Payment"
              onClick={() => setShowDownPayment(true)}
              loading={saleManagementHeaderLoading}
            />
          )}
          {showDecline && (
            <Button
              label="Decline"
              type="button"
              loading={saleManagementHeaderLoading}
              buttonStyle={headerButtonStyle}
              onClick={declineApplication}
            />
          )}
          {showPostFinance && (
            <Button
              label="Post Sale"
              onClick={postSale}
              loading={saleManagementHeaderLoading}
              buttonStyle={headerButtonStyle}
              disabled={!enablePostFinance || isApplicationInactive}
            />
          )}
          {showPostLease && (
            <Button
              label="Post Lease"
              onClick={postSale}
              loading={saleManagementHeaderLoading}
              buttonStyle={headerButtonStyle}
              disabled={!enablePostLease || isApplicationInactive}
            />
          )}
          {isApplicationInactive && (
            <Button
              label="Activate Deal"
              onClick={activateDeal}
              loading={appStatusLoading}
              buttonStyle={headerButtonStyle}
            />
          )}
          {isApplicationPending && (
            <Button
              label="Inactivate Deal"
              onClick={inactivateDeal}
              loading={appStatusLoading}
              buttonStyle={headerButtonStyle}
            />
          )}
        </div>
      </header>
      <form
        className={styles.formContainer}
        onSubmit={newSale ? handleSubmit(submitNewSale) : handleSubmit(updateSaleManagement)}
      >
        <div className={styles.bodyContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.formSubContainer}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextInput
                    required
                    label="First Name"
                    errors={errors.firstName?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextInput
                    required
                    label="Last Name"
                    errors={errors.lastName?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                rules={optionalPhoneValidation}
                render={({ field }) => (
                  <PhoneInput
                    label="Phone Number"
                    errors={errors.phoneNumber?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    label="Email"
                    errors={errors.email?.message}
                    readOnly={isDisabled}
                    {...field}
                  />
                )}
              />
            </div>
            {!newSale && (
              <>
                <div className={styles.formSubContainer}>
                  <Controller
                    name="saleDate"
                    control={control}
                    render={({ field }) => <DateInput readOnly label="Sale Date" {...field} />}
                  />
                  <Controller
                    name="salesperson1"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Salesperson 1"
                        data={employeeList}
                        dataItemKey="recId"
                        textField="shortName"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="salesperson2"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        data={employeeList}
                        dataItemKey="recId"
                        textField="shortName"
                        label="Salesperson 2"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="stockNum"
                    control={control}
                    render={({ field }) => <TextInput readOnly label="Stock Number" {...field} />}
                  />
                  <Controller
                    name="lienHolder"
                    control={control}
                    rules={{
                      required: "Lien Holder is required",
                    }}
                    render={({ field }) => (
                      <DropdownInput
                        label="Lien Holder"
                        data={lienholders}
                        dataItemKey="recId"
                        textField="abrName"
                        required
                        errors={errors.lienHolder?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="milesIn"
                    control={control}
                    render={({ field }) => <NumberInput readOnly label="Miles In" {...field} />}
                  />
                  <Controller
                    name="miles"
                    control={control}
                    render={({ field }) => <NumberInput label="Miles" {...field} />}
                  />
                  <div className={styles.checkBoxContainer}>
                    <Controller
                      name="notActual"
                      control={control}
                      render={({ field }) => <Checkbox label="Not Actual" {...field} />}
                    />
                    <Controller
                      name="exceedsLimits"
                      control={control}
                      render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
                    />
                    <Controller
                      name="printExempt"
                      control={control}
                      render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
                    />
                  </div>
                </div>
                <div className={styles.formSubContainer}>
                  <h3 className={styles.formHeader}>Credit Application</h3>
                  <Controller
                    name="verified"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label="Verified"
                        disabled={isDisabled}
                        labelStyles={{ fontWeight: 700 }}
                        {...field}
                      />
                    )}
                  />
                  {watch("verified") && (
                    <>
                      <Controller
                        name="verifiedBy"
                        control={control}
                        render={({ field }) => (
                          <TextInput readOnly label="Verified By" {...field} />
                        )}
                      />
                      <Controller
                        name="verifiedOn"
                        control={control}
                        render={({ field }) => (
                          <DateInput readOnly label="Verified On" {...field} />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="approved"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label="Approved"
                        labelStyles={{ fontWeight: 700 }}
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  {watch("approved") && (
                    <>
                      <Controller
                        name="approvedBy"
                        control={control}
                        render={({ field }) => (
                          <TextInput readOnly label="Approved By" {...field} />
                        )}
                      />
                      <Controller
                        name="approvedOn"
                        control={control}
                        render={({ field }) => (
                          <DateInput readOnly label="Approved On" {...field} />
                        )}
                      />
                    </>
                  )}
                </div>
                <div className={styles.formSubContainer}>
                  <h3 className={styles.formHeader}>Custom Sale Data</h3>
                  {customFieldLabels.map((value, index) => {
                    return (
                      <Controller
                        name={`customField${index + 1}` as any}
                        key={`customField${index + 1}`}
                        control={control}
                        render={({ field }) => <TextInput label={value} {...field} />}
                      />
                    );
                  })}
                  {customDateLabels.map((value, index) => {
                    return (
                      <Controller
                        name={`customDate${index + 1}` as any}
                        key={`customDate${index + 1}`}
                        control={control}
                        render={({ field }) => <DateInput label={value} {...field} />}
                      />
                    );
                  })}
                  {customNumLabels.map((value, index) => {
                    return (
                      <Controller
                        name={`customNum${index + 1}` as any}
                        key={`customNum${index + 1}`}
                        control={control}
                        render={({ field }) => <NumberInput label={value} {...field} />}
                      />
                    );
                  })}
                  {customYNLabels.map((value, index) => {
                    return (
                      <Controller
                        name={`customYN${index + 1}` as any}
                        key={`customYN${index + 1}`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            label={value}
                            labelStyles={{ fontWeight: 700 }}
                            labelPlacement="before"
                            {...field}
                          />
                        )}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.formSubContainer}>
              <Controller
                name="saleStatus"
                control={control}
                render={({ field }) => <TextInput readOnly label="Sale Status" {...field} />}
              />
              <Controller
                name="leadType"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="Lead Type" data={leadTypeData} {...field} />
                )}
              />
              <Controller
                name="saleType"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <DropdownInput
                    required
                    label="Sale Type"
                    disabled={!newSale}
                    data={saleTypes}
                    errors={errors.saleType?.message}
                    {...field}
                  />
                )}
              />
            </div>
            {!newSale && (
              <>
                <div className={styles.formSubContainer}>
                  <h3 className={styles.formHeader}>Sam</h3>
                  <div className={styles.samBody}>
                    <ul className={styles.samList}>
                      {samItems.map((item) => (
                        <li
                          key={item.text}
                          className={styles.samItem}
                          style={item.required ? { fontWeight: 700 } : {}}
                        >
                          {`${item.text}${item.required ? " *" : ""}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={styles.formSubContainer}>
                  <h3 className={styles.formHeader}>Application Notes</h3>
                  <Controller
                    name="applicationNotes"
                    control={control}
                    render={({ field }) => <TextArea rows={3} readOnly={isDisabled} {...field} />}
                  />
                </div>
                <div className={styles.formSubContainer}>
                  <h3 className={styles.formHeader}>We Owe</h3>
                  <Controller
                    name="weOwe"
                    control={control}
                    render={({ field }) => <TextArea rows={2} {...field} />}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            label="Submit"
            buttonStyle={headerButtonStyle}
            loading={updateSaleManagementLoading}
          />
        </div>
      </form>
      {NavigationConfirm}
    </>
  );
};
