import { FC, useEffect, useState } from 'react';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
} from '@progress/kendo-react-all';
import { Button } from '@/components';
import { Modal } from '@/components';
import TextCell from '@/components/kendoCells/TextCell';
// state
import { useWholesaleFormCtx } from '../WholesaleFormProvider';
// interfaces
import { IKendoPaginationState } from '@/interfaces/kendo';
import { WsFormVehicle } from '../interfaces';
import { KendoColumnDef } from '@/utils/tableLayout/utils';
// style
import styles from './vehicleSelectStyles.module.scss';

const initPageState = { skip: 0, take: 10 };
const paginationSettings = { buttonCount: 4, pageSizes: [initPageState.take] };
const headerClassName = styles.headerStyle;
const handlePageChange =
  (setPage: (p: IKendoPaginationState) => void) => (e: GridPageChangeEvent) => {
    setPage({ ...e.page });
  };

const paginateData = (data: any[], skip: number, take: number) => data.slice(skip, skip + take);

const VehicleSelectTable: FC = () => {
  const vehicleList = useWholesaleFormCtx((s) => s.formAddlData?.vehicles || []);
  const isModalOpen = useWholesaleFormCtx((s) => s.isModalOpen);
  const isWsCompanyModalOpen = useWholesaleFormCtx((s) => s.isWsCompanyModalOpen);
  const setWsFormField = useWholesaleFormCtx((s) => s.setWsFormField);
  const setIsModalOpen = useWholesaleFormCtx((s) => s.setIsModalOpen);
  const [page, setPage] = useState<IKendoPaginationState>({ ...initPageState });
  const [paginatedData, setPaginatedData] = useState(
    paginateData(vehicleList, page.skip, page.take)
  );
  const dbCt = vehicleList.length;

  // Event handlers
  const handleCloseModal = () => setIsModalOpen(false);
  const handleSelect = (vehicle: WsFormVehicle) => {
    setWsFormField('vehRecId', vehicle.recId || null);
    setIsModalOpen(false);
  };
  // Effects
  useEffect(() => {
    setPaginatedData(paginateData(vehicleList, page.skip, page.take));
  }, [vehicleList.map((v) => v.vin).join(), page.skip, page.take]);

  if (!isModalOpen || isWsCompanyModalOpen) return <></>;

  const columns: KendoColumnDef<keyof (WsFormVehicle & { select: any })>[] = [
    { field: 'stockNum', title: 'Stock Number', width: 150, headerClassName },
    { field: 'year', title: 'Year', width: 100, cell: TextCell, headerClassName },
    { field: 'make', title: 'Make', width: 100, cell: TextCell, headerClassName },
    { field: 'model', title: 'Model', width: 100, cell: TextCell, headerClassName },
    { field: 'color', title: 'Color', width: 100, cell: TextCell, headerClassName },
    { field: 'vin', title: 'VIN', width: 200, cell: TextCell, headerClassName },
    {
      field: 'select',
      title: 'Select',
      cell: (props: any) => (
        <td>
          <Button label="Select" onClick={() => handleSelect(props.dataItem)} />
        </td>
      ),
      width: 100,
      headerClassName,
    },
  ];

  const tableColumnElems = columns.map((c) => (
    <GridColumn
      field={c.field}
      title={c.title}
      width={c.width}
      cell={c.cell}
      headerClassName={c.headerClassName}
      key={c.field}
    />
  ));

  return (
    <Modal
      title="Vehicle List"
      isOpen={isModalOpen}
      closeButton
      centerModal
      onCloseButtonClick={handleCloseModal}
      panelChildrenStyle={{ overflow: 'hidden', display: 'flex' }}
    >
      <KendoGrid
        data={paginatedData}
        skip={page.skip}
        take={page.take}
        total={dbCt}
        pageable={{ ...paginationSettings, pageSizeValue: initPageState.take }}
        onPageChange={handlePageChange(setPage)}
        style={{ overflow: 'hidden' }}
      >
        <GridNoRecords>'No accounts found'</GridNoRecords>

        {tableColumnElems}
      </KendoGrid>
    </Modal>
  );
};

export default VehicleSelectTable;
