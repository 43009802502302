import { FC, useEffect, useState } from 'react';
import { useAccountSelector } from '../../../../accountSlice';
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import CreditEducation from '@/components/creditEducation/CreditEducation';
import { CreditEducationInfo } from '@/interfaces/CreditEducation';
import { applicationService } from '@/services/applicationService';
import { AccountInformation } from '@/interfaces';

const CreditEducationDetail: FC = () => {
  const { accountInformation, accountInformationLoading } = useAccountSelector((s) => s);
  const [saleDataLoading, setSaleDataLoading] = useState(false);
  const [creditEducationInfo, setCreditEducationInfo] = useState<CreditEducationInfo | null>(null);

  useEffect(() => {
    if (accountInformation == null || accountInformation.appRecId == null) return;

    const fetchData = async (accountInformation: AccountInformation | null) => {
      setSaleDataLoading(true);

      const getCreditEducationRes = await applicationService.getCreditEducationInfo(
        accountInformation?.appRecId
      );

      if (!getCreditEducationRes)
        throw new Error(
          `No credit education information found for appRecId: ${accountInformation?.appRecId}`
        );

      const buyerInfo = getCreditEducationRes?.buyer;
      const buyerCreditInfo = getCreditEducationRes?.buyerCreditEducation;
      const creditInfo = {
        firstName: buyerInfo?.b_FIRST,
        middleName: buyerInfo?.b_MID,
        lastName: buyerInfo?.b_LAST,
        dateOfBirth: buyerInfo?.b_DOB,
        SSNLast4: buyerInfo?.b_SSN!.slice(-4),
        phoneNumber: buyerInfo?.b_CPHONE,
        homePhoneNumber: buyerInfo?.b_HPHONE,
        workPhoneNumber: buyerInfo?.b_LLPHONE,
        faxNumber: buyerInfo?.b_LLFAX,
        email: buyerInfo?.b_EMAIL,
        currentAddress: buyerInfo?.b_ADDR,
        currentCity: buyerInfo?.b_CITY,
        currentState: buyerInfo?.b_STATE,
        currentZipCode: buyerInfo?.b_ZIP,
        appBuyersRecID: buyerInfo?.rec_Id!.toString(),
        creditRepairCloudID: buyerCreditInfo?.creditRepairCloudID,
        trackingToken: buyerCreditInfo?.smartCreditTrackingToken,
        customerToken: buyerCreditInfo?.smartCreditCustomerToken,
      } as CreditEducationInfo;

      setCreditEducationInfo(creditInfo);
      setSaleDataLoading(false);
    };

    fetchData(accountInformation).catch(console.error);
  }, [accountInformation?.appRecId]);

  return (
    <AccountsMainPanel
      navBarTitle="Credit Education"
      loading={accountInformationLoading || saleDataLoading}
    >
      <CreditEducation formData={creditEducationInfo} />
    </AccountsMainPanel>
  );
};

export default CreditEducationDetail;
