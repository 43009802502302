import { FC } from "react";
import { IconType } from "react-icons";
import { MdPrint } from "react-icons/md";
// mui
import Button from "@/mui/components/MuiKendoButton";
import { ButtonProps } from "@mui/material/Button";
// state
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
import PdfProvider, { usePdfCtx } from "./PdfProvider";

type IPrintPdfButtonProps = Omit<ButtonProps, "onClick" | "endIcon"> & {
  iconProps?: IconType;
  isInModal: boolean;
};

/** ###  */
const PrintPdfButtonLayout: FC<IPrintPdfButtonProps> = ({
  iconProps,
  isInModal,
  sx: sxProps,
  ...buttonProps
}) => {
  const currentAppEval = useAiUnderwritingCtx((s) =>
    isInModal ? s.selectedAppEval : s.latestAppEval
  );
  const instance = usePdfCtx((s) => s.instance);

  // Event handlers
  const handlePrintPdf = async () => {
    if (currentAppEval === null) {
      console.error("No app-eval loaded.");
      return;
    }

    const appEvalId = `${currentAppEval?.applicationId}-${currentAppEval?.dateCreated}'`;

    if (!instance.blob) {
      console.error(`No PDF generated for app-eval: '${appEvalId}'`);
      return;
    }

    const fileLink = document.createElement("a");
    fileLink.href = window.URL.createObjectURL(instance.blob);

    const filename = `ScienapticEval-AppId-${currentAppEval?.applicationId}-${currentAppEval?.dateCreated}.pdf`;
    fileLink.download = filename;

    fileLink.click();
    window.open(fileLink.href);
  };

  const isButtonDisabled = instance.loading || instance.blob === null;

  return (
    <Button
      onClick={handlePrintPdf}
      endIcon={<MdPrint {...iconProps} color="white" />}
      disabled={isButtonDisabled}
      {...buttonProps}
      sx={{ ...sxProps }}
    >
      Print Current Evaluation
    </Button>
  );
};

const PrintPdfButton: FC<IPrintPdfButtonProps> = (p) => (
  <PdfProvider>
    <PrintPdfButtonLayout {...p} />
  </PdfProvider>
);

export default PrintPdfButton;
