import { CSSProperties, FC, PropsWithChildren, RefObject } from 'react';
// kendo
import { BackLink } from '@/components/backLink/BackLink';
// style
import styles from './AccountsContainer.module.scss';

const AccountsContainer: FC<
  PropsWithChildren & {
    title?: string;
    containerStyle?: CSSProperties;
    containerRef?: RefObject<HTMLDivElement>;
    backLabel?: string;
    backUrl?: string;
  }
> = ({ children, title, containerStyle, backLabel, backUrl, containerRef }) => (
  <div className={styles.container} style={containerStyle} ref={containerRef}>
    <div className={styles.titleContainer}>
      <div className={styles.backLinkContainer}>
        <BackLink to={backUrl || '..'} title={backLabel || 'Go Back'} />
      </div>
      <div className={styles.title}>{title}</div>
    </div>
    <div className={styles.body} style={{ overflow: 'scroll' }}>
      {children}
    </div>
  </div>
);

export default AccountsContainer;
