import { Column } from '@/components/table/TableInterface';
import { SidenoteDetail } from '@/interfaces/Accounts';

export const tableColumns: (Column & { field: keyof SidenoteDetail<string> })[] = [
  { field: 'snDate', title: 'Date' },
  { field: 'snTotal', title: 'Total' },
  { field: 'snDown', title: 'Down' },
  { field: 'snAmtFin', title: 'Financed' },
  { field: 'prinBal', title: 'Prin. Balance' },
  { field: 'status', title: 'Status' },
  { field: 'snDetail', title: 'Detail' },
];
