import { PaymentAcceptedIn, TransactionType } from '@/enums';
import { PostPaymentPayload } from '@/services/paymentService';

export const defaultPaymentFormValues: Partial<PostPaymentPayload> = {
  CpiPaid: 0,
  CarPmt: 0,
  DdPmt: 0,
  LcPaid: 0,
  NsfPaid: 0,
  TotalReceived: 0,
};

export const accountTypeOptions = [
  { label: 'Checking', value: 0 },
  { label: 'Savings', value: 1 },
];

const transactionTypes = Object.values(TransactionType);
export const transactionTypeOptions = transactionTypes.map((type) => ({
  label: type,
  value: type,
}));

export const paidInOptions = Object.values(PaymentAcceptedIn);
