import { ActivityLog } from '@/interfaces';
import { Call, Email, Messages, Note, Payment } from '@/services/customerService';

export interface CustomerActivity {
  id: number;
  activityType: ActivityType;
  activity: Email | Messages | Note | Payment | Call | ActivityLog;
  timestamp: string;
}

export enum ActivityType {
  All = 'All',
  Email = 'Email',
  Message = 'Message',
  Note = 'Note',
  Payment = 'Payment',
  Call = 'Call',
  Log = 'Log',
}
