import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// components
import CollectionQueuesTable from './CollectionQueuesTable';
// import QueueDetailForm from './QueueDetailForm';
import CollectionsModal from './components/CollectionsModal';

/** ###  */
const CollectionQueues: FC = () => {
  return (
    <Grid container flex={1}>
      <CollectionQueuesTable />

      {/* @todo in progress */}
      {/* <QueueDetailForm /> */}

      <CollectionsModal />
    </Grid>
  );
};

export default CollectionQueues;
