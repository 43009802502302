import { FC } from 'react';
// kendo
// components
import Header from './layout/Header';
// state
import { useAccountSelector } from '../../accountSlice';
// utils
import { inCollectionsRoute } from '@/utils/helpers/general';
// interfaces
import { ColTypeCode, colTypeMap } from '@/enums/general';
// style
import styles from './AccountDetail.module.scss';
import PaymentFormOnly from './components/PaymentForm/form';
import { AcctsSubviewKey } from '../../viewInfo';
import BackButton from './layout/BackButton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useNavigate } from 'react-router-dom';

const PaymentOnlyLayout: FC = () => {
  const navigate = useNavigate();
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const accountInformationLoading = useAccountSelector((s) => s.accountInformationLoading);
  const colType = useAccountSelector((s) => s.accountInformation?.colType || '');
  const accountNumber = useAccountSelector((s) => s.accountInformation?.accountNum);

  const paymentTypeLabel = colTypeMap[colType as ColTypeCode]?.label || '';
  const paymentTitle = accountInformationLoading ? "Loading..." : `#${accountNumber} - ${paymentTypeLabel}  Payment for ${accountInformation?.buyer.firstName} ${accountInformation?.buyer.lastName}`;

  return (
    // @todo use Grid/div
    <Grid>
      <BackButton subView={AcctsSubviewKey.unpaidSales}/>
      <div className={styles.accountDetailsContainer}>
        <Header title={paymentTitle} hasTabs={false} />
        <div className={styles.contentsContainer}>
          <PaymentFormOnly onSuccessfullResponse={() => navigate(`/accounts/${AcctsSubviewKey.unpaidSales}`, { relative: 'path' })} />
        </div>
      </div>
    </Grid>
  );
};

export default PaymentOnlyLayout;