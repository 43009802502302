import { FC } from 'react';
import { useMatch } from 'react-router-dom';
// mui
import LinkButton from '@/mui/components/LinkButton';

/** ###  */
const AddNewButton: FC = () => {
  const match = useMatch({ path: '/:root/:viewRoot/:currentView' });
  const currentView = match?.params.currentView;

  const isUsersView = currentView === 'users';
  if (isUsersView) return <></>;

  const label = isUsersView ? 'User' : 'Role';

  return <LinkButton to="new">Add New {label} +</LinkButton>;
};

export default AddNewButton;
