import { FC } from 'react';
import { Outlet, Route, Routes, matchRoutes, useLocation } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import MuiViewHeader from '@/mui/components/layout/ViewHeader';
// components
import CollectionQueues from './CollectionQueues';
import { QueueRunList } from './QueueRunList';
import { RunQueue } from './runQueue/RunQueue';
import AddNewButton from './AddNewButton';
// state
import CollectionQueuesProvider, { useCollectionQueuesCtx } from './CollectionQueuesProvider';

const ViewHeader: FC = () => {
  const currentLocation = useLocation();
  const queues = useCollectionQueuesCtx((s) => s.queues);
  const isLoading = useCollectionQueuesCtx((s) => s.isLoading);
  const activeQueueName = useCollectionQueuesCtx((s) => s.activeQueue?.queueName);
  const someRoutes = [{ path: '/collections/:queueRecId' }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isMatch = matches ? matches.length > 0 : false;

  const dbCt = queues.length;

  return (
    <MuiViewHeader
      primaryText="Collection Queues"
      centerText={
        isMatch && (
          <Grid container direction="row" gap={1.5} flex={1} px={1} wrap="nowrap">
            <Typography fontSize={26} fontWeight={300} letterSpacing="-0.52px" noWrap>
              - Viewing queue:
            </Typography>
            <Typography fontSize={26} fontWeight={700} letterSpacing="-0.52px" noWrap>
              {activeQueueName ? `"${activeQueueName}"` : '-'}
            </Typography>
          </Grid>
        )
      }
      isLoading={isLoading}
      dbCt={isMatch ? undefined : dbCt}
      viewSelector={!isMatch ? <AddNewButton /> : <></>}
      centered={false}
    />
  );
};

const CollectionQueuesView: FC = () => {
  return (
    <CollectionQueuesProvider>
      <Grid px="45px">
        <ViewHeader />
        <Outlet />
      </Grid>
    </CollectionQueuesProvider>
  );
};

/** ###  */
const CollectionQueuesRouter: FC = () => (
  <Routes>
    <Route element={<CollectionQueuesView />}>
      <Route path="/" element={<CollectionQueues />} />
      <Route path="/:queueRecId" element={<QueueRunList />} />
    </Route>

    {/* Fallback */}
    <Route path="/:queueRecId/run">
      <Route path=":colRecId/*" element={<RunQueue />} />
    </Route>
  </Routes>
);

export default CollectionQueuesRouter;
