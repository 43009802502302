import { FC } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const ToastNotiLayout: FC<Grid2Props & { title: string }> = ({
  title,
  children,
  ...props
}) => (
  <Grid container direction="column" gap={1} {...props}>
    <Grid component="h6" fontSize="16px" fontWeight={700} m={0}>
      {title}
    </Grid>

    <Divider flexItem />

    {children}
  </Grid>
);

export const ToastNotiListPersist: FC<Grid2Props & { title: string; items: string[] }> = ({
  title,
  items,
  children,
}) => {
  const itemElems = items.map((item, idx) => (
    <ListItem dense divider={idx < items.length - 1} sx={{ pr: 0 }} key={item}>
      <ListItemText primary={item} />
    </ListItem>
  ));

  return (
    <ToastNotiLayout title={title} m={0} >
      <List sx={{ width: "100%",  bgcolor: "background.paper" }} dense>
        {itemElems}
      </List>
    </ToastNotiLayout>
  );
};
