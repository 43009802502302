import { toast } from 'react-toastify';
// interfaces
import { AxiosService } from './axiosService';
import { ApiResponse } from '@/interfaces';
import { GridResponse } from '@/interfaces/ServerGrids';
import { OrderForRepoRowData, OrderForRepoSubmitReq } from '@/features/Dashboard/interfaces';
import { OutForRepoReq } from '@/features/Accounts/accountsSubviews/AccountDetail/components/outForRepo/interfaces';

class CollectionsService extends AxiosService {
  public constructor() {
    super();
  }

  async getCollectionsQueue(locId: number, orgId: number, compId: number) {
    try {
      const { data } = await this.axios.get<GetCollectionsQueueResponse>(
        '/Collections/GetCollectionQueues',
        {
          params: { LocId: locId, OrgId: orgId, CompId: compId },
        }
      );
      return data;
    } catch (e) {
      toast.error('Unable to get collections queue');
      console.error(e);
      throw e;
    }
  }

  async postCollectionsQueue(payload: CollectionQueuePayload) {
    try {
      const { data } = await this.axios.post('/Collections/CreateCollectionQueue', payload);
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to add queue');
      }
      console.error(e);
      throw e;
    }
  }

  async deleteCollectionsQueue(recId: number) {
    try {
      const { data } = await this.axios.delete('/Collections/DeleteCollectionQueue', {
        data: {
          RecId: recId,
        },
      });
      return data;
    } catch (e) {
      toast.error('Unable to delete collections queue');
      console.error(e);
      throw e;
    }
  }

  async updateCollectionsQueue(payload: UpdateCollectionQueuePayload) {
    try {
      const { data } = await this.axios.put('/Collections/UpdateCollectionQueue', payload);
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to update collections queue');
      }
      console.error(e);
      throw e;
    }
  }

  async getCollectionsByQueue(queueRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CollectionsByQueueResponse>>(
        '/Collections/GetCollectionsForQueue',
        {
          params: {
            queueRecId,
          },
        }
      );
      return data.data!;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to get collections queue details');
      }
      console.error(e);
      throw e;
    }
  }

  async getCollectionQueue(queueRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CollectionsByQueueResponse>>(
        '/Collections/GetCollectionsQueue',
        {
          params: {
            queueRecId,
          },
        }
      );
      return data.data!;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to get collections queue details');
      }
      console.error(e);
      throw e;
    }
  }

  async sendBulkTTP(queueRecId: number, colRecIds: number[]) {
    try {
      const { data } = await this.axios.post('/Collections/SendBulkTTP', {
        colrecids: colRecIds,
        queueRecId: queueRecId,
      });
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to send bulk TTP');
      }
      console.error(e);
      throw e;
    }
  }

  async sendBulkSMS(colRecIds: number[], message: string) {
    try {
      const { data } = await this.axios.post('/Collections/SendBulkSMS', {
        colrecids: colRecIds,
        message: message,
      });
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to send bulk SMS');
      }
      console.error(e);
      throw e;
    }
  }

  async sendBulkEmail(colRecIds: number[], subject: string, body: string) {
    try {
      const { data } = await this.axios.post<ApiResponse<SendBulkEmailResponse>>(
        '/Collections/SendBulkEmail',
        {
          colrecids: colRecIds,
          subject: subject,
          body: body,
        }
      );
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to send bulk Email');
      }
      console.error(e);
      throw e;
    }
  }

  async sendEmail(colRecId: number, subject: string, body: string) {
    try {
      const { data } = await this.axios.post('/Collections/SendEmail', {
        colrecid: colRecId,
        subject: subject,
        body: body,
      });
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to send Email');
      }
      console.error(e);
      throw e;
    }
  }

  async getDynamicData() {
    try {
      const { data } = await this.axios.get<DynamicData[]>('/System/DynamicData');
      return data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to get dynamic data');
      }
      console.error(e);
      throw e;
    }
  }

  async getOutForReposPerApp(payload: OutForRepoReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<GridResponse<OrderForRepoRowData>>>(
        `/Collections/OutForRepoListPerApp`,
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load out for repo data');
      throw e;
    }
  }

  async updateOutForRepoOrder(payload: OrderForRepoSubmitReq) {
    try {
      const { data } = await this.axios.post(`/Collections/UpdateRepoOrder`, payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Failed to load out for repo data');
      throw e;
    }
  }

  async getTextTemplates(compId: number): Promise<TextTemplate[]> {
    try {
      const { data } = await this.axios.get<{ data: TextTemplate[] }>(
        '/Collections/GetTextTemplates',
        {
          params: { compId },
        }
      );
      return data.data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to get text templates');
      }
      console.error(e);
      throw e;
    }
  }

  async getEmailTemplates(compId: number): Promise<EmailTemplate[]> {
    try {
      const { data } = await this.axios.get<{ data: EmailTemplate[] }>(
        '/Collections/GetEmailTemplates',
        {
          params: { compId },
        }
      );

      return data.data;
    } catch (e: any) {
      if (e.response.status !== 400) {
        toast.error('Unable to get text templates');
      }
      console.error(e);
      throw e;
    }
  }

  async getColRecId(appRecId: number, colType: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<number>>('/Collections/ColRecId', {
        params: { appRecId, colType },
      });
      return data.data!;
    } catch (e) {
      toast.error(`Error fetching colRecId for application ${appRecId}`);
      console.error(e);
      throw e;
    }
  }
}

/** @deprecated scope to collections */
interface GetCollectionsQueueResponse {
  data: CollectionQueue[];
}

/** @deprecated scope to collections */
interface CollectionQueueCommon {
  queueName: string;
  queueType: number;
  visibility: string;
  includeOutForRepo: boolean;
  includeInOurPossession: boolean;
  includeFieldCall: boolean;
  includeSidenotes: boolean;
  includeFinanced: boolean;
  includeLease: boolean;
  includeDepositDown: boolean;
  includePaymentArrangements: boolean;
  includeAppointments: boolean;
  daysFrom: number;
  daysTo: number;
  alphaFrom: string;
  alphaTo: string;
  userId?: number | null;
  autoTTP: boolean;
  autoTTPLocalTime: string | undefined;
  autoTTPTemplate: string;
  sortColumn?: string | null;
}

/** @deprecated scope to collections */
export interface CollectionQueue extends CollectionQueueCommon {
  recid: number;
  orgid: number;
  locid: number;
  compid: number;
  autoTTPLastRunTime?: string | null;
  total: number;
  percentage: number;
  sortDirection?: 'Ascending' | 'Descending';
}
/** @deprecated scope to collections */
export interface CollectionQueuePayload extends CollectionQueueCommon {
  orgid: number;
  locid: number;
  compid: number;
  sortDirection: string | null;
}

/** @deprecated scope to collections */
export interface UpdateCollectionQueuePayload extends CollectionQueueCommon {
  recid: number;
  sortDirection: string | null;
}

/** @deprecated use zod enum */
export enum QueueType {
  RegularAccounts = 'Regular Accounts',
  PaymentArrangements = 'Payment Arrangements',
  Appointments = 'Appointments',
}

/** @deprecated scope to collections */
export enum QueueVisibility {
  Public = 'Public',
  Personal = 'Personal',
  Company = 'Company',
}

/** @deprecated scope to collections */
export const getQueueTypeIndex = (e: QueueType): number => {
  return Object.values(QueueType).indexOf(e);
};

/** @deprecated scope to collections */
export const getQueueFromIndex = (e: number): QueueType | undefined => {
  return Object.values(QueueType)[e];
};

/** @deprecated scope to collections */
export interface CollectionsAccount {
  recId: number;
  colType: string;
  gpsCode: number; // maybe boolean check with virginia
  starterInterruptCode: number; //might need for status
  contactStatus: string; //status of contact for the day (green, yellow, red)
  buyers: string; //buyers name
  lastPmtAmt: number; //last payment amount
  lastPmtPaid: string; //last payment date
  schedule: string; //payment schedule (weekly, bi-weekly, monthly)
  pmtAmt: number; //typical payment amount
  prinBal?: any; //principal balance
  pmtDue: number; //payment due
  daysLate: number; //days late
  tCall: string; //call status "if they have been called"?
  tEmail: string;
  tText: string;
  tStatement: string; //text to pay
  tRobo: string; //robo call
  stockNum: string;
  bNoCall: boolean;
  bNoEmail: boolean;
  bNoText: boolean;
}

/** @deprecated scope to collections */
export interface CollectionsByQueueResponse {
  queue: CollectionQueue;
  queueAccounts: CollectionsAccount[];
}

/** @deprecated scope to collections */
export interface Collection {
  recId: number;
  collectionType: string;
  gps: boolean;
  starterInterruptCode: boolean;
  contactStatus: 'green' | 'yellow' | 'red';
  buyers: string[];
  lastPaymentAmount: number;
  lastPaymentDate: string;
  paymentSchedule: 'weekly' | 'bi-weekly' | 'monthly';
  paymentAmount: number;
  principalBalance: number;
  paymentDue: number;
  daysLate: number;
  called: boolean;
  emailed: boolean;
  texted: boolean;
  statement: boolean;
  roboCalled: boolean;
  stockNum: string;
  noCall: boolean;
  noEmail: boolean;
  noText: boolean;
}

/** @deprecated scope to collections */
export const getCollectionsFromResponse = (e: CollectionsAccount): Collection => {
  return {
    recId: e.recId,
    collectionType: e.colType,
    gps: e.gpsCode === 1,
    starterInterruptCode: e.starterInterruptCode === 1,
    contactStatus: e.contactStatus as 'green' | 'yellow' | 'red',
    buyers: e.buyers.split(','),
    lastPaymentAmount: e.lastPmtAmt,
    lastPaymentDate: e.lastPmtPaid,
    paymentSchedule: e.schedule as 'weekly' | 'bi-weekly' | 'monthly',
    paymentAmount: e.pmtAmt,
    principalBalance: e.prinBal,
    paymentDue: e.pmtDue,
    daysLate: e.daysLate,
    called: e.tCall === '1',
    emailed: e.tEmail === '1',
    texted: e.tText === '1',
    statement: e.tStatement === '1',
    roboCalled: e.tRobo === '1',
    stockNum: e.stockNum,
    noCall: e.bNoCall,
    noEmail: e.bNoEmail,
    noText: e.bNoText,
  };
};

/** @deprecated scope to collections */
export interface DynamicData {
  code: string;
  description: string;
  sample: string;
}

/** @deprecated scope to collections */
export interface TextTemplate {
  recId: number;
  orgId: number;
  compId: number;
  locId: number;
  category: 'Collections' | 'Sales';
  name: string;
  body: string;
  tdesc: string;
  tbody: string;
  convRecId?: any;
}

/** @deprecated scope to collections */
export interface EmailTemplate {
  recId: number;
  orgId: number;
  compId: number;
  locId: number;
  category: string;
  name: string;
  body: string;
  subject: string;
  tdesc: string;
  tbody: string;
  convRecId?: any;
  recType: string;
  css: string;
}

/** @deprecated scope to collections */
export interface SendBulkEmailResponse {
  message: string;
  collectionEmails: CollectionEmail[];
}

/** @deprecated scope to collections */
export interface CollectionEmail {
  key: number;
  value: string;
}

export const collectionsService = new CollectionsService();
