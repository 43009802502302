import { toast } from 'react-toastify';
import { BlobServiceClient } from '@azure/storage-blob';
// utils
import { AxiosService } from '@services/axiosService';
// interfaces
import {
  GpsDevice,
  PasstimeGetLastLocateRes,
} from '@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces';
import { ApiResponse } from '@/interfaces/Api';
import {
  FormIds,
  GenericPasstimeResponse,
  GetPasstimeCustomerResponse,
  GetPasstimeGpsHistoryResponse,
  PasstimeCodesPayload,
  PasstimeTotalMilesResponse,
  PasstimeWarnPayload,
  PhotoInfo,
  PhotoOutput,
  UserInfo,
} from '@/interfaces/System';

class SystemService extends AxiosService {
  public constructor() {
    super();
  }

  async getGpsModels(provider: GpsDevice) {
    try {
      const { data } = await this.axios.get<{ recId: number; model: string; provider: string }[]>(
        '/System/GpsModels',
        {
          params: { provider },
        }
      );
      return data ? data.map((d) => d.model) : [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getPasstimeTotalMiles(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<PasstimeTotalMilesResponse>>(
        '/System/GetPasstimeTotalMiles',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Passtime total miles');
      throw e;
    }
  }

  async setPasstimeTotalMiles(inventoryRecId: number, totalMiles: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/SetPasstimeTotalMiles',
        { inventoryRecId, totalMiles }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to set Passtime total miles');
      throw e;
    }
  }

  async addPasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/AddPasstimeCustomer',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to add Passtime customer');
      throw e;
    }
  }

  async getPasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetPasstimeCustomerResponse>>(
        '/System/GetPasstimeCustomer',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Passtime customer data');
      throw e;
    }
  }

  async updatePasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/UpdatePasstimeCustomer',
        {},
        { params: { inventoryRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error(`Unable to update Passtime customer with invRecId: ${inventoryRecId}`);
      throw e;
    }
  }

  async deletePasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/DeletePasstimeCustomer',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to delete Passtime customer');
      throw e;
    }
  }

  async updatePasstimeMap(inventoryRecId: number) {
    try {
      const res = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/UpdatePasstimeMap',
        {},
        { params: { inventoryRecId } }
      );
      if (!res.data.data?.success) {
        console.error(res.data.data);
        throw new Error('Passtime error');
      }
    } catch (e) {
      toast.error('Passtime error');
      console.error(e);
      throw e;
    }
  }

  async getPasstimeLastLocate(inventoryRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<PasstimeGetLastLocateRes<string>>>(
        '/System/GetPasstimeLastLocate',
        { params: { inventoryRecId } }
      );

      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get GPS last location (PassTime)');
      throw e;
    }
  }

  async getPasstimeGpsHistoryByDate(inventoryRecId: number, beginDate: string, endDate: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetPasstimeGpsHistoryResponse>>(
        '/System/GetPasstimeGpsHistoryByDate',
        {
          params: { inventoryRecId, beginDate, endDate },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Passtime customer GPS history');
      throw e;
    }
  }

  async enableDisablePasstimeDevice(inventoryRecId: number, enable: boolean) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/EnableDisablePasstimeDevice',
        {},
        { params: { inventoryRecId, enable } }
      );
      if (!data.data?.success) throw new Error();
      toast.success(`Vehicle ${enable ? 'enabled' : 'disabled'}`);
    } catch (e) {
      console.error(e);
      toast.error(
        `Unable to ${enable ? 'enable' : 'disable'} passtime device for invRecId: ${inventoryRecId}`
      );
      throw e;
    }
  }

  async sendPasstimeEmergencyCode(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/SendPasstimeEmergencyCode',
        {},
        {
          params: { inventoryRecId },
        }
      );
      if (!data.data?.success) throw new Error();
    } catch (e) {
      console.error(e);
      toast.error(`Unable to send Passtime emergency code for invRecId: ${inventoryRecId}`);
      throw e;
    }
  }

  async sendPasstimeWarningNoDisable(payload: PasstimeWarnPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/SendPasstimeWarningNoDisable',
        payload
      );
      if (!data.data?.success) throw new Error();
    } catch (e) {
      console.error(e);
      toast.error(`Unable to send Passtime warning`);
      throw e;
    }
  }

  async generatePasstimeCode(payload: PasstimeCodesPayload) {
    // No one is sure what this route is for ... but it exists
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        '/System/GeneratePasstimeCodes',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error(`Unable to generate passtime codes`);
      throw e;
    }
  }

  async getBlobUploadUrl(orgId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>('/System/GetSasUploadUrl', {
        params: { orgId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async uploadBlob(blob: File, orgId: number, fileNameCloud: string) {
    try {
      const sasUrl = await this.getBlobUploadUrl(orgId);
      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient(''); // The container name is already set on the URL that was returned
      const blockBlobClient = containerClient.getBlockBlobClient(fileNameCloud);

      await blockBlobClient.upload(blob, blob.size, {
        blobHTTPHeaders: { blobContentType: blob.type },
      });

      const sasUrlWithoutToken = sasUrl.split('?')[0];
      const fileUrl = `${sasUrlWithoutToken}/${fileNameCloud}`;
      return fileUrl;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getBlobReadUrl(orgId: number, blobName: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>('/System/GetSasReadUrl', {
        params: { orgId, blobName },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getBlobReadUrls(photoList: PhotoInfo[]) {
    try {
      const { data } = await this.axios.post<ApiResponse<PhotoOutput[]>>(
        '/System/GetSasReadUrls',
        photoList
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getUserInfo() {
    try {
      const res = await this.axios.get<ApiResponse<UserInfo>>('/System/UserInfo');
      if (!res.data.data) throw new Error('UserInfo response is empty');

      return res.data.data;
    } catch (e) {
      toast.error('Error fetching user info');
      console.error(e);
      throw e;
    }
  }

  async getFormIds(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<FormIds>>('/System/FormIds', {
        params: { compId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async mappedCompanies() {
    try {
      const { data } = await this.axios.get<ApiResponse<[]>>('/System/MappedCompanies');
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSignalrToken() {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>('/System/GetSignalrToken');
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export const systemService = new SystemService();
