import { GridResponse, ServerGridOptions } from '@/interfaces/ServerGrids';
import { SortDescriptor, State as DataQueryState } from '@progress/kendo-data-query';
import {
  GridPageChangeEvent,
  GridPagerSettings,
  GridSortChangeEvent,
  PagerTargetEvent,
} from '@progress/kendo-react-all';
import { useCallback, useState } from 'react';
import { useDebounceEffect } from '../useDebounce/useDebounce';

export type CustomGridParams = { [key: string]: unknown };

interface UseServerGridProps<T> {
  fetchFunction: (
    options: ServerGridOptions,
    customParams?: CustomGridParams
  ) => Promise<GridResponse<T>>;
  initialSortOptions?: SortDescriptor[];
  initialPageOptions?: DataQueryState;
}

interface UseServerGridState<T> {
  loading: boolean;
  data: T[];
  total: number;
  sort: SortDescriptor[];
  page: DataQueryState;
  onPageChange: (event: GridPageChangeEvent) => void;
  onSortChange: (event: GridSortChangeEvent) => void;
  pageable: GridPagerSettings;
  search: string;
  setSearch: (value: string) => void;
  resetGridOptions: () => void;
  reload: () => Promise<void>;
  setCustomParams: (params: CustomGridParams) => void;
}

export function useServerGrid<T>(props: UseServerGridProps<T>): UseServerGridState<T> {
  const [data, setData] = useState<T[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [customParams, setCustomParams] = useState<CustomGridParams>({});

  const [loading, setLoading] = useState<boolean>(false);

  const [sort, setSort] = useState<SortDescriptor[]>(props.initialSortOptions ?? []);
  const [pageSizeValue, setPageSizeValue] = useState<number>(props.initialPageOptions?.take ?? 10);
  const [page, setPage] = useState<DataQueryState>(
    props.initialPageOptions ?? { skip: 0, take: 10 }
  );
  const [search, setSearch] = useState<string>('');

  const loadData = useCallback(async () => {
    setLoading(true);
    const options = new ServerGridOptions(sort, page, search);
    const response = await props.fetchFunction(options, customParams);
    setTotal(response.total);
    setData(response.data);
    setLoading(false);
  }, [setLoading, sort, page, search, setTotal, setData, customParams]);

  const onPageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage({
      ...event.page,
      take,
    });
  };

  const onSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort);
  };

  const resetGridOptions = () => {
    setSearch('');
    setSort(props.initialSortOptions ?? []);
    setPageSizeValue(props.initialPageOptions?.take ?? 10);
    setPage(props.initialPageOptions ?? { skip: 0, take: 10 });
  };

  // reset pagination when search text is changed
  useDebounceEffect(
    () => {
      setPage(props.initialPageOptions ?? { skip: 0, take: 10 });
    },
    [search],
    750
  );

  // reload data when sorting, paging, or search text is changed
  useDebounceEffect(loadData, [sort, page, pageSizeValue, customParams], 500);

  return {
    loading,
    data,
    total: total ?? 0,
    page,
    sort,
    onPageChange,
    onSortChange,
    pageable: {
      buttonCount: 4,
      pageSizes: [10, 25, 50],
      pageSizeValue: pageSizeValue,
    },
    search,
    setSearch,
    resetGridOptions,
    reload: loadData,
    setCustomParams,
  };
}
