import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

const useChangeFieldListener = () => {
  const { isAch, isCc, isPrincipalOnly, totalPayment } = useWsPmtsFormCtx((s) => s);
  const { control, setValue } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { CarPmt, TotalReceived = 0, PaidBy } = useWatch({ control });

  useEffect(() => {
    // Can't have change due if paying with card / ACH
    const totalPaid = isPrincipalOnly ? CarPmt! : totalPayment;
    if (!(isCc || isAch) && TotalReceived > totalPayment) {
      setValue('Change', TotalReceived - totalPaid);
    } else {
      setValue('Change', 0);
    }
  }, [TotalReceived, totalPayment, PaidBy]);
};

export default useChangeFieldListener;
