import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {   Button, PhoneInput, TextInput, ZipInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton, Typography } from '@mui/material';
import { Icons } from '@/components/icons';
import { CreditEducationInfo, CreditRepairCloud } from '@/interfaces/CreditEducation';
import creditEducationStyles from '../CreditEducation.module.scss';
import { applicationService } from '@/services/applicationService';
import { toast } from 'react-toastify';
import { useCreditEducationCtx } from '../CreditEducationProvider';
import { FiRefreshCcw } from 'react-icons/fi';
import { FiCheck } from 'react-icons/fi';
import TitleHeader from '../components/TitleHeader';

interface CreditRepairCloudProps {
  isUpdate: boolean;
  formData?: CreditEducationInfo | null | undefined;
}

const defaultFormValues = {
  creditEducationID: '',
  creditRepairCloudID: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: undefined,
  dateOfBirth: '',
  SSNLast4: '',
  phoneNumber: '',
  homePhoneNumber: '',
  workPhoneNumber: '',
  workPhoneExtension: '',
  faxNumber: '',
  email: '',
  currentAddress: '',
  currentCity: '',
  currentState: '',
  currentZipCode: '',
  previousAddress: '',
  previousCity: '',
  previousState: '',
  previousZipCode: '',
} as CreditRepairCloud;

const CreditRepairCloudForm = (props: CreditRepairCloudProps) => {
  const { formData, isUpdate } = props;
  const [showOptionalInformation, setShowOptionalInformation] = useState(false);
  const [showCreditRepairCloudID, setShowCreditRepairCloudID] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { setShowCreditRepairForm } = useCreditEducationCtx((s) => s);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<CreditRepairCloud>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const isDisabled = isSaving || !isDirty;

  useEffect(() => {
    reset(formData ?? defaultFormValues);
  }, []);

  const onSubmit = (creditEducationPayload: CreditRepairCloud) => {
    setIsSaving(true);

    applicationService
      .postCreditRepairCloud(creditEducationPayload)
      .then((res) => {
        if (isUpdate) {
          toast.success('Credit Repair Cloud updated!');
        } else {
          toast.success('Credit Repair Cloud created!');
        }
        setShowCreditRepairForm(false);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onIdSubmit = (creditEducationPayload: CreditRepairCloud) => {
    console.log('ID Submit: ', creditEducationPayload);
    console.log('ID Submit: ', creditEducationPayload.creditRepairCloudID);
  };

  return (
    <Grid container direction="column" component="form" onSubmit={handleSubmit(onSubmit)} flex={1}>
      <Grid container direction="row" flex={1} gap={4}>
        <Grid container direction="column" flex={1} gap={1}>
          <Grid container direction="row" flex={1} gap={2}>
            <Grid
              container
              direction="column"
              flex={1}
              alignItems={'center'}
              gap={2}
              flexWrap={'wrap'}
            >
              <Icons.CreditRepairCloud />
            </Grid>
          </Grid>
          <Spacer size={5} />
        </Grid>
        <Grid container direction="column" flex={1} gap={1}></Grid>
      </Grid>

      <Grid container direction="row" flex={1} gap={4} marginY={3}>
        <Grid container direction="column" flex={1} gap={2}>
          <Controller
            name="creditEducationID"
            control={control}
            render={({ field }) => <TextInput hidden {...field} />}
          />
          <Controller
            name="firstName"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={11} gap={2}>
                  <TextInput
                    label="First Name"
                    required
                    errors={errors.firstName?.message}
                    {...field}
                  />
                </Grid>
                {isUpdate && (
                  <Grid container direction="column" flex={1} gap={2}>
                    <IconButton
                      type="button"
                      sx={{
                        color: '#FFF',
                      }}
                      size="medium"
                      className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                      onClick={() => setValue('firstName', formData?.firstName)}
                      disabled={field.value == formData?.firstName}
                    >
                      <FiRefreshCcw />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={11} gap={2}>
                  <TextInput
                    label="Last Name"
                    required
                    errors={errors.lastName?.message}
                    {...field}
                  />
                </Grid>
                {isUpdate && (
                  <Grid container direction="column" flex={1} gap={2}>
                    <IconButton
                      type="button"
                      sx={{
                        color: '#FFF',
                      }}
                      size="medium"
                      className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                      onClick={() => setValue('lastName', formData?.lastName)}
                      disabled={field.value == formData?.lastName}
                    >
                      <FiRefreshCcw />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          />
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={11} gap={2}>
                  <DateInput
                    label="Date of Birth"
                    required
                    errors={errors.dateOfBirth?.message}
                    {...field}
                  />
                </Grid>
                {isUpdate && (
                  <Grid container direction="column" flex={1} gap={2}>
                    <IconButton
                      type="button"
                      sx={{
                        color: '#FFF',
                      }}
                      size="medium"
                      className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                      onClick={() => setValue('dateOfBirth', formData?.dateOfBirth)}
                      disabled={field.value == formData?.dateOfBirth}
                    >
                      <FiRefreshCcw />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          />
          <Controller
            name="SSNLast4"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={11} gap={2}>
                  <TextInput
                    label="SSN Last 4"
                    required
                    errors={errors.SSNLast4?.message}
                    {...field}
                  />
                </Grid>
                {isUpdate && (
                  <Grid container direction="column" flex={1} gap={2}>
                    <IconButton
                      type="button"
                      sx={{
                        color: '#FFF',
                      }}
                      size="medium"
                      className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                      onClick={() => setValue('SSNLast4', formData?.SSNLast4)}
                      disabled={field.value == formData?.SSNLast4}
                    >
                      <FiRefreshCcw />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={11} gap={2}>
                  <PhoneInput
                    label="Phone (Cell)"
                    required
                    errors={errors.phoneNumber?.message}
                    {...field}
                  />
                </Grid>
                {isUpdate && (
                  <Grid container direction="column" flex={1} gap={2}>
                    <IconButton
                      type="button"
                      sx={{
                        color: '#FFF',
                      }}
                      size="medium"
                      className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                      onClick={() => setValue('phoneNumber', formData?.phoneNumber)}
                      disabled={field.value == formData?.phoneNumber}
                    >
                      <FiRefreshCcw />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          />
        </Grid>

        <Grid container direction="column" flex={1} gap={2}>
          <Typography>
            <b>Important:</b> Confirm that all required fields are populated before proceeding with
            enrollment
          </Typography>
          {!isUpdate && (
            <>
              <Typography>
                Create a new CreditRepairCloud.com enrollment, or reuse an existing Client ID?
              </Typography>
              <Grid container direction="row" flex={1} gap={2}>
                <Grid container direction="column" flex={1} gap={2}></Grid>
                <Grid container direction="column" flex={1} gap={2}>
                  <Button label="Create New" loading={isSaving} />
                </Grid>
                <Grid container direction="column" flex={1} gap={2}>
                  <Button
                    label="Existing"
                    type="button"
                    onClick={() => setShowCreditRepairCloudID(!showCreditRepairCloudID)}
                  />
                </Grid>
                <Grid container direction="column" flex={1} gap={2}></Grid>
              </Grid>
              {showCreditRepairCloudID && (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={1} gap={2}>
                    <Controller
                      name="creditRepairCloudID"
                      control={control}
                      render={({ field }) => (
                        <Grid container direction="row" flex={1} gap={2}>
                          <Grid container direction="column" flex={11} gap={2}>
                            <TextInput label="Credit Repair Cloud ID" {...field} />
                          </Grid>
                          <Grid container direction="column" flex={1} gap={2}>
                            <IconButton
                              type="button"
                              sx={{
                                color: '#FFF',
                              }}
                              size="medium"
                              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                              onClick={() => onIdSubmit(control._formValues as any)}
                            >
                              <FiCheck />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <TitleHeader label="Optional Information" />

      {showOptionalInformation && (
        <Grid container direction="row" flex={1} gap={4} marginY={3}>
          <Grid container direction="column" flex={1} gap={2}>
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Middle Name" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('middleName', formData?.middleName)}
                        disabled={field.value == formData?.middleName}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="suffix"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Suffix" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('suffix', formData?.suffix)}
                        disabled={field.value == formData?.suffix}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="homePhoneNumber"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <PhoneInput label="Home Phone" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('homePhoneNumber', formData?.homePhoneNumber)}
                        disabled={field.value == formData?.homePhoneNumber}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="workPhoneNumber"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <PhoneInput label="Work Phone" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('workPhoneNumber', formData?.workPhoneNumber)}
                        disabled={field.value == formData?.workPhoneNumber}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="workPhoneExtension"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Work Phone Ext." {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('workPhoneExtension', formData?.workPhoneExtension)}
                        disabled={field.value == formData?.workPhoneExtension}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
          </Grid>

          <Grid container direction="column" flex={1} gap={2}>
            <Controller
              name="currentAddress"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Current Address" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('currentAddress', formData?.currentAddress)}
                        disabled={field.value == formData?.currentAddress}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="currentCity"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Current City" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('currentCity', formData?.currentCity)}
                        disabled={field.value == formData?.currentCity}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="currentState"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <TextInput label="Current State" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('currentState', formData?.currentState)}
                        disabled={field.value == formData?.currentState}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="currentZipCode"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <ZipInput label="Current Zip" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('currentZipCode', formData?.currentZipCode)}
                        disabled={field.value == formData?.currentZipCode}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
            <Controller
              name="faxNumber"
              control={control}
              render={({ field }) => (
                <Grid container direction="row" flex={1} gap={2}>
                  <Grid container direction="column" flex={11} gap={2}>
                    <PhoneInput label="Fax" {...field} />
                  </Grid>
                  {isUpdate && (
                    <Grid container direction="column" flex={1} gap={2}>
                      <IconButton
                        type="button"
                        sx={{
                          color: '#FFF',
                        }}
                        size="medium"
                        className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
                        onClick={() => setValue('faxNumber', formData?.faxNumber)}
                        disabled={field.value == formData?.faxNumber}
                      >
                        <FiRefreshCcw />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      )}

      <a
        className={creditEducationStyles.anchorText}
        onClick={() => setShowOptionalInformation((prev) => !prev)}
      >
        {showOptionalInformation ? 'Collapse' : 'Expand'} Information
      </a>

      <Grid container direction="row" justifyContent="flex-end" marginTop={4}>
        <Button
          label="Submit"
          loading={isSaving}
          disabled={isDisabled}
          secondary={!isDirty}
          type="submit"
        />
        <Spacer horizontal={true} size={10} />
        <Button
          label="Cancel"
          type="button"
          onClick={() => {
            reset();
            setShowCreditRepairForm(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CreditRepairCloudForm;
