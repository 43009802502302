import React, { forwardRef, useMemo, useState } from 'react';
import styles from '@components/inputs/maskedTextInput/MaskedTextInput.module.scss';
import { Label } from '@progress/kendo-react-all';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import textStyles from '@components/text/Text.module.scss';

export interface MaskedTextInputProps extends MaskedInputProps {
  label?: string;
  errors?: string | boolean;
  value?: string;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  inputStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  obscureUnlessFocused?: boolean;
}

export const MaskedTextInput = forwardRef<MaskedInput, MaskedTextInputProps>((props, _) => {
  // Not using the ref passed in from react-hook-form.
  // MaskedInput puts it's input ref on `ref.current.inputElement` which causes issues when react-hook-form tries to focus it
  // Still leaving this as forwardRef to suppress warnings from react-hook-form
  const {
    name,
    placeholder,
    guide = false,
    label,
    errors,
    required,
    boldLabel = true,
    horizontalLabel = true,
    inputStyles,
    containerStyles = {},
    obscureUnlessFocused,
    onFocus = () => null,
    onBlur = () => null,
    ...rest
  } = props;

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // call the original onFocus in case one was passed
    onFocus(e);
    // next, handle switching the input type if we need to
    if (!obscureUnlessFocused) return;
    setInputType('text');
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // call the original onBlur in case one was passed
    onBlur(e);
    // next, handle switching the input type if we need to
    if (!obscureUnlessFocused) return;
    setInputType('password');
  };

  const [inputType, setInputType] = useState(obscureUnlessFocused ? 'password' : 'text');

  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  const Input = (
    <MaskedInput
      id={editorId}
      className={errors ? styles.inputBoxError : styles.inputBox}
      placeholder={placeholder}
      style={inputStyles}
      guide={guide}
      type={inputType}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
    />
  );

  return (
    <div className={styles.textContainer} style={containerStyles}>
      {label ? (
        <div className={horizontalLabel ? textStyles.row : undefined}>
          <Label
            editorId={editorId}
            className={textStyles.label}
            style={{ fontWeight: boldLabel ? 700 : 400 }}
          >
            {`${label} ${required ? '*' : ''}`}
          </Label>
          <div className={styles.inputBoxContainer}>{Input}</div>
        </div>
      ) : (
        <div className={styles.inputBoxContainer}>{Input}</div>
      )}
      {!!errors && typeof errors === 'string' && (
        <div className={styles.errorMessage}>{errors}</div>
      )}
    </div>
  );
});
