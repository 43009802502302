// utils
import { collectionsService } from '@/services/collectionsService';
import { paymentService } from '@/services/paymentService';
import { getPaymentProviderArray, getPreferredPaymentProviderName } from '@/utils/helpers/payment';
// interfaces
import { IWsPmtsViewCtx } from './WsPmtsViewProvider';
import { IWsPmtsFormCtx } from './WsCollectForm/WsPmtsFormProvider';

/** ### Fetch `colRecId` from API using `appRecId` from browser-router URL params */
export const loadColRecId = async (
  appRecId: string,
  setColRecId: IWsPmtsViewCtx['setColRecId'],
  setSavedPaymentMethods: (v: IWsPmtsFormCtx['savedPaymentMethods']) => void
): Promise<number> => {
  try {
    const colRecIdRes = await collectionsService.getColRecId(Number(appRecId), 'WS');
    setColRecId(colRecIdRes);

    const paymentMethodsRes = await paymentService.getSavedPaymentMethods(Number(appRecId));
    setSavedPaymentMethods(paymentMethodsRes ?? []);

    return colRecIdRes;

    // Error is currently handled in the request method
  } catch (error) {
    throw error;
  }
};

/** ### Fetch Payment Providers from API using `colRecId` (from loadColRecId), and set related states */
export const loadPaymentProviderStates = async (
  colRecId: IWsPmtsViewCtx['colRecId'],
  setPaymentProviderData: IWsPmtsViewCtx['setPaymentProviderData'],
  setEnabledProviders: IWsPmtsViewCtx['setEnabledProviders'],
  setProvider: IWsPmtsViewCtx['setProvider']
  // @todo add
  // setProviderRes: IWsPmtsViewCtx['setProviderRes']
) => {
  if (colRecId === null) return;

  try {
    const paymentProviders = await paymentService.getPaymentProviders(colRecId!);
    setPaymentProviderData(paymentProviders);
    const enabledProviders = getPaymentProviderArray(paymentProviders);
    const preferredProviderName = getPreferredPaymentProviderName(
      paymentProviders.preferredPaymentProvider,
      enabledProviders
    );
    setEnabledProviders(enabledProviders);
    setProvider(preferredProviderName);
    // setProviderRes(preferredProviderNameRes);

    // @note Error is currently handled in the request method
  } catch (error) {}
};

export const loadPaymentDetailState = async (
  colRecId: IWsPmtsViewCtx['colRecId'],
  setPaymentData: IWsPmtsViewCtx['setPaymentData']
) => {
  if (colRecId === null) return;

  try {
    const paymentDetailsRes = await paymentService.getPaymentDetails(colRecId!);
    setPaymentData(paymentDetailsRes);

    // @note Error is currently handled in the request method
  } catch (error) {}
};
