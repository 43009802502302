import React, { useRef } from 'react';
import styles from './FileInput.module.scss';
import { Label } from '@progress/kendo-react-all';
import textStyles from '@components/text/Text.module.scss';
import {  Button, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { toast } from 'react-toastify';

export interface FileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  // If you want to have this component render the error message, pass errors as a string
  // If you only want the red outline around the input, pass as a boolean
  errors?: string | boolean;
  fileName?: string;
  boldLabel?: boolean;
  horizontalLabel?: boolean;
  containerStyles?: React.CSSProperties;
}

export const FileInput = (props: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    label,
    errors,
    fileName = 'No file chosen',
    required,
    boldLabel = true,
    horizontalLabel = true,
    containerStyles = {},
    ...rest
  } = props;

  const triggerInputClick = () => {
    if (!inputRef?.current) {
      toast.error('Unable to open file picker');
      return;
    }

    inputRef.current.click();
  };

  const Wrapper = React.memo(function Wrapper(wrapperProps: any) {
    return (
      <div className={styles.textContainer} style={containerStyles}>
        {label ? (
          horizontalLabel ? (
            <div className={styles.row}>
              <Label className={textStyles.label} style={{ fontWeight: boldLabel ? 700 : 400 }}>
                {`${label} ${required ? '*' : ''}`}
              </Label>
              {wrapperProps.children}
            </div>
          ) : (
            <Label className={textStyles.label} style={{ fontWeight: boldLabel ? 700 : 400 }}>
              {`${label} ${required ? '*' : ''}`}
              {wrapperProps.children}
            </Label>
          )
        ) : (
          <>{wrapperProps.children}</>
        )}
        {!!errors && typeof errors === 'string' && (
          <>
            <Spacer size={3} />
            <div className={styles.errorMessage}>{errors}</div>
          </>
        )}
      </div>
    );
  });

  return (
    <Wrapper>
      <div
        className={errors ? styles.inputBoxContainerError : styles.inputBoxContainer}
        onClick={triggerInputClick}
      >
        <input type="file" style={{ display: 'none' }} ref={inputRef} {...rest} />
        <Button secondary label="Select File" buttonStyle={{ padding: '2px 10px' }} type="button" />
        <span>{fileName}</span>
      </div>
    </Wrapper>
  );
};
