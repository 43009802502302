import {
  Application,
  DclUnderwriting,
  DealData,
  FinanceTaxRates,
  Lienholder,
  QuoteData,
  SaleData,
  SalesDocListData,
  VerificationList,
} from '@/interfaces';
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';
import { SaleType } from '@/enums';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesScoringSelected } from './components/underwritingScore/useUnderwritingScore';
import { DeskingNavigation } from './enums';
import { ISaleVehicle } from './detailView/WholesalesForm/VehicleTab/default';
import { ApplicationDb } from './interfaces/application';

const initialState = {
  quoteData: [] as QuoteData[],
  dealData: null as DealData | null,
  numberOfPayments: 0,
  amountPerSchedule: 0,
  paymentSchedule: '',
  dateStarted: '',
  activeQuickQuote: SaleType.Finance,
  residual: 0,
  residualRate: 0,
  totalDepreciation: 0,
  totalRentCharge: 0,
  adjCapCost: 0,
  leasePrice: 0,
  cashDown: 0,
  salesDocList: [] as SalesDocListData[],
  presaleDocList: [] as SalesDocListData[],
  saleData: {} as SaleData,
  applicationData: {} as ApplicationDb, // @note this is not the correct interface
  availableVehicles: [] as ISaleVehicle[],
  saleVehicle: undefined as ISaleVehicle | undefined,
  activeDeskingTab: DeskingNavigation.QuickQuote,
  leaseSellingPrice: 0,
  leaseMmr: 0,
  leaseDueAtInception: 0,
  leaseTerm: 0,
  leaseResidualRate: 0,
  leasePurchaseOption: 1495,
  leaseTax: 0,
  leaseApr: 0,
  leaseTaxRate: 0,
  leaseDownPayment: 0,
  refinanceAfterNumMonths: 0,
  refinanceEstimatedApr: 0,
  refinanceTerm: 0,
  financeTotal: 0,
  financeDownPayment: 0,
  financeApr: 0,
  financeTerm: 0,
  leaseExtraFees: [] as number[],
  leaseMoneyDueAtInceptionFees: [] as number[],
  leaseQuoteName: '',
  leaseQuotePhoneNumber: '',
  leaseQuoteDate: '',
  customField1: undefined as unknown as number,
  customField2: undefined as unknown as number,
  customField3: undefined as unknown as number,
  customField4: undefined as unknown as number,
  customField5: undefined as unknown as number,
  leaseSecurityDeposit: undefined as unknown as number,
  moneyDueAtInceptionField1: undefined as unknown as number,
  moneyDueAtInceptionField2: undefined as unknown as number,
  moneyDueAtInceptionField3: undefined as unknown as number,
  leaseServiceContract: undefined as unknown as number,
  hasCobuyer: false,
  verificationList: [] as VerificationList[],
  verificationListItem: {} as VerificationList,
  showVerificationForm: false,
  lienholders: [] as Lienholder[],
  financeTaxRates: {} as FinanceTaxRates,
  companyUnderwriting: {} as DclUnderwriting,
  underwritingScore: [] as SalesScoringSelected[],
  selectedVehicleId: undefined as number | undefined,
  // Loading States
  quoteDataLoading: false,
  dealDataLoading: false,
  salesDocLoading: false,
  saleDataLoading: false,
  applicationDataLoading: false,
  availableVehiclesLoading: false,
  verificationItemLoading: false,
  financeTaxRatesLoading: false,
};

export type SalesAppState = typeof initialState;

export const slice = createSlice<
  SalesAppState,
  {
    setQuoteData: CaseReducer<SalesAppState, PayloadAction<QuoteData[]>>;
    setDealData: CaseReducer<SalesAppState, PayloadAction<DealData>>;
    setNumberOfPayments: CaseReducer<SalesAppState, PayloadAction<number>>;
    setAmountPerSchedule: CaseReducer<SalesAppState, PayloadAction<number>>;
    setPaymentSchedule: CaseReducer<SalesAppState, PayloadAction<string>>;
    setDateStarted: CaseReducer<SalesAppState, PayloadAction<string>>;
    setActiveQuickQuote: CaseReducer<
      SalesAppState,
      PayloadAction<SaleType.Finance | SaleType.Lease>
    >;
    setResidual: CaseReducer<SalesAppState, PayloadAction<number>>;
    setResidualRate: CaseReducer<SalesAppState, PayloadAction<number>>;
    setTotalDepreciation: CaseReducer<SalesAppState, PayloadAction<number>>;
    setTotalRentCharge: CaseReducer<SalesAppState, PayloadAction<number>>;
    setAdjCapCost: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeasePrice: CaseReducer<SalesAppState, PayloadAction<number>>;
    setCashDown: CaseReducer<SalesAppState, PayloadAction<number>>;
    setSalesDocList: CaseReducer<SalesAppState, PayloadAction<SalesDocListData[]>>;
    setSalesDocInitial: CaseReducer<SalesAppState, PayloadAction<SalesDocListData[]>>;
    setPresaleDocList: CaseReducer<SalesAppState, PayloadAction<SalesDocListData[]>>;
    setPresaleDocListInitial: CaseReducer<SalesAppState, PayloadAction<SalesDocListData[]>>;
    setSaleData: CaseReducer<SalesAppState, PayloadAction<SaleData>>;
    setApplicationData: CaseReducer<SalesAppState, PayloadAction<ApplicationDb>>;
    setAvailableVehicles: CaseReducer<SalesAppState, PayloadAction<ISaleVehicle[]>>;
    setSaleVehicle: CaseReducer<SalesAppState, PayloadAction<ISaleVehicle | undefined>>;
    setActiveDeskingTab: CaseReducer<SalesAppState, PayloadAction<DeskingNavigation>>;
    setLeaseSellingPrice: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseMmr: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseDueAtInception: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseTerm: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseResidualRate: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeasePurchaseOption: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseTax: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseApr: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseTaxRate: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseDownPayment: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseSecurityDeposit: CaseReducer<SalesAppState, PayloadAction<number>>;
    setRefinanceAfterNumMonths: CaseReducer<SalesAppState, PayloadAction<number>>;
    setRefinanceEstimatedApr: CaseReducer<SalesAppState, PayloadAction<number>>;
    setRefinanceTerm: CaseReducer<SalesAppState, PayloadAction<number>>;
    setFinanceTotal: CaseReducer<SalesAppState, PayloadAction<number>>;
    setFinanceDownPayment: CaseReducer<SalesAppState, PayloadAction<number>>;
    setFinanceApr: CaseReducer<SalesAppState, PayloadAction<number>>;
    setFinanceTerm: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseExtraFees: CaseReducer<SalesAppState, PayloadAction<number[]>>;
    setLeaseMoneyDueAtInceptionFees: CaseReducer<SalesAppState, PayloadAction<number[]>>;
    setLeaseQuoteName: CaseReducer<SalesAppState, PayloadAction<string>>;
    setLeaseQuotePhoneNumber: CaseReducer<SalesAppState, PayloadAction<string>>;
    setLeaseQuoteDate: CaseReducer<SalesAppState, PayloadAction<string>>;
    setCustomField1: CaseReducer<SalesAppState, PayloadAction<number>>;
    setCustomField2: CaseReducer<SalesAppState, PayloadAction<number>>;
    setCustomField3: CaseReducer<SalesAppState, PayloadAction<number>>;
    setCustomField4: CaseReducer<SalesAppState, PayloadAction<number>>;
    setCustomField5: CaseReducer<SalesAppState, PayloadAction<number>>;
    setMoneyDueAtInceptionField1: CaseReducer<SalesAppState, PayloadAction<number>>;
    setMoneyDueAtInceptionField2: CaseReducer<SalesAppState, PayloadAction<number>>;
    setMoneyDueAtInceptionField3: CaseReducer<SalesAppState, PayloadAction<number>>;
    setLeaseServiceContract: CaseReducer<SalesAppState, PayloadAction<number>>;
    setHasCobuyer: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setVerificationList: CaseReducer<SalesAppState, PayloadAction<VerificationList[]>>;
    setVerificationListItem: CaseReducer<SalesAppState, PayloadAction<VerificationList>>;
    setShowVerificationForm: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setLienholders: CaseReducer<SalesAppState, PayloadAction<Lienholder[]>>;
    setFinanceTaxRates: CaseReducer<SalesAppState, PayloadAction<FinanceTaxRates>>;
    setCompanyUnderwriting: CaseReducer<SalesAppState, PayloadAction<DclUnderwriting>>;
    setUnderwritingScore: CaseReducer<SalesAppState, PayloadAction<SalesScoringSelected[]>>;
    // Loading States
    setQuoteDataLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setDealDataLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setSalesDocLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setSaleDataLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setApplicationDataLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setAvailableVehiclesLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setVerificationItemLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setFinanceTaxRatesLoading: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    setHasQuote: CaseReducer<SalesAppState, PayloadAction<boolean>>;
    reset: CaseReducer<SalesAppState>;
    setSelectedVehicleId: CaseReducer<SalesAppState, PayloadAction<number | undefined>>;
  }
>({
  name: 'sales',
  initialState,
  reducers: {
    setQuoteData: (state, { payload }) => {
      state.quoteData = payload;
    },
    setDealData: (state, { payload }) => {
      state.dealData = Object.keys(payload).length === 1 ? null : payload;
    },
    setNumberOfPayments: (state, { payload }) => {
      state.numberOfPayments = payload;
    },
    setAmountPerSchedule: (state, { payload }) => {
      state.amountPerSchedule = payload;
    },
    setPaymentSchedule: (state, { payload }) => {
      state.paymentSchedule = payload;
    },
    setDateStarted: (state, { payload }) => {
      state.dateStarted = payload;
    },
    setActiveQuickQuote: (state, { payload }) => {
      state.activeQuickQuote = payload;
    },
    setResidual: (state, { payload }) => {
      state.residual = payload;
    },
    setResidualRate: (state, { payload }) => {
      state.residualRate = payload;
    },
    setTotalDepreciation: (state, { payload }) => {
      state.totalDepreciation = payload;
    },
    setTotalRentCharge: (state, { payload }) => {
      state.totalRentCharge = payload;
    },
    setAdjCapCost: (state, { payload }) => {
      state.adjCapCost = payload;
    },
    setLeasePrice: (state, { payload }) => {
      state.leasePrice = payload;
    },
    setCashDown: (state, { payload }) => {
      state.cashDown = payload;
    },
    setSalesDocList: (state, { payload }) => {
      state.salesDocList = payload;
    },
    setSalesDocInitial: (state, { payload }) => {
      state.salesDocList = payload.map((value) => {
        return { ...value, selected: false };
      });
    },
    setPresaleDocList: (state, { payload }) => {
      state.presaleDocList = payload;
    },
    setPresaleDocListInitial: (state, { payload }) => {
      state.presaleDocList = payload.map((value) => {
        return { ...value, selected: false };
      });
    },
    setSaleData: (state, { payload }) => {
      state.saleData = payload;
    },
    setApplicationData: (state, { payload }) => {
      state.applicationData = payload;
    },
    setAvailableVehicles: (state, { payload }) => {
      state.availableVehicles = payload;
    },
    setSaleVehicle: (state, { payload }) => {
      state.saleVehicle = payload;
    },
    setActiveDeskingTab: (state, { payload }) => {
      state.activeDeskingTab = payload;
    },
    setLeaseSellingPrice: (state, { payload }) => {
      state.leaseSellingPrice = payload;
    },
    setLeaseMmr: (state, { payload }) => {
      state.leaseMmr = payload;
    },
    setLeaseDueAtInception: (state, { payload }) => {
      state.leaseDueAtInception = payload;
    },
    setLeaseTerm: (state, { payload }) => {
      state.leaseTerm = payload;
    },
    setLeaseResidualRate: (state, { payload }) => {
      state.leaseResidualRate = payload;
    },
    setLeasePurchaseOption: (state, { payload }) => {
      state.leasePurchaseOption = payload;
    },
    setLeaseTax: (state, { payload }) => {
      state.leaseTax = payload;
    },
    setLeaseApr: (state, { payload }) => {
      state.leaseApr = payload;
    },
    setLeaseTaxRate: (state, { payload }) => {
      state.leaseTaxRate = payload;
    },
    setLeaseDownPayment: (state, { payload }) => {
      state.leaseDownPayment = payload;
    },
    setLeaseSecurityDeposit: (state, { payload }) => {
      state.leaseSecurityDeposit = payload;
    },
    setRefinanceAfterNumMonths: (state, { payload }) => {
      state.refinanceAfterNumMonths = payload;
    },
    setRefinanceEstimatedApr: (state, { payload }) => {
      state.refinanceEstimatedApr = payload;
    },
    setRefinanceTerm: (state, { payload }) => {
      state.refinanceTerm = payload;
    },
    setFinanceTotal: (state, { payload }) => {
      state.financeTotal = payload;
    },
    setFinanceDownPayment: (state, { payload }) => {
      state.financeDownPayment = payload;
    },
    setFinanceApr: (state, { payload }) => {
      state.financeApr = payload;
    },
    setFinanceTerm: (state, { payload }) => {
      state.financeTerm = payload;
    },
    setLeaseExtraFees: (state, { payload }) => {
      state.leaseExtraFees = payload;
    },
    setLeaseMoneyDueAtInceptionFees: (state, { payload }) => {
      state.leaseMoneyDueAtInceptionFees = payload;
    },
    setLeaseQuoteName: (state, { payload }) => {
      state.leaseQuoteName = payload;
    },
    setLeaseQuotePhoneNumber: (state, { payload }) => {
      state.leaseQuotePhoneNumber = payload;
    },
    setLeaseQuoteDate: (state, { payload }) => {
      state.leaseQuoteDate = payload;
    },
    setCustomField1: (state, { payload }) => {
      state.customField1 = payload;
    },
    setCustomField2: (state, { payload }) => {
      state.customField2 = payload;
    },
    setCustomField3: (state, { payload }) => {
      state.customField3 = payload;
    },
    setCustomField4: (state, { payload }) => {
      state.customField4 = payload;
    },
    setCustomField5: (state, { payload }) => {
      state.customField5 = payload;
    },
    setMoneyDueAtInceptionField1: (state, { payload }) => {
      state.moneyDueAtInceptionField1 = payload;
    },
    setMoneyDueAtInceptionField2: (state, { payload }) => {
      state.moneyDueAtInceptionField2 = payload;
    },
    setMoneyDueAtInceptionField3: (state, { payload }) => {
      state.moneyDueAtInceptionField3 = payload;
    },
    setLeaseServiceContract: (state, { payload }) => {
      state.leaseServiceContract = payload;
    },
    setHasCobuyer: (state, { payload }) => {
      state.hasCobuyer = payload;
    },
    setVerificationList: (state, { payload }) => {
      state.verificationList = payload;
    },
    setVerificationListItem: (state, { payload }) => {
      state.verificationListItem = payload;
    },
    setShowVerificationForm: (state, { payload }) => {
      state.showVerificationForm = payload;
    },
    setLienholders: (state, { payload }) => {
      state.lienholders = payload;
    },
    setFinanceTaxRates: (state, { payload }) => {
      state.financeTaxRates = {
        ...payload,
        stateRate: (payload.stateRate || 0) / 100,
        cityRate: (payload.cityRate || 0) / 100,
        countyRate: (payload.countyRate || 0) / 100,
        locationRate: (payload.locationRate || 0) / 100,
      };
    },
    setCompanyUnderwriting: (state, { payload }) => {
      state.companyUnderwriting = payload;
    },
    setUnderwritingScore: (state, { payload }) => {
      state.underwritingScore = payload;
    },
    // Loading States
    setQuoteDataLoading: (state, { payload }) => {
      state.quoteDataLoading = payload;
    },
    setDealDataLoading: (state, { payload }) => {
      state.dealDataLoading = payload;
    },
    setSalesDocLoading: (state, { payload }) => {
      state.salesDocLoading = payload;
    },
    setSaleDataLoading: (state, { payload }) => {
      state.saleDataLoading = payload;
    },
    setApplicationDataLoading: (state, { payload }) => {
      state.applicationDataLoading = payload;
    },
    setAvailableVehiclesLoading: (state, { payload }) => {
      state.availableVehiclesLoading = payload;
    },
    setVerificationItemLoading: (state, { payload }) => {
      state.verificationItemLoading = payload;
    },
    setFinanceTaxRatesLoading: (state, { payload }) => {
      state.financeTaxRatesLoading = payload;
    },
    setHasQuote: (state, { payload }) => {
      if (!state.saleData || !state.saleData.sale) return;
      state.saleData.sale.hasQuote = payload;
    },
    reset: () => {
      return initialState;
    },
    setSelectedVehicleId: (state, { payload }) => {
      state.selectedVehicleId = payload;
    },
  },
});

export const useSalesSelector = buildSubStateSelector<SalesAppState>((state) => state.sales);
export const salesActions = slice.actions;
export const salesReducer = slice.reducer;
