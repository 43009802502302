import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { BackLink } from "@/components/backLink/BackLink";
// interfaces
import { AcctsSubviewKey } from "@/features/Accounts/viewInfo";

const BackButton: FC<{ subView?: AcctsSubviewKey }> = ({ subView = "" }) => (
  <Grid container pt="10px" pl="45px" mb="-50px">
    <BackLink to={`/accounts/${subView}`} title="Back to list" />
  </Grid>
);

export default BackButton;
