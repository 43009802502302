import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { accountsService } from '@/services/accountsService';
import useCtxFactory from '@/utils/ctxState/useCtxFactory';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { SidenoteDetail } from '@/interfaces/Accounts';

const useCtxState = () => {
  const appRecId = useAccountSelector((s) =>
    getRouteParamNum(s.accountInformation?.appRecId?.toString())
  );
  const [sidenoteRes, setSidenoteRes] = useState<SidenoteDetail[]>([]);

  const loadSidenoteHistory = async () => {
    if (!appRecId) return;
    try {
      const sidenoteList = await accountsService.getSidenotes(appRecId);
      setSidenoteRes(sidenoteList!);
    } catch (_) {}
  };

  useEffect(() => {
    loadSidenoteHistory();
  }, [appRecId]);

  // @todo use class constructor
  const sidenoteRows: SidenoteDetail<string, string>[] = sidenoteRes.map((value) => ({
    recId: value.recId,
    snDate: dayjs(value.snDate).utc().format('MM/DD/YYYY'),
    status: value.status,
    prinBal: formatCurrency(value.prinBal),
    snTotal: formatCurrency(value.snTotal),
    snDown: formatCurrency(value.snDown),
    snAmtFin: formatCurrency(value.snAmtFin),
    snDetail: value.snDetail,
  }));

  return { sidenoteRes, sidenoteRows };
};

export type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);
export const useSidenoteCtx = useCtxFactory(Ctx);
const SidenoteProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default SidenoteProvider;
