import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

// Updates 'total received'
const useTotalPaymentListener = () => {
  const { isAch, isCc, totalPayment } = useWsPmtsFormCtx((s) => s);
  const { control, setValue, trigger } = useWsPmtsFormCtx((s) => s.wsPmtsForm);
  const { ConvFee = 0, TotalReceived = 0 } = useWatch({ control });

  useEffect(() => {
    // Add conv fee if needed for payment type
    if (isCc || isAch) setValue('TotalReceived', totalPayment + (ConvFee ?? 0));
    if (totalPayment > TotalReceived) setValue('TotalReceived', totalPayment);

    // Update `TotalReceived` field if either were changed
    if (isCc || isAch || totalPayment > TotalReceived) trigger('TotalReceived');
  }, [isCc, isAch, totalPayment, TotalReceived]);
};

export default useTotalPaymentListener;
