import { FC } from 'react';
// kendo
import { Modal } from '@/components/modal/Modal';
import { RepayIframe } from '@/components/repayIframe/RepayIframe';
// state
import { useWsPmtsFormCtx } from './WsPmtsFormProvider';
// style
import styles from '@features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PaymentForm.module.scss';

/** ###  */
const RepayModal: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const paymentLogRecId = useWsPmtsFormCtx((s) => s.paymentLogRecId);
  const setRepayIframeUrl = useWsPmtsFormCtx((s) => s.setRepayIframeUrl);
  const repayIframeUrl = useWsPmtsFormCtx((s) => s.repayIframeUrl);

  if (!repayIframeUrl || repayIframeUrl === '') return <></>;
  return (
    <Modal
      centerModal
      isOpen={!!repayIframeUrl}
      closeButton
      panelStyle={{ height: '95vh' }}
      panelChildrenStyle={{ overflow: 'hidden' }}
      onCloseButtonClick={() => setRepayIframeUrl('')}
    >
      <div className={styles.repayIframeContainer}>
        <RepayIframe
          iframeUrl={repayIframeUrl}
          paymentLogRecId={paymentLogRecId}
          onComplete={onSuccess}
        />
      </div>
    </Modal>
  );
};

export default RepayModal;
