import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { TextInput } from '@/components/inputs/text/TextInput';
// components
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
// state
import { useAppDispatch } from '@/store/store';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { accountsService } from '@/services/accountsService';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { DateFormat } from '@/utils/helpers/general';
import { TakePossessionForm, TakePossessionInfo, TakePossessionReq } from './interfaces';
// style
import styles from './TakePossession.module.scss';

const initializeNewStockNum = (currStockNum?: string) => {
  if (!currStockNum) return '';
  if (/.*R$/.test(currStockNum)) return currStockNum + '1';
  if (/.*R[1-8]$/.test(currStockNum)) {
    // alpha implementation only handled repoing up to '*R9'. no idea if repoing up to '*R10' would break something, so, copying that behavior
    // one would hope a car will not be repo'd 10 times
    const currStockNumWithoutRepoNum = currStockNum.slice(0, currStockNum.length - 1);
    const currRepoNum = Number(currStockNum.slice(currStockNum.length - 1));
    return currStockNumWithoutRepoNum + (currRepoNum + 1);
  }
  return currStockNum + 'R';
};

export const TakePossession: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [submitRefreshing, setSubmitRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [takePossessionInfo, setTakePossessionInfo] = useState<TakePossessionInfo | null>(null);
  const accountInformation = useAccountSelector((s) => s.accountInformation);

  const colRecId = getRouteParamNum(params.colRecId);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TakePossessionForm>();

  const submitTakePossession = async (formBody: TakePossessionForm) => {
    if (!colRecId) return;

    try {
      setSubmitRefreshing(true);
      const reqBody: TakePossessionReq = {
        ...formBody,
        colRecId,
        newStockNumber: formBody.newStock,
      };
      await accountsService.postTakePossession(reqBody);

      dispatch(getAccountInformation(colRecId));
      navigate('..');
    } finally {
      setSubmitRefreshing(false);
    }
  };

  const initData = async () => {
    if (!colRecId) return;

    try {
      setLoading(true);
      const infoRes = await accountsService.getTakePossessionInfo(colRecId);
      setTakePossessionInfo(infoRes || null);

      reset({
        possessionDate: dayjs().format(DateFormat.YearMonthDayDash),
        miles: infoRes?.iopMiles ? Number(infoRes?.iopMiles) : undefined,
        printExempt: false,
        exceedsLimits: false,
        notActual: false,
        newStock: initializeNewStockNum(accountInformation?.stockNum),
        type: infoRes?.chgOffCat ?? undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountInformation) {
      initData();
    }
  }, [accountInformation]);

  return (
    <AccountsMainPanel navBarTitle="Take Possession of Vehicle">
      {loading ? (
        <Loader />
      ) : !takePossessionInfo ? (
        <div>Unable to load necessary information to take possession of this account</div>
      ) : (
        <div className={styles.container}>
          <form className={styles.form} onSubmit={handleSubmit(submitTakePossession)}>
            <Controller
              name="possessionDate"
              control={control}
              render={({ field }) => <DateInput label="Possession Date" readOnly {...field} />}
            />
            <Controller
              name="miles"
              control={control}
              rules={{
                min: { value: 0, message: 'Miles In cannot be less than 0' },
                required: 'Miles In is required',
              }}
              render={({ field }) => (
                <NumberInput label="Miles" {...field} required errors={errors.miles?.message} />
              )}
            />
            <Controller
              name="printExempt"
              control={control}
              render={({ field }) => <Checkbox label="Print Exempt" {...field} />}
            />
            <Controller
              name="exceedsLimits"
              control={control}
              render={({ field }) => <Checkbox label="Exceeds Limits" {...field} />}
            />
            <Controller
              name="notActual"
              control={control}
              render={({ field }) => <Checkbox label="Not Actual" {...field} />}
            />
            <Controller
              name="newStock"
              control={control}
              render={({ field }) => <TextInput label="New Stock" {...field} />}
            />
            <Controller
              name="type"
              control={control}
              rules={{ required: 'Type is required' }}
              render={({ field }) => (
                <DropdownInput
                  label="Type"
                  required
                  data={takePossessionInfo?.coRepoTypes?.map((t) => t.type)}
                  errors={errors.type?.message}
                  {...field}
                />
              )}
            />
            <Button label="Submit" loading={submitRefreshing} buttonStyle={{ width: '50%' }} />
          </form>
        </div>
      )}
    </AccountsMainPanel>
  );
};
