import { toast } from "react-toastify";
import { AxiosError, isAxiosError } from "axios";
import _ from "lodash";
// utils
import { AxiosService } from "./axiosService";
// interfaces
import { AiUnderwritingReportDb } from "@/features/Sales/detailView/tabs/ApplicationTab/subviews/AiUnderwritingTab/interfaces";
import { ApiResponse } from "@/interfaces/Api";
import { ToastNotiListPersist } from "@/utils/toast";

class ScienapticService extends AxiosService {
  public constructor() {
    super();
  }

  async generateNewAppEvaluation(appRecId: number) {
    try {
      // @todo remove from params
      const res = await this.axios.post(`/Scienaptic/GenerateAppEvaluation?appRecId=${appRecId}`);
      toast.success("App evaluation generated");
      return res.data.data;
    } catch (e) {
      const err = e as AxiosError<{ ErrorMessages: { [key: string]: string } }>;
      console.error(err);
      if (!isAxiosError(err)) throw err;

      const errMsgs = _.toArray(err.response?.data.ErrorMessages);
      toast.error(
        () => (
          <ToastNotiListPersist
            title="Error generating new Scienaptic evaluation"
            items={errMsgs}
          />
        ),
        { autoClose: false }
      );
      throw err;
    }
  }

  async getAppEvaluations(appRecId: number) {
    try {
      if (isNaN(appRecId)) throw new Error(`Invalid appRecId provided: ${appRecId}`);

      const res = await this.axios.get<ApiResponse<AiUnderwritingReportDb<string, string>[]>>(
        `/Scienaptic/GetAppEvaluations`,
        { params: { appRecId } }
      );

      return res.data.data;
    } catch (error) {
      toast.error("Error fetching Scienaptic evaluations");
      throw error;
    }
  }
}

export const scienapticService = new ScienapticService();
