import { ReactNode } from "react";
// components
import AiUnderwritingTab from "./subviews/AiUnderwritingTab";
import { ApplicationCoBuyer } from "./subviews/coBuyer/ApplicationCoBuyer";
import { ApplicationBuyer } from "./subviews/buyer/ApplicationBuyer";
import SalesInsurance from "./subviews/insurance/SalesInsurance";
import { ApplicationReferences } from "./subviews/references/ApplicationReferences";
import { ApplicationUnderwriting } from "./subviews/underwriting/ApplicationUnderwriting";
// interfaces
import { ApplicationTab } from "./interfaces";
import { BuyerType } from "@/enums/general";

/** @deprecated convert to React Router */
export const tabInfoList: { title: ApplicationTab; component: ReactNode }[] = [
  { title: "Buyer", component: <ApplicationBuyer buyerOrCobuyer={BuyerType.Buyer} /> },
  { title: "Co-Buyer", component: <ApplicationCoBuyer /> },
  { title: "Insurance", component: <SalesInsurance /> },
  { title: "References", component: <ApplicationReferences /> },
  { title: "Underwriting", component: <ApplicationUnderwriting /> },
  { title: "AutoLend IQ", component: <AiUnderwritingTab /> },
];

/** @deprecated convert to React Router */
export const tabInfoMap = new Map<ApplicationTab, { title: ApplicationTab; component: ReactNode }>(
  tabInfoList.map((t) => [t.title, t])
);
