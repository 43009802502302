import { FC } from "react";
import { Link, useParams } from "react-router-dom";
// mui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DCS_BLUE } from "@/mui/theme/componentDefaults";

const useRouteMatch = (patterns: readonly any[]) => {
  const tabRoute = useParams()["*"];
  for (let idx = 0; idx < patterns.length; idx++) {
    const origRoute = patterns[idx]!;

    if (origRoute === tabRoute) return origRoute;
  }
  return null;
};

const tabItems: { label: string; value: string }[] = [
  { label: "Finance Quote", value: "finance" },
  { label: "Lease Quote", value: "lease" },
  { label: "Lease vs Buy Comparison", value: "comparison" },
];

const NavTabs: FC = () => {
  const currentTab = useRouteMatch(tabItems.map((t) => t.value)) || tabItems[0]!.value;

  // Build tabs
  const tabElems = tabItems.map((t) => (
    <Tab
      label={t.label}
      value={t.value}
      role="navigation"
      component={Link}
      to={"./" + t.value}
      relative="path"
      sx={{
        // Container styles
        alignItems: "start",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "20px 30px",
        // Text styles
        textTransform: "none",
        fontSize: "12px",
        fontWeight: 700,
        // Selected tab styles
        "&.MuiTab-root.Mui-selected": {
          color: DCS_BLUE,
          backgroundColor: "rgba(41, 70, 149, 0.2)",
        },
      }}
      key={t.label}
    />
  ));

  return (
    <Tabs value={currentTab} orientation="horizontal" variant="scrollable" aria-label="sidebar-nav-tabs">
      {tabElems}
    </Tabs>
  );
};

export default NavTabs;
