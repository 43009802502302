import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import { DropdownInput, RadioGroupInput } from '@/components/inputs';
import { Button, Checkbox, TextInput, TextArea, NumberInput } from '@/components';
// components
import Section from '@/mui/components/form/Section';
import SectionBody from '@/mui/components/form/Section/SectionBody';
import { PrintButton } from '../PrintButton';
// state
import { useBuyersGuideCtx } from '../BuyersGuideProvider';
// utils
// interfaces
import { useParams } from 'react-router-dom';
import { BuyersGuideComplete, BuyersGuideUpdate } from '@/interfaces';
import { Divider, RadioGroup } from '@mui/material';
import ButtonGroup from './ButtonGroup';
import { inventoryService } from '@/services/inventoryService';
import dayjs from 'dayjs';

const BuyersGuideForm: FC = () => {
  const { invRecId } = useParams();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useBuyersGuideCtx((s) => s.buyersGuideForm);
  const bgType = watch('bgComplete')?.bgType;
  const fullW = watch('bgComplete')?.fullW;
  const limited = watch('bgComplete')?.limited;
  const perLabor = watch('bgComplete')?.perLabor;
  const perParts = watch('bgComplete')?.perParts;
  const system1 = watch('bgComplete')?.system1;
  const duration1 = watch('bgComplete')?.duration1;
  const seeFor = watch('bgComplete')?.seeFor;
  const servCont = watch('bgComplete')?.servCont;
  const asIs = watch('bgComplete')?.asIs;
  const asIsL = watch('bgComplete')?.asIsL;
  const war = watch('bgComplete')?.war;
  const params = useParams();
  const bgRows = useBuyersGuideCtx((s) => s.bgRows);
  const setIsLoading = useBuyersGuideCtx((s) => s.setIsLoading);
  const languageFilter = useBuyersGuideCtx((s) => s.languageFilter);
  const bgCurrent = useBuyersGuideCtx((s) => s.bgCurrent);
  const setLanguageFilter = useBuyersGuideCtx((s) => s.setLanguageFilter);

  const submitBuyersGuide = async (paymentPayload: BuyersGuideComplete) => {
    try {
      if (paymentPayload.bgComplete === null) return;
      setIsLoading(true);

      const requestBody: BuyersGuideUpdate = {
        recId: Number(invRecId),
        asIs: paymentPayload.bgComplete.asIs,
        asIsL: paymentPayload.bgComplete.asIsL,
        war: paymentPayload.bgComplete.war,
        fullW: paymentPayload.bgComplete.fullW,
        limited: paymentPayload.bgComplete.limited,
        perLabor: paymentPayload.bgComplete.perLabor,
        perParts: paymentPayload.bgComplete.perParts,
        system1: paymentPayload.bgComplete.system1,
        duration1: paymentPayload.bgComplete.duration1,
        servCont: paymentPayload.bgComplete.servCont,
        seeFor: paymentPayload.bgComplete.seeFor,
        bgType: paymentPayload.bgComplete.bgType,
      };

      await inventoryService.updateBuyersGuide(requestBody);
      setIsLoading(false);
    } catch (_) {
      setIsLoading(false);
    }
  };

  return (
    <Section expanded={true}>
      <SectionBody
        component="form"
        onSubmit={handleSubmit(submitBuyersGuide)}
        sx={{ padding: '20px 32px', backgroundColor: '#eff0f4', borderRadius: '10px' }}
      >
        <Grid2 container direction="row" flex={1} gap={1} justifyContent="end" fontSize="14px">
          {`Guide was last printed on ${dayjs(bgCurrent?.bgTimeStamp).format('M/D/YYYY')}`}
        </Grid2>
        <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
          <Controller
            name="bgComplete"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownInput
                {...field}
                label="Buyers Guide Template"
                data={bgRows}
                dataItemKey="bgType"
                textField="bgType"
                containerStyle={{ flex: 1 }}
              />
            )}
          />
          <Grid2 container direction="row" flex={1} gap={2} justifyContent="end">
            <RadioGroupInput
              data={[
                { label: 'English', value: 'English' },
                { label: 'Spanish', value: 'Spanish' },
              ]}
              layout="horizontal"
              value={languageFilter}
              onChange={(e) => setLanguageFilter(e.value)}
            />

            <PrintButton isDisabled={false} />
          </Grid2>
        </Grid2>
        <Divider orientation="horizontal" flexItem sx={{}} />
        <Grid2 container direction="column" gap={2} maxWidth="50%">
          <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
            <Checkbox containerStyles={{ flex: 1 }} disabled label={asIsL!} value={asIs} />
            <Checkbox containerStyles={{ flex: 1 }} disabled label="DEALER WARRANTY" value={war} />
          </Grid2>
          <Divider
            orientation="horizontal"
            flexItem
            variant="middle"
            sx={{ borderColor: 'rgba(0, 0, 0, 0.07)' }}
          />
          <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
            <Checkbox containerStyles={{ flex: 1 }} disabled label="Full" value={fullW} />
            <Checkbox containerStyles={{ flex: 1 }} disabled label="Limited" value={limited} />
          </Grid2>
          <Divider
            orientation="horizontal"
            flexItem
            variant="middle"
            sx={{ borderColor: 'rgba(0, 0, 0, 0.07)' }}
          />
          <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
            <NumberInput
              disabled
              label="Percentage Labor"
              isPercentage
              value={(perLabor ?? 0) / 100}
              containerStyle={{ flex: 1 }}
            />
            <NumberInput
              disabled
              label="Percentage Parts"
              isPercentage
              value={(perParts ?? 0) / 100}
              containerStyle={{ flex: 1 }}
            />
          </Grid2>
        </Grid2>
        <Divider
          orientation="horizontal"
          flexItem
          variant="middle"
          sx={{ borderColor: 'rgba(0, 0, 0, 0.07)' }}
        />
        <Grid2 container direction="column" gap={2}>
          <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
            <TextArea
              disabled
              label="System"
              value={system1!}
              containerStyle={{ flex: 1 }}
              style={{ minHeight: '114px' }}
            />
            <TextArea
              disabled
              label="Duration"
              value={duration1!}
              containerStyle={{ flex: 1 }}
              style={{ minHeight: '114px' }}
            />
          </Grid2>
          <Divider
            orientation="horizontal"
            flexItem
            variant="middle"
            sx={{ borderColor: 'rgba(0, 0, 0, 0.07)' }}
          />
          <Grid2 container direction="row" flex={1} gap={3} justifyContent="space-between">
            <Checkbox
              containerStyles={{ height: '38px', flex: 1 }}
              disabled
              label="Service Contract"
              value={servCont}
            />
            <TextInput
              disabled
              value={seeFor!}
              label="See For Complaints"
              containerStyles={{ flex: 1 }}
            />
          </Grid2>
        </Grid2>
        <ButtonGroup />
      </SectionBody>
    </Section>
  );
};

export default BuyersGuideForm;
