import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';
// interfaces
import { CustomerContact } from '@/interfaces/Customer';
import { AccountInformation, GetContactData } from '@/interfaces/Accounts';

const initialState = {
  accountInformation: null as unknown as AccountInformation | null,
  accountInformationLoading: true,
  contactInformation: {} as GetContactData,
  customerAdminListOpen: false,
  customerContacts: [] as CustomerContact[],
  getWarrantyLogsLoading: false,
  postAppointmentLoading: false,
  postPaymentArrangementLoading: false,
  postWarrantyRequestLoading: false,
  quickActionsListOpen: false,
  quickActionsModalOpened: false,
  quickActionType: '',
  sendTextToPayResponse: '',
};

export type AccountAppState = typeof initialState;

export const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInformation: (state, { payload }: PayloadAction<AccountInformation>) => {
      state.accountInformation = payload;
    },
    setAccountInformationLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.accountInformationLoading = payload;
    },
    setContactInformation: (state, action: PayloadAction<GetContactData>) => {
      state.contactInformation = action.payload;
    },
    setCustomerAdminListOpen: (state, action: PayloadAction<boolean>) => {
      state.customerAdminListOpen = action.payload;
    },
    setCustomerContacts: (state, action: PayloadAction<CustomerContact[]>) => {
      state.customerContacts = action.payload;
    },
    setGetWarrantyLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.getWarrantyLogsLoading = action.payload;
    },
    setPostAppointmentLoading: (state, action: PayloadAction<boolean>) => {
      state.postAppointmentLoading = action.payload;
    },
    setPostPaymentArrangementLoading: (state, action: PayloadAction<boolean>) => {
      state.postPaymentArrangementLoading = action.payload;
    },
    setPostWarrantyRequestLoading: (state, action: PayloadAction<boolean>) => {
      state.postWarrantyRequestLoading = action.payload;
    },
    setQuickActionsListOpen: (state, action: PayloadAction<boolean>) => {
      state.quickActionsListOpen = action.payload;
    },
    setQuickActionsModalOpened: (state, action: PayloadAction<boolean>) => {
      state.quickActionsModalOpened = action.payload;
    },
    setQuickActionType: (state, action: PayloadAction<string>) => {
      state.quickActionType = action.payload;
    },
    setSendTextToPayResponse: (state, action: PayloadAction<string>) => {
      state.sendTextToPayResponse = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const useAccountSelector = buildSubStateSelector<AccountAppState>((state) => state.account);
export const accountActions = slice.actions;
export const accountReducer = slice.reducer;
