import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Popover from '@mui/material/Popover';
// kendo
import { AccountsMainPanel } from '../accountsMainPanel/AccountsMainPanel';
import { RadioGroupInput } from '@/components/inputs/radioGroupInput/RadioGroupInput';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { SocialSecurityInput } from '@/components/inputs/socialSecurityInput/SocialSecurityInput';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import { Spacer } from '@/components/spacer/Spacer';
import { TextInput } from '@/components/inputs/text/TextInput';
import { ZipInput } from '@/components/inputs/zipInput/ZipInput';
import { BasicTable } from '@/components/table/Table';
import {
  AcceptedButton,
  DeniedButton,
  ErrorButton,
  PendingButton,
  UnsentButton,
} from './statusButtons';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// state
import { useAppDispatch } from '@/store/store';
import { useAcctDetailsCtx } from '../../AccountDetailProvider';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { getAccountInformation } from '@/features/Accounts/accountActionCreators';
// utils
import { customerService } from '@/services/customerService';
import { accountsService } from '@/services/accountsService';
import {
  DateFormat,
  capitalizeFirstLetter,
  formatAddress,
  formatDate,
  formatPhoneE164,
  formatSSN,
  getAddressFromGoogleMaps,
} from '@/utils/helpers/general';
import {
  optionalPhoneValidation,
  optionalNotGreaterThanTodayValidation,
} from '@/utils/helpers/formValidation';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { BuyerType, HomeType } from '@/enums';
import { Column } from '@/components/table/TableInterface';
import { OptInData } from '../../../interfaces';
import { BuyerContact, GetContactData } from '@/interfaces';
// style
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';

const formatAddressPart = (addrPart?: string | null) => {
  if (!addrPart) return '';
  return `${addrPart}, `;
};

const previousAddressColumns: Column[] = [
  {
    field: 'fullAddress',
    title: 'Address',
  },
  {
    field: 'livedAt',
    title: 'Lived At',
  },
];

// @todo use context to manage state
export const Contact: FC = () => {
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const dispatch = useAppDispatch();
  const [buyerType, setBuyerType] = useState<BuyerType>(BuyerType.Buyer);
  const [contactInformation, setContactInformation] = useState({} as GetContactData);
  const [getContactInformationLoading, setGetContactInformationLoading] = useState(false);
  const [updateContactInformationLoading, setUpdateContactInformationLoading] = useState(false);
  const setOptIn = useAcctDetailsCtx((s) => s.setOptIn);
  const optStatus = useAcctDetailsCtx((s) => s.optStatus);
  const optInAuthorization = useAcctDetailsCtx((s) => s.optInAuthorization);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { accountInformation, accountInformationLoading } = useAccountSelector((state) => state);

  const hasCoBuyer = !!(
    accountInformation?.coBuyer?.appBuyerRecId && accountInformation?.coBuyer?.firstName
  );

  const navBarItems = hasCoBuyer
    ? [
        {
          title: BuyerType.Buyer,
          isActive: buyerType === BuyerType.Buyer,
          onClick: () => setBuyerType(BuyerType.Buyer),
        },
        {
          title: BuyerType.CoBuyer,
          isActive: buyerType === BuyerType.CoBuyer,
          onClick: () => setBuyerType(BuyerType.CoBuyer),
        },
      ]
    : [];

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<BuyerContact>();

  const { rentOwn, sameMailingAddress, currentOrPast } = useWatch({ control });

  // @todo remove unnecessary optimization
  const fullAddress = useMemo(() => {
    const selectedBuyerType =
      buyerType === BuyerType.Buyer ? contactInformation.buyer : contactInformation.coBuyer;

    return formatAddress(
      selectedBuyerType?.address,
      selectedBuyerType?.city,
      selectedBuyerType?.state,
      selectedBuyerType?.zip
    );
  }, [contactInformation, buyerType]);

  // @todo remove unnecessary optimization
  const fullMailingAddress = useMemo(() => {
    const selectedBuyerType =
      buyerType === BuyerType.Buyer ? contactInformation.buyer : contactInformation.coBuyer;

    return formatAddress(
      selectedBuyerType?.mailingAddress,
      selectedBuyerType?.mailingCity,
      selectedBuyerType?.mailingState,
      selectedBuyerType?.mailingZip
    );
  }, [contactInformation, buyerType]);

  const handleAddressPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('address', fullAddress.address);
    setValue('city', fullAddress.city);
    setValue('state', fullAddress.state);
    setValue('zip', fullAddress.zip);
    setValue('county', fullAddress.county);
  };

  const handleMailingAddressPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue('mailingAddress', fullAddress.address);
    setValue('mailingCity', fullAddress.city);
    setValue('mailingState', fullAddress.state);
    setValue('mailingZip', fullAddress.zip);
  };

  useEffect(() => {
    setGetContactInformationLoading(true);
    // @todo use async/await
    accountsService
      .getContactInformation(colRecId)
      .then((res) => {
        setContactInformation(res);
      })
      .finally(() => setGetContactInformationLoading(false));
  }, []);

  const fetchOptInData = async () => {
    if (!accountInformation?.buyer.cellPhone || !accountInformation?.appRecId) {
      console.error('One or more of the required fields are undefined');
      return;
    }
    try {
      const res = await customerService.getOptIn(
        formatPhoneE164(accountInformation.buyer.cellPhone),
        accountInformation.appRecId
      );
      res && setOptIn(OptInData.new(res));
    } catch (e) {
      console.error('Error getting opt in data', e);
      throw e;
    }
  };

  // @todo duplicate useEffect
  useEffect(() => {
    fetchOptInData();
  }, []);

  useEffect(() => {
    const selectedBuyerInfo =
      buyerType === BuyerType.Buyer ? contactInformation.buyer : contactInformation.coBuyer;

    if (!selectedBuyerInfo) return;

    // @todo use class constructor function
    reset({
      address: selectedBuyerInfo.address || '',
      appRecId: selectedBuyerInfo.appRecId,
      cellPhone: selectedBuyerInfo.cellPhone || '',
      city: selectedBuyerInfo.city || '',
      county: selectedBuyerInfo.county || '',
      currentOrPast: selectedBuyerInfo.currentOrPast,
      dateOfBirth: selectedBuyerInfo.dateOfBirth
        ? formatDate(selectedBuyerInfo.dateOfBirth, {
            pattern: DateFormat.DateInput,
          })
        : '',
      driversLicenseExpiration: selectedBuyerInfo.driversLicenseExpiration
        ? formatDate(selectedBuyerInfo.driversLicenseExpiration, {
            pattern: DateFormat.DateInput,
          })
        : '',
      driversLicenseNumber: selectedBuyerInfo.driversLicenseNumber || '',
      driversLicenseState: selectedBuyerInfo.driversLicenseState || '',
      email: selectedBuyerInfo.email || '',
      firstName: selectedBuyerInfo.firstName || '',
      homePhone: selectedBuyerInfo.homePhone || '',
      homeType: selectedBuyerInfo.homeType,
      isBuyer: selectedBuyerInfo.isBuyer,
      landlordFax: selectedBuyerInfo.landlordFax || '',
      landlordOrMortgageHolder: selectedBuyerInfo.landlordOrMortgageHolder || '',
      landlordPhone: selectedBuyerInfo.landlordPhone || '',
      lastName: selectedBuyerInfo.lastName || '',
      mailingAddress: selectedBuyerInfo.mailingAddress || '',
      mailingCity: selectedBuyerInfo.mailingCity || '',
      mailingState: selectedBuyerInfo.mailingState || '',
      mailingZip: selectedBuyerInfo.mailingZip || '',
      middleName: selectedBuyerInfo.middleName || '',
      monthsAtAddress: selectedBuyerInfo.monthsAtAddress || 0,
      mortgageBalance: selectedBuyerInfo.mortgageBalance,
      nickName: selectedBuyerInfo.nickName || '',
      noCall: selectedBuyerInfo.noCall || false,
      noEmail: selectedBuyerInfo.noEmail || false,
      noLetter: selectedBuyerInfo.noLetter || false,
      noRobo: selectedBuyerInfo.noRobo || false,
      noText: selectedBuyerInfo.noText || false,
      numberOfDependents: selectedBuyerInfo.numberOfDependents || 0,
      otherPhone: selectedBuyerInfo.otherPhone || '',
      otherPhoneType: selectedBuyerInfo.otherPhoneType || 'Work',
      rentOrMortgage: selectedBuyerInfo.rentOrMortgage || 0,
      rentOwn: selectedBuyerInfo.rentOwn,
      sameMailingAddress: selectedBuyerInfo.sameMailingAddress === false ? false : true,
      socialSecurityNumber: formatSSN(selectedBuyerInfo.socialSecurityNumber) || '',
      state: selectedBuyerInfo.state || '',
      yearsAtAddress: selectedBuyerInfo.yearsAtAddress || 0,
      zip: selectedBuyerInfo.zip || '',
      recId: selectedBuyerInfo.recId,
    });
    // @todo deps arr must use stable ref
  }, [contactInformation, buyerType]);

  const updateContactInformation = (updatedContactInfo: BuyerContact) => {
    setUpdateContactInformationLoading(true);

    const { dateOfBirth, driversLicenseExpiration, ...restUpdatedContactInfo } = updatedContactInfo;

    // @todo use async/await
    accountsService
      .updateContactInformation({
        dateOfBirth: dateOfBirth || null,
        driversLicenseExpiration: driversLicenseExpiration || null,
        ...restUpdatedContactInfo,
      })
      .then(() => {
        dispatch(getAccountInformation(colRecId));
        // @todo use async/await
        accountsService
          .getContactInformation(colRecId)
          .then((res) => dispatch(accountActions.setContactInformation(res)));
      })
      .finally(() => setUpdateContactInformationLoading(false));
  };

  const updateContactInformationText = async (updatedContactInfo: BuyerContact) => {
    setUpdateContactInformationLoading(true);

    const { dateOfBirth, driversLicenseExpiration, ...restUpdatedContactInfo } = updatedContactInfo;

    await accountsService.updateContactInformation({
      dateOfBirth: dateOfBirth || null,
      driversLicenseExpiration: driversLicenseExpiration || null,
      ...restUpdatedContactInfo,
    });
    dispatch(getAccountInformation(colRecId));
    try {
      await customerService.beginOptIn(
        formatPhoneE164(updatedContactInfo.cellPhone),
        Number(accountInformation!.appRecId!)
      );
    } catch (e) {
      console.error('Error beginning opt in process', e);
      throw e;
    }
    // Adding a delay of 3 seconds (adjust as needed)
    await new Promise((resolve) => setTimeout(resolve, 5000));

    const fetchRes = await accountsService.getContactInformation(colRecId);
    dispatch(accountActions.setContactInformation(fetchRes));
    setUpdateContactInformationLoading(false);
  };

  const currentBuyerInfo =
    buyerType === BuyerType.Buyer ? contactInformation.buyer : contactInformation.coBuyer;

  const previousAddresses = currentBuyerInfo?.previousAddresses?.length
    ? currentBuyerInfo.previousAddresses.map((addr) => ({
        fullAddress:
          formatAddressPart(addr.address) +
            formatAddressPart(addr.city) +
            formatAddressPart(addr.state) +
            addr.zip || '',
        livedAt: `${addr.years || 0} Years ${addr.months || 0} Months`,
      }))
    : [];

  // Handler function to open the popover
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  // Handler function to close the popover
  const handleClosePopover = () => {
    setIsPopoverOpen(false);
  };

  // @todo move nested components to separate files
  return (
    <AccountsMainPanel
      navBarTitle="Contact"
      navBarItems={navBarItems}
      loading={getContactInformationLoading || accountInformationLoading}
    >
      <>
        <form onSubmit={handleSubmit(updateContactInformation)}>
          <div className={styles.columns} style={{ gap: '30px' }}>
            <div className={styles.column}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => <TextInput label="First Name" {...field} />}
              />
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => <TextInput label="Middle Name" {...field} />}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => <TextInput label="Last Name" {...field} />}
              />
              <Controller
                name="nickName"
                control={control}
                render={({ field }) => <TextInput label="Nickname" {...field} />}
              />
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ ...optionalNotGreaterThanTodayValidation }}
                render={({ field }) => (
                  <DateInput
                    label="Date Of Birth"
                    errors={errors.dateOfBirth?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="socialSecurityNumber"
                control={control}
                render={({ field }) => <SocialSecurityInput label="Social Security" {...field} />}
              />
              <Controller
                name="driversLicenseNumber"
                control={control}
                render={({ field }) => <TextInput label="License Number" {...field} />}
              />
              <Controller
                name="driversLicenseExpiration"
                control={control}
                render={({ field }) => <DateInput label="Expiration Date" {...field} />}
              />
              <Controller
                name="driversLicenseState"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="License State" data={usaStateCodes} {...field} />
                )}
              />
            </div>
            <div className={styles.column}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <AddressAutocomplete
                    label="Address"
                    {...field}
                    onPlaceSelected={handleAddressPlaceChange}
                    fullAddress={fullAddress}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({ field }) => <TextInput label="City" {...field} />}
              />
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <DropdownInput label="State" data={usaStateCodes} {...field} />
                )}
              />
              <Controller
                name="zip"
                control={control}
                render={({ field }) => <ZipInput label="Zip" {...field} />}
              />
              <Controller
                name="county"
                control={control}
                render={({ field }) => <TextInput label="County" {...field} />}
              />
              <div className={styles.inlineInputContainer}>
                <span className={styles.inlineInputLabel}>Time at address</span>
                <div className={styles.inlineInputItems}>
                  <Controller
                    name="yearsAtAddress"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        label="Years"
                        horizontalLabel={false}
                        errors={!!errors.yearsAtAddress}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="monthsAtAddress"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        label="Months"
                        horizontalLabel={false}
                        errors={!!errors.monthsAtAddress}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              <Controller
                name="homeType"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    label="Home Type"
                    data={Object.values(HomeType)}
                    errors={!!errors.homeType}
                    {...field}
                  />
                )}
              />
              <Controller
                name="rentOwn"
                control={control}
                render={({ field }) => {
                  // eslint-disable-next-line
                  const { value, onChange, ...restField } = field;
                  return (
                    <RadioGroupInput
                      label="Rent/Own"
                      data={[
                        { label: 'Rent', value: 'Rent' },
                        { label: 'Own', value: 'Own' },
                      ]}
                      layout="horizontal"
                      value={rentOwn}
                      onChange={(e) => setValue('rentOwn', e.value)}
                      {...restField}
                    />
                  );
                }}
              />
              {rentOwn && (
                <>
                  <Controller
                    name="rentOrMortgage"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        label={rentOwn === 'Rent' ? 'Rent' : 'Mortgage'}
                        errors={!!errors.rentOrMortgage}
                        {...field}
                      />
                    )}
                  />
                  {rentOwn === 'Own' && (
                    <Controller
                      name="mortgageBalance"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          label="Mortgage Balance"
                          errors={!!errors.mortgageBalance}
                          {...field}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name="currentOrPast"
                    control={control}
                    render={({ field }) => {
                      // eslint-disable-next-line
                      const { value, onChange, ...restField } = field;
                      return (
                        <RadioGroupInput
                          label="Current/Past Due"
                          data={[
                            { label: 'Current', value: 'Current' },
                            { label: 'Past Due', value: 'Past Due' },
                          ]}
                          layout="horizontal"
                          value={currentOrPast}
                          onChange={(e) => setValue('currentOrPast', e.value)}
                          {...restField}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="landlordOrMortgageHolder"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label={rentOwn === 'Rent' ? 'Landlord Name' : 'Mortgage Holder'}
                        errors={!!errors.landlordOrMortgageHolder}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="landlordPhone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        label={rentOwn === 'Rent' ? 'Landlord Phone' : 'Mortgage Co. Phone'}
                        errors={!!errors.landlordPhone}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="landlordFax"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        label={rentOwn === 'Rent' ? 'Landlord Fax' : 'Mortgage Co. Fax'}
                        errors={!!errors.landlordFax}
                        {...field}
                      />
                    )}
                  />
                </>
              )}
            </div>
            <div className={styles.column}>
              <Controller
                name="sameMailingAddress"
                control={control}
                render={({ field }) => <Checkbox label="Same Mailing Address" {...field} />}
              />
              {!sameMailingAddress && (
                <>
                  <Controller
                    name="mailingAddress"
                    control={control}
                    render={({ field }) => (
                      <AddressAutocomplete
                        label="Mailing Address"
                        {...field}
                        onPlaceSelected={handleMailingAddressPlaceChange}
                        fullAddress={fullMailingAddress}
                      />
                    )}
                  />
                  <Controller
                    name="mailingCity"
                    control={control}
                    render={({ field }) => <TextInput label="Mailing City" {...field} />}
                  />
                  <Controller
                    name="mailingState"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput label="Mailing State" data={usaStateCodes} {...field} />
                    )}
                  />
                  <Controller
                    name="mailingZip"
                    control={control}
                    render={({ field }) => <ZipInput label="Mailing Zip" {...field} />}
                  />
                </>
              )}
              {!optInAuthorization && (
                <Controller
                  name="cellPhone"
                  control={control}
                  rules={optionalPhoneValidation}
                  render={({ field }) => (
                    <PhoneInput
                      label="Cell Phone"
                      errors={errors.cellPhone?.message || !!errors.cellPhone}
                      {...field}
                    />
                  )}
                />
              )}
              {optInAuthorization && (
                <div>
                  <Controller
                    name="cellPhone"
                    control={control}
                    rules={optionalPhoneValidation}
                    render={({ field }) => (
                      <Grid container wrap="nowrap">
                        <PhoneInput
                          style={{ fontSize: '12px', borderRadius: '8px 0px 0px 8px' }}
                          label="Cell Phone"
                          errors={errors.cellPhone?.message || !!errors.cellPhone}
                          {...field}
                        />
                        {optStatus === 'unsent' && <UnsentButton onClick={handleOpenPopover} />}
                        {optStatus === 'error' && <ErrorButton />}
                        {optStatus === 'pending' && <PendingButton />}
                        {optStatus === 'accepted' && <AcceptedButton />}
                        {optStatus === 'denied' && <DeniedButton />}
                      </Grid>
                    )}
                  />
                  <Popover
                    open={isPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    slotProps={{
                      paper: { sx: { borderRadius: '12px' } },
                    }}
                  >
                    <Card sx={{ borderRadius: '12px' }}>
                      <CardHeader
                        title="Opt In"
                        titleTypographyProps={{ fontSize: '20px' }}
                        sx={{
                          bgcolor: '#294694',
                          color: '#FFF',
                          maxHeight: '38px',
                        }}
                      />
                      <CardContent sx={{ padding: '8px' }}>
                        <Controller
                          name="cellPhone"
                          control={control}
                          rules={optionalPhoneValidation}
                          render={({ field }) => (
                            <PhoneInput
                              style={{ fontSize: '12px', borderRadius: '8px' }}
                              label="Cell Phone"
                              errors={errors.cellPhone?.message || !!errors.cellPhone}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="firstName"
                          control={control}
                          render={() => (
                            <TextInput
                              label="Status"
                              readOnly={true}
                              value={capitalizeFirstLetter(optStatus!)}
                              style={{ fontSize: '12px', borderRadius: '8px' }}
                            />
                          )}
                        />
                      </CardContent>
                      <CardActions
                        disableSpacing
                        sx={{ justifyContent: 'end', padding: '0px 8px 8px 8px' }}
                      >
                        <Button
                          label="Send Text"
                          themeColor="primary"
                          onClick={handleSubmit(updateContactInformationText)}
                        />
                      </CardActions>
                    </Card>
                  </Popover>
                </div>
              )}
              <Controller
                name="homePhone"
                control={control}
                rules={optionalPhoneValidation}
                render={({ field }) => (
                  <PhoneInput
                    label="Home Phone"
                    errors={errors.homePhone?.message || !!errors.homePhone}
                    {...field}
                  />
                )}
              />
              <Controller
                name="otherPhone"
                control={control}
                rules={optionalPhoneValidation}
                render={({ field }) => (
                  <PhoneInput
                    label="Work Phone"
                    errors={errors.otherPhone?.message || !!errors.otherPhone}
                    {...field}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => <TextInput label="Email" {...field} />}
              />
              <div className={styles.optOutCheckboxes}>
                <div>
                  <Controller
                    name="noCall"
                    control={control}
                    render={({ field }) => <Checkbox label="Do Not Call" {...field} />}
                  />
                </div>
                <div>
                  <Controller
                    name="noText"
                    control={control}
                    render={({ field }) => <Checkbox label="Do Not Text" {...field} />}
                  />
                </div>
                <div>
                  <Controller
                    name="noRobo"
                    control={control}
                    render={({ field }) => <Checkbox label="Do Not Robo Call" {...field} />}
                  />
                </div>
                <div>
                  <Controller
                    name="noEmail"
                    control={control}
                    render={({ field }) => <Checkbox label="Do Not Email" {...field} />}
                  />
                </div>
              </div>
            </div>
          </div>
          <Spacer size={50} />
          <div className={styles.submitButtonContainer}>
            <Button label="Submit" loading={updateContactInformationLoading} />
          </div>
        </form>
        {!!currentBuyerInfo?.previousAddresses?.length && (
          <>
            <Spacer size={20} />
            <div className={styles.tableContainer}>
              <h3>{buyerType}'s Previous Addresses</h3>
              <BasicTable data={previousAddresses} columns={previousAddressColumns} />
            </div>
            <Spacer size={20} />
          </>
        )}
      </>
    </AccountsMainPanel>
  );
};
