import React, { useEffect, useState } from 'react';
import { config } from '@/config';
import { paymentService } from '@services/paymentService';
import { displayErrors } from '@/utils/helpers/general';
import { Spacer } from "@/components/spacer/Spacer";
import styles from './OpenEdge.module.scss';
import { CircleSpinner } from 'react-spinners-kit';

type Props = {
  paymentLogRecId: number;
  onComplete?: () => void;
  apiKey?: string;
};

export const OpenEdge: React.FC<Props> = (props) => {
  const { paymentLogRecId, onComplete = () => null, apiKey } = props;
  const [successModal, setSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const getOpenEdgeNewCreditCard = () => {
    if (!apiKey) {
      displayErrors('Unable to load OpenEdge payment form');
      return;
    }
    // Clear out the old script and fields in case this is a rerender
    document.querySelector('#openEdgeScript')?.remove();
    document.querySelectorAll('#openEdgeElements iframe')?.forEach((el) => el.remove());
    const script = document.createElement('script');
    script.src = 'https://js.paygateway.com/secure_payment/v1/globalpayments.js';
    script.async = true;
    script.id = 'openEdgeScript';
    script.addEventListener('load', () => {
      window.GlobalPayments.configure({
        'X-GP-Api-Key': apiKey,
        'X-GP-Environment': config.openEdgeEnvironment,
        enableAutocomplete: true,
      });

      const cardForm = window.GlobalPayments.ui.form({
        styles: {
          input: {
            height: '30px',
            padding: '5px 10px',
          },
          'input:focus': {
            outline: 'none',
            border: '2px solid #4399de',
          },
          button: {
            height: '40px',
            color: '#fff',
            background: '#3299df',
            cursor: 'pointer',
            border: 'none',
          },
          'button:hover': {
            background: 'rgb(65, 125, 175)',
          },
        },
        fields: {
          'card-number': {
            target: '#card-number',
            placeholder: '•••• •••• •••• ••••',
          },
          'card-expiration': {
            target: '#card-expiration',
            placeholder: 'MM / YYYY',
          },
          'card-cvv': {
            target: '#card-cvv',
            placeholder: '•••',
          },
          submit: {
            target: '#submit',
            text: 'Submit',
          },
        },
      });

      cardForm.on('error', (err: any) => {
        displayErrors('There was an issue in processing your payment');
        console.error(err);
      });

      cardForm.on('token-success', (tokenRes: any) => {
        paymentService
          .postOpenEdgeCCPayment(tokenRes.temporary_token, paymentLogRecId)
          .then((paymentRes) => {
            setSuccessModal(true);
            if (paymentRes.success) {
              setSuccessModalMessage('Thank you, your payment went through successfully');
              paymentService.getReceiptUrl(paymentRes.paymentRecId).then((url) => {
                window.open(url);
                onComplete();
              });
            } else {
              setSuccessModalMessage('There was an issue processing your payment');
            }
          })
          .catch((err) => {
            console.error(err);
            displayErrors('There was an issue in processing your payment');
          });
      });
    });
    document.body.appendChild(script);
  };

  useEffect(() => {
    setRefreshing(true);
    getOpenEdgeNewCreditCard();
    setRefreshing(false);
  }, [paymentLogRecId]);

  if (refreshing)
    return (
      <div className={styles.refreshing}>
        <CircleSpinner color={'#3299df'} size={50} />
      </div>
    );

  if (successModal) return <div>{successModalMessage}</div>;

  return (
    <div className={styles.container} id="openEdgeElements">
      <div className={styles.inputLabel}>Card Number</div>
      <div id="card-number" className={styles.openEdgeField}></div>
      <div className={styles.inputLabel}>Expiration Date</div>
      <div id="card-expiration" className={styles.openEdgeField}></div>
      <div className={styles.inputLabel}>CVV</div>
      <div id="card-cvv" className={styles.openEdgeField}></div>
      <Spacer size={20} />
      <div id="submit" className={styles.openEdgeSubmit}></div>
    </div>
  );
};
