import { DropdownInput } from '@/components/inputs';
import { useEffect, useState } from 'react';
import styles from './LogCallModal.module.scss';
import { accountsService } from '@/services/accountsService';
import { Controller, useForm } from 'react-hook-form';
import { Loader } from '@progress/kendo-react-all';
import { Button, Modal, TextArea } from '@/components';

interface LogCallModalProps {
  colRecId: number;
  open: boolean;
  reloadActivity: () => void;
  onClose: () => void;
}

interface ContactOption {
  name: string;
  relationship: string;
  phone: string;
  key: string;
  display: string;
  recId: number;
}

interface FormProps {
  contact: ContactOption;
  callResult: string;
  notes: string;
}

const callResultOptions = [
  'Made Contact',
  'Left Voicemail',
  'No Answer',
  'Disconnected',
  'Voicemail Full',
  'Wrong Number',
  'Busy',
  'Pick Up/Hang Up',
  'Blocked Our Number',
  'Number Changed',
  'Voicemail Not Setup',
];

export const LogCallModal = (props: LogCallModalProps) => {
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [submittingLog, setSubmittingLog] = useState(false);
  const [contactOptions, setContactOptions] = useState<ContactOption[]>([]);
  const { colRecId, open, onClose, reloadActivity } = props;

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormProps>();

  const submit = (data: FormProps) => {
    setSubmittingLog(true);
    accountsService
      .logCall({
        colRecId,
        contactWho: data.contact.name,
        phoneNumber: data.contact.phone,
        relationship: data.contact.relationship,
        note: data.notes,
        result: data.callResult,
        acContactRecId: data.contact.recId,
      })
      .then(() => {
        reloadActivity();
        onClose();
      })
      .finally(() => {
        setSubmittingLog(false);
      });
  };

  useEffect(() => {
    setLoadingContacts(true);
    accountsService
      .getAccountContacts(colRecId)
      .then((contactsRes) => {
        const tempDropdownContacts: ContactOption[] = [];
        contactsRes.forEach((contact) => {
          [contact.cPhone, contact.hPhone, contact.wPhone]
            .filter((phoneNumber) => !!phoneNumber) // Prevent empty phone numbers
            .forEach((phoneNumber) => {
              const formattedContact = `${contact.contact} | ${contact.contactType} | ${phoneNumber}`;
              if (tempDropdownContacts.find((tdc) => tdc.display === formattedContact)) return; // Prevent duplicates
              tempDropdownContacts.push({
                name: contact.contact,
                relationship: contact.contactType,
                phone: phoneNumber,
                key: crypto.randomUUID(),
                display: formattedContact,
                recId: contact.recId,
              });
            });
        });

        setContactOptions(tempDropdownContacts as ContactOption[]);

        reset({
          contact: tempDropdownContacts[0],
          callResult: callResultOptions[0],
          notes: '',
        });
      })
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingContacts(false));
  }, []);

  return (
    <Modal
      title="Log Call"
      centerModal
      isOpen={open}
      onDimmerClick={onClose}
      onCloseButtonClick={onClose}
      closeButton
    >
      <div className={styles.logCallContainer}>
        {loadingContacts ? (
          <Loader size="large" />
        ) : loadingError || !contactOptions ? (
          <div>Unable to load contacts</div>
        ) : !contactOptions.length ? (
          <div>Account does not have any contact information to log to</div>
        ) : (
          <form onSubmit={handleSubmit(submit)} className={styles.form}>
            <Controller
              name="contact"
              control={control}
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <DropdownInput
                  label="Contact With"
                  horizontalLabel={false}
                  required
                  data={contactOptions}
                  dataItemKey="key"
                  textField="display"
                  errors={errors.contact?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="callResult"
              control={control}
              rules={{ required: 'Field is required' }}
              render={({ field }) => (
                <DropdownInput
                  label="Call Result"
                  horizontalLabel={false}
                  required
                  data={callResultOptions}
                  errors={errors.callResult?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextArea label="Notes" boldLabel className={styles.textArea} {...field} />
              )}
            />
            <div className={styles.buttonContainer}>
              <Button label="Submit" loading={submittingLog} />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};
