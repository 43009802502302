export const paymentProcessors = ['OpenEdge', 'BlytzPay', 'Repay'] as const;
export type PaymentProcessorKey = (typeof paymentProcessors)[number];

export const reversalTypes = ['chargeBack', 'refund', 'reapply', 'nsfCheck', 'reversal'] as const;
export type ReversalType = (typeof reversalTypes)[number];
const reversalTypeLabelArrMap: [ReversalType, string][] = [
  ['chargeBack', 'Credit Card Chargeback'],
  ['refund', 'Refund'],
  ['reapply', 'Reapply'],
  ['nsfCheck', 'NSF Check'],
  ['reversal', 'Payment Reversal'],
];
export const reversalLabelMap = new Map<ReversalType, string>(reversalTypeLabelArrMap);
export const reversalLookupMap = new Map<string, ReversalType>(
  reversalTypeLabelArrMap.map((item) => [item[1], item[0]])
);
export const reversalTypeLabels: string[] = [];
reversalLabelMap.forEach((v, _) => {
  reversalTypeLabels.push(v);
});
export const reversalTypeDropdownLabels: { key: ReversalType; value: string }[] = [];
reversalLabelMap.forEach((value, key) => {
  reversalTypeDropdownLabels.push({ key, value });
});

export interface UpdateEmailPostReq {
  colRecId: number;
  appRecId: number;
  buyerCoBuyerRecId: number;
  isBuyer: boolean;
  email: string;
}

export interface UpdateEmailOptOutStatusPostReq {
  colRecId: number;
  appRecId: number;
  optOut: boolean;
  isBuyer: boolean;
}

export interface TextToPayNumberPayload {
  colRecId: number;
  appRecId: number;
  isBuyer: boolean;
  buyerCoBuyerRecId: number;
  cellPhone: number;
}
