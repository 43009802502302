import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { useSalesViewCtx } from '@/features/Sales/SalesViewProvider';
import { QuoteData } from '@/interfaces';
import { calculateAdjCapCost, calculateLeasePayment } from '@/utils/helpers/sales';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface FormProps {
  fullName: string;
  phoneNumber: string;
  dateOfQuote: string;
  sellingPrice: number;
  mmr: number;
  moneyDueAtInception: number;
  moneyDueAtInceptionField1: number;
  moneyDueAtInceptionField2: number;
  moneyDueAtInceptionField3: number;
  securityDeposit: number;
  downPayment: number;
  customField1: number;
  customField2: number;
  customField3: number;
  customField4: number;
  customField5: number;
  serviceContract: number;
  taxRate: number;
  apr: number;
  term1: number;
  term1EstPayment: number;
  term1ResidualRate: number;
  term1Residual: number;
  term1GuaranteedTradeOption: number;
  term1EstimatedNetPayoff: number;
  term2: number;
  term2EstPayment: number;
  term2ResidualRate: number;
  term2Residual: number;
  term2GuaranteedTradeOption: number;
  term2EstimatedNetPayoff: number;
  term3: number;
  term3EstPayment: number;
  term3ResidualRate: number;
  term3Residual: number;
  term3GuaranteedTradeOption: number;
  term3EstimatedNetPayoff: number;
  tradeOption: number;
}

export const useLease = () => {
  const { leaseSellingPrice, leaseMmr, leaseDueAtInception } = useSalesSelector((s) => s);
  const setIsLoading = useSalesViewCtx((s) => s.setIsLoading);
  const isLoading = useSalesViewCtx((s) => s.isLoading);
  const { quoteData, dealData, setDealData, isQuoteInfoLoading } = useDealsQuotesDetailCtx(
    (s) => s
  );
  const [term1Open, setTerm1Open] = useState(false);
  const [term2Open, setTerm2Open] = useState(false);
  const [term3Open, setTerm3Open] = useState(false);
  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [moneyDueAtInceptionField1Name, setMoneyDueAtInceptionField1Name] = useState('');
  const [moneyDueAtInceptionField2Name, setMoneyDueAtInceptionField2Name] = useState('');
  const [moneyDueAtInceptionField3Name, setMoneyDueAtInceptionField3Name] = useState('');
  const [showFullLeaseQuote, setShowFullLeaseQuote] = useState(false);
  const [isTradeOption50Percent, setIsTradeOption50Percent] = useState(false);
  const [isTradeOption67Percent, setIsTradeOption67Percent] = useState(false);
  const [isTradeOptionNA, setIsTradeOptionNA] = useState(true);
  const [leaseData, setLeaseData] = useState<QuoteData | undefined>(undefined);
  const [isQuoteDataLoading, setIsQuoteDataLoading] = useState(true);

  const termData = [12, 18, 24, 30, 36, 42, 48];
  const totalSalesTax = leaseData?.totalTaxRate || 0 / 100;

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful, errors },
  } = useForm<FormProps>({
    defaultValues: {
      fullName: '',
      phoneNumber: '',
      dateOfQuote: dayjs().format('YYYY-MM-DD'),
      sellingPrice: leaseSellingPrice,
      mmr: leaseMmr,
      moneyDueAtInception: 0,
      moneyDueAtInceptionField1: 0,
      moneyDueAtInceptionField2: 0,
      moneyDueAtInceptionField3: 0,
      securityDeposit: 0,
      downPayment: 0,
      customField1: 0,
      customField2: 0,
      customField3: 0,
      customField4: 0,
      customField5: 0,
      serviceContract: 0,
      taxRate: totalSalesTax,
      apr: 0,
      term1: 24,
      term1EstPayment: 0,
      term1ResidualRate: 0.55,
      term1Residual: leaseMmr * 0.55,
      term1GuaranteedTradeOption: 0,
      term1EstimatedNetPayoff: 0,
      term2: 36,
      term2EstPayment: 0,
      term2ResidualRate: 0.35,
      term2Residual: leaseMmr * 0.35,
      term2GuaranteedTradeOption: 0,
      term2EstimatedNetPayoff: 0,
      term3: 48,
      term3EstPayment: 0,
      term3ResidualRate: 0.25,
      term3Residual: leaseMmr * 0.25,
      term3GuaranteedTradeOption: 0,
      term3EstimatedNetPayoff: 0,
      tradeOption: 0,
    },
  });

  const calculateResidual = (mmr: number, residualRate: number) => {
    return mmr * residualRate;
  };

  const calculateAddedSalesTax = () => {
    const taxableFees = [];

    if (leaseData?.staX2) {
      taxableFees.push(watch('moneyDueAtInceptionField1'));
    }
    if (leaseData?.staX3) {
      taxableFees.push(watch('moneyDueAtInceptionField2'));
    }
    if (leaseData?.staX4) {
      taxableFees.push(watch('moneyDueAtInceptionField3'));
    }
    if (leaseData?.staX5) {
      taxableFees.push(watch('customField1'));
    }
    if (leaseData?.staX6) {
      taxableFees.push(watch('customField2'));
    }
    if (leaseData?.staX7) {
      taxableFees.push(watch('customField3'));
    }
    if (leaseData?.staX8) {
      taxableFees.push(watch('customField4'));
    }
    if (leaseData?.staX9) {
      taxableFees.push(watch('customField5'));
    }
    if (leaseData?.staX10) {
      taxableFees.push(watch('serviceContract'));
    }

    let addedTax = watch('sellingPrice') * watch('taxRate');

    taxableFees.forEach((item) => {
      addedTax += item * watch('taxRate');
    });

    return addedTax;
  };

  useEffect(() => {
    if (!quoteData) return;
    const leaseData = quoteData.find((value) => value.stype === 'Lease Sale');
    setLeaseData(leaseData);
    const stateRate = leaseData?.staterate ? leaseData.staterate : 0;
    const cityRate = leaseData?.cityrate ? leaseData.cityrate : 0;
    const locRate = leaseData?.locrate ? leaseData.locrate : 0;
    const totalSalesTax = (stateRate + cityRate + locRate) / 100;
    reset({
      fullName: dealData?.customerName ?? '',
      phoneNumber: dealData?.customerPhone ?? '',
      dateOfQuote: dealData?.quoteDate
        ? dayjs(dealData?.quoteDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
      sellingPrice: dealData?.sellingPrice ?? 0,
      mmr: dealData?.mmr ?? leaseMmr,
      moneyDueAtInception:
        dealData?.moneyDueAtInception ??
        (leaseDueAtInception
          ? leaseDueAtInception
          : (leaseData?.pricE2 || 0) +
            (leaseData?.pricE3 || 0) +
            (leaseData?.pricE4 || 0) +
            (leaseData?.lease_SecurityDeposit || 0)),
      moneyDueAtInceptionField1: dealData?.moneyDueAtInceptionField1 ?? leaseData?.pricE2,
      moneyDueAtInceptionField2: dealData?.moneyDueAtInceptionField2 ?? leaseData?.pricE3,
      moneyDueAtInceptionField3: dealData?.moneyDueAtInceptionField3 ?? leaseData?.pricE4,
      securityDeposit: dealData?.securityDeposit ?? leaseData?.lease_SecurityDeposit,
      downPayment: dealData?.downPayment ?? 0,
      customField1: dealData?.customField1 ?? leaseData?.pricE5,
      customField2: dealData?.customField2 ?? leaseData?.pricE6,
      customField3: dealData?.customField3 ?? leaseData?.pricE7,
      customField4: dealData?.customField4 ?? leaseData?.pricE8,
      customField5: dealData?.customField5 ?? leaseData?.pricE9,
      serviceContract: dealData?.serviceContract ?? leaseData?.pricE10,
      taxRate: dealData?.taxRate ?? totalSalesTax,
      apr: dealData?.aPR ?? (leaseData?.def_APR ? leaseData.def_APR / 100 : 0),
      term1: dealData?.term1 ?? 24,
      term1EstPayment: dealData?.term1EstPayment ?? 0,
      term1ResidualRate: dealData?.term1ResidualRate ?? 0.55,
      term1Residual: dealData?.term1Residual ?? leaseMmr * 0.55,
      term1GuaranteedTradeOption: dealData?.term1GuaranteedTradeOption ?? 0,
      term1EstimatedNetPayoff: dealData?.term1EstimatedNetPayoff ?? 0,
      term2: dealData?.term2 ?? 36,
      term2EstPayment: dealData?.term2EstPayment ?? 0,
      term2ResidualRate: dealData?.term2ResidualRate ?? 0.35,
      term2Residual: dealData?.term2Residual ?? leaseMmr * 0.35,
      term2GuaranteedTradeOption: dealData?.term2GuaranteedTradeOption ?? 0,
      term2EstimatedNetPayoff: dealData?.term2EstimatedNetPayoff ?? 0,
      term3: dealData?.term3 ?? 48,
      term3EstPayment: dealData?.term3EstPayment ?? 0,
      term3ResidualRate: dealData?.term3ResidualRate ?? 0.25,
      term3Residual: dealData?.term3Residual ?? leaseMmr * 0.25,
      term3GuaranteedTradeOption: dealData?.term3GuaranteedTradeOption ?? 0,
      term3EstimatedNetPayoff: dealData?.term3EstimatedNetPayoff ?? 0,
      tradeOption: dealData?.tradeOption ?? 0,
    });
    setCustomField1Name(leaseData?.adD5 ?? '');
    setCustomField2Name(leaseData?.adD6 ?? '');
    setCustomField3Name(leaseData?.adD7 ?? '');
    setCustomField4Name(leaseData?.adD8 ?? '');
    setCustomField5Name(leaseData?.adD9 ?? '');
    setMoneyDueAtInceptionField1Name(leaseData?.adD2 ?? '');
    setMoneyDueAtInceptionField2Name(leaseData?.adD3 ?? '');
    setMoneyDueAtInceptionField3Name(leaseData?.adD4 ?? '');
    setIsTradeOption50Percent(dealData?.tradeOption ? dealData?.tradeOption === 0.5 : false);
    setIsTradeOption67Percent(
      dealData?.tradeOption ? dealData?.tradeOption !== 0.5 && dealData?.tradeOption !== 0 : false
    );
    setIsTradeOptionNA(dealData?.tradeOption ? dealData?.tradeOption === 0 : true);
    setIsQuoteDataLoading(false);
  }, [quoteData, dealData]);

  useEffect(() => {
    setValue(
      'downPayment',
      watch('moneyDueAtInception') -
        watch('moneyDueAtInceptionField1') -
        watch('moneyDueAtInceptionField2') -
        watch('moneyDueAtInceptionField3') -
        watch('securityDeposit')
    );
  }, [
    watch('moneyDueAtInception'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('securityDeposit'),
  ]);

  useEffect(() => {
    setValue('term1GuaranteedTradeOption', watch('term1') * (1 - watch('tradeOption')));
    setValue('term2GuaranteedTradeOption', watch('term2') * (1 - watch('tradeOption')));
    setValue('term3GuaranteedTradeOption', watch('term3') * (1 - watch('tradeOption')));
  }, [watch('term1'), watch('term2'), watch('term3'), watch('tradeOption')]);

  useEffect(() => {
    setValue(
      'term1EstimatedNetPayoff',
      watch('term1EstPayment') * (watch('term1') - watch('term1GuaranteedTradeOption'))
    );
    setValue(
      'term2EstimatedNetPayoff',
      watch('term2EstPayment') * (watch('term2') - watch('term2GuaranteedTradeOption'))
    );
    setValue(
      'term3EstimatedNetPayoff',
      watch('term3EstPayment') * (watch('term3') - watch('term3GuaranteedTradeOption'))
    );
  }, [
    watch('term1EstPayment'),
    watch('term1'),
    watch('term1GuaranteedTradeOption'),
    watch('term2EstPayment'),
    watch('term2'),
    watch('term2GuaranteedTradeOption'),
    watch('term3EstPayment'),
    watch('term3'),
    watch('term3GuaranteedTradeOption'),
  ]);

  // First comparison item
  useEffect(() => {
    setValue('term1Residual', calculateResidual(watch('mmr'), watch('term1ResidualRate')));
  }, [watch('mmr'), watch('term1ResidualRate')]);

  useEffect(() => {
    setValue(
      'term1EstPayment',
      calculateLeasePayment(
        calculateResidual(watch('mmr'), watch('term1ResidualRate')),
        calculateAdjCapCost(watch('sellingPrice'), watch('downPayment'), [
          watch('customField1'),
          watch('customField2'),
          watch('customField3'),
          watch('customField4'),
          watch('customField5'),
          watch('serviceContract'),
        ]) + calculateAddedSalesTax(),
        watch('term1'),
        watch('apr'),
        watch('term1')
      )
    );
  }, [
    watch('mmr'),
    watch('term1ResidualRate'),
    watch('sellingPrice'),
    watch('apr'),
    watch('term1'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('customField1'),
    watch('customField2'),
    watch('customField3'),
    watch('customField4'),
    watch('customField5'),
    watch('serviceContract'),
    watch('downPayment'),
  ]);

  // Second comparison item
  useEffect(() => {
    setValue('term2Residual', calculateResidual(watch('mmr'), watch('term2ResidualRate')));
  }, [watch('mmr'), watch('term2ResidualRate')]);

  useEffect(() => {
    setValue(
      'term2EstPayment',
      calculateLeasePayment(
        calculateResidual(watch('mmr'), watch('term2ResidualRate')),
        calculateAdjCapCost(watch('sellingPrice'), watch('downPayment'), [
          watch('customField1'),
          watch('customField2'),
          watch('customField3'),
          watch('customField4'),
          watch('customField5'),
          watch('serviceContract'),
        ]) + calculateAddedSalesTax(),
        watch('term2'),
        watch('apr'),
        watch('term2')
      )
    );
  }, [
    watch('mmr'),
    watch('term2ResidualRate'),
    watch('sellingPrice'),
    watch('apr'),
    watch('term2'),
    watch('customField1'),
    watch('customField2'),
    watch('customField3'),
    watch('customField4'),
    watch('customField5'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('serviceContract'),
    watch('downPayment'),
  ]);

  // Third comparison item
  useEffect(() => {
    setValue('term3Residual', calculateResidual(watch('mmr'), watch('term3ResidualRate')));
  }, [watch('mmr'), watch('term3ResidualRate')]);

  useEffect(() => {
    setValue(
      'term3EstPayment',
      calculateLeasePayment(
        calculateResidual(watch('mmr'), watch('term3ResidualRate')),
        calculateAdjCapCost(watch('sellingPrice'), watch('downPayment'), [
          watch('customField1'),
          watch('customField2'),
          watch('customField3'),
          watch('customField4'),
          watch('customField5'),
          watch('serviceContract') + calculateAddedSalesTax(),
        ]),
        watch('term3'),
        watch('apr'),
        watch('term3')
      )
    );
  }, [
    watch('mmr'),
    watch('term3ResidualRate'),
    watch('sellingPrice'),
    watch('apr'),
    watch('term3'),
    watch('customField1'),
    watch('customField2'),
    watch('customField3'),
    watch('customField4'),
    watch('customField5'),
    watch('moneyDueAtInceptionField1'),
    watch('moneyDueAtInceptionField2'),
    watch('moneyDueAtInceptionField3'),
    watch('serviceContract'),
    watch('downPayment'),
  ]);

  // reset form after successful submission
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(watch(), { keepDirty: false });
    }
  }, [isSubmitSuccessful]);

  return {
    control,
    watch,
    setValue,
    termData,
    term1Open,
    setTerm1Open,
    term2Open,
    setTerm2Open,
    term3Open,
    setTerm3Open,
    showFullLeaseQuote,
    setShowFullLeaseQuote,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    moneyDueAtInceptionField1Name,
    moneyDueAtInceptionField2Name,
    moneyDueAtInceptionField3Name,
    handleSubmit,
    isDirty,
    errors,
    isLoading,
    reset,
    setIsLoading,
    dealData,
    setDealData,
    isQuoteDataLoading,
    isTradeOption50Percent,
    isTradeOption67Percent,
    isTradeOptionNA,
    isQuoteInfoLoading,
  };
};
