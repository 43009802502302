import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@/mui/components/MuiKendoButton';
import { DCS_BLUE } from '@/mui/theme/componentDefaults';
// components
import WsPaymentsHeaderButtonGroup from './WsPaymentsView/WsPaymentsHeaderButtonGroup';
// state
import { useAppDispatch } from '@/store/store';
import { useSalesViewCtx } from '../../SalesViewProvider';
import { useWholesaleFormCtx } from './WholesaleFormProvider';
import { salesActions } from '../../salesSlice';
// utils
import { salesService } from '@/services/salesService';
import { useSalesParams } from '../utils';
// interfaces
import { WsForm } from './formInterfaces';

// @todo move to separate file
const WsFormHeaderButtonGroup: FC = () => {
  const loc = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appRecId } = useSalesParams();
  const isAppRecId = !isNaN(Number(appRecId));

  const setIsSubmitting = useSalesViewCtx((s) => s.setIsLoading);
  const isSubmitting = useSalesViewCtx((s) => s.isLoading);
  const currentTab = useWholesaleFormCtx((s) => s.currentTab);
  const wsForm = useWholesaleFormCtx((s) => s.wsForm);
  const wsFormVehicle = useWholesaleFormCtx((s) => s.wsFormVehicle);
  const setWsFormRes = useWholesaleFormCtx((s) => s.setWsFormRes);
  const setWsForm = useWholesaleFormCtx((s) => s.setWsForm);
  const setWsFormFieldError = useWholesaleFormCtx((s) => s.setWsFormFieldError);

  // Event handlers
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (wsForm === null) return;

    if (!wsForm.salesPrice || parseFloat(wsForm.salesPrice) <= 0) {
      setWsFormFieldError('salesPrice', 'Invalid sale price. Must be greater than $0.00');
      setIsSubmitting(false);
      return;
    }

    if (!wsForm.milesOut) {
      setWsFormFieldError('milesOut', 'Miles Out must be a number.');
      setIsSubmitting(false);
      return;
    }

    if (wsFormVehicle?.milesIn && parseFloat(wsForm.milesOut) < parseFloat(wsFormVehicle.milesIn)) {
      setWsFormFieldError('milesOut', 'Miles Out must be greater than Miles In.');
      setIsSubmitting(false);
      return;
    }

    try {
      if (wsForm.saleStatus === 'Pending') {
        const result = await salesService.postWholesale(wsForm.toReq());
        if (!isAppRecId) {
          navigate(`../${result.appRecId}`, { relative: 'path', replace: true });
        } else {
          const wsFormRes = await salesService.getWholesaleForm(appRecId?.toString());
          setWsFormRes(Object.keys(wsFormRes).length === 1 ? null : wsFormRes);
        }
      } else if (wsForm.saleStatus === 'Posted') {
        // Reset just the sale-status and sale-date variables, rather than resetting all fields
        await salesService.unpostWholesale(wsForm.appRecId!);
        const newWsFormRes = await salesService.getWholesaleForm(wsForm.appRecId!.toString());
        const wsFormInst = WsForm.fromRes(newWsFormRes);
        const updatedWsForm = WsForm.new({
          ...wsForm,
          saleStatus: wsFormInst.saleStatus,
          saleDate: wsFormInst.saleDate,
        });
        setWsForm(updatedWsForm);
      }

      dispatch(salesActions.setSelectedVehicleId(wsFormVehicle?.recId));
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  let buttonLabel = '';
  if (isSubmitting) buttonLabel = 'Submitting';
  else if (!isAppRecId || wsForm?.saleStatus === 'Pending') buttonLabel = 'Post Wholesale';
  else if (isAppRecId && wsForm?.saleStatus === 'Posted') buttonLabel = 'Unpost Wholesale';
  else buttonLabel = 'Submit form';

  const isWsPaymentsRoute = loc.pathname.includes('wholesale-management/payments');
  const isWsMgmtTab = currentTab.value === 'wholesale-management' && !isWsPaymentsRoute;
  const isPostButtonDisabled = !isWsMgmtTab || isSubmitting;
  const isPaymentButtonDisabled = wsForm?.saleStatus !== 'Posted' || isSubmitting || !isWsMgmtTab;

  return (
    <Grid container direction="row" sx={{ opacity: !isWsMgmtTab ? 0 : undefined }} gap={3.5}>
      <Button
        onClick={handleSubmit}
        disabled={isPostButtonDisabled}
        sx={{ px: 6, backgroundColor: DCS_BLUE }}
      >
        {buttonLabel}
      </Button>
      <Button
        disabled={isPaymentButtonDisabled}
        sx={{ px: 6, backgroundColor: DCS_BLUE }}
        onClick={() => navigate('wholesale-management/payments')}
      >
        Payment
      </Button>
    </Grid>
  );
};

/** ### Renders title (current tab) and submit button */
const WsFormHeader: FC = () => {
  const loc = useLocation();
  // Form state
  const currentTab = useWholesaleFormCtx((s) => s.currentTab);

  // @note payment-route-specific logic will be cleaned up in a future ticket
  const isWsPaymentsRoute = loc.pathname.includes('wholesale-management/payments');
  const headerTitle = !isWsPaymentsRoute ? currentTab.label : 'Payments';

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      m={2}
      borderRadius="10px"
      wrap="nowrap"
      sx={{ backgroundColor: 'rgb(239, 240, 244)' }}
    >
      <Typography
        fontWeight={700}
        fontSize={20}
        lineHeight="22px"
        textOverflow="ellipsis"
        noWrap
        flexWrap="nowrap"
        sx={{ textWrap: 'nowrap' }}
      >
        {headerTitle}
      </Typography>

      {/* @note payment-route-specific logic will be cleaned up in a future ticket */}
      {!isWsPaymentsRoute ? <WsFormHeaderButtonGroup /> : <WsPaymentsHeaderButtonGroup />}
    </Grid>
  );
};

export default WsFormHeader;
