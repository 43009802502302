import { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
// utils
import { pollForReceipt } from '@/utils/helpers/payment';
// style
import styles from './RepayIframe.module.scss';

export interface RepayIframeProps {
  iframeUrl: string;
  paymentLogRecId: number | null;
  onComplete?: () => void;
}

export const RepayIframe: FC<RepayIframeProps> = (props) => {
  const { iframeUrl, paymentLogRecId, onComplete = () => null } = props;

  const handleMessage = useCallback(
    (e: any) => {
      if (!e.origin || !e.origin.includes('repay') || !paymentLogRecId) return;

      if (e.data === 'PAYMENT_ERROR') {
        toast.error('There was an error processing your payment');
      }

      if (e.data === 'PAYMENT_SUCCESS') {
        pollForReceipt(paymentLogRecId, onComplete);
      }
    },
    [paymentLogRecId]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <iframe className={styles.container} src={iframeUrl} title="Repay Credit Card Payment"></iframe>
  );
};
