import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
// mui
import Button, { ButtonProps } from '@mui/material/Button';
// style
import { DCS_BLUE } from '../theme/componentDefaults';

/** ### DCS-styled button
 *
 * Demos:
 *
 * - [Button Group](https://mui.com/material-ui/react-button-group/)
 * - [Button](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [Button API](https://mui.com/material-ui/api/button/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */
const LinkButton: FC<ButtonProps & NavLinkProps> = ({ children, ...props }) => (
  <Button
    variant="contained"
    component={NavLink}
    relative="path"
    {...props}
    style={{ color: 'rgb(255, 255, 255', fontSize: '14px', fontWeight: 600, ...props.style }}
    sx={{ borderRadius: '16px', backgroundColor: DCS_BLUE, height: '38px', ...props.sx }}
  >
    {children}
  </Button>
);

export default LinkButton;
