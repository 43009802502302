import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
// kendo
import { Button } from '@components';
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// style
import styles from './LeftContainer.module.scss';

/** ###  */
const VehicleStatusCard: FC = () => {
  const navigate = useNavigate();
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  if (!accountInformation) return <></>;
  const year = useAccountSelector((s) => s.accountInformation!.year);
  const make = useAccountSelector((s) => s.accountInformation!.make);
  const model = useAccountSelector((s) => s.accountInformation!.model);
  const vin = useAccountSelector((s) => s.accountInformation!.vin);
  const amountDue = useAccountSelector((s) => s.accountInformation!.amountDue);
  const nextRegularPaymentDueDate = useAccountSelector(
    (s) => s.accountInformation!.nextRegularPaymentDueDate
  );
  const paymentAmount = useAccountSelector((s) => s.accountInformation!.paymentAmount);
  const paymentSchedule = useAccountSelector((s) => s.accountInformation!.paymentSchedule);
  const totalPayoff = useAccountSelector((s) => s.accountInformation!.totalPayoff);
  const accountNum = useAccountSelector((s) => s.accountInformation!.accountNum);
  const colType = useAccountSelector((s) => s.accountInformation!.colType);

  const disableTakePayment =
    (accountInformation.status === 'Charged Off' && accountInformation.colType === 'LS') ||
    accountInformation.status === 'Inactive';

  return (
    <div className={styles.vehicleCard}>
      <Spacer size={5} />
      <div className={styles.title}>
        {year} {make} {model}
      </div>
      <Spacer size={12} />
      <div>
        <span style={{ fontWeight: 'bold' }}>VIN:</span> {vin}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Account #:</span> {accountNum}
      </div>
      <Spacer size={20} />
      <Button
        label="Take A Payment"
        style={{ width: '190px' }}
        onClick={() => navigate('payment')}
        disabled={disableTakePayment}
      />
      <Spacer size={28} />
      <div className={styles.paymentData}>
        <span>
          <div className={styles.type}>Next Payment</div>
          <div className={styles.amount}>{formatCurrency(amountDue)}</div>
          <div className={styles.details}>Due {formatDate(nextRegularPaymentDueDate)}</div>
        </span>
        <span className={styles.divider}></span>
        <span>
          <div className={styles.type}>{colType === 'LS' ? 'Lease Buyout' : 'Total Payoff'}</div>
          <div className={styles.amount}>{formatCurrency(totalPayoff)}</div>
          <div className={styles.details}>
            ${paymentAmount} / {paymentSchedule}
          </div>
        </span>
      </div>
      <Spacer size={10} />
    </div>
  );
};

export default VehicleStatusCard;
