// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useSalesDetailCtx } from "@/features/Sales/detailView/SalesDetailProvider";
// interfaces
import { ValidationItem } from "./interfaces";

const useSubmitValidationErrors = () => {
  // const buyer = useSalesSelector((s) => s.saleData.buyer);

  const years = useSalesSelector((s) => s.saleData.buyer?.b_RYEARS);
  const months = useSalesSelector((s) => s.saleData.buyer?.b_RMONTHS);
  const isInvalidResidencyDuration = !years && !months;

  const purPrice = useSalesDetailCtx((s) => s.vehicleCost.value?.purPrice);
  const isInvalidPurPrice = !purPrice;

  const bookValue = useSalesDetailCtx((s) => s.suggestedSalesInfo.value?.bookValue1);
  const isInvalidBookValue = !bookValue;

  // console.log("saleData.buyer", buyer); // @note need this for finding the other fields in another ticket

  const submitEvalValidationErrors: ValidationItem[] = [];

  isInvalidBookValue &&
    submitEvalValidationErrors.push(
      new ValidationItem({
        key: "bookValue1",
        provider: "useSalesDetailCtx",
        rootStatePath: "suggestedSalesInfo.value",
        value: bookValue,
        label: "Book Value/MMR",
        pageLocation: "Inventory page > Suggested Sale/Sold Vehicle tab",
      })
    );
  isInvalidResidencyDuration &&
    submitEvalValidationErrors.push(
      new ValidationItem({
        key: "[b_RYEARS, b_RMONTHS]",
        provider: "useSalesSelector",
        rootStatePath: "saleData.buyer",
        value: `{ years: ${years}, months: ${months}`,
        label: "Length of Residency",
        pageLocation: "Application > Buyer > Residency section",
      })
    );

  isInvalidPurPrice &&
    submitEvalValidationErrors.push(
      new ValidationItem({
        key: "purPrice",
        provider: "useSalesDetailCtx",
        rootStatePath: "vehicleCost.value",
        value: purPrice,
        label: "Purchase Price",
        pageLocation: "Inventory > Vehicle tab > Vehicle Cost section",
      })
    );

  return submitEvalValidationErrors.length > 0 ? submitEvalValidationErrors : null;
};

export default useSubmitValidationErrors;
