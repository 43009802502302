// mui
import { DCS_BLUE } from '@/mui/theme/componentDefaults';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

export const LhPhSectionHeader = (props: {
  title: string;
  showBar?: boolean;
  children?: React.ReactNode;
}) => {
  const { showBar = true, title } = props;
  return (
    <Grid
      direction="row"
      wrap="nowrap"
      container
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      fontFamily="Inter"
    >
      <Grid gap={1}
        sx={{
          color: DCS_BLUE,
          fontSize: '16px',
          flexShrink: '0',
          fontWeight: '700',
          margin: '0',
        }}
      >
        {title}
      </Grid>
      {props.showBar && (
        <Grid
          flex={1}
          sx={{
            backgroundColor: 'rgb(204, 204, 204)',
            // width: '80%',
            margin: '5px 20px 5px 20px',
            height: '1.5px',
            borderRadius: '16px',
          }}
        ></Grid>
      )}
      {props.children && props.children}
    </Grid>
  );
};
