import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
// components
import WsFormBody from '../layoutComponents/WsFormBody';
import WsFormSection from '../layoutComponents/WsFormSection';
import PaymentsSummary from './PaymentsSummary';
import WsCollectForm from './WsCollectForm';
// state
import WsPmtsFormProvider from './WsCollectForm/WsPmtsFormProvider';

/** ### Displays payment details about app and list of sent payments */
const WsPaymentsViewLayout: FC = () => (
  <WsFormBody>
    <WsFormSection>
      <Outlet />
    </WsFormSection>
  </WsFormBody>
);

const WsPaymentsView: FC = () => (
  <WsPmtsFormProvider>
    <Routes>
      <Route element={<WsPaymentsViewLayout />}>
        <Route path="" element={<PaymentsSummary />} />

        <Route path="collect/*">
          <Route path="" element={<WsCollectForm />} />

          {/* Fallback */}
          <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
        </Route>
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
    </Routes>
  </WsPmtsFormProvider>
);

export default WsPaymentsView;
