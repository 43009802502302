import { z } from 'zod';

export const QueueVisibility = z.enum(['Public', 'Personal', 'Company']);
export type QueueVisibility = z.infer<typeof QueueVisibility>;
export const SortDirection = z.enum(['Ascending', 'Descending']);
export type SortDirection = z.infer<typeof SortDirection>;
export const QueueType = z.enum(['Regular Accounts', 'Payment Arrangements', 'Appointments']);
export type QueueType = z.infer<typeof QueueType>;

export const CollectionsQueueForm = z.object({
  // @note LEFT COLUMN //
  // queue type - convert to int on submit
  queueType: QueueType.default('Regular Accounts'),
  // visibility
  visibility: z.string().default(QueueVisibility.enum.Company),
  // queue name
  queueName: z.string().default(''),
  // days from
  daysFrom: z.number().gte(0).default(0),
  // days to
  daysTo: z.number().gte(0).default(0),
  // alpha from
  alphaFrom: z.string().default('A'),
  // alpha to
  alphaTo: z.string().default('Z'),
  // sort by
  sortColumn: z.string().nullish().default('lastPaymentDate'),
  // Direction
  sortDirection: SortDirection.default('Descending'),
  // Auto Text To Pay
  autoTTP: z.boolean().default(false),
  // Auto TTP Local Time
  autoTTPLocalTime: z.date().nullish().default(null),
  // (text box)
  autoTTPTemplate: z.string().default(''),
  //
  // @note RIGHT COLUMNS //
  // @note RIGHT COLUMN 1 //
  // Out for Repo
  includeOutForRepo: z.boolean().default(false),
  // Recovered
  includeInOurPossession: z.boolean().default(false),
  // Field Calls
  includeFieldCall: z.boolean().default(false),
  // Payment Arrangements
  includePaymentArrangements: z.boolean().default(false),
  // Appointments
  includeAppointments: z.boolean().default(false),
  // @note RIGHT COLUMN 2 //
  includeFinanced: z.boolean().default(true),
  includeLease: z.boolean().default(false),
  includeSidenotes: z.boolean().default(false),
  includeDepositDown: z.boolean().default(false),
});
export type CollectionsQueueForm = z.infer<typeof CollectionsQueueForm>;

/**
{
    "queueName": "Virginia All Q",
    "queueType": 0,
    "visibility": "Company",
    "includeOutForRepo": false,
    "includeInOurPossession": false,
    "includeFieldCall": false,
    "includeSidenotes": true,
    "includeFinanced": true,
    "includeLease": true,
    "includeDepositDown": false,
    "includePaymentArrangements": false,
    "includeAppointments": false,
    "daysFrom": -300,
    "daysTo": 999,
    "alphaFrom": "A",
    "alphaTo": "Z",
    "userId": null,
    "autoTTP": false,
    "autoTTPLocalTime": null,
    "autoTTPTemplate": "test2",
    "autoTTPLastRunTime": null,
    "total": 116,
    "sortColumn": "daysLate",
    "sortDirection": "Descending",
    "percentage": 0.39,
    "queueTypeString": "Regular Accounts"
}
 */
