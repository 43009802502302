import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { TextInput } from '@/components';
// state
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

/** ###  */
const MpdFieldGroup: FC = () => {
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);

  return (
    <>
      <Controller
        name="Mpd.AccountNumber"
        control={control}
        rules={{
          required: true,
          pattern: { value: /^\d{5,17}$/, message: 'Format is incorrect' },
        }}
        render={({ field }) => (
          <TextInput
            label="Account Number"
            required
            errors={errors.Mpd?.AccountNumber?.message || !!errors.Mpd?.AccountNumber}
            {...field}
          />
        )}
      />
      <Controller
        name="Mpd.RoutingNumber"
        control={control}
        rules={{
          required: true,
          pattern: { value: /^\d{9}$/, message: 'Format is incorrect' },
        }}
        render={({ field }) => (
          <TextInput
            label="Routing Number"
            required
            errors={errors.Mpd?.RoutingNumber?.message || !!errors.Mpd?.RoutingNumber}
            {...field}
          />
        )}
      />
    </>
  );
};

export default MpdFieldGroup;
