import useCtxFactory from '@/utils/ctxState/useCtxFactory';
import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
// interfaces

export interface ICreditEducationCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  showCreditRepairForm: boolean;
  setShowCreditRepairForm: (_: boolean) => void;
}

const CreditEducationCtx = createContext<ICreditEducationCtx | null>(null);

const CreditEducationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCreditRepairForm, setShowCreditRepairForm] = useState(false);

  return (
    <CreditEducationCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        showCreditRepairForm,
        setShowCreditRepairForm,
      }}
    >
      {children}
    </CreditEducationCtx.Provider>
  );
};

export default CreditEducationProvider;

export const useCreditEducationCtx = useCtxFactory(CreditEducationCtx);
