import { FC } from "react";
// mui
import Typography from "@mui/material/Typography";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
// utils
import { formatCurrency } from "@/utils/helpers/general";

/** ### Kendo cell */
const CurrencyCell: FC<GridCellProps> = ({ dataItem, field, style }) => {
  if (!field) throw new Error(`CurrencyCell: invalid field. Input: "${field}"`);
  if (dataItem[field] === undefined)
    throw new Error(`CurrencyCell: undefined value. Field: "${field}" dataItem: ${JSON.stringify(dataItem)}`);
  const value: number = dataItem[field];
  if (typeof value !== "number" && value !== null)
    throw new Error(
      `CurrencyCell: 'value' is incorrect type, must use 'number'. Value: [${value}] Field: '${field}' 'type': ${typeof value}`
    );

  const isNull = value === null;
  const valueFmt = isNull ? "-" : formatCurrency(value);

  return (
    <td className="k-table-td" style={{ ...style, textAlign: "end" }}>
      <Typography component="span" variant="tableCell">
        {valueFmt}
      </Typography>
    </td>
  );
};

export default CurrencyCell;
