import { FC } from 'react';
// mui
import MuiKendoButton from '@/mui/components/MuiKendoButton';
// state
import { useCollectionQueuesCtx } from './CollectionQueuesProvider';

/** ###  */
const AddNewButton: FC = () => {
  const setIsModalOpen = useCollectionQueuesCtx((s) => s.setIsModalOpen);

  // Event handlers
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return <MuiKendoButton onClick={handleOpenModal}>Add New +</MuiKendoButton>;
};

export default AddNewButton;
