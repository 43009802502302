import { useEffect } from 'react';

/* 
    When clicked into an account / sale / inventory / etc, navigating through sub-pages of that feature *should* be going through the router.
    (It's not doing so in all cases, but ideally anything that is navigating via state should be converted to use the router)
    This means that a lot of browser history can be built up within one item. E.G. Clicking into a sale and clicking through all of the tabs for that sale
    
    DCL wants the back button to take the user directly back to the relevant main page (accounts list, sales list, inventory list, etc),
    instead of having to click back several times to get back through all of the sub-pages they clicked into. That is the intention of this hook

    Example usage within the sale detail router:

    const navigate = useNavigate();
    // ***Need to wrap in useCallback***, otherwise it will tear down and rebuild the effect every time, which makes this hook not work
    const handleBackPress = useCallback(() => navigate('/sales'), []);
    useBackListener(handleBackPress);
*/
export const useBackListener = (callback: () => void) => {
  // It seems like this should be using react-router-dom APIs, but all of the relevant ones seem to be unstable or have other issues, and this works fine
  // https://stackoverflow.com/questions/71369320/how-to-controling-browser-back-button-with-react-router-dom-v6
  useEffect(() => {
    window.addEventListener('popstate', callback);
    return () => {
      window.removeEventListener('popstate', callback);
    };
  }, [callback]);
};
