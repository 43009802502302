import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  GridCustomCellProps,
  GridNoRecords,
  Loader,
} from '@progress/kendo-react-all';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modal/Modal';
import { TextInput } from '@/components/inputs/text/TextInput';
// components
import AddVendorModal from '@/components/addVendorModal/addVendorModal';
// state
// utils
import { inventoryService } from '@/services/inventoryService';
import { useServerGrid } from '@/hooks/useServerGrid/useServerGrid';
// interfaces
import { SetState } from '@/interfaces/utilityTypes';
import { ServerGridOptions } from '@/interfaces/ServerGrids';
import { IVendor } from '@/interfaces/Vendors';
// style
import styles from './selectVendorModal.module.scss';

const VendorActionCell: FC<GridCustomCellProps & { onEdit: () => void; onSelect: () => void }> = ({
  onEdit,
  onSelect,
  ...rest
}) => (
  <td {...rest.tdProps}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Button label="Select" secondary onClick={onSelect} />
      <Button label="Edit" secondary onClick={onEdit} />
    </div>
  </td>
);

const fetchFunction = async (options: ServerGridOptions) =>
  await inventoryService.getVendorsForGrid(options);

/** @deprecated needs refactor */
export const SelectVendorModal: FC<{
  opened: boolean;
  setOpened: SetState<boolean>;
  selectVendor: (vendor: IVendor) => void;
}> = ({ opened, setOpened, selectVendor }) => {
  const {
    loading,
    data,
    total,
    page,
    sort,
    onPageChange,
    onSortChange,
    pageable,
    search,
    setSearch,
    resetGridOptions,
    reload,
  } = useServerGrid<IVendor>({
    fetchFunction: fetchFunction,
    initialSortOptions: [{ field: 'vendorName', dir: 'asc' }],
  });

  const [selectedVendor, setSelectedVendor] = useState<IVendor | null>(null);
  const [addVendorModalOpen, setAddVendorModalOpened] = useState<boolean>(false);

  // Event handlers
  const closeModal = () => {
    setSelectedVendor(null);
    resetGridOptions();
    setOpened(false);
  };
  const chooseVendor = (vendor?: IVendor) => {
    if (vendor) {
      selectVendor(vendor);
      closeModal();
    }
  };
  const createVendor = () => {
    setSelectedVendor(null);
    setAddVendorModalOpened(true);
  };
  const editVendor = (vendor?: IVendor) => {
    if (vendor) {
      setSelectedVendor(vendor);
      setAddVendorModalOpened(true);
    }
  };

  // Unselect vendor if the search field is changed
  useEffect(() => {
    setSelectedVendor(null);
  }, [search]);

  useEffect(() => {
    if (opened && !addVendorModalOpen) {
      reload();
    }
    // @todo use stable refs for deps array
  }, [opened, addVendorModalOpen]);

  // @todo move components out of render body
  const ActionCell: FC<GridCustomCellProps> = ({ dataItem, ...props }) => (
    <VendorActionCell
      onEdit={() => editVendor(dataItem)}
      onSelect={() => chooseVendor(dataItem)}
      dataItem={dataItem}
      {...props}
    />
  );

  return (
    <>
      <Modal
        isOpen={opened}
        centerModal
        closeButton
        onCloseButtonClick={() => closeModal()}
        onDimmerClick={() => closeModal()}
        panelStyle={{ maxWidth: '80vw', maxHeight: '95vh' }}
      >
        <div className={styles.header}>Select Vendor</div>
        <div className={styles.actionBar}>
          <TextInput
            placeholder="Search"
            defaultValue=""
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchBox}
          />
          <Button label="Add New" onClick={createVendor} />
        </div>
        <div className={styles.gridContainer}>
          {loading && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              p="40px 0px"
              // @todo remove unnecessary style rules
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={1}
              sx={{ bgcolor: 'rgba(204, 204, 204, 0.4)' }}
            >
              <Loader themeColor="primary" size="large" />
            </Grid>
          )}
          <KendoGrid
            className={styles.grid}
            data={data}
            total={total}
            scrollable="scrollable"
            sortable={true}
            sort={sort}
            onSortChange={onSortChange}
            pageable={pageable}
            skip={page.skip}
            take={page.take}
            onPageChange={onPageChange}
          >
            <GridNoRecords>No records found</GridNoRecords>
            <GridColumn field="vendorName" title="Vendor Name" key="vendorName" />
            <GridColumn field="accountNumber" title="Account Number" key="accountNumber" />
            <GridColumn cell={ActionCell} width="200" />
          </KendoGrid>
        </div>
      </Modal>
      <AddVendorModal
        opened={addVendorModalOpen}
        setOpened={setAddVendorModalOpened}
        vendor={selectedVendor}
      />
    </>
  );
};
