// https://dev.to/ivanzm123/dont-use-enums-in-typescript-they-are-very-dangerous-57bh

import { z } from 'zod';

/** `paidIn` column in the `payment` table (`payment.paidIn`) */
export const paidInValues = [
  'ACH',
  'AS Conversion',
  'Auto Pay',
  'BlytzPay',
  'Cash',
  'Check',
  'Conversion',
  'Credit Card',
  'Customer Credit',
  'CustPay',
  'Dealer',
  'Draft',
  'Expense',
  'Gift Card',
  'In Mail',
  'In person',
  'In-Person',
  'Kiosk',
  'M Gram',
  'Mail',
  'Mail/Drop Box',
  'Money Order',
  'MoneyGram',
  'MSP',
  'Night drop',
  'No Payment',
  'On Phone',
  'Online',
  'OpenEdge',
  'Other',
  'PH',
  'Phone',
  'Recurring',
  'Sidenote',
  'SIVR',
  'Speed Pay',
  'Suspense',
  'System',
  'Wire Transfer',
] as const;
export type PaidIn = (typeof paidInValues)[number];

// Outlier items/typos for `paidBy`: [AC, AD, American Expres, BC, C, C-List (Conv), CASHIER CHECK, CC, CL, CR, DB , DP, EF, FE, FG, K, Legal(3), Legal(A), Legal(R), LF, MO, NL, paidBy, PD, PMCC, PO, PPAH, PPCC, PVAH, PVCC, PVCK, RA, RE, RP, VP, WO,]
/** `paidBy` column in the `payment` table (`payment.paidBy`)*/
const paidByValues = [
  'ACH',
  'Adjustment',
  'AMERICAN EXPRESS',
  'ATM',
  'Audit',
  'Bump Payment',
  'Cash',
  "Cashier's Check",
  'Cashiers Check',
  'Charge',
  'Charge Off',
  'Check',
  'Credit Card',
  'Credit Pay-Off',
  'Debit',
  'DEBIT CARD',
  'DINERS CLUB',
  'DISCOVER',
  'Draft',
  'FEX EPS',
  'FMV',
  'INTER COMPANY TRANSF',
  'Internal',
  'IVRA',
  'IVRC',
  'Manual CC',
  'MASTER CARD',
  'Money Order',
  'MONEYGRAM',
  'No Payment',
  'Non Cash',
  'None',
  'NSF Check',
  'OpenEdge',
  'OPTIMA CARD',
  'Other',
  'Payment',
  'ReActive',
  'REFI',
  'ReImbursement',
  'Release Possession',
  'REPAY',
  'REPOSSESSION PROCEED',
  'RESTOCK REPO',
  'REVERSE PAYMENT',
  'Rewrite',
  'Take Possession',
  'Trade',
  'VISA',
  'Walk In',
  'WESTERN UNION',
];
export type PaidBy = (typeof paidByValues)[number];

/** @deprecated replace with zod enum */
export enum PaymentType {
  CreditCard = 'Credit Card',
  Ach = 'ACH',
  Cash = 'Cash',
  Check = 'Check',
  ManualCC = 'Manual CC',
  MoneyOrder = 'Money Order',
  CashiersCheck = "Cashier's Check",
  Other = 'Other',
}

/** @deprecated replace with zod enum */
export enum CardProcessorName {
  OpenEdge = 'OpenEdge',
  BlytzPay = 'BlytzPay',
  Repay = 'REPAY',
}

/** @deprecated replace with zod enum */
export enum CardProcessor {
  OpenEdge = 1,
  BlytzPay = 2,
  Repay = 3,
}

/** @deprecated replace with zod enum */
export enum AchAccountType {
  NewAccount = 'New Account',
  AccountOnFile = 'Account on File',
}

/** @deprecated replace with zod enum */
export enum CcAccountType {
  NewCard = 'New Card',
  CardOnFile = 'Card on File',
}

/** @deprecated replace with zod enum */
export enum RepayFeeModel {
  NOFEE = 'NOFEE',
  FEE_CAN_WAIVE = 'FEE_CAN_WAIVE',
  FEE_NO_WAIVE = 'FEE_NO_WAIVE',
}

export const PaymentInterval = z.enum(['Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly']);
export type PaymentInterval = z.infer<typeof PaymentInterval>;

/** @deprecated replace with `ZTransactionType` */
export enum TransactionType {
  Payment = 'Payment',
  Payoff = 'Payoff',
}
export const ZTransactionType = z.enum(['Payment', 'Payoff']);
export type ZTransactionType = z.infer<typeof ZTransactionType>;

/** @deprecated replace with `ZPaymentAcceptedIn` */
export enum PaymentAcceptedIn {
  InPerson = 'In-Person',
  Phone = 'Phone',
  Mail = 'Mail',
  NightDrop = 'Night drop',
}
export const ZPaymentAcceptedIn = z.enum(['In-Person', 'Phone', 'Mail', 'Night drop']);
export type ZPaymentAcceptedIn = z.infer<typeof ZPaymentAcceptedIn>;
