import { useEffect } from 'react';
// components
import {
  DmsNumberInput,
  DmsCheckbox,
  DmsRadioButtonGroup,
  DmsTextInput,
  DmsDropDown,
} from '@components/old';
// utils
import { states } from '@components/old/dmsDropDown/states';
import { lowerCaseLetters } from '@/utils/helpers/general';
// interfaces
import { SavedPaymentMethod } from '@/interfaces/CreditCard';
import { CcAccountType } from '@/enums';
// style
import styles from './SavedCreditCard.module.scss';

type CustomValue = { display: string; value: string };
export interface SavedCreditCardProps {
  convenienceFee?: number;
  waiveFee: boolean;
  saveCard: boolean;
  newCard: boolean;
  firstName?: string;
  lastName?: string;
  zip?: string;
  address?: string;
  city?: string;
  state: string | null | undefined;
  savedPaymentMethods: SavedPaymentMethod[];
  mpdId?: string;
  useCardOnFile: boolean;
  paymentProvider: string;
  canWaiveFee: boolean;
  onWaiveFee: (v: boolean) => void;
  onSaveCard: (v: boolean) => void;
  onNewCard: (v: boolean) => void;
  onFirstName: (v: string) => void;
  onLastName: (v: string) => void;
  onZip: (v: string) => void;
  onAddress: (v: string) => void;
  onCity: (v: string) => void;
  onState: (v: string) => void;
  onMpdId?: (v: string) => void; // if not present, cannot save payment methods or have saved payment methods (misc payment)
  onSetFormErrors: (v: boolean) => void;
}

export const SavedCreditCard: React.FunctionComponent<SavedCreditCardProps> = (props) => {
  const {
    convenienceFee,
    waiveFee,
    saveCard,
    newCard,
    firstName,
    lastName,
    zip,
    address,
    city,
    state,
    savedPaymentMethods = [],
    mpdId,
    useCardOnFile,
    paymentProvider,
    onWaiveFee,
    onSaveCard,
    onNewCard,
    onFirstName,
    onLastName,
    onZip,
    onAddress,
    onCity,
    onState,
    onMpdId,
    onSetFormErrors,
  } = props;

  const canSavePaymentMethod = !!onMpdId;

  const savedCards = savedPaymentMethods.filter((card) => {
    // Probably just bad old testing data, but I've seen some bunk saved payment methods. Filter them out
    return (
      card.mpdType === 'CreditCard' &&
      lowerCaseLetters(card.cardProcessor) === lowerCaseLetters(paymentProvider) &&
      card.fName &&
      card.lName &&
      card.last4 &&
      card.mpdId &&
      card.isActive
    );
  });

  const {
    fName: savedCardFirstName,
    lName: savedCardLastName,
    zip: savedCardZip,
    address: savedCardAddress,
    city: savedCardCity,
    state: savedCardState,
  } = savedCards[0] ?? {};

  useEffect(() => {
    if (mpdId) {
      onFirstName(savedCardFirstName!);
      onLastName(savedCardLastName!);
      onZip(savedCardZip!);
      onAddress(savedCardAddress!);
      onCity(savedCardCity!);
      onState(savedCardState!);
    }
  }, [mpdId]);

  useEffect(() => {
    if (!canSavePaymentMethod) return;
    if (newCard) {
      onMpdId('');
    } else if (savedCards.length && savedCards[0]?.mpdId) {
      onMpdId(savedCards[0].mpdId);
    }
  }, [newCard, paymentProvider]);

  useEffect(() => {
    if (newCard && canSavePaymentMethod) {
      onSaveCard(true);
    }
    if (!state) {
      onState(states[0]!);
    }
  }, []);

  useEffect(() => {
    const missingNameError = !firstName || !lastName;
    const missingAddressError = newCard && (!zip || !address || !city || !state);
    if (missingNameError || missingAddressError) {
      onSetFormErrors(true);
    } else {
      onSetFormErrors(false);
    }
  }, [firstName, lastName, zip, address, city, state]);

  const handleErrorMessage = (input: string | number | undefined) => {
    if (!input) {
      return 'Field cannot be empty';
    } else {
      return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>Credit Card Convenience Fee</div>
      <div className={styles.inputRow}>
        <DmsNumberInput
          inputLabel="Fee Amount"
          inputStyles={{ width: '70px' }}
          value={convenienceFee}
          fixedDecimalScale
          decimalScale={2}
          readOnly
        />
        {props.canWaiveFee && (
          <DmsCheckbox
            checkboxLabel="Waive Fee"
            onChange={() => onWaiveFee(!waiveFee)}
            checked={waiveFee}
          />
        )}
      </div>
      {canSavePaymentMethod && useCardOnFile && (
        <DmsRadioButtonGroup
          inputLabel="Transaction"
          buttons={[CcAccountType.NewCard, CcAccountType.CardOnFile]}
          radioButtonGroupName="Transaction"
          required
          onChange={(e) => {
            if (e.target.value === CcAccountType.NewCard) {
              onNewCard(true);
            } else {
              onNewCard(false);
            }
          }}
        />
      )}
      {newCard ? (
        <div className={styles.flexGap}>
          {canSavePaymentMethod && (
            <DmsCheckbox
              checkboxLabel="Save Card"
              onChange={() => onSaveCard(!saveCard)}
              checked={saveCard}
            />
          )}
          <div className={styles.subHeader}>Name on Card</div>
          <DmsTextInput
            inputLabel="First Name"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onFirstName(e.target.value)}
            value={firstName}
            errorMessage={handleErrorMessage(firstName)}
          />
          <DmsTextInput
            inputLabel="Last Name"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onLastName(e.target.value)}
            value={lastName}
            errorMessage={handleErrorMessage(lastName)}
          />
          <div className={styles.subHeader}>Billing Address of Card</div>
          <DmsTextInput
            inputLabel="Zip"
            inputStyles={{ width: '70px' }}
            value={zip}
            onChange={(e) => {
              onZip(e.target.value);
            }}
            errorMessage={handleErrorMessage(zip)}
          />
          <DmsTextInput
            inputLabel="Address"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onAddress(e.target.value)}
            value={address}
            errorMessage={handleErrorMessage(address)}
          />
          <DmsTextInput
            inputLabel="City"
            inputStyles={{ width: '100%' }}
            onChange={(e) => onCity(e.target.value)}
            value={city}
            errorMessage={handleErrorMessage(city)}
          />
          <DmsDropDown
            label="State"
            values={states}
            value={state as string}
            inputContainerStyle={{ width: '100%' }}
            onChange={(e) => onState(e.target.value)}
          />
        </div>
      ) : (
        <div className={styles.flexGap}>
          <div className={styles.subHeader}>Cards on File</div>
          {savedCards && savedCards.length > 0 && (
            <>
              <DmsDropDown
                label="Cards"
                customValues={savedCards.map(
                  (x) =>
                    ({
                      display: x.last4,
                      value: x.mpdId,
                    } as CustomValue)
                )}
                inputContainerStyle={{ width: '100%' }}
                value={mpdId}
                onChange={(e) => {
                  onMpdId!(e.target.value);
                }}
              />
              <div className={styles.flexGap}>
                <div className={styles.subHeader}>Name on Card</div>
                <DmsTextInput
                  inputLabel="First Name"
                  inputStyles={{ width: '100%' }}
                  value={savedCardFirstName}
                  errorMessage={handleErrorMessage(firstName)}
                  disabled
                />
                <DmsTextInput
                  inputLabel="Last Name"
                  inputStyles={{ width: '100%' }}
                  value={savedCardLastName}
                  errorMessage={handleErrorMessage(lastName)}
                  disabled
                />
                {/* <div className={styles.subHeader}>Billing Address of Card</div>
                <DmsTextInput
                  inputLabel="Zip"
                  inputStyles={{ width: '70px' }}
                  value={savedCardZip}
                  errorMessage={handleErrorMessage(zip)}
                  disabled
                />
                <DmsTextInput
                  inputLabel="Address"
                  inputStyles={{ width: '100%' }}
                  value={savedCardAddress}
                  errorMessage={handleErrorMessage(address)}
                  disabled
                />
                <DmsTextInput
                  inputLabel="City"
                  inputStyles={{ width: '100%' }}
                  value={savedCardCity}
                  errorMessage={handleErrorMessage(city)}
                  disabled
                />
                <DmsDropDown
                  label="State"
                  values={states}
                  value={savedCardState}
                  inputContainerStyle={{ width: '100%' }}
                  disabled
                /> */}
              </div>
            </>
          )}
          {savedCards.length === 0 && <div>No cards on file</div>}
        </div>
      )}
    </div>
  );
};
